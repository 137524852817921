// Template for wrapping the Closure Compiler output as a UMD module
// Based on https://github.com/umdjs/umd/blob/master/templates/returnExports.js
(function (root, factory) {
    if (typeof define === 'function' && define.amd) {
        define([], factory);
    } else if (typeof module === 'object' && module.exports) {
        module.exports = factory();
    } else {
        root.returnExports = factory();
  }
}(typeof self !== 'undefined' ? self : this, (function () {
    this.CLOSURE_NO_DEPS = true;
    var $jscomp={scope:{},checkStringArgs:function(a,b,c){if(null==a)throw new TypeError("The 'this' value for String.prototype."+c+" must not be null or undefined");if(b instanceof RegExp)throw new TypeError("First argument to String.prototype."+c+" must not be a regular expression");return a+""}};
$jscomp.defineProperty="function"==typeof Object.defineProperties?Object.defineProperty:function(a,b,c){if(c.get||c.set)throw new TypeError("ES3 does not support getters and setters.");a!=Array.prototype&&a!=Object.prototype&&(a[b]=c.value)};$jscomp.getGlobal=function(a){return"undefined"!=typeof window&&window===a?a:"undefined"!=typeof global&&null!=global?global:a};$jscomp.global=$jscomp.getGlobal(this);
$jscomp.polyfill=function(a,b,c,d){if(b){c=$jscomp.global;a=a.split(".");for(d=0;d<a.length-1;d++){var e=a[d];e in c||(c[e]={});c=c[e]}a=a[a.length-1];d=c[a];b=b(d);b!=d&&null!=b&&$jscomp.defineProperty(c,a,{configurable:!0,writable:!0,value:b})}};
$jscomp.polyfill("String.prototype.repeat",function(a){return a?a:function(a){var b=$jscomp.checkStringArgs(this,null,"repeat");if(0>a||1342177279<a)throw new RangeError("Invalid count value");a|=0;for(var d="";a;)if(a&1&&(d+=b),a>>>=1)b+=b;return d}},"es6-impl","es3");$jscomp.findInternal=function(a,b,c){a instanceof String&&(a=String(a));for(var d=a.length,e=0;e<d;e++){var f=a[e];if(b.call(c,f,e,a))return{i:e,v:f}}return{i:-1,v:void 0}};
$jscomp.polyfill("Array.prototype.findIndex",function(a){return a?a:function(a,c){return $jscomp.findInternal(this,a,c).i}},"es6-impl","es3");$jscomp.polyfill("Array.prototype.find",function(a){return a?a:function(a,c){return $jscomp.findInternal(this,a,c).v}},"es6-impl","es3");
$jscomp.polyfill("String.prototype.endsWith",function(a){return a?a:function(a,c){var b=$jscomp.checkStringArgs(this,a,"endsWith");a+="";void 0===c&&(c=b.length);for(var e=Math.max(0,Math.min(c|0,b.length)),f=a.length;0<f&&0<e;)if(b[--e]!=a[--f])return!1;return 0>=f}},"es6-impl","es3");
$jscomp.polyfill("String.prototype.startsWith",function(a){return a?a:function(a,c){var b=$jscomp.checkStringArgs(this,a,"startsWith");a+="";for(var e=b.length,f=a.length,g=Math.max(0,Math.min(c|0,b.length)),h=0;h<f&&g<e;)if(b[g++]!=a[h++])return!1;return h>=f}},"es6-impl","es3");var COMPILED=!0,goog=goog||{};goog.global=this;goog.isDef=function(a){return void 0!==a};
goog.exportPath_=function(a,b,c){a=a.split(".");c=c||goog.global;a[0]in c||!c.execScript||c.execScript("var "+a[0]);for(var d;a.length&&(d=a.shift());)!a.length&&goog.isDef(b)?c[d]=b:c=c[d]&&Object.prototype.hasOwnProperty.call(c,d)?c[d]:c[d]={}};
goog.define=function(a,b){var c=b;COMPILED||(goog.global.CLOSURE_UNCOMPILED_DEFINES&&Object.prototype.hasOwnProperty.call(goog.global.CLOSURE_UNCOMPILED_DEFINES,a)?c=goog.global.CLOSURE_UNCOMPILED_DEFINES[a]:goog.global.CLOSURE_DEFINES&&Object.prototype.hasOwnProperty.call(goog.global.CLOSURE_DEFINES,a)&&(c=goog.global.CLOSURE_DEFINES[a]));goog.exportPath_(a,c)};goog.DEBUG=!0;goog.LOCALE="en";goog.TRUSTED_SITE=!0;goog.STRICT_MODE_COMPATIBLE=!1;goog.DISALLOW_TEST_ONLY_CODE=COMPILED&&!goog.DEBUG;
goog.ENABLE_CHROME_APP_SAFE_SCRIPT_LOADING=!1;goog.provide=function(a){if(goog.isInModuleLoader_())throw Error("goog.provide can not be used within a goog.module.");if(!COMPILED&&goog.isProvided_(a))throw Error('Namespace "'+a+'" already declared.');goog.constructNamespace_(a)};goog.constructNamespace_=function(a,b){if(!COMPILED){delete goog.implicitNamespaces_[a];for(var c=a;(c=c.substring(0,c.lastIndexOf(".")))&&!goog.getObjectByName(c);)goog.implicitNamespaces_[c]=!0}goog.exportPath_(a,b)};
goog.VALID_MODULE_RE_=/^[a-zA-Z_$][a-zA-Z0-9._$]*$/;
goog.module=function(a){if(!goog.isString(a)||!a||-1==a.search(goog.VALID_MODULE_RE_))throw Error("Invalid module identifier");if(!goog.isInModuleLoader_())throw Error("Module "+a+" has been loaded incorrectly. Note, modules cannot be loaded as normal scripts. They require some kind of pre-processing step. You're likely trying to load a module via a script tag or as a part of a concatenated bundle without rewriting the module. For more info see: https://github.com/google/closure-library/wiki/goog.module:-an-ES6-module-like-alternative-to-goog.provide.");if(goog.moduleLoaderState_.moduleName)throw Error("goog.module may only be called once per module.");
goog.moduleLoaderState_.moduleName=a;if(!COMPILED){if(goog.isProvided_(a))throw Error('Namespace "'+a+'" already declared.');delete goog.implicitNamespaces_[a]}};goog.module.get=function(a){return goog.module.getInternal_(a)};goog.module.getInternal_=function(a){if(!COMPILED){if(a in goog.loadedModules_)return goog.loadedModules_[a];if(!goog.implicitNamespaces_[a])return a=goog.getObjectByName(a),null!=a?a:null}return null};goog.moduleLoaderState_=null;
goog.isInModuleLoader_=function(){return null!=goog.moduleLoaderState_};goog.module.declareLegacyNamespace=function(){if(!COMPILED&&!goog.isInModuleLoader_())throw Error("goog.module.declareLegacyNamespace must be called from within a goog.module");if(!COMPILED&&!goog.moduleLoaderState_.moduleName)throw Error("goog.module must be called prior to goog.module.declareLegacyNamespace.");goog.moduleLoaderState_.declareLegacyNamespace=!0};
goog.setTestOnly=function(a){if(goog.DISALLOW_TEST_ONLY_CODE)throw a=a||"",Error("Importing test-only code into non-debug environment"+(a?": "+a:"."));};goog.forwardDeclare=function(a){};COMPILED||(goog.isProvided_=function(a){return a in goog.loadedModules_||!goog.implicitNamespaces_[a]&&goog.isDefAndNotNull(goog.getObjectByName(a))},goog.implicitNamespaces_={"goog.module":!0});
goog.getObjectByName=function(a,b){for(var c=a.split("."),d=b||goog.global,e;e=c.shift();)if(goog.isDefAndNotNull(d[e]))d=d[e];else return null;return d};goog.globalize=function(a,b){var c=b||goog.global,d;for(d in a)c[d]=a[d]};
goog.addDependency=function(a,b,c,d){if(goog.DEPENDENCIES_ENABLED){var e;a=a.replace(/\\/g,"/");var f=goog.dependencies_;d&&"boolean"!==typeof d||(d=d?{module:"goog"}:{});for(var g=0;e=b[g];g++)f.nameToPath[e]=a,f.loadFlags[a]=d;for(d=0;b=c[d];d++)a in f.requires||(f.requires[a]={}),f.requires[a][b]=!0}};goog.ENABLE_DEBUG_LOADER=!0;goog.logToConsole_=function(a){goog.global.console&&goog.global.console.error(a)};
goog.require=function(a){if(!COMPILED){goog.ENABLE_DEBUG_LOADER&&goog.IS_OLD_IE_&&goog.maybeProcessDeferredDep_(a);if(goog.isProvided_(a)){if(goog.isInModuleLoader_())return goog.module.getInternal_(a)}else if(goog.ENABLE_DEBUG_LOADER){var b=goog.getPathFromDeps_(a);if(b)goog.writeScripts_(b);else throw a="goog.require could not find: "+a,goog.logToConsole_(a),Error(a);}return null}};goog.basePath="";goog.nullFunction=function(){};
goog.abstractMethod=function(){throw Error("unimplemented abstract method");};goog.addSingletonGetter=function(a){a.instance_=void 0;a.getInstance=function(){if(a.instance_)return a.instance_;goog.DEBUG&&(goog.instantiatedSingletons_[goog.instantiatedSingletons_.length]=a);return a.instance_=new a}};goog.instantiatedSingletons_=[];goog.LOAD_MODULE_USING_EVAL=!0;goog.SEAL_MODULE_EXPORTS=goog.DEBUG;goog.loadedModules_={};goog.DEPENDENCIES_ENABLED=!COMPILED&&goog.ENABLE_DEBUG_LOADER;goog.TRANSPILE="detect";
goog.TRANSPILER="transpile.js";
goog.DEPENDENCIES_ENABLED&&(goog.dependencies_={loadFlags:{},nameToPath:{},requires:{},visited:{},written:{},deferred:{}},goog.inHtmlDocument_=function(){var a=goog.global.document;return null!=a&&"write"in a},goog.findBasePath_=function(){if(goog.isDef(goog.global.CLOSURE_BASE_PATH))goog.basePath=goog.global.CLOSURE_BASE_PATH;else if(goog.inHtmlDocument_())for(var a=goog.global.document.getElementsByTagName("SCRIPT"),b=a.length-1;0<=b;--b){var c=a[b].src,d=c.lastIndexOf("?"),d=-1==d?c.length:d;if("base.js"==
c.substr(d-7,7)){goog.basePath=c.substr(0,d-7);break}}},goog.importScript_=function(a,b){(goog.global.CLOSURE_IMPORT_SCRIPT||goog.writeScriptTag_)(a,b)&&(goog.dependencies_.written[a]=!0)},goog.IS_OLD_IE_=!(goog.global.atob||!goog.global.document||!goog.global.document.all),goog.importProcessedScript_=function(a,b,c){goog.importScript_("",'goog.retrieveAndExec_("'+a+'", '+b+", "+c+");")},goog.queuedModules_=[],goog.wrapModule_=function(a,b){return goog.LOAD_MODULE_USING_EVAL&&goog.isDef(goog.global.JSON)?
"goog.loadModule("+goog.global.JSON.stringify(b+"\n//# sourceURL="+a+"\n")+");":'goog.loadModule(function(exports) {"use strict";'+b+"\n;return exports});\n//# sourceURL="+a+"\n"},goog.loadQueuedModules_=function(){var a=goog.queuedModules_.length;if(0<a){var b=goog.queuedModules_;goog.queuedModules_=[];for(var c=0;c<a;c++)goog.maybeProcessDeferredPath_(b[c])}},goog.maybeProcessDeferredDep_=function(a){goog.isDeferredModule_(a)&&goog.allDepsAreAvailable_(a)&&(a=goog.getPathFromDeps_(a),goog.maybeProcessDeferredPath_(goog.basePath+
a))},goog.isDeferredModule_=function(a){var b=(a=goog.getPathFromDeps_(a))&&goog.dependencies_.loadFlags[a]||{},c=b.lang||"es3";return a&&("goog"==b.module||goog.needsTranspile_(c))?goog.basePath+a in goog.dependencies_.deferred:!1},goog.allDepsAreAvailable_=function(a){if((a=goog.getPathFromDeps_(a))&&a in goog.dependencies_.requires)for(var b in goog.dependencies_.requires[a])if(!goog.isProvided_(b)&&!goog.isDeferredModule_(b))return!1;return!0},goog.maybeProcessDeferredPath_=function(a){if(a in
goog.dependencies_.deferred){var b=goog.dependencies_.deferred[a];delete goog.dependencies_.deferred[a];goog.globalEval(b)}},goog.loadModuleFromUrl=function(a){goog.retrieveAndExec_(a,!0,!1)},goog.writeScriptSrcNode_=function(a){goog.global.document.write('<script type="text/javascript" src="'+a+'">\x3c/script>')},goog.appendScriptSrcNode_=function(a){var b=goog.global.document,c=b.createElement("script");c.type="text/javascript";c.src=a;c.defer=!1;c.async=!1;b.head.appendChild(c)},goog.writeScriptTag_=
function(a,b){if(goog.inHtmlDocument_()){var c=goog.global.document;if(!goog.ENABLE_CHROME_APP_SAFE_SCRIPT_LOADING&&"complete"==c.readyState){if(/\bdeps.js$/.test(a))return!1;throw Error('Cannot write "'+a+'" after document load');}if(void 0===b)if(goog.IS_OLD_IE_){var d=" onreadystatechange='goog.onScriptLoad_(this, "+ ++goog.lastNonModuleScriptIndex_+")' ";c.write('<script type="text/javascript" src="'+a+'"'+d+">\x3c/script>")}else goog.ENABLE_CHROME_APP_SAFE_SCRIPT_LOADING?goog.appendScriptSrcNode_(a):
goog.writeScriptSrcNode_(a);else c.write('<script type="text/javascript">'+goog.protectScriptTag_(b)+"\x3c/script>");return!0}return!1},goog.protectScriptTag_=function(a){return a.replace(/<\/(SCRIPT)/ig,"\\x3c\\$1")},goog.needsTranspile_=function(a){if("always"==goog.TRANSPILE)return!0;if("never"==goog.TRANSPILE)return!1;goog.requiresTranspilation_||(goog.requiresTranspilation_=goog.createRequiresTranspilation_());if(a in goog.requiresTranspilation_)return goog.requiresTranspilation_[a];throw Error("Unknown language mode: "+
a);},goog.requiresTranspilation_=null,goog.lastNonModuleScriptIndex_=0,goog.onScriptLoad_=function(a,b){"complete"==a.readyState&&goog.lastNonModuleScriptIndex_==b&&goog.loadQueuedModules_();return!0},goog.writeScripts_=function(a){function b(a){if(!(a in e.written||a in e.visited)){e.visited[a]=!0;if(a in e.requires)for(var f in e.requires[a])if(!goog.isProvided_(f))if(f in e.nameToPath)b(e.nameToPath[f]);else throw Error("Undefined nameToPath for "+f);a in d||(d[a]=!0,c.push(a))}}var c=[],d={},
e=goog.dependencies_;b(a);for(a=0;a<c.length;a++){var f=c[a];goog.dependencies_.written[f]=!0}var g=goog.moduleLoaderState_;goog.moduleLoaderState_=null;for(a=0;a<c.length;a++)if(f=c[a]){var h=e.loadFlags[f]||{},k=goog.needsTranspile_(h.lang||"es3");"goog"==h.module||k?goog.importProcessedScript_(goog.basePath+f,"goog"==h.module,k):goog.importScript_(goog.basePath+f)}else throw goog.moduleLoaderState_=g,Error("Undefined script input");goog.moduleLoaderState_=g},goog.getPathFromDeps_=function(a){return a in
goog.dependencies_.nameToPath?goog.dependencies_.nameToPath[a]:null},goog.findBasePath_(),goog.global.CLOSURE_NO_DEPS||goog.importScript_(goog.basePath+"deps.js"));goog.hasBadLetScoping=null;goog.useSafari10Workaround=function(){if(null==goog.hasBadLetScoping){var a;try{a=!eval('"use strict";let x = 1; function f() { return typeof x; };f() == "number";')}catch(b){a=!1}goog.hasBadLetScoping=a}return goog.hasBadLetScoping};goog.workaroundSafari10EvalBug=function(a){return"(function(){"+a+"\n;})();\n"};
goog.loadModule=function(a){var b=goog.moduleLoaderState_;try{goog.moduleLoaderState_={moduleName:void 0,declareLegacyNamespace:!1};var c;if(goog.isFunction(a))c=a.call(void 0,{});else if(goog.isString(a))goog.useSafari10Workaround()&&(a=goog.workaroundSafari10EvalBug(a)),c=goog.loadModuleFromSource_.call(void 0,a);else throw Error("Invalid module definition");var d=goog.moduleLoaderState_.moduleName;if(!goog.isString(d)||!d)throw Error('Invalid module name "'+d+'"');goog.moduleLoaderState_.declareLegacyNamespace?
goog.constructNamespace_(d,c):goog.SEAL_MODULE_EXPORTS&&Object.seal&&"object"==typeof c&&null!=c&&Object.seal(c);goog.loadedModules_[d]=c}finally{goog.moduleLoaderState_=b}};goog.loadModuleFromSource_=function(a){eval(a);return{}};goog.normalizePath_=function(a){a=a.split("/");for(var b=0;b<a.length;)"."==a[b]?a.splice(b,1):b&&".."==a[b]&&a[b-1]&&".."!=a[b-1]?a.splice(--b,2):b++;return a.join("/")};
goog.loadFileSync_=function(a){if(goog.global.CLOSURE_LOAD_FILE_SYNC)return goog.global.CLOSURE_LOAD_FILE_SYNC(a);try{var b=new goog.global.XMLHttpRequest;b.open("get",a,!1);b.send();return 0==b.status||200==b.status?b.responseText:null}catch(c){return null}};
goog.retrieveAndExec_=function(a,b,c){if(!COMPILED){var d=a;a=goog.normalizePath_(a);var e=goog.global.CLOSURE_IMPORT_SCRIPT||goog.writeScriptTag_,f=goog.loadFileSync_(a);if(null==f)throw Error('Load of "'+a+'" failed');c&&(f=goog.transpile_.call(goog.global,f,a));f=b?goog.wrapModule_(a,f):f+("\n//# sourceURL="+a);goog.IS_OLD_IE_?(goog.dependencies_.deferred[d]=f,goog.queuedModules_.push(d)):e(a,f)}};
goog.transpile_=function(a,b){var c=goog.global.$jscomp;c||(goog.global.$jscomp=c={});var d=c.transpile;if(!d){var e=goog.basePath+goog.TRANSPILER,f=goog.loadFileSync_(e);if(f){eval(f+"\n//# sourceURL="+e);if(goog.global.$gwtExport&&goog.global.$gwtExport.$jscomp&&!goog.global.$gwtExport.$jscomp.transpile)throw Error('The transpiler did not properly export the "transpile" method. $gwtExport: '+JSON.stringify(goog.global.$gwtExport));goog.global.$jscomp.transpile=goog.global.$gwtExport.$jscomp.transpile;
c=goog.global.$jscomp;d=c.transpile}}d||(d=c.transpile=function(a,b){goog.logToConsole_(b+" requires transpilation but no transpiler was found.");return a});return d(a,b)};
goog.typeOf=function(a){var b=typeof a;if("object"==b)if(a){if(a instanceof Array)return"array";if(a instanceof Object)return b;var c=Object.prototype.toString.call(a);if("[object Window]"==c)return"object";if("[object Array]"==c||"number"==typeof a.length&&"undefined"!=typeof a.splice&&"undefined"!=typeof a.propertyIsEnumerable&&!a.propertyIsEnumerable("splice"))return"array";if("[object Function]"==c||"undefined"!=typeof a.call&&"undefined"!=typeof a.propertyIsEnumerable&&!a.propertyIsEnumerable("call"))return"function"}else return"null";
else if("function"==b&&"undefined"==typeof a.call)return"object";return b};goog.isNull=function(a){return null===a};goog.isDefAndNotNull=function(a){return null!=a};goog.isArray=function(a){return"array"==goog.typeOf(a)};goog.isArrayLike=function(a){var b=goog.typeOf(a);return"array"==b||"object"==b&&"number"==typeof a.length};goog.isDateLike=function(a){return goog.isObject(a)&&"function"==typeof a.getFullYear};goog.isString=function(a){return"string"==typeof a};
goog.isBoolean=function(a){return"boolean"==typeof a};goog.isNumber=function(a){return"number"==typeof a};goog.isFunction=function(a){return"function"==goog.typeOf(a)};goog.isObject=function(a){var b=typeof a;return"object"==b&&null!=a||"function"==b};goog.getUid=function(a){return a[goog.UID_PROPERTY_]||(a[goog.UID_PROPERTY_]=++goog.uidCounter_)};goog.hasUid=function(a){return!!a[goog.UID_PROPERTY_]};
goog.removeUid=function(a){null!==a&&"removeAttribute"in a&&a.removeAttribute(goog.UID_PROPERTY_);try{delete a[goog.UID_PROPERTY_]}catch(b){}};goog.UID_PROPERTY_="closure_uid_"+(1E9*Math.random()>>>0);goog.uidCounter_=0;goog.getHashCode=goog.getUid;goog.removeHashCode=goog.removeUid;goog.cloneObject=function(a){var b=goog.typeOf(a);if("object"==b||"array"==b){if(a.clone)return a.clone();var b="array"==b?[]:{},c;for(c in a)b[c]=goog.cloneObject(a[c]);return b}return a};
goog.bindNative_=function(a,b,c){return a.call.apply(a.bind,arguments)};goog.bindJs_=function(a,b,c){if(!a)throw Error();if(2<arguments.length){var d=Array.prototype.slice.call(arguments,2);return function(){var c=Array.prototype.slice.call(arguments);Array.prototype.unshift.apply(c,d);return a.apply(b,c)}}return function(){return a.apply(b,arguments)}};
goog.bind=function(a,b,c){Function.prototype.bind&&-1!=Function.prototype.bind.toString().indexOf("native code")?goog.bind=goog.bindNative_:goog.bind=goog.bindJs_;return goog.bind.apply(null,arguments)};goog.partial=function(a,b){var c=Array.prototype.slice.call(arguments,1);return function(){var b=c.slice();b.push.apply(b,arguments);return a.apply(this,b)}};goog.mixin=function(a,b){for(var c in b)a[c]=b[c]};goog.now=goog.TRUSTED_SITE&&Date.now||function(){return+new Date};
goog.globalEval=function(a){if(goog.global.execScript)goog.global.execScript(a,"JavaScript");else if(goog.global.eval){if(null==goog.evalWorksForGlobals_)if(goog.global.eval("var _evalTest_ = 1;"),"undefined"!=typeof goog.global._evalTest_){try{delete goog.global._evalTest_}catch(d){}goog.evalWorksForGlobals_=!0}else goog.evalWorksForGlobals_=!1;if(goog.evalWorksForGlobals_)goog.global.eval(a);else{var b=goog.global.document,c=b.createElement("SCRIPT");c.type="text/javascript";c.defer=!1;c.appendChild(b.createTextNode(a));
b.body.appendChild(c);b.body.removeChild(c)}}else throw Error("goog.globalEval not available");};goog.evalWorksForGlobals_=null;
goog.getCssName=function(a,b){if("."==String(a).charAt(0))throw Error('className passed in goog.getCssName must not start with ".". You passed: '+a);var c=function(a){return goog.cssNameMapping_[a]||a},d=function(a){a=a.split("-");for(var b=[],d=0;d<a.length;d++)b.push(c(a[d]));return b.join("-")},d=goog.cssNameMapping_?"BY_WHOLE"==goog.cssNameMappingStyle_?c:d:function(a){return a},d=b?a+"-"+d(b):d(a);return goog.global.CLOSURE_CSS_NAME_MAP_FN?goog.global.CLOSURE_CSS_NAME_MAP_FN(d):d};
goog.setCssNameMapping=function(a,b){goog.cssNameMapping_=a;goog.cssNameMappingStyle_=b};!COMPILED&&goog.global.CLOSURE_CSS_NAME_MAPPING&&(goog.cssNameMapping_=goog.global.CLOSURE_CSS_NAME_MAPPING);goog.getMsg=function(a,b){b&&(a=a.replace(/\{\$([^}]+)}/g,function(a,d){return null!=b&&d in b?b[d]:a}));return a};goog.getMsgWithFallback=function(a,b){return a};goog.exportSymbol=function(a,b,c){goog.exportPath_(a,b,c)};goog.exportProperty=function(a,b,c){a[b]=c};
goog.inherits=function(a,b){function c(){}c.prototype=b.prototype;a.superClass_=b.prototype;a.prototype=new c;a.prototype.constructor=a;a.base=function(a,c,f){for(var d=Array(arguments.length-2),e=2;e<arguments.length;e++)d[e-2]=arguments[e];return b.prototype[c].apply(a,d)}};
goog.base=function(a,b,c){var d=arguments.callee.caller;if(goog.STRICT_MODE_COMPATIBLE||goog.DEBUG&&!d)throw Error("arguments.caller not defined.  goog.base() cannot be used with strict mode code. See http://www.ecma-international.org/ecma-262/5.1/#sec-C");if(d.superClass_){for(var e=Array(arguments.length-1),f=1;f<arguments.length;f++)e[f-1]=arguments[f];return d.superClass_.constructor.apply(a,e)}e=Array(arguments.length-2);for(f=2;f<arguments.length;f++)e[f-2]=arguments[f];for(var f=!1,g=a.constructor;g;g=
g.superClass_&&g.superClass_.constructor)if(g.prototype[b]===d)f=!0;else if(f)return g.prototype[b].apply(a,e);if(a[b]===d)return a.constructor.prototype[b].apply(a,e);throw Error("goog.base called from a method of one name to a method of a different name");};goog.scope=function(a){if(goog.isInModuleLoader_())throw Error("goog.scope is not supported within a goog.module.");a.call(goog.global)};COMPILED||(goog.global.COMPILED=COMPILED);
goog.defineClass=function(a,b){var c=b.constructor,d=b.statics;c&&c!=Object.prototype.constructor||(c=function(){throw Error("cannot instantiate an interface (no constructor defined).");});c=goog.defineClass.createSealingConstructor_(c,a);a&&goog.inherits(c,a);delete b.constructor;delete b.statics;goog.defineClass.applyProperties_(c.prototype,b);null!=d&&(d instanceof Function?d(c):goog.defineClass.applyProperties_(c,d));return c};goog.defineClass.SEAL_CLASS_INSTANCES=goog.DEBUG;
goog.defineClass.createSealingConstructor_=function(a,b){if(!goog.defineClass.SEAL_CLASS_INSTANCES)return a;var c=!goog.defineClass.isUnsealable_(b),d=function(){var b=a.apply(this,arguments)||this;b[goog.UID_PROPERTY_]=b[goog.UID_PROPERTY_];this.constructor===d&&c&&Object.seal instanceof Function&&Object.seal(b);return b};return d};goog.defineClass.isUnsealable_=function(a){return a&&a.prototype&&a.prototype[goog.UNSEALABLE_CONSTRUCTOR_PROPERTY_]};goog.defineClass.OBJECT_PROTOTYPE_FIELDS_="constructor hasOwnProperty isPrototypeOf propertyIsEnumerable toLocaleString toString valueOf".split(" ");
goog.defineClass.applyProperties_=function(a,b){for(var c in b)Object.prototype.hasOwnProperty.call(b,c)&&(a[c]=b[c]);for(var d=0;d<goog.defineClass.OBJECT_PROTOTYPE_FIELDS_.length;d++)c=goog.defineClass.OBJECT_PROTOTYPE_FIELDS_[d],Object.prototype.hasOwnProperty.call(b,c)&&(a[c]=b[c])};goog.tagUnsealableClass=function(a){!COMPILED&&goog.defineClass.SEAL_CLASS_INSTANCES&&(a.prototype[goog.UNSEALABLE_CONSTRUCTOR_PROPERTY_]=!0)};goog.UNSEALABLE_CONSTRUCTOR_PROPERTY_="goog_defineClass_legacy_unsealable";
goog.createRequiresTranspilation_=function(){function a(a,b){d?c[a]=!0:b()?c[a]=!1:d=c[a]=!0}function b(a){try{return!!eval(a)}catch(f){return!1}}var c={es3:!1},d=!1;a("es5",function(){return b("[1,].length==1")});a("es6",function(){return b('(()=>{"use strict";class X{constructor(){if(new.target!=String)throw 1;this.x=42}}let q=Reflect.construct(X,[],String);if(q.x!=42||!(q instanceof String))throw 1;for(const a of[2,3]){if(a==2)continue;function f(z={a}){let a=0;return z.a}{function f(){return 0;}}return f()==3}})()')});
a("es6-impl",function(){return!0});a("es7",function(){return b("2 ** 2 == 4")});a("es8",function(){return b("async () => 1, true")});return c};goog.disposable={};goog.disposable.IDisposable=function(){};goog.disposable.IDisposable.prototype.dispose=goog.abstractMethod;goog.disposable.IDisposable.prototype.isDisposed=goog.abstractMethod;goog.Disposable=function(){goog.Disposable.MONITORING_MODE!=goog.Disposable.MonitoringMode.OFF&&(goog.Disposable.INCLUDE_STACK_ON_CREATION&&(this.creationStack=Error().stack),goog.Disposable.instances_[goog.getUid(this)]=this);this.disposed_=this.disposed_;this.onDisposeCallbacks_=this.onDisposeCallbacks_};goog.Disposable.MonitoringMode={OFF:0,PERMANENT:1,INTERACTIVE:2};goog.Disposable.MONITORING_MODE=0;goog.Disposable.INCLUDE_STACK_ON_CREATION=!0;goog.Disposable.instances_={};
goog.Disposable.getUndisposedObjects=function(){var a=[],b;for(b in goog.Disposable.instances_)goog.Disposable.instances_.hasOwnProperty(b)&&a.push(goog.Disposable.instances_[Number(b)]);return a};goog.Disposable.clearUndisposedObjects=function(){goog.Disposable.instances_={}};goog.Disposable.prototype.disposed_=!1;goog.Disposable.prototype.isDisposed=function(){return this.disposed_};goog.Disposable.prototype.getDisposed=goog.Disposable.prototype.isDisposed;
goog.Disposable.prototype.dispose=function(){if(!this.disposed_&&(this.disposed_=!0,this.disposeInternal(),goog.Disposable.MONITORING_MODE!=goog.Disposable.MonitoringMode.OFF)){var a=goog.getUid(this);if(goog.Disposable.MONITORING_MODE==goog.Disposable.MonitoringMode.PERMANENT&&!goog.Disposable.instances_.hasOwnProperty(a))throw Error(this+" did not call the goog.Disposable base constructor or was disposed of after a clearUndisposedObjects call");delete goog.Disposable.instances_[a]}};
goog.Disposable.prototype.registerDisposable=function(a){this.addOnDisposeCallback(goog.partial(goog.dispose,a))};goog.Disposable.prototype.addOnDisposeCallback=function(a,b){this.disposed_?goog.isDef(b)?a.call(b):a():(this.onDisposeCallbacks_||(this.onDisposeCallbacks_=[]),this.onDisposeCallbacks_.push(goog.isDef(b)?goog.bind(a,b):a))};goog.Disposable.prototype.disposeInternal=function(){if(this.onDisposeCallbacks_)for(;this.onDisposeCallbacks_.length;)this.onDisposeCallbacks_.shift()()};
goog.Disposable.isDisposed=function(a){return a&&"function"==typeof a.isDisposed?a.isDisposed():!1};goog.dispose=function(a){a&&"function"==typeof a.dispose&&a.dispose()};goog.disposeAll=function(a){for(var b=0,c=arguments.length;b<c;++b){var d=arguments[b];goog.isArrayLike(d)?goog.disposeAll.apply(null,d):goog.dispose(d)}};goog.Thenable=function(){};goog.Thenable.prototype.then=function(a,b,c){};goog.Thenable.IMPLEMENTED_BY_PROP="$goog_Thenable";goog.Thenable.addImplementation=function(a){a.prototype.then=a.prototype.then;COMPILED?a.prototype[goog.Thenable.IMPLEMENTED_BY_PROP]=!0:a.prototype.$goog_Thenable=!0};goog.Thenable.isImplementedBy=function(a){if(!a)return!1;try{return COMPILED?!!a[goog.Thenable.IMPLEMENTED_BY_PROP]:!!a.$goog_Thenable}catch(b){return!1}};goog.debug={};goog.debug.Error=function(a){if(Error.captureStackTrace)Error.captureStackTrace(this,goog.debug.Error);else{var b=Error().stack;b&&(this.stack=b)}a&&(this.message=String(a));this.reportErrorToServer=!0};goog.inherits(goog.debug.Error,Error);goog.debug.Error.prototype.name="CustomError";goog.dom={};goog.dom.NodeType={ELEMENT:1,ATTRIBUTE:2,TEXT:3,CDATA_SECTION:4,ENTITY_REFERENCE:5,ENTITY:6,PROCESSING_INSTRUCTION:7,COMMENT:8,DOCUMENT:9,DOCUMENT_TYPE:10,DOCUMENT_FRAGMENT:11,NOTATION:12};goog.string={};goog.string.DETECT_DOUBLE_ESCAPING=!1;goog.string.FORCE_NON_DOM_HTML_UNESCAPING=!1;goog.string.Unicode={NBSP:"\u00a0"};goog.string.startsWith=function(a,b){return 0==a.lastIndexOf(b,0)};goog.string.endsWith=function(a,b){var c=a.length-b.length;return 0<=c&&a.indexOf(b,c)==c};goog.string.caseInsensitiveStartsWith=function(a,b){return 0==goog.string.caseInsensitiveCompare(b,a.substr(0,b.length))};
goog.string.caseInsensitiveEndsWith=function(a,b){return 0==goog.string.caseInsensitiveCompare(b,a.substr(a.length-b.length,b.length))};goog.string.caseInsensitiveEquals=function(a,b){return a.toLowerCase()==b.toLowerCase()};goog.string.subs=function(a,b){for(var c=a.split("%s"),d="",e=Array.prototype.slice.call(arguments,1);e.length&&1<c.length;)d+=c.shift()+e.shift();return d+c.join("%s")};goog.string.collapseWhitespace=function(a){return a.replace(/[\s\xa0]+/g," ").replace(/^\s+|\s+$/g,"")};
goog.string.isEmptyOrWhitespace=function(a){return/^[\s\xa0]*$/.test(a)};goog.string.isEmptyString=function(a){return 0==a.length};goog.string.isEmpty=goog.string.isEmptyOrWhitespace;goog.string.isEmptyOrWhitespaceSafe=function(a){return goog.string.isEmptyOrWhitespace(goog.string.makeSafe(a))};goog.string.isEmptySafe=goog.string.isEmptyOrWhitespaceSafe;goog.string.isBreakingWhitespace=function(a){return!/[^\t\n\r ]/.test(a)};goog.string.isAlpha=function(a){return!/[^a-zA-Z]/.test(a)};
goog.string.isNumeric=function(a){return!/[^0-9]/.test(a)};goog.string.isAlphaNumeric=function(a){return!/[^a-zA-Z0-9]/.test(a)};goog.string.isSpace=function(a){return" "==a};goog.string.isUnicodeChar=function(a){return 1==a.length&&" "<=a&&"~">=a||"\u0080"<=a&&"\ufffd">=a};goog.string.stripNewlines=function(a){return a.replace(/(\r\n|\r|\n)+/g," ")};goog.string.canonicalizeNewlines=function(a){return a.replace(/(\r\n|\r|\n)/g,"\n")};
goog.string.normalizeWhitespace=function(a){return a.replace(/\xa0|\s/g," ")};goog.string.normalizeSpaces=function(a){return a.replace(/\xa0|[ \t]+/g," ")};goog.string.collapseBreakingSpaces=function(a){return a.replace(/[\t\r\n ]+/g," ").replace(/^[\t\r\n ]+|[\t\r\n ]+$/g,"")};goog.string.trim=goog.TRUSTED_SITE&&String.prototype.trim?function(a){return a.trim()}:function(a){return a.replace(/^[\s\xa0]+|[\s\xa0]+$/g,"")};goog.string.trimLeft=function(a){return a.replace(/^[\s\xa0]+/,"")};
goog.string.trimRight=function(a){return a.replace(/[\s\xa0]+$/,"")};goog.string.caseInsensitiveCompare=function(a,b){var c=String(a).toLowerCase(),d=String(b).toLowerCase();return c<d?-1:c==d?0:1};
goog.string.numberAwareCompare_=function(a,b,c){if(a==b)return 0;if(!a)return-1;if(!b)return 1;for(var d=a.toLowerCase().match(c),e=b.toLowerCase().match(c),f=Math.min(d.length,e.length),g=0;g<f;g++){c=d[g];var h=e[g];if(c!=h)return a=parseInt(c,10),!isNaN(a)&&(b=parseInt(h,10),!isNaN(b)&&a-b)?a-b:c<h?-1:1}return d.length!=e.length?d.length-e.length:a<b?-1:1};goog.string.intAwareCompare=function(a,b){return goog.string.numberAwareCompare_(a,b,/\d+|\D+/g)};
goog.string.floatAwareCompare=function(a,b){return goog.string.numberAwareCompare_(a,b,/\d+|\.\d+|\D+/g)};goog.string.numerateCompare=goog.string.floatAwareCompare;goog.string.urlEncode=function(a){return encodeURIComponent(String(a))};goog.string.urlDecode=function(a){return decodeURIComponent(a.replace(/\+/g," "))};goog.string.newLineToBr=function(a,b){return a.replace(/(\r\n|\r|\n)/g,b?"<br />":"<br>")};
goog.string.htmlEscape=function(a,b){if(b)a=a.replace(goog.string.AMP_RE_,"&amp;").replace(goog.string.LT_RE_,"&lt;").replace(goog.string.GT_RE_,"&gt;").replace(goog.string.QUOT_RE_,"&quot;").replace(goog.string.SINGLE_QUOTE_RE_,"&#39;").replace(goog.string.NULL_RE_,"&#0;"),goog.string.DETECT_DOUBLE_ESCAPING&&(a=a.replace(goog.string.E_RE_,"&#101;"));else{if(!goog.string.ALL_RE_.test(a))return a;-1!=a.indexOf("&")&&(a=a.replace(goog.string.AMP_RE_,"&amp;"));-1!=a.indexOf("<")&&(a=a.replace(goog.string.LT_RE_,
"&lt;"));-1!=a.indexOf(">")&&(a=a.replace(goog.string.GT_RE_,"&gt;"));-1!=a.indexOf('"')&&(a=a.replace(goog.string.QUOT_RE_,"&quot;"));-1!=a.indexOf("'")&&(a=a.replace(goog.string.SINGLE_QUOTE_RE_,"&#39;"));-1!=a.indexOf("\x00")&&(a=a.replace(goog.string.NULL_RE_,"&#0;"));goog.string.DETECT_DOUBLE_ESCAPING&&-1!=a.indexOf("e")&&(a=a.replace(goog.string.E_RE_,"&#101;"))}return a};goog.string.AMP_RE_=/&/g;goog.string.LT_RE_=/</g;goog.string.GT_RE_=/>/g;goog.string.QUOT_RE_=/"/g;
goog.string.SINGLE_QUOTE_RE_=/'/g;goog.string.NULL_RE_=/\x00/g;goog.string.E_RE_=/e/g;goog.string.ALL_RE_=goog.string.DETECT_DOUBLE_ESCAPING?/[\x00&<>"'e]/:/[\x00&<>"']/;goog.string.unescapeEntities=function(a){return goog.string.contains(a,"&")?!goog.string.FORCE_NON_DOM_HTML_UNESCAPING&&"document"in goog.global?goog.string.unescapeEntitiesUsingDom_(a):goog.string.unescapePureXmlEntities_(a):a};
goog.string.unescapeEntitiesWithDocument=function(a,b){return goog.string.contains(a,"&")?goog.string.unescapeEntitiesUsingDom_(a,b):a};
goog.string.unescapeEntitiesUsingDom_=function(a,b){var c={"&amp;":"&","&lt;":"<","&gt;":">","&quot;":'"'},d;d=b?b.createElement("div"):goog.global.document.createElement("div");return a.replace(goog.string.HTML_ENTITY_PATTERN_,function(a,b){var e=c[a];if(e)return e;if("#"==b.charAt(0)){var f=Number("0"+b.substr(1));isNaN(f)||(e=String.fromCharCode(f))}e||(d.innerHTML=a+" ",e=d.firstChild.nodeValue.slice(0,-1));return c[a]=e})};
goog.string.unescapePureXmlEntities_=function(a){return a.replace(/&([^;]+);/g,function(a,c){switch(c){case "amp":return"&";case "lt":return"<";case "gt":return">";case "quot":return'"';default:if("#"==c.charAt(0)){var b=Number("0"+c.substr(1));if(!isNaN(b))return String.fromCharCode(b)}return a}})};goog.string.HTML_ENTITY_PATTERN_=/&([^;\s<&]+);?/g;goog.string.whitespaceEscape=function(a,b){return goog.string.newLineToBr(a.replace(/  /g," &#160;"),b)};
goog.string.preserveSpaces=function(a){return a.replace(/(^|[\n ]) /g,"$1"+goog.string.Unicode.NBSP)};goog.string.stripQuotes=function(a,b){for(var c=b.length,d=0;d<c;d++){var e=1==c?b:b.charAt(d);if(a.charAt(0)==e&&a.charAt(a.length-1)==e)return a.substring(1,a.length-1)}return a};goog.string.truncate=function(a,b,c){c&&(a=goog.string.unescapeEntities(a));a.length>b&&(a=a.substring(0,b-3)+"...");c&&(a=goog.string.htmlEscape(a));return a};
goog.string.truncateMiddle=function(a,b,c,d){c&&(a=goog.string.unescapeEntities(a));if(d&&a.length>b){d>b&&(d=b);var e=a.length-d;a=a.substring(0,b-d)+"..."+a.substring(e)}else a.length>b&&(d=Math.floor(b/2),e=a.length-d,a=a.substring(0,d+b%2)+"..."+a.substring(e));c&&(a=goog.string.htmlEscape(a));return a};goog.string.specialEscapeChars_={"\x00":"\\0","\b":"\\b","\f":"\\f","\n":"\\n","\r":"\\r","\t":"\\t","\x0B":"\\x0B",'"':'\\"',"\\":"\\\\","<":"<"};goog.string.jsEscapeCache_={"'":"\\'"};
goog.string.quote=function(a){a=String(a);for(var b=['"'],c=0;c<a.length;c++){var d=a.charAt(c),e=d.charCodeAt(0);b[c+1]=goog.string.specialEscapeChars_[d]||(31<e&&127>e?d:goog.string.escapeChar(d))}b.push('"');return b.join("")};goog.string.escapeString=function(a){for(var b=[],c=0;c<a.length;c++)b[c]=goog.string.escapeChar(a.charAt(c));return b.join("")};
goog.string.escapeChar=function(a){if(a in goog.string.jsEscapeCache_)return goog.string.jsEscapeCache_[a];if(a in goog.string.specialEscapeChars_)return goog.string.jsEscapeCache_[a]=goog.string.specialEscapeChars_[a];var b,c=a.charCodeAt(0);if(31<c&&127>c)b=a;else{if(256>c){if(b="\\x",16>c||256<c)b+="0"}else b="\\u",4096>c&&(b+="0");b+=c.toString(16).toUpperCase()}return goog.string.jsEscapeCache_[a]=b};goog.string.contains=function(a,b){return-1!=a.indexOf(b)};
goog.string.caseInsensitiveContains=function(a,b){return goog.string.contains(a.toLowerCase(),b.toLowerCase())};goog.string.countOf=function(a,b){return a&&b?a.split(b).length-1:0};goog.string.removeAt=function(a,b,c){var d=a;0<=b&&b<a.length&&0<c&&(d=a.substr(0,b)+a.substr(b+c,a.length-b-c));return d};goog.string.remove=function(a,b){return a.replace(b,"")};goog.string.removeAll=function(a,b){var c=new RegExp(goog.string.regExpEscape(b),"g");return a.replace(c,"")};
goog.string.replaceAll=function(a,b,c){b=new RegExp(goog.string.regExpEscape(b),"g");return a.replace(b,c.replace(/\$/g,"$$$$"))};goog.string.regExpEscape=function(a){return String(a).replace(/([-()\[\]{}+?*.$\^|,:#<!\\])/g,"\\$1").replace(/\x08/g,"\\x08")};goog.string.repeat=String.prototype.repeat?function(a,b){return a.repeat(b)}:function(a,b){return Array(b+1).join(a)};
goog.string.padNumber=function(a,b,c){a=goog.isDef(c)?a.toFixed(c):String(a);c=a.indexOf(".");-1==c&&(c=a.length);return goog.string.repeat("0",Math.max(0,b-c))+a};goog.string.makeSafe=function(a){return null==a?"":String(a)};goog.string.buildString=function(a){return Array.prototype.join.call(arguments,"")};goog.string.getRandomString=function(){return Math.floor(2147483648*Math.random()).toString(36)+Math.abs(Math.floor(2147483648*Math.random())^goog.now()).toString(36)};
goog.string.compareVersions=function(a,b){for(var c=0,d=goog.string.trim(String(a)).split("."),e=goog.string.trim(String(b)).split("."),f=Math.max(d.length,e.length),g=0;0==c&&g<f;g++){var h=d[g]||"",k=e[g]||"";do{h=/(\d*)(\D*)(.*)/.exec(h)||["","","",""];k=/(\d*)(\D*)(.*)/.exec(k)||["","","",""];if(0==h[0].length&&0==k[0].length)break;var c=0==h[1].length?0:parseInt(h[1],10),l=0==k[1].length?0:parseInt(k[1],10),c=goog.string.compareElements_(c,l)||goog.string.compareElements_(0==h[2].length,0==k[2].length)||
goog.string.compareElements_(h[2],k[2]),h=h[3],k=k[3]}while(0==c)}return c};goog.string.compareElements_=function(a,b){return a<b?-1:a>b?1:0};goog.string.hashCode=function(a){for(var b=0,c=0;c<a.length;++c)b=31*b+a.charCodeAt(c)>>>0;return b};goog.string.uniqueStringCounter_=2147483648*Math.random()|0;goog.string.createUniqueString=function(){return"goog_"+goog.string.uniqueStringCounter_++};goog.string.toNumber=function(a){var b=Number(a);return 0==b&&goog.string.isEmptyOrWhitespace(a)?NaN:b};
goog.string.isLowerCamelCase=function(a){return/^[a-z]+([A-Z][a-z]*)*$/.test(a)};goog.string.isUpperCamelCase=function(a){return/^([A-Z][a-z]*)+$/.test(a)};goog.string.toCamelCase=function(a){return String(a).replace(/\-([a-z])/g,function(a,c){return c.toUpperCase()})};goog.string.toSelectorCase=function(a){return String(a).replace(/([A-Z])/g,"-$1").toLowerCase()};
goog.string.toTitleCase=function(a,b){var c=goog.isString(b)?goog.string.regExpEscape(b):"\\s";return a.replace(new RegExp("(^"+(c?"|["+c+"]+":"")+")([a-z])","g"),function(a,b,c){return b+c.toUpperCase()})};goog.string.capitalize=function(a){return String(a.charAt(0)).toUpperCase()+String(a.substr(1)).toLowerCase()};goog.string.parseInt=function(a){isFinite(a)&&(a=String(a));return goog.isString(a)?/^\s*-?0x/i.test(a)?parseInt(a,16):parseInt(a,10):NaN};
goog.string.splitLimit=function(a,b,c){a=a.split(b);for(var d=[];0<c&&a.length;)d.push(a.shift()),c--;a.length&&d.push(a.join(b));return d};goog.string.lastComponent=function(a,b){if(b)"string"==typeof b&&(b=[b]);else return a;for(var c=-1,d=0;d<b.length;d++)if(""!=b[d]){var e=a.lastIndexOf(b[d]);e>c&&(c=e)}return-1==c?a:a.slice(c+1)};
goog.string.editDistance=function(a,b){var c=[],d=[];if(a==b)return 0;if(!a.length||!b.length)return Math.max(a.length,b.length);for(var e=0;e<b.length+1;e++)c[e]=e;for(e=0;e<a.length;e++){d[0]=e+1;for(var f=0;f<b.length;f++)d[f+1]=Math.min(d[f]+1,c[f+1]+1,c[f]+Number(a[e]!=b[f]));for(f=0;f<c.length;f++)c[f]=d[f]}return d[b.length]};goog.asserts={};goog.asserts.ENABLE_ASSERTS=goog.DEBUG;goog.asserts.AssertionError=function(a,b){b.unshift(a);goog.debug.Error.call(this,goog.string.subs.apply(null,b));b.shift();this.messagePattern=a};goog.inherits(goog.asserts.AssertionError,goog.debug.Error);goog.asserts.AssertionError.prototype.name="AssertionError";goog.asserts.DEFAULT_ERROR_HANDLER=function(a){throw a;};goog.asserts.errorHandler_=goog.asserts.DEFAULT_ERROR_HANDLER;
goog.asserts.doAssertFailure_=function(a,b,c,d){var e="Assertion failed";if(c)var e=e+(": "+c),f=d;else a&&(e+=": "+a,f=b);a=new goog.asserts.AssertionError(""+e,f||[]);goog.asserts.errorHandler_(a)};goog.asserts.setErrorHandler=function(a){goog.asserts.ENABLE_ASSERTS&&(goog.asserts.errorHandler_=a)};goog.asserts.assert=function(a,b,c){goog.asserts.ENABLE_ASSERTS&&!a&&goog.asserts.doAssertFailure_("",null,b,Array.prototype.slice.call(arguments,2));return a};
goog.asserts.fail=function(a,b){goog.asserts.ENABLE_ASSERTS&&goog.asserts.errorHandler_(new goog.asserts.AssertionError("Failure"+(a?": "+a:""),Array.prototype.slice.call(arguments,1)))};goog.asserts.assertNumber=function(a,b,c){goog.asserts.ENABLE_ASSERTS&&!goog.isNumber(a)&&goog.asserts.doAssertFailure_("Expected number but got %s: %s.",[goog.typeOf(a),a],b,Array.prototype.slice.call(arguments,2));return a};
goog.asserts.assertString=function(a,b,c){goog.asserts.ENABLE_ASSERTS&&!goog.isString(a)&&goog.asserts.doAssertFailure_("Expected string but got %s: %s.",[goog.typeOf(a),a],b,Array.prototype.slice.call(arguments,2));return a};goog.asserts.assertFunction=function(a,b,c){goog.asserts.ENABLE_ASSERTS&&!goog.isFunction(a)&&goog.asserts.doAssertFailure_("Expected function but got %s: %s.",[goog.typeOf(a),a],b,Array.prototype.slice.call(arguments,2));return a};
goog.asserts.assertObject=function(a,b,c){goog.asserts.ENABLE_ASSERTS&&!goog.isObject(a)&&goog.asserts.doAssertFailure_("Expected object but got %s: %s.",[goog.typeOf(a),a],b,Array.prototype.slice.call(arguments,2));return a};goog.asserts.assertArray=function(a,b,c){goog.asserts.ENABLE_ASSERTS&&!goog.isArray(a)&&goog.asserts.doAssertFailure_("Expected array but got %s: %s.",[goog.typeOf(a),a],b,Array.prototype.slice.call(arguments,2));return a};
goog.asserts.assertBoolean=function(a,b,c){goog.asserts.ENABLE_ASSERTS&&!goog.isBoolean(a)&&goog.asserts.doAssertFailure_("Expected boolean but got %s: %s.",[goog.typeOf(a),a],b,Array.prototype.slice.call(arguments,2));return a};goog.asserts.assertElement=function(a,b,c){!goog.asserts.ENABLE_ASSERTS||goog.isObject(a)&&a.nodeType==goog.dom.NodeType.ELEMENT||goog.asserts.doAssertFailure_("Expected Element but got %s: %s.",[goog.typeOf(a),a],b,Array.prototype.slice.call(arguments,2));return a};
goog.asserts.assertInstanceof=function(a,b,c,d){!goog.asserts.ENABLE_ASSERTS||a instanceof b||goog.asserts.doAssertFailure_("Expected instanceof %s but got %s.",[goog.asserts.getType_(b),goog.asserts.getType_(a)],c,Array.prototype.slice.call(arguments,3));return a};goog.asserts.assertObjectPrototypeIsIntact=function(){for(var a in Object.prototype)goog.asserts.fail(a+" should not be enumerable in Object.prototype.")};
goog.asserts.getType_=function(a){return a instanceof Function?a.displayName||a.name||"unknown type name":a instanceof Object?a.constructor.displayName||a.constructor.name||Object.prototype.toString.call(a):null===a?"null":typeof a};goog.async={};goog.async.FreeList=function(a,b,c){this.limit_=c;this.create_=a;this.reset_=b;this.occupants_=0;this.head_=null};goog.async.FreeList.prototype.get=function(){var a;0<this.occupants_?(this.occupants_--,a=this.head_,this.head_=a.next,a.next=null):a=this.create_();return a};goog.async.FreeList.prototype.put=function(a){this.reset_(a);this.occupants_<this.limit_&&(this.occupants_++,a.next=this.head_,this.head_=a)};goog.async.FreeList.prototype.occupants=function(){return this.occupants_};goog.async.WorkQueue=function(){this.workTail_=this.workHead_=null};goog.async.WorkQueue.DEFAULT_MAX_UNUSED=100;goog.async.WorkQueue.freelist_=new goog.async.FreeList(function(){return new goog.async.WorkItem},function(a){a.reset()},goog.async.WorkQueue.DEFAULT_MAX_UNUSED);goog.async.WorkQueue.prototype.add=function(a,b){var c=this.getUnusedItem_();c.set(a,b);this.workTail_?this.workTail_.next=c:(goog.asserts.assert(!this.workHead_),this.workHead_=c);this.workTail_=c};
goog.async.WorkQueue.prototype.remove=function(){var a=null;this.workHead_&&(a=this.workHead_,this.workHead_=this.workHead_.next,this.workHead_||(this.workTail_=null),a.next=null);return a};goog.async.WorkQueue.prototype.returnUnused=function(a){goog.async.WorkQueue.freelist_.put(a)};goog.async.WorkQueue.prototype.getUnusedItem_=function(){return goog.async.WorkQueue.freelist_.get()};goog.async.WorkItem=function(){this.next=this.scope=this.fn=null};
goog.async.WorkItem.prototype.set=function(a,b){this.fn=a;this.scope=b;this.next=null};goog.async.WorkItem.prototype.reset=function(){this.next=this.scope=this.fn=null};goog.debug.entryPointRegistry={};goog.debug.EntryPointMonitor=function(){};goog.debug.entryPointRegistry.refList_=[];goog.debug.entryPointRegistry.monitors_=[];goog.debug.entryPointRegistry.monitorsMayExist_=!1;goog.debug.entryPointRegistry.register=function(a){goog.debug.entryPointRegistry.refList_[goog.debug.entryPointRegistry.refList_.length]=a;if(goog.debug.entryPointRegistry.monitorsMayExist_)for(var b=goog.debug.entryPointRegistry.monitors_,c=0;c<b.length;c++)a(goog.bind(b[c].wrap,b[c]))};
goog.debug.entryPointRegistry.monitorAll=function(a){goog.debug.entryPointRegistry.monitorsMayExist_=!0;for(var b=goog.bind(a.wrap,a),c=0;c<goog.debug.entryPointRegistry.refList_.length;c++)goog.debug.entryPointRegistry.refList_[c](b);goog.debug.entryPointRegistry.monitors_.push(a)};
goog.debug.entryPointRegistry.unmonitorAllIfPossible=function(a){var b=goog.debug.entryPointRegistry.monitors_;goog.asserts.assert(a==b[b.length-1],"Only the most recent monitor can be unwrapped.");a=goog.bind(a.unwrap,a);for(var c=0;c<goog.debug.entryPointRegistry.refList_.length;c++)goog.debug.entryPointRegistry.refList_[c](a);b.length--};goog.dom.TagName=function(a){this.tagName_=a};goog.dom.TagName.prototype.toString=function(){return this.tagName_};goog.dom.TagName.A=new goog.dom.TagName("A");goog.dom.TagName.ABBR=new goog.dom.TagName("ABBR");goog.dom.TagName.ACRONYM=new goog.dom.TagName("ACRONYM");goog.dom.TagName.ADDRESS=new goog.dom.TagName("ADDRESS");goog.dom.TagName.APPLET=new goog.dom.TagName("APPLET");goog.dom.TagName.AREA=new goog.dom.TagName("AREA");goog.dom.TagName.ARTICLE=new goog.dom.TagName("ARTICLE");
goog.dom.TagName.ASIDE=new goog.dom.TagName("ASIDE");goog.dom.TagName.AUDIO=new goog.dom.TagName("AUDIO");goog.dom.TagName.B=new goog.dom.TagName("B");goog.dom.TagName.BASE=new goog.dom.TagName("BASE");goog.dom.TagName.BASEFONT=new goog.dom.TagName("BASEFONT");goog.dom.TagName.BDI=new goog.dom.TagName("BDI");goog.dom.TagName.BDO=new goog.dom.TagName("BDO");goog.dom.TagName.BIG=new goog.dom.TagName("BIG");goog.dom.TagName.BLOCKQUOTE=new goog.dom.TagName("BLOCKQUOTE");goog.dom.TagName.BODY=new goog.dom.TagName("BODY");
goog.dom.TagName.BR=new goog.dom.TagName("BR");goog.dom.TagName.BUTTON=new goog.dom.TagName("BUTTON");goog.dom.TagName.CANVAS=new goog.dom.TagName("CANVAS");goog.dom.TagName.CAPTION=new goog.dom.TagName("CAPTION");goog.dom.TagName.CENTER=new goog.dom.TagName("CENTER");goog.dom.TagName.CITE=new goog.dom.TagName("CITE");goog.dom.TagName.CODE=new goog.dom.TagName("CODE");goog.dom.TagName.COL=new goog.dom.TagName("COL");goog.dom.TagName.COLGROUP=new goog.dom.TagName("COLGROUP");
goog.dom.TagName.COMMAND=new goog.dom.TagName("COMMAND");goog.dom.TagName.DATA=new goog.dom.TagName("DATA");goog.dom.TagName.DATALIST=new goog.dom.TagName("DATALIST");goog.dom.TagName.DD=new goog.dom.TagName("DD");goog.dom.TagName.DEL=new goog.dom.TagName("DEL");goog.dom.TagName.DETAILS=new goog.dom.TagName("DETAILS");goog.dom.TagName.DFN=new goog.dom.TagName("DFN");goog.dom.TagName.DIALOG=new goog.dom.TagName("DIALOG");goog.dom.TagName.DIR=new goog.dom.TagName("DIR");goog.dom.TagName.DIV=new goog.dom.TagName("DIV");
goog.dom.TagName.DL=new goog.dom.TagName("DL");goog.dom.TagName.DT=new goog.dom.TagName("DT");goog.dom.TagName.EM=new goog.dom.TagName("EM");goog.dom.TagName.EMBED=new goog.dom.TagName("EMBED");goog.dom.TagName.FIELDSET=new goog.dom.TagName("FIELDSET");goog.dom.TagName.FIGCAPTION=new goog.dom.TagName("FIGCAPTION");goog.dom.TagName.FIGURE=new goog.dom.TagName("FIGURE");goog.dom.TagName.FONT=new goog.dom.TagName("FONT");goog.dom.TagName.FOOTER=new goog.dom.TagName("FOOTER");goog.dom.TagName.FORM=new goog.dom.TagName("FORM");
goog.dom.TagName.FRAME=new goog.dom.TagName("FRAME");goog.dom.TagName.FRAMESET=new goog.dom.TagName("FRAMESET");goog.dom.TagName.H1=new goog.dom.TagName("H1");goog.dom.TagName.H2=new goog.dom.TagName("H2");goog.dom.TagName.H3=new goog.dom.TagName("H3");goog.dom.TagName.H4=new goog.dom.TagName("H4");goog.dom.TagName.H5=new goog.dom.TagName("H5");goog.dom.TagName.H6=new goog.dom.TagName("H6");goog.dom.TagName.HEAD=new goog.dom.TagName("HEAD");goog.dom.TagName.HEADER=new goog.dom.TagName("HEADER");
goog.dom.TagName.HGROUP=new goog.dom.TagName("HGROUP");goog.dom.TagName.HR=new goog.dom.TagName("HR");goog.dom.TagName.HTML=new goog.dom.TagName("HTML");goog.dom.TagName.I=new goog.dom.TagName("I");goog.dom.TagName.IFRAME=new goog.dom.TagName("IFRAME");goog.dom.TagName.IMG=new goog.dom.TagName("IMG");goog.dom.TagName.INPUT=new goog.dom.TagName("INPUT");goog.dom.TagName.INS=new goog.dom.TagName("INS");goog.dom.TagName.ISINDEX=new goog.dom.TagName("ISINDEX");goog.dom.TagName.KBD=new goog.dom.TagName("KBD");
goog.dom.TagName.KEYGEN=new goog.dom.TagName("KEYGEN");goog.dom.TagName.LABEL=new goog.dom.TagName("LABEL");goog.dom.TagName.LEGEND=new goog.dom.TagName("LEGEND");goog.dom.TagName.LI=new goog.dom.TagName("LI");goog.dom.TagName.LINK=new goog.dom.TagName("LINK");goog.dom.TagName.MAP=new goog.dom.TagName("MAP");goog.dom.TagName.MARK=new goog.dom.TagName("MARK");goog.dom.TagName.MATH=new goog.dom.TagName("MATH");goog.dom.TagName.MENU=new goog.dom.TagName("MENU");goog.dom.TagName.META=new goog.dom.TagName("META");
goog.dom.TagName.METER=new goog.dom.TagName("METER");goog.dom.TagName.NAV=new goog.dom.TagName("NAV");goog.dom.TagName.NOFRAMES=new goog.dom.TagName("NOFRAMES");goog.dom.TagName.NOSCRIPT=new goog.dom.TagName("NOSCRIPT");goog.dom.TagName.OBJECT=new goog.dom.TagName("OBJECT");goog.dom.TagName.OL=new goog.dom.TagName("OL");goog.dom.TagName.OPTGROUP=new goog.dom.TagName("OPTGROUP");goog.dom.TagName.OPTION=new goog.dom.TagName("OPTION");goog.dom.TagName.OUTPUT=new goog.dom.TagName("OUTPUT");
goog.dom.TagName.P=new goog.dom.TagName("P");goog.dom.TagName.PARAM=new goog.dom.TagName("PARAM");goog.dom.TagName.PRE=new goog.dom.TagName("PRE");goog.dom.TagName.PROGRESS=new goog.dom.TagName("PROGRESS");goog.dom.TagName.Q=new goog.dom.TagName("Q");goog.dom.TagName.RP=new goog.dom.TagName("RP");goog.dom.TagName.RT=new goog.dom.TagName("RT");goog.dom.TagName.RUBY=new goog.dom.TagName("RUBY");goog.dom.TagName.S=new goog.dom.TagName("S");goog.dom.TagName.SAMP=new goog.dom.TagName("SAMP");
goog.dom.TagName.SCRIPT=new goog.dom.TagName("SCRIPT");goog.dom.TagName.SECTION=new goog.dom.TagName("SECTION");goog.dom.TagName.SELECT=new goog.dom.TagName("SELECT");goog.dom.TagName.SMALL=new goog.dom.TagName("SMALL");goog.dom.TagName.SOURCE=new goog.dom.TagName("SOURCE");goog.dom.TagName.SPAN=new goog.dom.TagName("SPAN");goog.dom.TagName.STRIKE=new goog.dom.TagName("STRIKE");goog.dom.TagName.STRONG=new goog.dom.TagName("STRONG");goog.dom.TagName.STYLE=new goog.dom.TagName("STYLE");
goog.dom.TagName.SUB=new goog.dom.TagName("SUB");goog.dom.TagName.SUMMARY=new goog.dom.TagName("SUMMARY");goog.dom.TagName.SUP=new goog.dom.TagName("SUP");goog.dom.TagName.SVG=new goog.dom.TagName("SVG");goog.dom.TagName.TABLE=new goog.dom.TagName("TABLE");goog.dom.TagName.TBODY=new goog.dom.TagName("TBODY");goog.dom.TagName.TD=new goog.dom.TagName("TD");goog.dom.TagName.TEMPLATE=new goog.dom.TagName("TEMPLATE");goog.dom.TagName.TEXTAREA=new goog.dom.TagName("TEXTAREA");goog.dom.TagName.TFOOT=new goog.dom.TagName("TFOOT");
goog.dom.TagName.TH=new goog.dom.TagName("TH");goog.dom.TagName.THEAD=new goog.dom.TagName("THEAD");goog.dom.TagName.TIME=new goog.dom.TagName("TIME");goog.dom.TagName.TITLE=new goog.dom.TagName("TITLE");goog.dom.TagName.TR=new goog.dom.TagName("TR");goog.dom.TagName.TRACK=new goog.dom.TagName("TRACK");goog.dom.TagName.TT=new goog.dom.TagName("TT");goog.dom.TagName.U=new goog.dom.TagName("U");goog.dom.TagName.UL=new goog.dom.TagName("UL");goog.dom.TagName.VAR=new goog.dom.TagName("VAR");
goog.dom.TagName.VIDEO=new goog.dom.TagName("VIDEO");goog.dom.TagName.WBR=new goog.dom.TagName("WBR");goog.functions={};goog.functions.constant=function(a){return function(){return a}};goog.functions.FALSE=goog.functions.constant(!1);goog.functions.TRUE=goog.functions.constant(!0);goog.functions.NULL=goog.functions.constant(null);goog.functions.identity=function(a,b){return a};goog.functions.error=function(a){return function(){throw Error(a);}};goog.functions.fail=function(a){return function(){throw a;}};
goog.functions.lock=function(a,b){b=b||0;return function(){return a.apply(this,Array.prototype.slice.call(arguments,0,b))}};goog.functions.nth=function(a){return function(){return arguments[a]}};goog.functions.partialRight=function(a,b){var c=Array.prototype.slice.call(arguments,1);return function(){var b=Array.prototype.slice.call(arguments);b.push.apply(b,c);return a.apply(this,b)}};goog.functions.withReturnValue=function(a,b){return goog.functions.sequence(a,goog.functions.constant(b))};
goog.functions.equalTo=function(a,b){return function(c){return b?a==c:a===c}};goog.functions.compose=function(a,b){var c=arguments,d=c.length;return function(){var a;d&&(a=c[d-1].apply(this,arguments));for(var b=d-2;0<=b;b--)a=c[b].call(this,a);return a}};goog.functions.sequence=function(a){var b=arguments,c=b.length;return function(){for(var a,e=0;e<c;e++)a=b[e].apply(this,arguments);return a}};
goog.functions.and=function(a){var b=arguments,c=b.length;return function(){for(var a=0;a<c;a++)if(!b[a].apply(this,arguments))return!1;return!0}};goog.functions.or=function(a){var b=arguments,c=b.length;return function(){for(var a=0;a<c;a++)if(b[a].apply(this,arguments))return!0;return!1}};goog.functions.not=function(a){return function(){return!a.apply(this,arguments)}};
goog.functions.create=function(a,b){var c=function(){};c.prototype=a.prototype;c=new c;a.apply(c,Array.prototype.slice.call(arguments,1));return c};goog.functions.CACHE_RETURN_VALUE=!0;goog.functions.cacheReturnValue=function(a){var b=!1,c;return function(){if(!goog.functions.CACHE_RETURN_VALUE)return a();b||(c=a(),b=!0);return c}};goog.functions.once=function(a){var b=a;return function(){if(b){var a=b;b=null;a()}}};
goog.functions.debounce=function(a,b,c){var d=0;return function(e){goog.global.clearTimeout(d);var f=arguments;d=goog.global.setTimeout(function(){a.apply(c,f)},b)}};goog.functions.throttle=function(a,b,c){var d=0,e=!1,f=[],g=function(){d=0;e&&(e=!1,h())},h=function(){d=goog.global.setTimeout(g,b);a.apply(c,f)};return function(a){f=arguments;d?e=!0:h()}};goog.functions.rateLimit=function(a,b,c){var d=0,e=function(){d=0};return function(f){d||(d=goog.global.setTimeout(e,b),a.apply(c,arguments))}};goog.array={};goog.NATIVE_ARRAY_PROTOTYPES=goog.TRUSTED_SITE;goog.array.ASSUME_NATIVE_FUNCTIONS=!1;goog.array.peek=function(a){return a[a.length-1]};goog.array.last=goog.array.peek;
goog.array.indexOf=goog.NATIVE_ARRAY_PROTOTYPES&&(goog.array.ASSUME_NATIVE_FUNCTIONS||Array.prototype.indexOf)?function(a,b,c){goog.asserts.assert(null!=a.length);return Array.prototype.indexOf.call(a,b,c)}:function(a,b,c){c=null==c?0:0>c?Math.max(0,a.length+c):c;if(goog.isString(a))return goog.isString(b)&&1==b.length?a.indexOf(b,c):-1;for(;c<a.length;c++)if(c in a&&a[c]===b)return c;return-1};
goog.array.lastIndexOf=goog.NATIVE_ARRAY_PROTOTYPES&&(goog.array.ASSUME_NATIVE_FUNCTIONS||Array.prototype.lastIndexOf)?function(a,b,c){goog.asserts.assert(null!=a.length);return Array.prototype.lastIndexOf.call(a,b,null==c?a.length-1:c)}:function(a,b,c){c=null==c?a.length-1:c;0>c&&(c=Math.max(0,a.length+c));if(goog.isString(a))return goog.isString(b)&&1==b.length?a.lastIndexOf(b,c):-1;for(;0<=c;c--)if(c in a&&a[c]===b)return c;return-1};
goog.array.forEach=goog.NATIVE_ARRAY_PROTOTYPES&&(goog.array.ASSUME_NATIVE_FUNCTIONS||Array.prototype.forEach)?function(a,b,c){goog.asserts.assert(null!=a.length);Array.prototype.forEach.call(a,b,c)}:function(a,b,c){for(var d=a.length,e=goog.isString(a)?a.split(""):a,f=0;f<d;f++)f in e&&b.call(c,e[f],f,a)};goog.array.forEachRight=function(a,b,c){for(var d=a.length,e=goog.isString(a)?a.split(""):a,d=d-1;0<=d;--d)d in e&&b.call(c,e[d],d,a)};
goog.array.filter=goog.NATIVE_ARRAY_PROTOTYPES&&(goog.array.ASSUME_NATIVE_FUNCTIONS||Array.prototype.filter)?function(a,b,c){goog.asserts.assert(null!=a.length);return Array.prototype.filter.call(a,b,c)}:function(a,b,c){for(var d=a.length,e=[],f=0,g=goog.isString(a)?a.split(""):a,h=0;h<d;h++)if(h in g){var k=g[h];b.call(c,k,h,a)&&(e[f++]=k)}return e};
goog.array.map=goog.NATIVE_ARRAY_PROTOTYPES&&(goog.array.ASSUME_NATIVE_FUNCTIONS||Array.prototype.map)?function(a,b,c){goog.asserts.assert(null!=a.length);return Array.prototype.map.call(a,b,c)}:function(a,b,c){for(var d=a.length,e=Array(d),f=goog.isString(a)?a.split(""):a,g=0;g<d;g++)g in f&&(e[g]=b.call(c,f[g],g,a));return e};
goog.array.reduce=goog.NATIVE_ARRAY_PROTOTYPES&&(goog.array.ASSUME_NATIVE_FUNCTIONS||Array.prototype.reduce)?function(a,b,c,d){goog.asserts.assert(null!=a.length);d&&(b=goog.bind(b,d));return Array.prototype.reduce.call(a,b,c)}:function(a,b,c,d){var e=c;goog.array.forEach(a,function(c,g){e=b.call(d,e,c,g,a)});return e};
goog.array.reduceRight=goog.NATIVE_ARRAY_PROTOTYPES&&(goog.array.ASSUME_NATIVE_FUNCTIONS||Array.prototype.reduceRight)?function(a,b,c,d){goog.asserts.assert(null!=a.length);goog.asserts.assert(null!=b);d&&(b=goog.bind(b,d));return Array.prototype.reduceRight.call(a,b,c)}:function(a,b,c,d){var e=c;goog.array.forEachRight(a,function(c,g){e=b.call(d,e,c,g,a)});return e};
goog.array.some=goog.NATIVE_ARRAY_PROTOTYPES&&(goog.array.ASSUME_NATIVE_FUNCTIONS||Array.prototype.some)?function(a,b,c){goog.asserts.assert(null!=a.length);return Array.prototype.some.call(a,b,c)}:function(a,b,c){for(var d=a.length,e=goog.isString(a)?a.split(""):a,f=0;f<d;f++)if(f in e&&b.call(c,e[f],f,a))return!0;return!1};
goog.array.every=goog.NATIVE_ARRAY_PROTOTYPES&&(goog.array.ASSUME_NATIVE_FUNCTIONS||Array.prototype.every)?function(a,b,c){goog.asserts.assert(null!=a.length);return Array.prototype.every.call(a,b,c)}:function(a,b,c){for(var d=a.length,e=goog.isString(a)?a.split(""):a,f=0;f<d;f++)if(f in e&&!b.call(c,e[f],f,a))return!1;return!0};goog.array.count=function(a,b,c){var d=0;goog.array.forEach(a,function(a,f,g){b.call(c,a,f,g)&&++d},c);return d};
goog.array.find=function(a,b,c){b=goog.array.findIndex(a,b,c);return 0>b?null:goog.isString(a)?a.charAt(b):a[b]};goog.array.findIndex=function(a,b,c){for(var d=a.length,e=goog.isString(a)?a.split(""):a,f=0;f<d;f++)if(f in e&&b.call(c,e[f],f,a))return f;return-1};goog.array.findRight=function(a,b,c){b=goog.array.findIndexRight(a,b,c);return 0>b?null:goog.isString(a)?a.charAt(b):a[b]};
goog.array.findIndexRight=function(a,b,c){for(var d=a.length,e=goog.isString(a)?a.split(""):a,d=d-1;0<=d;d--)if(d in e&&b.call(c,e[d],d,a))return d;return-1};goog.array.contains=function(a,b){return 0<=goog.array.indexOf(a,b)};goog.array.isEmpty=function(a){return 0==a.length};goog.array.clear=function(a){if(!goog.isArray(a))for(var b=a.length-1;0<=b;b--)delete a[b];a.length=0};goog.array.insert=function(a,b){goog.array.contains(a,b)||a.push(b)};
goog.array.insertAt=function(a,b,c){goog.array.splice(a,c,0,b)};goog.array.insertArrayAt=function(a,b,c){goog.partial(goog.array.splice,a,c,0).apply(null,b)};goog.array.insertBefore=function(a,b,c){var d;2==arguments.length||0>(d=goog.array.indexOf(a,c))?a.push(b):goog.array.insertAt(a,b,d)};goog.array.remove=function(a,b){var c=goog.array.indexOf(a,b),d;(d=0<=c)&&goog.array.removeAt(a,c);return d};
goog.array.removeLast=function(a,b){var c=goog.array.lastIndexOf(a,b);return 0<=c?(goog.array.removeAt(a,c),!0):!1};goog.array.removeAt=function(a,b){goog.asserts.assert(null!=a.length);return 1==Array.prototype.splice.call(a,b,1).length};goog.array.removeIf=function(a,b,c){b=goog.array.findIndex(a,b,c);return 0<=b?(goog.array.removeAt(a,b),!0):!1};goog.array.removeAllIf=function(a,b,c){var d=0;goog.array.forEachRight(a,function(e,f){b.call(c,e,f,a)&&goog.array.removeAt(a,f)&&d++});return d};
goog.array.concat=function(a){return Array.prototype.concat.apply(Array.prototype,arguments)};goog.array.join=function(a){return Array.prototype.concat.apply(Array.prototype,arguments)};goog.array.toArray=function(a){var b=a.length;if(0<b){for(var c=Array(b),d=0;d<b;d++)c[d]=a[d];return c}return[]};goog.array.clone=goog.array.toArray;
goog.array.extend=function(a,b){for(var c=1;c<arguments.length;c++){var d=arguments[c];if(goog.isArrayLike(d)){var e=a.length||0,f=d.length||0;a.length=e+f;for(var g=0;g<f;g++)a[e+g]=d[g]}else a.push(d)}};goog.array.splice=function(a,b,c,d){goog.asserts.assert(null!=a.length);return Array.prototype.splice.apply(a,goog.array.slice(arguments,1))};
goog.array.slice=function(a,b,c){goog.asserts.assert(null!=a.length);return 2>=arguments.length?Array.prototype.slice.call(a,b):Array.prototype.slice.call(a,b,c)};goog.array.removeDuplicates=function(a,b,c){b=b||a;var d=function(a){return goog.isObject(a)?"o"+goog.getUid(a):(typeof a).charAt(0)+a};c=c||d;for(var d={},e=0,f=0;f<a.length;){var g=a[f++],h=c(g);Object.prototype.hasOwnProperty.call(d,h)||(d[h]=!0,b[e++]=g)}b.length=e};
goog.array.binarySearch=function(a,b,c){return goog.array.binarySearch_(a,c||goog.array.defaultCompare,!1,b)};goog.array.binarySelect=function(a,b,c){return goog.array.binarySearch_(a,b,!0,void 0,c)};goog.array.binarySearch_=function(a,b,c,d,e){for(var f=0,g=a.length,h;f<g;){var k=f+g>>1,l;l=c?b.call(e,a[k],k,a):b(d,a[k]);0<l?f=k+1:(g=k,h=!l)}return h?f:~f};goog.array.sort=function(a,b){a.sort(b||goog.array.defaultCompare)};
goog.array.stableSort=function(a,b){for(var c=Array(a.length),d=0;d<a.length;d++)c[d]={index:d,value:a[d]};var e=b||goog.array.defaultCompare;goog.array.sort(c,function(a,b){return e(a.value,b.value)||a.index-b.index});for(d=0;d<a.length;d++)a[d]=c[d].value};goog.array.sortByKey=function(a,b,c){var d=c||goog.array.defaultCompare;goog.array.sort(a,function(a,c){return d(b(a),b(c))})};goog.array.sortObjectsByKey=function(a,b,c){goog.array.sortByKey(a,function(a){return a[b]},c)};
goog.array.isSorted=function(a,b,c){b=b||goog.array.defaultCompare;for(var d=1;d<a.length;d++){var e=b(a[d-1],a[d]);if(0<e||0==e&&c)return!1}return!0};goog.array.equals=function(a,b,c){if(!goog.isArrayLike(a)||!goog.isArrayLike(b)||a.length!=b.length)return!1;var d=a.length;c=c||goog.array.defaultCompareEquality;for(var e=0;e<d;e++)if(!c(a[e],b[e]))return!1;return!0};
goog.array.compare3=function(a,b,c){c=c||goog.array.defaultCompare;for(var d=Math.min(a.length,b.length),e=0;e<d;e++){var f=c(a[e],b[e]);if(0!=f)return f}return goog.array.defaultCompare(a.length,b.length)};goog.array.defaultCompare=function(a,b){return a>b?1:a<b?-1:0};goog.array.inverseDefaultCompare=function(a,b){return-goog.array.defaultCompare(a,b)};goog.array.defaultCompareEquality=function(a,b){return a===b};
goog.array.binaryInsert=function(a,b,c){c=goog.array.binarySearch(a,b,c);return 0>c?(goog.array.insertAt(a,b,-(c+1)),!0):!1};goog.array.binaryRemove=function(a,b,c){b=goog.array.binarySearch(a,b,c);return 0<=b?goog.array.removeAt(a,b):!1};goog.array.bucket=function(a,b,c){for(var d={},e=0;e<a.length;e++){var f=a[e],g=b.call(c,f,e,a);goog.isDef(g)&&(d[g]||(d[g]=[])).push(f)}return d};goog.array.toObject=function(a,b,c){var d={};goog.array.forEach(a,function(e,f){d[b.call(c,e,f,a)]=e});return d};
goog.array.range=function(a,b,c){var d=[],e=0,f=a;c=c||1;void 0!==b&&(e=a,f=b);if(0>c*(f-e))return[];if(0<c)for(a=e;a<f;a+=c)d.push(a);else for(a=e;a>f;a+=c)d.push(a);return d};goog.array.repeat=function(a,b){for(var c=[],d=0;d<b;d++)c[d]=a;return c};goog.array.flatten=function(a){for(var b=[],c=0;c<arguments.length;c++){var d=arguments[c];if(goog.isArray(d))for(var e=0;e<d.length;e+=8192)for(var f=goog.array.slice(d,e,e+8192),f=goog.array.flatten.apply(null,f),g=0;g<f.length;g++)b.push(f[g]);else b.push(d)}return b};
goog.array.rotate=function(a,b){goog.asserts.assert(null!=a.length);a.length&&(b%=a.length,0<b?Array.prototype.unshift.apply(a,a.splice(-b,b)):0>b&&Array.prototype.push.apply(a,a.splice(0,-b)));return a};goog.array.moveItem=function(a,b,c){goog.asserts.assert(0<=b&&b<a.length);goog.asserts.assert(0<=c&&c<a.length);b=Array.prototype.splice.call(a,b,1);Array.prototype.splice.call(a,c,0,b[0])};
goog.array.zip=function(a){if(!arguments.length)return[];for(var b=[],c=arguments[0].length,d=1;d<arguments.length;d++)arguments[d].length<c&&(c=arguments[d].length);for(d=0;d<c;d++){for(var e=[],f=0;f<arguments.length;f++)e.push(arguments[f][d]);b.push(e)}return b};goog.array.shuffle=function(a,b){for(var c=b||Math.random,d=a.length-1;0<d;d--){var e=Math.floor(c()*(d+1)),f=a[d];a[d]=a[e];a[e]=f}};goog.array.copyByIndex=function(a,b){var c=[];goog.array.forEach(b,function(b){c.push(a[b])});return c};
goog.array.concatMap=function(a,b,c){return goog.array.concat.apply([],goog.array.map(a,b,c))};goog.labs={};goog.labs.userAgent={};goog.labs.userAgent.util={};goog.labs.userAgent.util.getNativeUserAgentString_=function(){var a=goog.labs.userAgent.util.getNavigator_();return a&&(a=a.userAgent)?a:""};goog.labs.userAgent.util.getNavigator_=function(){return goog.global.navigator};goog.labs.userAgent.util.userAgent_=goog.labs.userAgent.util.getNativeUserAgentString_();goog.labs.userAgent.util.setUserAgent=function(a){goog.labs.userAgent.util.userAgent_=a||goog.labs.userAgent.util.getNativeUserAgentString_()};
goog.labs.userAgent.util.getUserAgent=function(){return goog.labs.userAgent.util.userAgent_};goog.labs.userAgent.util.matchUserAgent=function(a){var b=goog.labs.userAgent.util.getUserAgent();return goog.string.contains(b,a)};goog.labs.userAgent.util.matchUserAgentIgnoreCase=function(a){var b=goog.labs.userAgent.util.getUserAgent();return goog.string.caseInsensitiveContains(b,a)};
goog.labs.userAgent.util.extractVersionTuples=function(a){for(var b=RegExp("(\\w[\\w ]+)/([^\\s]+)\\s*(?:\\((.*?)\\))?","g"),c=[],d;d=b.exec(a);)c.push([d[1],d[2],d[3]||void 0]);return c};goog.object={};goog.object.is=function(a,b){return a===b?0!==a||1/a===1/b:a!==a&&b!==b};goog.object.forEach=function(a,b,c){for(var d in a)b.call(c,a[d],d,a)};goog.object.filter=function(a,b,c){var d={},e;for(e in a)b.call(c,a[e],e,a)&&(d[e]=a[e]);return d};goog.object.map=function(a,b,c){var d={},e;for(e in a)d[e]=b.call(c,a[e],e,a);return d};goog.object.some=function(a,b,c){for(var d in a)if(b.call(c,a[d],d,a))return!0;return!1};
goog.object.every=function(a,b,c){for(var d in a)if(!b.call(c,a[d],d,a))return!1;return!0};goog.object.getCount=function(a){var b=0,c;for(c in a)b++;return b};goog.object.getAnyKey=function(a){for(var b in a)return b};goog.object.getAnyValue=function(a){for(var b in a)return a[b]};goog.object.contains=function(a,b){return goog.object.containsValue(a,b)};goog.object.getValues=function(a){var b=[],c=0,d;for(d in a)b[c++]=a[d];return b};
goog.object.getKeys=function(a){var b=[],c=0,d;for(d in a)b[c++]=d;return b};goog.object.getValueByKeys=function(a,b){for(var c=goog.isArrayLike(b),d=c?b:arguments,c=c?0:1;c<d.length&&(a=a[d[c]],goog.isDef(a));c++);return a};goog.object.containsKey=function(a,b){return null!==a&&b in a};goog.object.containsValue=function(a,b){for(var c in a)if(a[c]==b)return!0;return!1};goog.object.findKey=function(a,b,c){for(var d in a)if(b.call(c,a[d],d,a))return d};
goog.object.findValue=function(a,b,c){return(b=goog.object.findKey(a,b,c))&&a[b]};goog.object.isEmpty=function(a){for(var b in a)return!1;return!0};goog.object.clear=function(a){for(var b in a)delete a[b]};goog.object.remove=function(a,b){var c;(c=b in a)&&delete a[b];return c};goog.object.add=function(a,b,c){if(null!==a&&b in a)throw Error('The object already contains the key "'+b+'"');goog.object.set(a,b,c)};goog.object.get=function(a,b,c){return null!==a&&b in a?a[b]:c};
goog.object.set=function(a,b,c){a[b]=c};goog.object.setIfUndefined=function(a,b,c){return b in a?a[b]:a[b]=c};goog.object.setWithReturnValueIfNotSet=function(a,b,c){if(b in a)return a[b];c=c();return a[b]=c};goog.object.equals=function(a,b){for(var c in a)if(!(c in b)||a[c]!==b[c])return!1;for(c in b)if(!(c in a))return!1;return!0};goog.object.clone=function(a){var b={},c;for(c in a)b[c]=a[c];return b};
goog.object.unsafeClone=function(a){var b=goog.typeOf(a);if("object"==b||"array"==b){if(goog.isFunction(a.clone))return a.clone();var b="array"==b?[]:{},c;for(c in a)b[c]=goog.object.unsafeClone(a[c]);return b}return a};goog.object.transpose=function(a){var b={},c;for(c in a)b[a[c]]=c;return b};goog.object.PROTOTYPE_FIELDS_="constructor hasOwnProperty isPrototypeOf propertyIsEnumerable toLocaleString toString valueOf".split(" ");
goog.object.extend=function(a,b){for(var c,d,e=1;e<arguments.length;e++){d=arguments[e];for(c in d)a[c]=d[c];for(var f=0;f<goog.object.PROTOTYPE_FIELDS_.length;f++)c=goog.object.PROTOTYPE_FIELDS_[f],Object.prototype.hasOwnProperty.call(d,c)&&(a[c]=d[c])}};
goog.object.create=function(a){var b=arguments.length;if(1==b&&goog.isArray(arguments[0]))return goog.object.create.apply(null,arguments[0]);if(b%2)throw Error("Uneven number of arguments");for(var c={},d=0;d<b;d+=2)c[arguments[d]]=arguments[d+1];return c};goog.object.createSet=function(a){var b=arguments.length;if(1==b&&goog.isArray(arguments[0]))return goog.object.createSet.apply(null,arguments[0]);for(var c={},d=0;d<b;d++)c[arguments[d]]=!0;return c};
goog.object.createImmutableView=function(a){var b=a;Object.isFrozen&&!Object.isFrozen(a)&&(b=Object.create(a),Object.freeze(b));return b};goog.object.isImmutableView=function(a){return!!Object.isFrozen&&Object.isFrozen(a)};goog.object.getAllPropertyNames=function(a,b){if(!a)return[];if(!Object.getOwnPropertyNames||!Object.getPrototypeOf)return goog.object.getKeys(a);for(var c={},d=a;d&&(d!==Object.prototype||b);){for(var e=Object.getOwnPropertyNames(d),f=0;f<e.length;f++)c[e[f]]=!0;d=Object.getPrototypeOf(d)}return goog.object.getKeys(c)};goog.labs.userAgent.browser={};goog.labs.userAgent.browser.matchOpera_=function(){return goog.labs.userAgent.util.matchUserAgent("Opera")};goog.labs.userAgent.browser.matchIE_=function(){return goog.labs.userAgent.util.matchUserAgent("Trident")||goog.labs.userAgent.util.matchUserAgent("MSIE")};goog.labs.userAgent.browser.matchEdge_=function(){return goog.labs.userAgent.util.matchUserAgent("Edge")};goog.labs.userAgent.browser.matchFirefox_=function(){return goog.labs.userAgent.util.matchUserAgent("Firefox")};
goog.labs.userAgent.browser.matchSafari_=function(){return goog.labs.userAgent.util.matchUserAgent("Safari")&&!(goog.labs.userAgent.browser.matchChrome_()||goog.labs.userAgent.browser.matchCoast_()||goog.labs.userAgent.browser.matchOpera_()||goog.labs.userAgent.browser.matchEdge_()||goog.labs.userAgent.browser.isSilk()||goog.labs.userAgent.util.matchUserAgent("Android"))};goog.labs.userAgent.browser.matchCoast_=function(){return goog.labs.userAgent.util.matchUserAgent("Coast")};
goog.labs.userAgent.browser.matchIosWebview_=function(){return(goog.labs.userAgent.util.matchUserAgent("iPad")||goog.labs.userAgent.util.matchUserAgent("iPhone"))&&!goog.labs.userAgent.browser.matchSafari_()&&!goog.labs.userAgent.browser.matchChrome_()&&!goog.labs.userAgent.browser.matchCoast_()&&goog.labs.userAgent.util.matchUserAgent("AppleWebKit")};
goog.labs.userAgent.browser.matchChrome_=function(){return(goog.labs.userAgent.util.matchUserAgent("Chrome")||goog.labs.userAgent.util.matchUserAgent("CriOS"))&&!goog.labs.userAgent.browser.matchEdge_()};goog.labs.userAgent.browser.matchAndroidBrowser_=function(){return goog.labs.userAgent.util.matchUserAgent("Android")&&!(goog.labs.userAgent.browser.isChrome()||goog.labs.userAgent.browser.isFirefox()||goog.labs.userAgent.browser.isOpera()||goog.labs.userAgent.browser.isSilk())};
goog.labs.userAgent.browser.isOpera=goog.labs.userAgent.browser.matchOpera_;goog.labs.userAgent.browser.isIE=goog.labs.userAgent.browser.matchIE_;goog.labs.userAgent.browser.isEdge=goog.labs.userAgent.browser.matchEdge_;goog.labs.userAgent.browser.isFirefox=goog.labs.userAgent.browser.matchFirefox_;goog.labs.userAgent.browser.isSafari=goog.labs.userAgent.browser.matchSafari_;goog.labs.userAgent.browser.isCoast=goog.labs.userAgent.browser.matchCoast_;goog.labs.userAgent.browser.isIosWebview=goog.labs.userAgent.browser.matchIosWebview_;
goog.labs.userAgent.browser.isChrome=goog.labs.userAgent.browser.matchChrome_;goog.labs.userAgent.browser.isAndroidBrowser=goog.labs.userAgent.browser.matchAndroidBrowser_;goog.labs.userAgent.browser.isSilk=function(){return goog.labs.userAgent.util.matchUserAgent("Silk")};
goog.labs.userAgent.browser.getVersion=function(){function a(a){a=goog.array.find(a,d);return c[a]||""}var b=goog.labs.userAgent.util.getUserAgent();if(goog.labs.userAgent.browser.isIE())return goog.labs.userAgent.browser.getIEVersion_(b);var b=goog.labs.userAgent.util.extractVersionTuples(b),c={};goog.array.forEach(b,function(a){c[a[0]]=a[1]});var d=goog.partial(goog.object.containsKey,c);return goog.labs.userAgent.browser.isOpera()?a(["Version","Opera"]):goog.labs.userAgent.browser.isEdge()?a(["Edge"]):
goog.labs.userAgent.browser.isChrome()?a(["Chrome","CriOS"]):(b=b[2])&&b[1]||""};goog.labs.userAgent.browser.isVersionOrHigher=function(a){return 0<=goog.string.compareVersions(goog.labs.userAgent.browser.getVersion(),a)};
goog.labs.userAgent.browser.getIEVersion_=function(a){var b=/rv: *([\d\.]*)/.exec(a);if(b&&b[1])return b[1];var b="",c=/MSIE +([\d\.]+)/.exec(a);if(c&&c[1])if(a=/Trident\/(\d.\d)/.exec(a),"7.0"==c[1])if(a&&a[1])switch(a[1]){case "4.0":b="8.0";break;case "5.0":b="9.0";break;case "6.0":b="10.0";break;case "7.0":b="11.0"}else b="7.0";else b=c[1];return b};goog.labs.userAgent.engine={};goog.labs.userAgent.engine.isPresto=function(){return goog.labs.userAgent.util.matchUserAgent("Presto")};goog.labs.userAgent.engine.isTrident=function(){return goog.labs.userAgent.util.matchUserAgent("Trident")||goog.labs.userAgent.util.matchUserAgent("MSIE")};goog.labs.userAgent.engine.isEdge=function(){return goog.labs.userAgent.util.matchUserAgent("Edge")};
goog.labs.userAgent.engine.isWebKit=function(){return goog.labs.userAgent.util.matchUserAgentIgnoreCase("WebKit")&&!goog.labs.userAgent.engine.isEdge()};goog.labs.userAgent.engine.isGecko=function(){return goog.labs.userAgent.util.matchUserAgent("Gecko")&&!goog.labs.userAgent.engine.isWebKit()&&!goog.labs.userAgent.engine.isTrident()&&!goog.labs.userAgent.engine.isEdge()};
goog.labs.userAgent.engine.getVersion=function(){var a=goog.labs.userAgent.util.getUserAgent();if(a){var a=goog.labs.userAgent.util.extractVersionTuples(a),b=goog.labs.userAgent.engine.getEngineTuple_(a);if(b)return"Gecko"==b[0]?goog.labs.userAgent.engine.getVersionForKey_(a,"Firefox"):b[1];var a=a[0],c;if(a&&(c=a[2])&&(c=/Trident\/([^\s;]+)/.exec(c)))return c[1]}return""};
goog.labs.userAgent.engine.getEngineTuple_=function(a){if(!goog.labs.userAgent.engine.isEdge())return a[1];for(var b=0;b<a.length;b++){var c=a[b];if("Edge"==c[0])return c}};goog.labs.userAgent.engine.isVersionOrHigher=function(a){return 0<=goog.string.compareVersions(goog.labs.userAgent.engine.getVersion(),a)};goog.labs.userAgent.engine.getVersionForKey_=function(a,b){var c=goog.array.find(a,function(a){return b==a[0]});return c&&c[1]||""};goog.async.throwException=function(a){goog.global.setTimeout(function(){throw a;},0)};goog.async.nextTick=function(a,b,c){var d=a;b&&(d=goog.bind(a,b));d=goog.async.nextTick.wrapCallback_(d);goog.isFunction(goog.global.setImmediate)&&(c||goog.async.nextTick.useSetImmediate_())?goog.global.setImmediate(d):(goog.async.nextTick.setImmediate_||(goog.async.nextTick.setImmediate_=goog.async.nextTick.getSetImmediateEmulator_()),goog.async.nextTick.setImmediate_(d))};
goog.async.nextTick.useSetImmediate_=function(){return goog.global.Window&&goog.global.Window.prototype&&!goog.labs.userAgent.browser.isEdge()&&goog.global.Window.prototype.setImmediate==goog.global.setImmediate?!1:!0};
goog.async.nextTick.getSetImmediateEmulator_=function(){var a=goog.global.MessageChannel;"undefined"===typeof a&&"undefined"!==typeof window&&window.postMessage&&window.addEventListener&&!goog.labs.userAgent.engine.isPresto()&&(a=function(){var a=document.createElement("IFRAME");a.style.display="none";a.src="";document.documentElement.appendChild(a);var b=a.contentWindow,a=b.document;a.open();a.write("");a.close();var c="callImmediate"+Math.random(),d="file:"==b.location.protocol?"*":b.location.protocol+
"//"+b.location.host,a=goog.bind(function(a){if(("*"==d||a.origin==d)&&a.data==c)this.port1.onmessage()},this);b.addEventListener("message",a,!1);this.port1={};this.port2={postMessage:function(){b.postMessage(c,d)}}});if("undefined"!==typeof a&&!goog.labs.userAgent.browser.isIE()){var b=new a,c={},d=c;b.port1.onmessage=function(){if(goog.isDef(c.next)){c=c.next;var a=c.cb;c.cb=null;a()}};return function(a){d.next={cb:a};d=d.next;b.port2.postMessage(0)}}return"undefined"!==typeof document&&"onreadystatechange"in
document.createElement("SCRIPT")?function(a){var b=document.createElement("SCRIPT");b.onreadystatechange=function(){b.onreadystatechange=null;b.parentNode.removeChild(b);b=null;a();a=null};document.documentElement.appendChild(b)}:function(a){goog.global.setTimeout(a,0)}};goog.async.nextTick.wrapCallback_=goog.functions.identity;goog.debug.entryPointRegistry.register(function(a){goog.async.nextTick.wrapCallback_=a});goog.async.run=function(a,b){goog.async.run.schedule_||goog.async.run.initializeRunner_();goog.async.run.workQueueScheduled_||(goog.async.run.schedule_(),goog.async.run.workQueueScheduled_=!0);goog.async.run.workQueue_.add(a,b)};goog.async.run.initializeRunner_=function(){if(-1!=String(goog.global.Promise).indexOf("[native code]")){var a=goog.global.Promise.resolve(void 0);goog.async.run.schedule_=function(){a.then(goog.async.run.processWorkQueue)}}else goog.async.run.schedule_=function(){goog.async.nextTick(goog.async.run.processWorkQueue)}};
goog.async.run.forceNextTick=function(a){goog.async.run.schedule_=function(){goog.async.nextTick(goog.async.run.processWorkQueue);a&&a(goog.async.run.processWorkQueue)}};goog.async.run.workQueueScheduled_=!1;goog.async.run.workQueue_=new goog.async.WorkQueue;goog.DEBUG&&(goog.async.run.resetQueue=function(){goog.async.run.workQueueScheduled_=!1;goog.async.run.workQueue_=new goog.async.WorkQueue});
goog.async.run.processWorkQueue=function(){for(var a;a=goog.async.run.workQueue_.remove();){try{a.fn.call(a.scope)}catch(b){goog.async.throwException(b)}goog.async.run.workQueue_.returnUnused(a)}goog.async.run.workQueueScheduled_=!1};goog.promise={};goog.promise.Resolver=function(){};goog.Promise=function(a,b){this.state_=goog.Promise.State_.PENDING;this.result_=void 0;this.callbackEntriesTail_=this.callbackEntries_=this.parent_=null;this.executing_=!1;0<goog.Promise.UNHANDLED_REJECTION_DELAY?this.unhandledRejectionId_=0:0==goog.Promise.UNHANDLED_REJECTION_DELAY&&(this.hadUnhandledRejection_=!1);goog.Promise.LONG_STACK_TRACES&&(this.stack_=[],this.addStackTrace_(Error("created")),this.currentStep_=0);if(a!=goog.nullFunction)try{var c=this;a.call(b,function(a){c.resolve_(goog.Promise.State_.FULFILLED,
a)},function(a){if(goog.DEBUG&&!(a instanceof goog.Promise.CancellationError))try{if(a instanceof Error)throw a;throw Error("Promise rejected.");}catch(e){}c.resolve_(goog.Promise.State_.REJECTED,a)})}catch(d){this.resolve_(goog.Promise.State_.REJECTED,d)}};goog.Promise.LONG_STACK_TRACES=!1;goog.Promise.UNHANDLED_REJECTION_DELAY=0;goog.Promise.State_={PENDING:0,BLOCKED:1,FULFILLED:2,REJECTED:3};
goog.Promise.CallbackEntry_=function(){this.next=this.context=this.onRejected=this.onFulfilled=this.child=null;this.always=!1};goog.Promise.CallbackEntry_.prototype.reset=function(){this.context=this.onRejected=this.onFulfilled=this.child=null;this.always=!1};goog.Promise.DEFAULT_MAX_UNUSED=100;goog.Promise.freelist_=new goog.async.FreeList(function(){return new goog.Promise.CallbackEntry_},function(a){a.reset()},goog.Promise.DEFAULT_MAX_UNUSED);
goog.Promise.getCallbackEntry_=function(a,b,c){var d=goog.Promise.freelist_.get();d.onFulfilled=a;d.onRejected=b;d.context=c;return d};goog.Promise.returnEntry_=function(a){goog.Promise.freelist_.put(a)};goog.Promise.resolve=function(a){if(a instanceof goog.Promise)return a;var b=new goog.Promise(goog.nullFunction);b.resolve_(goog.Promise.State_.FULFILLED,a);return b};goog.Promise.reject=function(a){return new goog.Promise(function(b,c){c(a)})};
goog.Promise.resolveThen_=function(a,b,c){goog.Promise.maybeThen_(a,b,c,null)||goog.async.run(goog.partial(b,a))};goog.Promise.race=function(a){return new goog.Promise(function(b,c){a.length||b(void 0);for(var d=0,e;d<a.length;d++)e=a[d],goog.Promise.resolveThen_(e,b,c)})};
goog.Promise.all=function(a){return new goog.Promise(function(b,c){var d=a.length,e=[];if(d)for(var f=function(a,c){d--;e[a]=c;0==d&&b(e)},g=function(a){c(a)},h=0,k;h<a.length;h++)k=a[h],goog.Promise.resolveThen_(k,goog.partial(f,h),g);else b(e)})};
goog.Promise.allSettled=function(a){return new goog.Promise(function(b,c){var d=a.length,e=[];if(d)for(var f=function(a,c,f){d--;e[a]=c?{fulfilled:!0,value:f}:{fulfilled:!1,reason:f};0==d&&b(e)},g=0,h;g<a.length;g++)h=a[g],goog.Promise.resolveThen_(h,goog.partial(f,g,!0),goog.partial(f,g,!1));else b(e)})};
goog.Promise.firstFulfilled=function(a){return new goog.Promise(function(b,c){var d=a.length,e=[];if(d)for(var f=function(a){b(a)},g=function(a,b){d--;e[a]=b;0==d&&c(e)},h=0,k;h<a.length;h++)k=a[h],goog.Promise.resolveThen_(k,f,goog.partial(g,h));else b(void 0)})};goog.Promise.withResolver=function(){var a,b,c=new goog.Promise(function(c,e){a=c;b=e});return new goog.Promise.Resolver_(c,a,b)};
goog.Promise.prototype.then=function(a,b,c){null!=a&&goog.asserts.assertFunction(a,"opt_onFulfilled should be a function.");null!=b&&goog.asserts.assertFunction(b,"opt_onRejected should be a function. Did you pass opt_context as the second argument instead of the third?");goog.Promise.LONG_STACK_TRACES&&this.addStackTrace_(Error("then"));return this.addChildPromise_(goog.isFunction(a)?a:null,goog.isFunction(b)?b:null,c)};goog.Thenable.addImplementation(goog.Promise);
goog.Promise.prototype.thenVoid=function(a,b,c){null!=a&&goog.asserts.assertFunction(a,"opt_onFulfilled should be a function.");null!=b&&goog.asserts.assertFunction(b,"opt_onRejected should be a function. Did you pass opt_context as the second argument instead of the third?");goog.Promise.LONG_STACK_TRACES&&this.addStackTrace_(Error("then"));this.addCallbackEntry_(goog.Promise.getCallbackEntry_(a||goog.nullFunction,b||null,c))};
goog.Promise.prototype.thenAlways=function(a,b){goog.Promise.LONG_STACK_TRACES&&this.addStackTrace_(Error("thenAlways"));var c=goog.Promise.getCallbackEntry_(a,a,b);c.always=!0;this.addCallbackEntry_(c);return this};goog.Promise.prototype.thenCatch=function(a,b){goog.Promise.LONG_STACK_TRACES&&this.addStackTrace_(Error("thenCatch"));return this.addChildPromise_(null,a,b)};
goog.Promise.prototype.cancel=function(a){this.state_==goog.Promise.State_.PENDING&&goog.async.run(function(){var b=new goog.Promise.CancellationError(a);this.cancelInternal_(b)},this)};goog.Promise.prototype.cancelInternal_=function(a){this.state_==goog.Promise.State_.PENDING&&(this.parent_?(this.parent_.cancelChild_(this,a),this.parent_=null):this.resolve_(goog.Promise.State_.REJECTED,a))};
goog.Promise.prototype.cancelChild_=function(a,b){if(this.callbackEntries_){for(var c=0,d=null,e=null,f=this.callbackEntries_;f&&(f.always||(c++,f.child==a&&(d=f),!(d&&1<c)));f=f.next)d||(e=f);d&&(this.state_==goog.Promise.State_.PENDING&&1==c?this.cancelInternal_(b):(e?this.removeEntryAfter_(e):this.popEntry_(),this.executeCallback_(d,goog.Promise.State_.REJECTED,b)))}};
goog.Promise.prototype.addCallbackEntry_=function(a){this.hasEntry_()||this.state_!=goog.Promise.State_.FULFILLED&&this.state_!=goog.Promise.State_.REJECTED||this.scheduleCallbacks_();this.queueEntry_(a)};
goog.Promise.prototype.addChildPromise_=function(a,b,c){var d=goog.Promise.getCallbackEntry_(null,null,null);d.child=new goog.Promise(function(e,f){d.onFulfilled=a?function(b){try{var d=a.call(c,b);e(d)}catch(k){f(k)}}:e;d.onRejected=b?function(a){try{var d=b.call(c,a);!goog.isDef(d)&&a instanceof goog.Promise.CancellationError?f(a):e(d)}catch(k){f(k)}}:f});d.child.parent_=this;this.addCallbackEntry_(d);return d.child};
goog.Promise.prototype.unblockAndFulfill_=function(a){goog.asserts.assert(this.state_==goog.Promise.State_.BLOCKED);this.state_=goog.Promise.State_.PENDING;this.resolve_(goog.Promise.State_.FULFILLED,a)};goog.Promise.prototype.unblockAndReject_=function(a){goog.asserts.assert(this.state_==goog.Promise.State_.BLOCKED);this.state_=goog.Promise.State_.PENDING;this.resolve_(goog.Promise.State_.REJECTED,a)};
goog.Promise.prototype.resolve_=function(a,b){this.state_==goog.Promise.State_.PENDING&&(this===b&&(a=goog.Promise.State_.REJECTED,b=new TypeError("Promise cannot resolve to itself")),this.state_=goog.Promise.State_.BLOCKED,goog.Promise.maybeThen_(b,this.unblockAndFulfill_,this.unblockAndReject_,this)||(this.result_=b,this.state_=a,this.parent_=null,this.scheduleCallbacks_(),a!=goog.Promise.State_.REJECTED||b instanceof goog.Promise.CancellationError||goog.Promise.addUnhandledRejection_(this,b)))};
goog.Promise.maybeThen_=function(a,b,c,d){if(a instanceof goog.Promise)return a.thenVoid(b,c,d),!0;if(goog.Thenable.isImplementedBy(a))return a.then(b,c,d),!0;if(goog.isObject(a))try{var e=a.then;if(goog.isFunction(e))return goog.Promise.tryThen_(a,e,b,c,d),!0}catch(f){return c.call(d,f),!0}return!1};goog.Promise.tryThen_=function(a,b,c,d,e){var f=!1,g=function(a){f||(f=!0,c.call(e,a))},h=function(a){f||(f=!0,d.call(e,a))};try{b.call(a,g,h)}catch(k){h(k)}};
goog.Promise.prototype.scheduleCallbacks_=function(){this.executing_||(this.executing_=!0,goog.async.run(this.executeCallbacks_,this))};goog.Promise.prototype.hasEntry_=function(){return!!this.callbackEntries_};goog.Promise.prototype.queueEntry_=function(a){goog.asserts.assert(null!=a.onFulfilled);this.callbackEntriesTail_?this.callbackEntriesTail_.next=a:this.callbackEntries_=a;this.callbackEntriesTail_=a};
goog.Promise.prototype.popEntry_=function(){var a=null;this.callbackEntries_&&(a=this.callbackEntries_,this.callbackEntries_=a.next,a.next=null);this.callbackEntries_||(this.callbackEntriesTail_=null);null!=a&&goog.asserts.assert(null!=a.onFulfilled);return a};goog.Promise.prototype.removeEntryAfter_=function(a){goog.asserts.assert(this.callbackEntries_);goog.asserts.assert(null!=a);a.next==this.callbackEntriesTail_&&(this.callbackEntriesTail_=a);a.next=a.next.next};
goog.Promise.prototype.executeCallbacks_=function(){for(var a;a=this.popEntry_();)goog.Promise.LONG_STACK_TRACES&&this.currentStep_++,this.executeCallback_(a,this.state_,this.result_);this.executing_=!1};
goog.Promise.prototype.executeCallback_=function(a,b,c){b==goog.Promise.State_.REJECTED&&a.onRejected&&!a.always&&this.removeUnhandledRejection_();if(a.child)a.child.parent_=null,goog.Promise.invokeCallback_(a,b,c);else try{a.always?a.onFulfilled.call(a.context):goog.Promise.invokeCallback_(a,b,c)}catch(d){goog.Promise.handleRejection_.call(null,d)}goog.Promise.returnEntry_(a)};
goog.Promise.invokeCallback_=function(a,b,c){b==goog.Promise.State_.FULFILLED?a.onFulfilled.call(a.context,c):a.onRejected&&a.onRejected.call(a.context,c)};goog.Promise.prototype.addStackTrace_=function(a){if(goog.Promise.LONG_STACK_TRACES&&goog.isString(a.stack)){var b=a.stack.split("\n",4)[3];a=a.message;a+=Array(11-a.length).join(" ");this.stack_.push(a+b)}};
goog.Promise.prototype.appendLongStack_=function(a){if(goog.Promise.LONG_STACK_TRACES&&a&&goog.isString(a.stack)&&this.stack_.length){for(var b=["Promise trace:"],c=this;c;c=c.parent_){for(var d=this.currentStep_;0<=d;d--)b.push(c.stack_[d]);b.push("Value: ["+(c.state_==goog.Promise.State_.REJECTED?"REJECTED":"FULFILLED")+"] <"+String(c.result_)+">")}a.stack+="\n\n"+b.join("\n")}};
goog.Promise.prototype.removeUnhandledRejection_=function(){if(0<goog.Promise.UNHANDLED_REJECTION_DELAY)for(var a=this;a&&a.unhandledRejectionId_;a=a.parent_)goog.global.clearTimeout(a.unhandledRejectionId_),a.unhandledRejectionId_=0;else if(0==goog.Promise.UNHANDLED_REJECTION_DELAY)for(a=this;a&&a.hadUnhandledRejection_;a=a.parent_)a.hadUnhandledRejection_=!1};
goog.Promise.addUnhandledRejection_=function(a,b){0<goog.Promise.UNHANDLED_REJECTION_DELAY?a.unhandledRejectionId_=goog.global.setTimeout(function(){a.appendLongStack_(b);goog.Promise.handleRejection_.call(null,b)},goog.Promise.UNHANDLED_REJECTION_DELAY):0==goog.Promise.UNHANDLED_REJECTION_DELAY&&(a.hadUnhandledRejection_=!0,goog.async.run(function(){a.hadUnhandledRejection_&&(a.appendLongStack_(b),goog.Promise.handleRejection_.call(null,b))}))};goog.Promise.handleRejection_=goog.async.throwException;
goog.Promise.setUnhandledRejectionHandler=function(a){goog.Promise.handleRejection_=a};goog.Promise.CancellationError=function(a){goog.debug.Error.call(this,a)};goog.inherits(goog.Promise.CancellationError,goog.debug.Error);goog.Promise.CancellationError.prototype.name="cancel";goog.Promise.Resolver_=function(a,b,c){this.promise=a;this.resolve=b;this.reject=c};goog.labs.userAgent.platform={};goog.labs.userAgent.platform.isAndroid=function(){return goog.labs.userAgent.util.matchUserAgent("Android")};goog.labs.userAgent.platform.isIpod=function(){return goog.labs.userAgent.util.matchUserAgent("iPod")};goog.labs.userAgent.platform.isIphone=function(){return goog.labs.userAgent.util.matchUserAgent("iPhone")&&!goog.labs.userAgent.util.matchUserAgent("iPod")&&!goog.labs.userAgent.util.matchUserAgent("iPad")};goog.labs.userAgent.platform.isIpad=function(){return goog.labs.userAgent.util.matchUserAgent("iPad")};
goog.labs.userAgent.platform.isIos=function(){return goog.labs.userAgent.platform.isIphone()||goog.labs.userAgent.platform.isIpad()||goog.labs.userAgent.platform.isIpod()};goog.labs.userAgent.platform.isMacintosh=function(){return goog.labs.userAgent.util.matchUserAgent("Macintosh")};goog.labs.userAgent.platform.isLinux=function(){return goog.labs.userAgent.util.matchUserAgent("Linux")};goog.labs.userAgent.platform.isWindows=function(){return goog.labs.userAgent.util.matchUserAgent("Windows")};
goog.labs.userAgent.platform.isChromeOS=function(){return goog.labs.userAgent.util.matchUserAgent("CrOS")};
goog.labs.userAgent.platform.getVersion=function(){var a=goog.labs.userAgent.util.getUserAgent(),b="";goog.labs.userAgent.platform.isWindows()?(b=/Windows (?:NT|Phone) ([0-9.]+)/,b=(a=b.exec(a))?a[1]:"0.0"):goog.labs.userAgent.platform.isIos()?(b=/(?:iPhone|iPod|iPad|CPU)\s+OS\s+(\S+)/,b=(a=b.exec(a))&&a[1].replace(/_/g,".")):goog.labs.userAgent.platform.isMacintosh()?(b=/Mac OS X ([0-9_.]+)/,b=(a=b.exec(a))?a[1].replace(/_/g,"."):"10"):goog.labs.userAgent.platform.isAndroid()?(b=/Android\s+([^\);]+)(\)|;)/,
b=(a=b.exec(a))&&a[1]):goog.labs.userAgent.platform.isChromeOS()&&(b=/(?:CrOS\s+(?:i686|x86_64)\s+([0-9.]+))/,b=(a=b.exec(a))&&a[1]);return b||""};goog.labs.userAgent.platform.isVersionOrHigher=function(a){return 0<=goog.string.compareVersions(goog.labs.userAgent.platform.getVersion(),a)};goog.reflect={};goog.reflect.object=function(a,b){return b};goog.reflect.objectProperty=function(a,b){return a};goog.reflect.sinkValue=function(a){goog.reflect.sinkValue[" "](a);return a};goog.reflect.sinkValue[" "]=goog.nullFunction;goog.reflect.canAccessProperty=function(a,b){try{return goog.reflect.sinkValue(a[b]),!0}catch(c){}return!1};goog.reflect.cache=function(a,b,c,d){d=d?d(b):b;return Object.prototype.hasOwnProperty.call(a,d)?a[d]:a[d]=c(b)};goog.userAgent={};goog.userAgent.ASSUME_IE=!1;goog.userAgent.ASSUME_EDGE=!1;goog.userAgent.ASSUME_GECKO=!1;goog.userAgent.ASSUME_WEBKIT=!1;goog.userAgent.ASSUME_MOBILE_WEBKIT=!1;goog.userAgent.ASSUME_OPERA=!1;goog.userAgent.ASSUME_ANY_VERSION=!1;goog.userAgent.BROWSER_KNOWN_=goog.userAgent.ASSUME_IE||goog.userAgent.ASSUME_EDGE||goog.userAgent.ASSUME_GECKO||goog.userAgent.ASSUME_MOBILE_WEBKIT||goog.userAgent.ASSUME_WEBKIT||goog.userAgent.ASSUME_OPERA;goog.userAgent.getUserAgentString=function(){return goog.labs.userAgent.util.getUserAgent()};
goog.userAgent.getNavigator=function(){return goog.global.navigator||null};goog.userAgent.OPERA=goog.userAgent.BROWSER_KNOWN_?goog.userAgent.ASSUME_OPERA:goog.labs.userAgent.browser.isOpera();goog.userAgent.IE=goog.userAgent.BROWSER_KNOWN_?goog.userAgent.ASSUME_IE:goog.labs.userAgent.browser.isIE();goog.userAgent.EDGE=goog.userAgent.BROWSER_KNOWN_?goog.userAgent.ASSUME_EDGE:goog.labs.userAgent.engine.isEdge();goog.userAgent.EDGE_OR_IE=goog.userAgent.EDGE||goog.userAgent.IE;
goog.userAgent.GECKO=goog.userAgent.BROWSER_KNOWN_?goog.userAgent.ASSUME_GECKO:goog.labs.userAgent.engine.isGecko();goog.userAgent.WEBKIT=goog.userAgent.BROWSER_KNOWN_?goog.userAgent.ASSUME_WEBKIT||goog.userAgent.ASSUME_MOBILE_WEBKIT:goog.labs.userAgent.engine.isWebKit();goog.userAgent.isMobile_=function(){return goog.userAgent.WEBKIT&&goog.labs.userAgent.util.matchUserAgent("Mobile")};goog.userAgent.MOBILE=goog.userAgent.ASSUME_MOBILE_WEBKIT||goog.userAgent.isMobile_();goog.userAgent.SAFARI=goog.userAgent.WEBKIT;
goog.userAgent.determinePlatform_=function(){var a=goog.userAgent.getNavigator();return a&&a.platform||""};goog.userAgent.PLATFORM=goog.userAgent.determinePlatform_();goog.userAgent.ASSUME_MAC=!1;goog.userAgent.ASSUME_WINDOWS=!1;goog.userAgent.ASSUME_LINUX=!1;goog.userAgent.ASSUME_X11=!1;goog.userAgent.ASSUME_ANDROID=!1;goog.userAgent.ASSUME_IPHONE=!1;goog.userAgent.ASSUME_IPAD=!1;goog.userAgent.ASSUME_IPOD=!1;
goog.userAgent.PLATFORM_KNOWN_=goog.userAgent.ASSUME_MAC||goog.userAgent.ASSUME_WINDOWS||goog.userAgent.ASSUME_LINUX||goog.userAgent.ASSUME_X11||goog.userAgent.ASSUME_ANDROID||goog.userAgent.ASSUME_IPHONE||goog.userAgent.ASSUME_IPAD||goog.userAgent.ASSUME_IPOD;goog.userAgent.MAC=goog.userAgent.PLATFORM_KNOWN_?goog.userAgent.ASSUME_MAC:goog.labs.userAgent.platform.isMacintosh();goog.userAgent.WINDOWS=goog.userAgent.PLATFORM_KNOWN_?goog.userAgent.ASSUME_WINDOWS:goog.labs.userAgent.platform.isWindows();
goog.userAgent.isLegacyLinux_=function(){return goog.labs.userAgent.platform.isLinux()||goog.labs.userAgent.platform.isChromeOS()};goog.userAgent.LINUX=goog.userAgent.PLATFORM_KNOWN_?goog.userAgent.ASSUME_LINUX:goog.userAgent.isLegacyLinux_();goog.userAgent.isX11_=function(){var a=goog.userAgent.getNavigator();return!!a&&goog.string.contains(a.appVersion||"","X11")};goog.userAgent.X11=goog.userAgent.PLATFORM_KNOWN_?goog.userAgent.ASSUME_X11:goog.userAgent.isX11_();
goog.userAgent.ANDROID=goog.userAgent.PLATFORM_KNOWN_?goog.userAgent.ASSUME_ANDROID:goog.labs.userAgent.platform.isAndroid();goog.userAgent.IPHONE=goog.userAgent.PLATFORM_KNOWN_?goog.userAgent.ASSUME_IPHONE:goog.labs.userAgent.platform.isIphone();goog.userAgent.IPAD=goog.userAgent.PLATFORM_KNOWN_?goog.userAgent.ASSUME_IPAD:goog.labs.userAgent.platform.isIpad();goog.userAgent.IPOD=goog.userAgent.PLATFORM_KNOWN_?goog.userAgent.ASSUME_IPOD:goog.labs.userAgent.platform.isIpod();
goog.userAgent.IOS=goog.userAgent.PLATFORM_KNOWN_?goog.userAgent.ASSUME_IPHONE||goog.userAgent.ASSUME_IPAD||goog.userAgent.ASSUME_IPOD:goog.labs.userAgent.platform.isIos();goog.userAgent.determineVersion_=function(){var a="",b=goog.userAgent.getVersionRegexResult_();b&&(a=b?b[1]:"");return goog.userAgent.IE&&(b=goog.userAgent.getDocumentMode_(),null!=b&&b>parseFloat(a))?String(b):a};
goog.userAgent.getVersionRegexResult_=function(){var a=goog.userAgent.getUserAgentString();if(goog.userAgent.GECKO)return/rv\:([^\);]+)(\)|;)/.exec(a);if(goog.userAgent.EDGE)return/Edge\/([\d\.]+)/.exec(a);if(goog.userAgent.IE)return/\b(?:MSIE|rv)[: ]([^\);]+)(\)|;)/.exec(a);if(goog.userAgent.WEBKIT)return/WebKit\/(\S+)/.exec(a);if(goog.userAgent.OPERA)return/(?:Version)[ \/]?(\S+)/.exec(a)};goog.userAgent.getDocumentMode_=function(){var a=goog.global.document;return a?a.documentMode:void 0};
goog.userAgent.VERSION=goog.userAgent.determineVersion_();goog.userAgent.compare=function(a,b){return goog.string.compareVersions(a,b)};goog.userAgent.isVersionOrHigherCache_={};goog.userAgent.isVersionOrHigher=function(a){return goog.userAgent.ASSUME_ANY_VERSION||goog.reflect.cache(goog.userAgent.isVersionOrHigherCache_,a,function(){return 0<=goog.string.compareVersions(goog.userAgent.VERSION,a)})};goog.userAgent.isVersion=goog.userAgent.isVersionOrHigher;
goog.userAgent.isDocumentModeOrHigher=function(a){return Number(goog.userAgent.DOCUMENT_MODE)>=a};goog.userAgent.isDocumentMode=goog.userAgent.isDocumentModeOrHigher;goog.userAgent.DOCUMENT_MODE=function(){var a=goog.global.document,b=goog.userAgent.getDocumentMode_();if(a&&goog.userAgent.IE)return b||("CSS1Compat"==a.compatMode?parseInt(goog.userAgent.VERSION,10):5)}();goog.events={};
goog.events.BrowserFeature={HAS_W3C_BUTTON:!goog.userAgent.IE||goog.userAgent.isDocumentModeOrHigher(9),HAS_W3C_EVENT_SUPPORT:!goog.userAgent.IE||goog.userAgent.isDocumentModeOrHigher(9),SET_KEY_CODE_TO_PREVENT_DEFAULT:goog.userAgent.IE&&!goog.userAgent.isVersionOrHigher("9"),HAS_NAVIGATOR_ONLINE_PROPERTY:!goog.userAgent.WEBKIT||goog.userAgent.isVersionOrHigher("528"),HAS_HTML5_NETWORK_EVENT_SUPPORT:goog.userAgent.GECKO&&goog.userAgent.isVersionOrHigher("1.9b")||goog.userAgent.IE&&goog.userAgent.isVersionOrHigher("8")||
goog.userAgent.OPERA&&goog.userAgent.isVersionOrHigher("9.5")||goog.userAgent.WEBKIT&&goog.userAgent.isVersionOrHigher("528"),HTML5_NETWORK_EVENTS_FIRE_ON_BODY:goog.userAgent.GECKO&&!goog.userAgent.isVersionOrHigher("8")||goog.userAgent.IE&&!goog.userAgent.isVersionOrHigher("9"),TOUCH_ENABLED:"ontouchstart"in goog.global||!!(goog.global.document&&document.documentElement&&"ontouchstart"in document.documentElement)||!(!goog.global.navigator||!goog.global.navigator.msMaxTouchPoints)};goog.events.EventId=function(a){this.id=a};goog.events.EventId.prototype.toString=function(){return this.id};goog.events.Event=function(a,b){this.type=a instanceof goog.events.EventId?String(a):a;this.currentTarget=this.target=b;this.defaultPrevented=this.propagationStopped_=!1;this.returnValue_=!0};goog.events.Event.prototype.stopPropagation=function(){this.propagationStopped_=!0};goog.events.Event.prototype.preventDefault=function(){this.defaultPrevented=!0;this.returnValue_=!1};goog.events.Event.stopPropagation=function(a){a.stopPropagation()};goog.events.Event.preventDefault=function(a){a.preventDefault()};goog.events.getVendorPrefixedName_=function(a){return goog.userAgent.WEBKIT?"webkit"+a:goog.userAgent.OPERA?"o"+a.toLowerCase():a.toLowerCase()};
goog.events.EventType={CLICK:"click",RIGHTCLICK:"rightclick",DBLCLICK:"dblclick",MOUSEDOWN:"mousedown",MOUSEUP:"mouseup",MOUSEOVER:"mouseover",MOUSEOUT:"mouseout",MOUSEMOVE:"mousemove",MOUSEENTER:"mouseenter",MOUSELEAVE:"mouseleave",SELECTIONCHANGE:"selectionchange",SELECTSTART:"selectstart",WHEEL:"wheel",KEYPRESS:"keypress",KEYDOWN:"keydown",KEYUP:"keyup",BLUR:"blur",FOCUS:"focus",DEACTIVATE:"deactivate",FOCUSIN:goog.userAgent.IE?"focusin":"DOMFocusIn",FOCUSOUT:goog.userAgent.IE?"focusout":"DOMFocusOut",
CHANGE:"change",RESET:"reset",SELECT:"select",SUBMIT:"submit",INPUT:"input",PROPERTYCHANGE:"propertychange",DRAGSTART:"dragstart",DRAG:"drag",DRAGENTER:"dragenter",DRAGOVER:"dragover",DRAGLEAVE:"dragleave",DROP:"drop",DRAGEND:"dragend",TOUCHSTART:"touchstart",TOUCHMOVE:"touchmove",TOUCHEND:"touchend",TOUCHCANCEL:"touchcancel",BEFOREUNLOAD:"beforeunload",CONSOLEMESSAGE:"consolemessage",CONTEXTMENU:"contextmenu",DEVICEMOTION:"devicemotion",DEVICEORIENTATION:"deviceorientation",DOMCONTENTLOADED:"DOMContentLoaded",
ERROR:"error",HELP:"help",LOAD:"load",LOSECAPTURE:"losecapture",ORIENTATIONCHANGE:"orientationchange",READYSTATECHANGE:"readystatechange",RESIZE:"resize",SCROLL:"scroll",UNLOAD:"unload",CANPLAY:"canplay",CANPLAYTHROUGH:"canplaythrough",DURATIONCHANGE:"durationchange",EMPTIED:"emptied",ENDED:"ended",LOADEDDATA:"loadeddata",LOADEDMETADATA:"loadedmetadata",PAUSE:"pause",PLAY:"play",PLAYING:"playing",RATECHANGE:"ratechange",SEEKED:"seeked",SEEKING:"seeking",STALLED:"stalled",SUSPEND:"suspend",TIMEUPDATE:"timeupdate",
VOLUMECHANGE:"volumechange",WAITING:"waiting",HASHCHANGE:"hashchange",PAGEHIDE:"pagehide",PAGESHOW:"pageshow",POPSTATE:"popstate",COPY:"copy",PASTE:"paste",CUT:"cut",BEFORECOPY:"beforecopy",BEFORECUT:"beforecut",BEFOREPASTE:"beforepaste",ONLINE:"online",OFFLINE:"offline",MESSAGE:"message",CONNECT:"connect",ANIMATIONSTART:goog.events.getVendorPrefixedName_("AnimationStart"),ANIMATIONEND:goog.events.getVendorPrefixedName_("AnimationEnd"),ANIMATIONITERATION:goog.events.getVendorPrefixedName_("AnimationIteration"),
TRANSITIONEND:goog.events.getVendorPrefixedName_("TransitionEnd"),POINTERDOWN:"pointerdown",POINTERUP:"pointerup",POINTERCANCEL:"pointercancel",POINTERMOVE:"pointermove",POINTEROVER:"pointerover",POINTEROUT:"pointerout",POINTERENTER:"pointerenter",POINTERLEAVE:"pointerleave",GOTPOINTERCAPTURE:"gotpointercapture",LOSTPOINTERCAPTURE:"lostpointercapture",MSGESTURECHANGE:"MSGestureChange",MSGESTUREEND:"MSGestureEnd",MSGESTUREHOLD:"MSGestureHold",MSGESTURESTART:"MSGestureStart",MSGESTURETAP:"MSGestureTap",
MSGOTPOINTERCAPTURE:"MSGotPointerCapture",MSINERTIASTART:"MSInertiaStart",MSLOSTPOINTERCAPTURE:"MSLostPointerCapture",MSPOINTERCANCEL:"MSPointerCancel",MSPOINTERDOWN:"MSPointerDown",MSPOINTERENTER:"MSPointerEnter",MSPOINTERHOVER:"MSPointerHover",MSPOINTERLEAVE:"MSPointerLeave",MSPOINTERMOVE:"MSPointerMove",MSPOINTEROUT:"MSPointerOut",MSPOINTEROVER:"MSPointerOver",MSPOINTERUP:"MSPointerUp",TEXT:"text",TEXTINPUT:goog.userAgent.IE?"textinput":"textInput",COMPOSITIONSTART:"compositionstart",COMPOSITIONUPDATE:"compositionupdate",
COMPOSITIONEND:"compositionend",EXIT:"exit",LOADABORT:"loadabort",LOADCOMMIT:"loadcommit",LOADREDIRECT:"loadredirect",LOADSTART:"loadstart",LOADSTOP:"loadstop",RESPONSIVE:"responsive",SIZECHANGED:"sizechanged",UNRESPONSIVE:"unresponsive",VISIBILITYCHANGE:"visibilitychange",STORAGE:"storage",DOMSUBTREEMODIFIED:"DOMSubtreeModified",DOMNODEINSERTED:"DOMNodeInserted",DOMNODEREMOVED:"DOMNodeRemoved",DOMNODEREMOVEDFROMDOCUMENT:"DOMNodeRemovedFromDocument",DOMNODEINSERTEDINTODOCUMENT:"DOMNodeInsertedIntoDocument",
DOMATTRMODIFIED:"DOMAttrModified",DOMCHARACTERDATAMODIFIED:"DOMCharacterDataModified",BEFOREPRINT:"beforeprint",AFTERPRINT:"afterprint"};goog.events.BrowserEvent=function(a,b){goog.events.Event.call(this,a?a.type:"");this.relatedTarget=this.currentTarget=this.target=null;this.button=this.screenY=this.screenX=this.clientY=this.clientX=this.offsetY=this.offsetX=0;this.key="";this.charCode=this.keyCode=0;this.metaKey=this.shiftKey=this.altKey=this.ctrlKey=!1;this.state=null;this.platformModifierKey=!1;this.event_=null;a&&this.init(a,b)};goog.inherits(goog.events.BrowserEvent,goog.events.Event);
goog.events.BrowserEvent.MouseButton={LEFT:0,MIDDLE:1,RIGHT:2};goog.events.BrowserEvent.IEButtonMap=[1,4,2];
goog.events.BrowserEvent.prototype.init=function(a,b){var c=this.type=a.type,d=a.changedTouches?a.changedTouches[0]:null;this.target=a.target||a.srcElement;this.currentTarget=b;var e=a.relatedTarget;e?goog.userAgent.GECKO&&(goog.reflect.canAccessProperty(e,"nodeName")||(e=null)):c==goog.events.EventType.MOUSEOVER?e=a.fromElement:c==goog.events.EventType.MOUSEOUT&&(e=a.toElement);this.relatedTarget=e;goog.isNull(d)?(this.offsetX=goog.userAgent.WEBKIT||void 0!==a.offsetX?a.offsetX:a.layerX,this.offsetY=
goog.userAgent.WEBKIT||void 0!==a.offsetY?a.offsetY:a.layerY,this.clientX=void 0!==a.clientX?a.clientX:a.pageX,this.clientY=void 0!==a.clientY?a.clientY:a.pageY,this.screenX=a.screenX||0,this.screenY=a.screenY||0):(this.clientX=void 0!==d.clientX?d.clientX:d.pageX,this.clientY=void 0!==d.clientY?d.clientY:d.pageY,this.screenX=d.screenX||0,this.screenY=d.screenY||0);this.button=a.button;this.keyCode=a.keyCode||0;this.key=a.key||"";this.charCode=a.charCode||("keypress"==c?a.keyCode:0);this.ctrlKey=
a.ctrlKey;this.altKey=a.altKey;this.shiftKey=a.shiftKey;this.metaKey=a.metaKey;this.platformModifierKey=goog.userAgent.MAC?a.metaKey:a.ctrlKey;this.state=a.state;this.event_=a;a.defaultPrevented&&this.preventDefault()};goog.events.BrowserEvent.prototype.isButton=function(a){return goog.events.BrowserFeature.HAS_W3C_BUTTON?this.event_.button==a:"click"==this.type?a==goog.events.BrowserEvent.MouseButton.LEFT:!!(this.event_.button&goog.events.BrowserEvent.IEButtonMap[a])};
goog.events.BrowserEvent.prototype.isMouseActionButton=function(){return this.isButton(goog.events.BrowserEvent.MouseButton.LEFT)&&!(goog.userAgent.WEBKIT&&goog.userAgent.MAC&&this.ctrlKey)};goog.events.BrowserEvent.prototype.stopPropagation=function(){goog.events.BrowserEvent.superClass_.stopPropagation.call(this);this.event_.stopPropagation?this.event_.stopPropagation():this.event_.cancelBubble=!0};
goog.events.BrowserEvent.prototype.preventDefault=function(){goog.events.BrowserEvent.superClass_.preventDefault.call(this);var a=this.event_;if(a.preventDefault)a.preventDefault();else if(a.returnValue=!1,goog.events.BrowserFeature.SET_KEY_CODE_TO_PREVENT_DEFAULT)try{if(a.ctrlKey||112<=a.keyCode&&123>=a.keyCode)a.keyCode=-1}catch(b){}};goog.events.BrowserEvent.prototype.getBrowserEvent=function(){return this.event_};goog.events.Listenable=function(){};goog.events.Listenable.IMPLEMENTED_BY_PROP="closure_listenable_"+(1E6*Math.random()|0);goog.events.Listenable.addImplementation=function(a){a.prototype[goog.events.Listenable.IMPLEMENTED_BY_PROP]=!0};goog.events.Listenable.isImplementedBy=function(a){return!(!a||!a[goog.events.Listenable.IMPLEMENTED_BY_PROP])};goog.events.ListenableKey=function(){};goog.events.ListenableKey.counter_=0;goog.events.ListenableKey.reserveKey=function(){return++goog.events.ListenableKey.counter_};goog.events.Listener=function(a,b,c,d,e,f){goog.events.Listener.ENABLE_MONITORING&&(this.creationStack=Error().stack);this.listener=a;this.proxy=b;this.src=c;this.type=d;this.capture=!!e;this.handler=f;this.key=goog.events.ListenableKey.reserveKey();this.removed=this.callOnce=!1};goog.events.Listener.ENABLE_MONITORING=!1;goog.events.Listener.prototype.markAsRemoved=function(){this.removed=!0;this.handler=this.src=this.proxy=this.listener=null};goog.events.ListenerMap=function(a){this.src=a;this.listeners={};this.typeCount_=0};goog.events.ListenerMap.prototype.getTypeCount=function(){return this.typeCount_};goog.events.ListenerMap.prototype.getListenerCount=function(){var a=0,b;for(b in this.listeners)a+=this.listeners[b].length;return a};
goog.events.ListenerMap.prototype.add=function(a,b,c,d,e){var f=a.toString();a=this.listeners[f];a||(a=this.listeners[f]=[],this.typeCount_++);var g=goog.events.ListenerMap.findListenerIndex_(a,b,d,e);-1<g?(b=a[g],c||(b.callOnce=!1)):(b=new goog.events.Listener(b,null,this.src,f,!!d,e),b.callOnce=c,a.push(b));return b};
goog.events.ListenerMap.prototype.remove=function(a,b,c,d){a=a.toString();if(!(a in this.listeners))return!1;var e=this.listeners[a];b=goog.events.ListenerMap.findListenerIndex_(e,b,c,d);return-1<b?(e[b].markAsRemoved(),goog.array.removeAt(e,b),0==e.length&&(delete this.listeners[a],this.typeCount_--),!0):!1};
goog.events.ListenerMap.prototype.removeByKey=function(a){var b=a.type;if(!(b in this.listeners))return!1;var c=goog.array.remove(this.listeners[b],a);c&&(a.markAsRemoved(),0==this.listeners[b].length&&(delete this.listeners[b],this.typeCount_--));return c};goog.events.ListenerMap.prototype.removeAll=function(a){a=a&&a.toString();var b=0,c;for(c in this.listeners)if(!a||c==a){for(var d=this.listeners[c],e=0;e<d.length;e++)++b,d[e].markAsRemoved();delete this.listeners[c];this.typeCount_--}return b};
goog.events.ListenerMap.prototype.getListeners=function(a,b){var c=this.listeners[a.toString()],d=[];if(c)for(var e=0;e<c.length;++e){var f=c[e];f.capture==b&&d.push(f)}return d};goog.events.ListenerMap.prototype.getListener=function(a,b,c,d){a=this.listeners[a.toString()];var e=-1;a&&(e=goog.events.ListenerMap.findListenerIndex_(a,b,c,d));return-1<e?a[e]:null};
goog.events.ListenerMap.prototype.hasListener=function(a,b){var c=goog.isDef(a),d=c?a.toString():"",e=goog.isDef(b);return goog.object.some(this.listeners,function(a,g){for(var f=0;f<a.length;++f)if(!(c&&a[f].type!=d||e&&a[f].capture!=b))return!0;return!1})};goog.events.ListenerMap.findListenerIndex_=function(a,b,c,d){for(var e=0;e<a.length;++e){var f=a[e];if(!f.removed&&f.listener==b&&f.capture==!!c&&f.handler==d)return e}return-1};goog.events.LISTENER_MAP_PROP_="closure_lm_"+(1E6*Math.random()|0);goog.events.onString_="on";goog.events.onStringMap_={};goog.events.CaptureSimulationMode={OFF_AND_FAIL:0,OFF_AND_SILENT:1,ON:2};goog.events.CAPTURE_SIMULATION_MODE=2;goog.events.listenerCountEstimate_=0;
goog.events.listen=function(a,b,c,d,e){if(goog.isArray(b)){for(var f=0;f<b.length;f++)goog.events.listen(a,b[f],c,d,e);return null}c=goog.events.wrapListener(c);return goog.events.Listenable.isImplementedBy(a)?a.listen(b,c,d,e):goog.events.listen_(a,b,c,!1,d,e)};
goog.events.listen_=function(a,b,c,d,e,f){if(!b)throw Error("Invalid event type");var g=!!e;if(g&&!goog.events.BrowserFeature.HAS_W3C_EVENT_SUPPORT){if(goog.events.CAPTURE_SIMULATION_MODE==goog.events.CaptureSimulationMode.OFF_AND_FAIL)return goog.asserts.fail("Can not register capture listener in IE8-."),null;if(goog.events.CAPTURE_SIMULATION_MODE==goog.events.CaptureSimulationMode.OFF_AND_SILENT)return null}var h=goog.events.getListenerMap_(a);h||(a[goog.events.LISTENER_MAP_PROP_]=h=new goog.events.ListenerMap(a));
c=h.add(b,c,d,e,f);if(c.proxy)return c;d=goog.events.getProxy();c.proxy=d;d.src=a;d.listener=c;if(a.addEventListener)a.addEventListener(b.toString(),d,g);else if(a.attachEvent)a.attachEvent(goog.events.getOnString_(b.toString()),d);else throw Error("addEventListener and attachEvent are unavailable.");goog.events.listenerCountEstimate_++;return c};
goog.events.getProxy=function(){var a=goog.events.handleBrowserEvent_,b=goog.events.BrowserFeature.HAS_W3C_EVENT_SUPPORT?function(c){return a.call(b.src,b.listener,c)}:function(c){c=a.call(b.src,b.listener,c);if(!c)return c};return b};
goog.events.listenOnce=function(a,b,c,d,e){if(goog.isArray(b)){for(var f=0;f<b.length;f++)goog.events.listenOnce(a,b[f],c,d,e);return null}c=goog.events.wrapListener(c);return goog.events.Listenable.isImplementedBy(a)?a.listenOnce(b,c,d,e):goog.events.listen_(a,b,c,!0,d,e)};goog.events.listenWithWrapper=function(a,b,c,d,e){b.listen(a,c,d,e)};
goog.events.unlisten=function(a,b,c,d,e){if(goog.isArray(b)){for(var f=0;f<b.length;f++)goog.events.unlisten(a,b[f],c,d,e);return null}c=goog.events.wrapListener(c);if(goog.events.Listenable.isImplementedBy(a))return a.unlisten(b,c,d,e);if(!a)return!1;d=!!d;if(a=goog.events.getListenerMap_(a))if(b=a.getListener(b,c,d,e))return goog.events.unlistenByKey(b);return!1};
goog.events.unlistenByKey=function(a){if(goog.isNumber(a)||!a||a.removed)return!1;var b=a.src;if(goog.events.Listenable.isImplementedBy(b))return b.unlistenByKey(a);var c=a.type,d=a.proxy;b.removeEventListener?b.removeEventListener(c,d,a.capture):b.detachEvent&&b.detachEvent(goog.events.getOnString_(c),d);goog.events.listenerCountEstimate_--;(c=goog.events.getListenerMap_(b))?(c.removeByKey(a),0==c.getTypeCount()&&(c.src=null,b[goog.events.LISTENER_MAP_PROP_]=null)):a.markAsRemoved();return!0};
goog.events.unlistenWithWrapper=function(a,b,c,d,e){b.unlisten(a,c,d,e)};goog.events.removeAll=function(a,b){if(!a)return 0;if(goog.events.Listenable.isImplementedBy(a))return a.removeAllListeners(b);var c=goog.events.getListenerMap_(a);if(!c)return 0;var d=0,e=b&&b.toString(),f;for(f in c.listeners)if(!e||f==e)for(var g=c.listeners[f].concat(),h=0;h<g.length;++h)goog.events.unlistenByKey(g[h])&&++d;return d};
goog.events.getListeners=function(a,b,c){return goog.events.Listenable.isImplementedBy(a)?a.getListeners(b,c):a?(a=goog.events.getListenerMap_(a))?a.getListeners(b,c):[]:[]};goog.events.getListener=function(a,b,c,d,e){c=goog.events.wrapListener(c);d=!!d;return goog.events.Listenable.isImplementedBy(a)?a.getListener(b,c,d,e):a?(a=goog.events.getListenerMap_(a))?a.getListener(b,c,d,e):null:null};
goog.events.hasListener=function(a,b,c){if(goog.events.Listenable.isImplementedBy(a))return a.hasListener(b,c);a=goog.events.getListenerMap_(a);return!!a&&a.hasListener(b,c)};goog.events.expose=function(a){var b=[],c;for(c in a)a[c]&&a[c].id?b.push(c+" = "+a[c]+" ("+a[c].id+")"):b.push(c+" = "+a[c]);return b.join("\n")};goog.events.getOnString_=function(a){return a in goog.events.onStringMap_?goog.events.onStringMap_[a]:goog.events.onStringMap_[a]=goog.events.onString_+a};
goog.events.fireListeners=function(a,b,c,d){return goog.events.Listenable.isImplementedBy(a)?a.fireListeners(b,c,d):goog.events.fireListeners_(a,b,c,d)};goog.events.fireListeners_=function(a,b,c,d){var e=!0;if(a=goog.events.getListenerMap_(a))if(b=a.listeners[b.toString()])for(b=b.concat(),a=0;a<b.length;a++){var f=b[a];f&&f.capture==c&&!f.removed&&(f=goog.events.fireListener(f,d),e=e&&!1!==f)}return e};
goog.events.fireListener=function(a,b){var c=a.listener,d=a.handler||a.src;a.callOnce&&goog.events.unlistenByKey(a);return c.call(d,b)};goog.events.getTotalListenerCount=function(){return goog.events.listenerCountEstimate_};goog.events.dispatchEvent=function(a,b){goog.asserts.assert(goog.events.Listenable.isImplementedBy(a),"Can not use goog.events.dispatchEvent with non-goog.events.Listenable instance.");return a.dispatchEvent(b)};
goog.events.protectBrowserEventEntryPoint=function(a){goog.events.handleBrowserEvent_=a.protectEntryPoint(goog.events.handleBrowserEvent_)};
goog.events.handleBrowserEvent_=function(a,b){if(a.removed)return!0;if(!goog.events.BrowserFeature.HAS_W3C_EVENT_SUPPORT){var c=b||goog.getObjectByName("window.event"),d=new goog.events.BrowserEvent(c,this),e=!0;if(goog.events.CAPTURE_SIMULATION_MODE==goog.events.CaptureSimulationMode.ON){if(!goog.events.isMarkedIeEvent_(c)){goog.events.markIeEvent_(c);for(var c=[],f=d.currentTarget;f;f=f.parentNode)c.push(f);for(var f=a.type,g=c.length-1;!d.propagationStopped_&&0<=g;g--){d.currentTarget=c[g];var h=
goog.events.fireListeners_(c[g],f,!0,d),e=e&&h}for(g=0;!d.propagationStopped_&&g<c.length;g++)d.currentTarget=c[g],h=goog.events.fireListeners_(c[g],f,!1,d),e=e&&h}}else e=goog.events.fireListener(a,d);return e}return goog.events.fireListener(a,new goog.events.BrowserEvent(b,this))};goog.events.markIeEvent_=function(a){var b=!1;if(0==a.keyCode)try{a.keyCode=-1;return}catch(c){b=!0}if(b||void 0==a.returnValue)a.returnValue=!0};goog.events.isMarkedIeEvent_=function(a){return 0>a.keyCode||void 0!=a.returnValue};
goog.events.uniqueIdCounter_=0;goog.events.getUniqueId=function(a){return a+"_"+goog.events.uniqueIdCounter_++};goog.events.getListenerMap_=function(a){a=a[goog.events.LISTENER_MAP_PROP_];return a instanceof goog.events.ListenerMap?a:null};goog.events.LISTENER_WRAPPER_PROP_="__closure_events_fn_"+(1E9*Math.random()>>>0);
goog.events.wrapListener=function(a){goog.asserts.assert(a,"Listener can not be null.");if(goog.isFunction(a))return a;goog.asserts.assert(a.handleEvent,"An object listener must have handleEvent method.");a[goog.events.LISTENER_WRAPPER_PROP_]||(a[goog.events.LISTENER_WRAPPER_PROP_]=function(b){return a.handleEvent(b)});return a[goog.events.LISTENER_WRAPPER_PROP_]};goog.debug.entryPointRegistry.register(function(a){goog.events.handleBrowserEvent_=a(goog.events.handleBrowserEvent_)});goog.events.EventTarget=function(){goog.Disposable.call(this);this.eventTargetListeners_=new goog.events.ListenerMap(this);this.actualEventTarget_=this;this.parentEventTarget_=null};goog.inherits(goog.events.EventTarget,goog.Disposable);goog.events.Listenable.addImplementation(goog.events.EventTarget);goog.events.EventTarget.MAX_ANCESTORS_=1E3;goog.events.EventTarget.prototype.getParentEventTarget=function(){return this.parentEventTarget_};
goog.events.EventTarget.prototype.setParentEventTarget=function(a){this.parentEventTarget_=a};goog.events.EventTarget.prototype.addEventListener=function(a,b,c,d){goog.events.listen(this,a,b,c,d)};goog.events.EventTarget.prototype.removeEventListener=function(a,b,c,d){goog.events.unlisten(this,a,b,c,d)};
goog.events.EventTarget.prototype.dispatchEvent=function(a){this.assertInitialized_();var b,c=this.getParentEventTarget();if(c){b=[];for(var d=1;c;c=c.getParentEventTarget())b.push(c),goog.asserts.assert(++d<goog.events.EventTarget.MAX_ANCESTORS_,"infinite loop")}return goog.events.EventTarget.dispatchEventInternal_(this.actualEventTarget_,a,b)};
goog.events.EventTarget.prototype.disposeInternal=function(){goog.events.EventTarget.superClass_.disposeInternal.call(this);this.removeAllListeners();this.parentEventTarget_=null};goog.events.EventTarget.prototype.listen=function(a,b,c,d){this.assertInitialized_();return this.eventTargetListeners_.add(String(a),b,!1,c,d)};goog.events.EventTarget.prototype.listenOnce=function(a,b,c,d){return this.eventTargetListeners_.add(String(a),b,!0,c,d)};
goog.events.EventTarget.prototype.unlisten=function(a,b,c,d){return this.eventTargetListeners_.remove(String(a),b,c,d)};goog.events.EventTarget.prototype.unlistenByKey=function(a){return this.eventTargetListeners_.removeByKey(a)};goog.events.EventTarget.prototype.removeAllListeners=function(a){return this.eventTargetListeners_?this.eventTargetListeners_.removeAll(a):0};
goog.events.EventTarget.prototype.fireListeners=function(a,b,c){a=this.eventTargetListeners_.listeners[String(a)];if(!a)return!0;a=a.concat();for(var d=!0,e=0;e<a.length;++e){var f=a[e];if(f&&!f.removed&&f.capture==b){var g=f.listener,h=f.handler||f.src;f.callOnce&&this.unlistenByKey(f);d=!1!==g.call(h,c)&&d}}return d&&0!=c.returnValue_};goog.events.EventTarget.prototype.getListeners=function(a,b){return this.eventTargetListeners_.getListeners(String(a),b)};
goog.events.EventTarget.prototype.getListener=function(a,b,c,d){return this.eventTargetListeners_.getListener(String(a),b,c,d)};goog.events.EventTarget.prototype.hasListener=function(a,b){var c=goog.isDef(a)?String(a):void 0;return this.eventTargetListeners_.hasListener(c,b)};goog.events.EventTarget.prototype.setTargetForTesting=function(a){this.actualEventTarget_=a};goog.events.EventTarget.prototype.assertInitialized_=function(){goog.asserts.assert(this.eventTargetListeners_,"Event target is not initialized. Did you call the superclass (goog.events.EventTarget) constructor?")};
goog.events.EventTarget.dispatchEventInternal_=function(a,b,c){var d=b.type||b;if(goog.isString(b))b=new goog.events.Event(b,a);else if(b instanceof goog.events.Event)b.target=b.target||a;else{var e=b;b=new goog.events.Event(d,a);goog.object.extend(b,e)}var e=!0,f;if(c)for(var g=c.length-1;!b.propagationStopped_&&0<=g;g--)f=b.currentTarget=c[g],e=f.fireListeners(d,!0,b)&&e;b.propagationStopped_||(f=b.currentTarget=a,e=f.fireListeners(d,!0,b)&&e,b.propagationStopped_||(e=f.fireListeners(d,!1,b)&&e));
if(c)for(g=0;!b.propagationStopped_&&g<c.length;g++)f=b.currentTarget=c[g],e=f.fireListeners(d,!1,b)&&e;return e};goog.Timer=function(a,b){goog.events.EventTarget.call(this);this.interval_=a||1;this.timerObject_=b||goog.Timer.defaultTimerObject;this.boundTick_=goog.bind(this.tick_,this);this.last_=goog.now()};goog.inherits(goog.Timer,goog.events.EventTarget);goog.Timer.MAX_TIMEOUT_=2147483647;goog.Timer.INVALID_TIMEOUT_ID_=-1;goog.Timer.prototype.enabled=!1;goog.Timer.defaultTimerObject=goog.global;goog.Timer.intervalScale=.8;goog.Timer.prototype.timer_=null;goog.Timer.prototype.getInterval=function(){return this.interval_};
goog.Timer.prototype.setInterval=function(a){this.interval_=a;this.timer_&&this.enabled?(this.stop(),this.start()):this.timer_&&this.stop()};
goog.Timer.prototype.tick_=function(){if(this.enabled){var a=goog.now()-this.last_;0<a&&a<this.interval_*goog.Timer.intervalScale?this.timer_=this.timerObject_.setTimeout(this.boundTick_,this.interval_-a):(this.timer_&&(this.timerObject_.clearTimeout(this.timer_),this.timer_=null),this.dispatchTick(),this.enabled&&(this.timer_=this.timerObject_.setTimeout(this.boundTick_,this.interval_),this.last_=goog.now()))}};goog.Timer.prototype.dispatchTick=function(){this.dispatchEvent(goog.Timer.TICK)};
goog.Timer.prototype.start=function(){this.enabled=!0;this.timer_||(this.timer_=this.timerObject_.setTimeout(this.boundTick_,this.interval_),this.last_=goog.now())};goog.Timer.prototype.stop=function(){this.enabled=!1;this.timer_&&(this.timerObject_.clearTimeout(this.timer_),this.timer_=null)};goog.Timer.prototype.disposeInternal=function(){goog.Timer.superClass_.disposeInternal.call(this);this.stop();delete this.timerObject_};goog.Timer.TICK="tick";
goog.Timer.callOnce=function(a,b,c){if(goog.isFunction(a))c&&(a=goog.bind(a,c));else if(a&&"function"==typeof a.handleEvent)a=goog.bind(a.handleEvent,a);else throw Error("Invalid listener argument");return Number(b)>goog.Timer.MAX_TIMEOUT_?goog.Timer.INVALID_TIMEOUT_ID_:goog.Timer.defaultTimerObject.setTimeout(a,b||0)};goog.Timer.clear=function(a){goog.Timer.defaultTimerObject.clearTimeout(a)};
goog.Timer.promise=function(a,b){var c=null;return(new goog.Promise(function(d,e){c=goog.Timer.callOnce(function(){d(b)},a);c==goog.Timer.INVALID_TIMEOUT_ID_&&e(Error("Failed to schedule timer."))})).thenCatch(function(a){goog.Timer.clear(c);throw a;})};goog.dom.BrowserFeature={CAN_ADD_NAME_OR_TYPE_ATTRIBUTES:!goog.userAgent.IE||goog.userAgent.isDocumentModeOrHigher(9),CAN_USE_CHILDREN_ATTRIBUTE:!goog.userAgent.GECKO&&!goog.userAgent.IE||goog.userAgent.IE&&goog.userAgent.isDocumentModeOrHigher(9)||goog.userAgent.GECKO&&goog.userAgent.isVersionOrHigher("1.9.1"),CAN_USE_INNER_TEXT:goog.userAgent.IE&&!goog.userAgent.isVersionOrHigher("9"),CAN_USE_PARENT_ELEMENT_PROPERTY:goog.userAgent.IE||goog.userAgent.OPERA||goog.userAgent.WEBKIT,INNER_HTML_NEEDS_SCOPED_ELEMENT:goog.userAgent.IE,
LEGACY_IE_RANGES:goog.userAgent.IE&&!goog.userAgent.isDocumentModeOrHigher(9)};goog.dom.tags={};goog.dom.tags.VOID_TAGS_={area:!0,base:!0,br:!0,col:!0,command:!0,embed:!0,hr:!0,img:!0,input:!0,keygen:!0,link:!0,meta:!0,param:!0,source:!0,track:!0,wbr:!0};goog.dom.tags.isVoidTag=function(a){return!0===goog.dom.tags.VOID_TAGS_[a]};goog.string.TypedString=function(){};goog.string.Const=function(){this.stringConstValueWithSecurityContract__googStringSecurityPrivate_="";this.STRING_CONST_TYPE_MARKER__GOOG_STRING_SECURITY_PRIVATE_=goog.string.Const.TYPE_MARKER_};goog.string.Const.prototype.implementsGoogStringTypedString=!0;goog.string.Const.prototype.getTypedStringValue=function(){return this.stringConstValueWithSecurityContract__googStringSecurityPrivate_};
goog.string.Const.prototype.toString=function(){return"Const{"+this.stringConstValueWithSecurityContract__googStringSecurityPrivate_+"}"};goog.string.Const.unwrap=function(a){if(a instanceof goog.string.Const&&a.constructor===goog.string.Const&&a.STRING_CONST_TYPE_MARKER__GOOG_STRING_SECURITY_PRIVATE_===goog.string.Const.TYPE_MARKER_)return a.stringConstValueWithSecurityContract__googStringSecurityPrivate_;goog.asserts.fail("expected object of type Const, got '"+a+"'");return"type_error:Const"};
goog.string.Const.from=function(a){return goog.string.Const.create__googStringSecurityPrivate_(a)};goog.string.Const.TYPE_MARKER_={};goog.string.Const.create__googStringSecurityPrivate_=function(a){var b=new goog.string.Const;b.stringConstValueWithSecurityContract__googStringSecurityPrivate_=a;return b};goog.string.Const.EMPTY=goog.string.Const.from("");goog.html={};goog.html.SafeScript=function(){this.privateDoNotAccessOrElseSafeScriptWrappedValue_="";this.SAFE_SCRIPT_TYPE_MARKER_GOOG_HTML_SECURITY_PRIVATE_=goog.html.SafeScript.TYPE_MARKER_GOOG_HTML_SECURITY_PRIVATE_};goog.html.SafeScript.prototype.implementsGoogStringTypedString=!0;goog.html.SafeScript.TYPE_MARKER_GOOG_HTML_SECURITY_PRIVATE_={};goog.html.SafeScript.fromConstant=function(a){a=goog.string.Const.unwrap(a);return 0===a.length?goog.html.SafeScript.EMPTY:goog.html.SafeScript.createSafeScriptSecurityPrivateDoNotAccessOrElse(a)};
goog.html.SafeScript.prototype.getTypedStringValue=function(){return this.privateDoNotAccessOrElseSafeScriptWrappedValue_};goog.DEBUG&&(goog.html.SafeScript.prototype.toString=function(){return"SafeScript{"+this.privateDoNotAccessOrElseSafeScriptWrappedValue_+"}"});
goog.html.SafeScript.unwrap=function(a){if(a instanceof goog.html.SafeScript&&a.constructor===goog.html.SafeScript&&a.SAFE_SCRIPT_TYPE_MARKER_GOOG_HTML_SECURITY_PRIVATE_===goog.html.SafeScript.TYPE_MARKER_GOOG_HTML_SECURITY_PRIVATE_)return a.privateDoNotAccessOrElseSafeScriptWrappedValue_;goog.asserts.fail("expected object of type SafeScript, got '"+a+"' of type "+goog.typeOf(a));return"type_error:SafeScript"};goog.html.SafeScript.createSafeScriptSecurityPrivateDoNotAccessOrElse=function(a){return(new goog.html.SafeScript).initSecurityPrivateDoNotAccessOrElse_(a)};
goog.html.SafeScript.prototype.initSecurityPrivateDoNotAccessOrElse_=function(a){this.privateDoNotAccessOrElseSafeScriptWrappedValue_=a;return this};goog.html.SafeScript.EMPTY=goog.html.SafeScript.createSafeScriptSecurityPrivateDoNotAccessOrElse("");goog.html.SafeStyle=function(){this.privateDoNotAccessOrElseSafeStyleWrappedValue_="";this.SAFE_STYLE_TYPE_MARKER_GOOG_HTML_SECURITY_PRIVATE_=goog.html.SafeStyle.TYPE_MARKER_GOOG_HTML_SECURITY_PRIVATE_};goog.html.SafeStyle.prototype.implementsGoogStringTypedString=!0;goog.html.SafeStyle.TYPE_MARKER_GOOG_HTML_SECURITY_PRIVATE_={};
goog.html.SafeStyle.fromConstant=function(a){a=goog.string.Const.unwrap(a);if(0===a.length)return goog.html.SafeStyle.EMPTY;goog.html.SafeStyle.checkStyle_(a);goog.asserts.assert(goog.string.endsWith(a,";"),"Last character of style string is not ';': "+a);goog.asserts.assert(goog.string.contains(a,":"),"Style string must contain at least one ':', to specify a \"name: value\" pair: "+a);return goog.html.SafeStyle.createSafeStyleSecurityPrivateDoNotAccessOrElse(a)};
goog.html.SafeStyle.checkStyle_=function(a){goog.asserts.assert(!/[<>]/.test(a),"Forbidden characters in style string: "+a)};goog.html.SafeStyle.prototype.getTypedStringValue=function(){return this.privateDoNotAccessOrElseSafeStyleWrappedValue_};goog.DEBUG&&(goog.html.SafeStyle.prototype.toString=function(){return"SafeStyle{"+this.privateDoNotAccessOrElseSafeStyleWrappedValue_+"}"});
goog.html.SafeStyle.unwrap=function(a){if(a instanceof goog.html.SafeStyle&&a.constructor===goog.html.SafeStyle&&a.SAFE_STYLE_TYPE_MARKER_GOOG_HTML_SECURITY_PRIVATE_===goog.html.SafeStyle.TYPE_MARKER_GOOG_HTML_SECURITY_PRIVATE_)return a.privateDoNotAccessOrElseSafeStyleWrappedValue_;goog.asserts.fail("expected object of type SafeStyle, got '"+a+"' of type "+goog.typeOf(a));return"type_error:SafeStyle"};goog.html.SafeStyle.createSafeStyleSecurityPrivateDoNotAccessOrElse=function(a){return(new goog.html.SafeStyle).initSecurityPrivateDoNotAccessOrElse_(a)};
goog.html.SafeStyle.prototype.initSecurityPrivateDoNotAccessOrElse_=function(a){this.privateDoNotAccessOrElseSafeStyleWrappedValue_=a;return this};goog.html.SafeStyle.EMPTY=goog.html.SafeStyle.createSafeStyleSecurityPrivateDoNotAccessOrElse("");goog.html.SafeStyle.INNOCUOUS_STRING="zClosurez";
goog.html.SafeStyle.create=function(a){var b="",c;for(c in a){if(!/^[-_a-zA-Z0-9]+$/.test(c))throw Error("Name allows only [-_a-zA-Z0-9], got: "+c);var d=a[c];null!=d&&(d instanceof goog.string.Const?(d=goog.string.Const.unwrap(d),goog.asserts.assert(!/[{;}]/.test(d),"Value does not allow [{;}].")):goog.html.SafeStyle.VALUE_RE_.test(d)?goog.html.SafeStyle.hasBalancedQuotes_(d)||(goog.asserts.fail("String value requires balanced quotes, got: "+d),d=goog.html.SafeStyle.INNOCUOUS_STRING):(goog.asserts.fail("String value allows only [-,.\"'%_!# a-zA-Z0-9], rgb() and rgba(), got: "+
d),d=goog.html.SafeStyle.INNOCUOUS_STRING),b+=c+":"+d+";")}if(!b)return goog.html.SafeStyle.EMPTY;goog.html.SafeStyle.checkStyle_(b);return goog.html.SafeStyle.createSafeStyleSecurityPrivateDoNotAccessOrElse(b)};goog.html.SafeStyle.hasBalancedQuotes_=function(a){for(var b=!0,c=!0,d=0;d<a.length;d++){var e=a.charAt(d);"'"==e&&c?b=!b:'"'==e&&b&&(c=!c)}return b&&c};goog.html.SafeStyle.VALUE_RE_=/^([-,."'%_!# a-zA-Z0-9]+|(?:rgb|hsl)a?\([0-9.%, ]+\))$/;
goog.html.SafeStyle.concat=function(a){var b="",c=function(a){goog.isArray(a)?goog.array.forEach(a,c):b+=goog.html.SafeStyle.unwrap(a)};goog.array.forEach(arguments,c);return b?goog.html.SafeStyle.createSafeStyleSecurityPrivateDoNotAccessOrElse(b):goog.html.SafeStyle.EMPTY};goog.html.SafeStyleSheet=function(){this.privateDoNotAccessOrElseSafeStyleSheetWrappedValue_="";this.SAFE_STYLE_SHEET_TYPE_MARKER_GOOG_HTML_SECURITY_PRIVATE_=goog.html.SafeStyleSheet.TYPE_MARKER_GOOG_HTML_SECURITY_PRIVATE_};goog.html.SafeStyleSheet.prototype.implementsGoogStringTypedString=!0;goog.html.SafeStyleSheet.TYPE_MARKER_GOOG_HTML_SECURITY_PRIVATE_={};
goog.html.SafeStyleSheet.concat=function(a){var b="",c=function(a){goog.isArray(a)?goog.array.forEach(a,c):b+=goog.html.SafeStyleSheet.unwrap(a)};goog.array.forEach(arguments,c);return goog.html.SafeStyleSheet.createSafeStyleSheetSecurityPrivateDoNotAccessOrElse(b)};
goog.html.SafeStyleSheet.fromConstant=function(a){a=goog.string.Const.unwrap(a);if(0===a.length)return goog.html.SafeStyleSheet.EMPTY;goog.asserts.assert(!goog.string.contains(a,"<"),"Forbidden '<' character in style sheet string: "+a);return goog.html.SafeStyleSheet.createSafeStyleSheetSecurityPrivateDoNotAccessOrElse(a)};goog.html.SafeStyleSheet.prototype.getTypedStringValue=function(){return this.privateDoNotAccessOrElseSafeStyleSheetWrappedValue_};
goog.DEBUG&&(goog.html.SafeStyleSheet.prototype.toString=function(){return"SafeStyleSheet{"+this.privateDoNotAccessOrElseSafeStyleSheetWrappedValue_+"}"});
goog.html.SafeStyleSheet.unwrap=function(a){if(a instanceof goog.html.SafeStyleSheet&&a.constructor===goog.html.SafeStyleSheet&&a.SAFE_STYLE_SHEET_TYPE_MARKER_GOOG_HTML_SECURITY_PRIVATE_===goog.html.SafeStyleSheet.TYPE_MARKER_GOOG_HTML_SECURITY_PRIVATE_)return a.privateDoNotAccessOrElseSafeStyleSheetWrappedValue_;goog.asserts.fail("expected object of type SafeStyleSheet, got '"+a+"' of type "+goog.typeOf(a));return"type_error:SafeStyleSheet"};
goog.html.SafeStyleSheet.createSafeStyleSheetSecurityPrivateDoNotAccessOrElse=function(a){return(new goog.html.SafeStyleSheet).initSecurityPrivateDoNotAccessOrElse_(a)};goog.html.SafeStyleSheet.prototype.initSecurityPrivateDoNotAccessOrElse_=function(a){this.privateDoNotAccessOrElseSafeStyleSheetWrappedValue_=a;return this};goog.html.SafeStyleSheet.EMPTY=goog.html.SafeStyleSheet.createSafeStyleSheetSecurityPrivateDoNotAccessOrElse("");goog.fs={};goog.fs.url={};goog.fs.url.createObjectUrl=function(a){return goog.fs.url.getUrlObject_().createObjectURL(a)};goog.fs.url.revokeObjectUrl=function(a){goog.fs.url.getUrlObject_().revokeObjectURL(a)};goog.fs.url.getUrlObject_=function(){var a=goog.fs.url.findUrlObject_();if(null!=a)return a;throw Error("This browser doesn't seem to support blob URLs");};
goog.fs.url.findUrlObject_=function(){return goog.isDef(goog.global.URL)&&goog.isDef(goog.global.URL.createObjectURL)?goog.global.URL:goog.isDef(goog.global.webkitURL)&&goog.isDef(goog.global.webkitURL.createObjectURL)?goog.global.webkitURL:goog.isDef(goog.global.createObjectURL)?goog.global:null};goog.fs.url.browserSupportsObjectUrls=function(){return null!=goog.fs.url.findUrlObject_()};goog.i18n={};goog.i18n.bidi={};goog.i18n.bidi.FORCE_RTL=!1;
goog.i18n.bidi.IS_RTL=goog.i18n.bidi.FORCE_RTL||("ar"==goog.LOCALE.substring(0,2).toLowerCase()||"fa"==goog.LOCALE.substring(0,2).toLowerCase()||"he"==goog.LOCALE.substring(0,2).toLowerCase()||"iw"==goog.LOCALE.substring(0,2).toLowerCase()||"ps"==goog.LOCALE.substring(0,2).toLowerCase()||"sd"==goog.LOCALE.substring(0,2).toLowerCase()||"ug"==goog.LOCALE.substring(0,2).toLowerCase()||"ur"==goog.LOCALE.substring(0,2).toLowerCase()||"yi"==goog.LOCALE.substring(0,2).toLowerCase())&&(2==goog.LOCALE.length||
"-"==goog.LOCALE.substring(2,3)||"_"==goog.LOCALE.substring(2,3))||3<=goog.LOCALE.length&&"ckb"==goog.LOCALE.substring(0,3).toLowerCase()&&(3==goog.LOCALE.length||"-"==goog.LOCALE.substring(3,4)||"_"==goog.LOCALE.substring(3,4));goog.i18n.bidi.Format={LRE:"\u202a",RLE:"\u202b",PDF:"\u202c",LRM:"\u200e",RLM:"\u200f"};goog.i18n.bidi.Dir={LTR:1,RTL:-1,NEUTRAL:0};goog.i18n.bidi.RIGHT="right";goog.i18n.bidi.LEFT="left";goog.i18n.bidi.I18N_RIGHT=goog.i18n.bidi.IS_RTL?goog.i18n.bidi.LEFT:goog.i18n.bidi.RIGHT;
goog.i18n.bidi.I18N_LEFT=goog.i18n.bidi.IS_RTL?goog.i18n.bidi.RIGHT:goog.i18n.bidi.LEFT;goog.i18n.bidi.toDir=function(a,b){return"number"==typeof a?0<a?goog.i18n.bidi.Dir.LTR:0>a?goog.i18n.bidi.Dir.RTL:b?null:goog.i18n.bidi.Dir.NEUTRAL:null==a?null:a?goog.i18n.bidi.Dir.RTL:goog.i18n.bidi.Dir.LTR};goog.i18n.bidi.ltrChars_="A-Za-z\u00c0-\u00d6\u00d8-\u00f6\u00f8-\u02b8\u0300-\u0590\u0800-\u1fff\u200e\u2c00-\ufb1c\ufe00-\ufe6f\ufefd-\uffff";goog.i18n.bidi.rtlChars_="\u0591-\u06ef\u06fa-\u07ff\u200f\ufb1d-\ufdff\ufe70-\ufefc";
goog.i18n.bidi.htmlSkipReg_=/<[^>]*>|&[^;]+;/g;goog.i18n.bidi.stripHtmlIfNeeded_=function(a,b){return b?a.replace(goog.i18n.bidi.htmlSkipReg_,""):a};goog.i18n.bidi.rtlCharReg_=new RegExp("["+goog.i18n.bidi.rtlChars_+"]");goog.i18n.bidi.ltrCharReg_=new RegExp("["+goog.i18n.bidi.ltrChars_+"]");goog.i18n.bidi.hasAnyRtl=function(a,b){return goog.i18n.bidi.rtlCharReg_.test(goog.i18n.bidi.stripHtmlIfNeeded_(a,b))};goog.i18n.bidi.hasRtlChar=goog.i18n.bidi.hasAnyRtl;
goog.i18n.bidi.hasAnyLtr=function(a,b){return goog.i18n.bidi.ltrCharReg_.test(goog.i18n.bidi.stripHtmlIfNeeded_(a,b))};goog.i18n.bidi.ltrRe_=new RegExp("^["+goog.i18n.bidi.ltrChars_+"]");goog.i18n.bidi.rtlRe_=new RegExp("^["+goog.i18n.bidi.rtlChars_+"]");goog.i18n.bidi.isRtlChar=function(a){return goog.i18n.bidi.rtlRe_.test(a)};goog.i18n.bidi.isLtrChar=function(a){return goog.i18n.bidi.ltrRe_.test(a)};goog.i18n.bidi.isNeutralChar=function(a){return!goog.i18n.bidi.isLtrChar(a)&&!goog.i18n.bidi.isRtlChar(a)};
goog.i18n.bidi.ltrDirCheckRe_=new RegExp("^[^"+goog.i18n.bidi.rtlChars_+"]*["+goog.i18n.bidi.ltrChars_+"]");goog.i18n.bidi.rtlDirCheckRe_=new RegExp("^[^"+goog.i18n.bidi.ltrChars_+"]*["+goog.i18n.bidi.rtlChars_+"]");goog.i18n.bidi.startsWithRtl=function(a,b){return goog.i18n.bidi.rtlDirCheckRe_.test(goog.i18n.bidi.stripHtmlIfNeeded_(a,b))};goog.i18n.bidi.isRtlText=goog.i18n.bidi.startsWithRtl;
goog.i18n.bidi.startsWithLtr=function(a,b){return goog.i18n.bidi.ltrDirCheckRe_.test(goog.i18n.bidi.stripHtmlIfNeeded_(a,b))};goog.i18n.bidi.isLtrText=goog.i18n.bidi.startsWithLtr;goog.i18n.bidi.isRequiredLtrRe_=/^http:\/\/.*/;goog.i18n.bidi.isNeutralText=function(a,b){a=goog.i18n.bidi.stripHtmlIfNeeded_(a,b);return goog.i18n.bidi.isRequiredLtrRe_.test(a)||!goog.i18n.bidi.hasAnyLtr(a)&&!goog.i18n.bidi.hasAnyRtl(a)};
goog.i18n.bidi.ltrExitDirCheckRe_=new RegExp("["+goog.i18n.bidi.ltrChars_+"][^"+goog.i18n.bidi.rtlChars_+"]*$");goog.i18n.bidi.rtlExitDirCheckRe_=new RegExp("["+goog.i18n.bidi.rtlChars_+"][^"+goog.i18n.bidi.ltrChars_+"]*$");goog.i18n.bidi.endsWithLtr=function(a,b){return goog.i18n.bidi.ltrExitDirCheckRe_.test(goog.i18n.bidi.stripHtmlIfNeeded_(a,b))};goog.i18n.bidi.isLtrExitText=goog.i18n.bidi.endsWithLtr;
goog.i18n.bidi.endsWithRtl=function(a,b){return goog.i18n.bidi.rtlExitDirCheckRe_.test(goog.i18n.bidi.stripHtmlIfNeeded_(a,b))};goog.i18n.bidi.isRtlExitText=goog.i18n.bidi.endsWithRtl;goog.i18n.bidi.rtlLocalesRe_=/^(ar|ckb|dv|he|iw|fa|nqo|ps|sd|ug|ur|yi|.*[-_](Arab|Hebr|Thaa|Nkoo|Tfng))(?!.*[-_](Latn|Cyrl)($|-|_))($|-|_)/i;goog.i18n.bidi.isRtlLanguage=function(a){return goog.i18n.bidi.rtlLocalesRe_.test(a)};goog.i18n.bidi.bracketGuardTextRe_=/(\(.*?\)+)|(\[.*?\]+)|(\{.*?\}+)|(<.*?>+)/g;
goog.i18n.bidi.guardBracketInText=function(a,b){var c=(void 0===b?goog.i18n.bidi.hasAnyRtl(a):b)?goog.i18n.bidi.Format.RLM:goog.i18n.bidi.Format.LRM;return a.replace(goog.i18n.bidi.bracketGuardTextRe_,c+"$&"+c)};goog.i18n.bidi.enforceRtlInHtml=function(a){return"<"==a.charAt(0)?a.replace(/<\w+/,"$& dir=rtl"):"\n<span dir=rtl>"+a+"</span>"};goog.i18n.bidi.enforceRtlInText=function(a){return goog.i18n.bidi.Format.RLE+a+goog.i18n.bidi.Format.PDF};
goog.i18n.bidi.enforceLtrInHtml=function(a){return"<"==a.charAt(0)?a.replace(/<\w+/,"$& dir=ltr"):"\n<span dir=ltr>"+a+"</span>"};goog.i18n.bidi.enforceLtrInText=function(a){return goog.i18n.bidi.Format.LRE+a+goog.i18n.bidi.Format.PDF};goog.i18n.bidi.dimensionsRe_=/:\s*([.\d][.\w]*)\s+([.\d][.\w]*)\s+([.\d][.\w]*)\s+([.\d][.\w]*)/g;goog.i18n.bidi.leftRe_=/left/gi;goog.i18n.bidi.rightRe_=/right/gi;goog.i18n.bidi.tempRe_=/%%%%/g;
goog.i18n.bidi.mirrorCSS=function(a){return a.replace(goog.i18n.bidi.dimensionsRe_,":$1 $4 $3 $2").replace(goog.i18n.bidi.leftRe_,"%%%%").replace(goog.i18n.bidi.rightRe_,goog.i18n.bidi.LEFT).replace(goog.i18n.bidi.tempRe_,goog.i18n.bidi.RIGHT)};goog.i18n.bidi.doubleQuoteSubstituteRe_=/([\u0591-\u05f2])"/g;goog.i18n.bidi.singleQuoteSubstituteRe_=/([\u0591-\u05f2])'/g;
goog.i18n.bidi.normalizeHebrewQuote=function(a){return a.replace(goog.i18n.bidi.doubleQuoteSubstituteRe_,"$1\u05f4").replace(goog.i18n.bidi.singleQuoteSubstituteRe_,"$1\u05f3")};goog.i18n.bidi.wordSeparatorRe_=/\s+/;goog.i18n.bidi.hasNumeralsRe_=/[\d\u06f0-\u06f9]/;goog.i18n.bidi.rtlDetectionThreshold_=.4;
goog.i18n.bidi.estimateDirection=function(a,b){for(var c=0,d=0,e=!1,f=goog.i18n.bidi.stripHtmlIfNeeded_(a,b).split(goog.i18n.bidi.wordSeparatorRe_),g=0;g<f.length;g++){var h=f[g];goog.i18n.bidi.startsWithRtl(h)?(c++,d++):goog.i18n.bidi.isRequiredLtrRe_.test(h)?e=!0:goog.i18n.bidi.hasAnyLtr(h)?d++:goog.i18n.bidi.hasNumeralsRe_.test(h)&&(e=!0)}return 0==d?e?goog.i18n.bidi.Dir.LTR:goog.i18n.bidi.Dir.NEUTRAL:c/d>goog.i18n.bidi.rtlDetectionThreshold_?goog.i18n.bidi.Dir.RTL:goog.i18n.bidi.Dir.LTR};
goog.i18n.bidi.detectRtlDirectionality=function(a,b){return goog.i18n.bidi.estimateDirection(a,b)==goog.i18n.bidi.Dir.RTL};goog.i18n.bidi.setElementDirAndAlign=function(a,b){a&&(b=goog.i18n.bidi.toDir(b))&&(a.style.textAlign=b==goog.i18n.bidi.Dir.RTL?goog.i18n.bidi.RIGHT:goog.i18n.bidi.LEFT,a.dir=b==goog.i18n.bidi.Dir.RTL?"rtl":"ltr")};
goog.i18n.bidi.setElementDirByTextDirectionality=function(a,b){switch(goog.i18n.bidi.estimateDirection(b)){case goog.i18n.bidi.Dir.LTR:a.dir="ltr";break;case goog.i18n.bidi.Dir.RTL:a.dir="rtl";break;default:a.removeAttribute("dir")}};goog.i18n.bidi.DirectionalString=function(){};goog.html.TrustedResourceUrl=function(){this.privateDoNotAccessOrElseTrustedResourceUrlWrappedValue_="";this.TRUSTED_RESOURCE_URL_TYPE_MARKER_GOOG_HTML_SECURITY_PRIVATE_=goog.html.TrustedResourceUrl.TYPE_MARKER_GOOG_HTML_SECURITY_PRIVATE_};goog.html.TrustedResourceUrl.prototype.implementsGoogStringTypedString=!0;goog.html.TrustedResourceUrl.prototype.getTypedStringValue=function(){return this.privateDoNotAccessOrElseTrustedResourceUrlWrappedValue_};
goog.html.TrustedResourceUrl.prototype.implementsGoogI18nBidiDirectionalString=!0;goog.html.TrustedResourceUrl.prototype.getDirection=function(){return goog.i18n.bidi.Dir.LTR};goog.DEBUG&&(goog.html.TrustedResourceUrl.prototype.toString=function(){return"TrustedResourceUrl{"+this.privateDoNotAccessOrElseTrustedResourceUrlWrappedValue_+"}"});
goog.html.TrustedResourceUrl.unwrap=function(a){if(a instanceof goog.html.TrustedResourceUrl&&a.constructor===goog.html.TrustedResourceUrl&&a.TRUSTED_RESOURCE_URL_TYPE_MARKER_GOOG_HTML_SECURITY_PRIVATE_===goog.html.TrustedResourceUrl.TYPE_MARKER_GOOG_HTML_SECURITY_PRIVATE_)return a.privateDoNotAccessOrElseTrustedResourceUrlWrappedValue_;goog.asserts.fail("expected object of type TrustedResourceUrl, got '"+a+"' of type "+goog.typeOf(a));return"type_error:TrustedResourceUrl"};
goog.html.TrustedResourceUrl.format=function(a,b){var c=goog.string.Const.unwrap(a);if(!goog.html.TrustedResourceUrl.BASE_URL_.test(c))throw Error("Invalid TrustedResourceUrl format: "+c);var d=c.replace(goog.html.TrustedResourceUrl.FORMAT_MARKER_,function(a,d){if(!Object.prototype.hasOwnProperty.call(b,d))throw Error('Found marker, "'+d+'", in format string, "'+c+'", but no valid label mapping found in args: '+JSON.stringify(b));var e=b[d];return e instanceof goog.string.Const?goog.string.Const.unwrap(e):
encodeURIComponent(String(e))});return goog.html.TrustedResourceUrl.createTrustedResourceUrlSecurityPrivateDoNotAccessOrElse(d)};goog.html.TrustedResourceUrl.FORMAT_MARKER_=/%{(\w+)}/g;goog.html.TrustedResourceUrl.BASE_URL_=/^(?:https:)?\/\/[0-9a-z.:[\]-]+\/|^\/[^\/\\]/i;goog.html.TrustedResourceUrl.fromConstant=function(a){return goog.html.TrustedResourceUrl.createTrustedResourceUrlSecurityPrivateDoNotAccessOrElse(goog.string.Const.unwrap(a))};
goog.html.TrustedResourceUrl.fromConstants=function(a){for(var b="",c=0;c<a.length;c++)b+=goog.string.Const.unwrap(a[c]);return goog.html.TrustedResourceUrl.createTrustedResourceUrlSecurityPrivateDoNotAccessOrElse(b)};goog.html.TrustedResourceUrl.TYPE_MARKER_GOOG_HTML_SECURITY_PRIVATE_={};goog.html.TrustedResourceUrl.createTrustedResourceUrlSecurityPrivateDoNotAccessOrElse=function(a){var b=new goog.html.TrustedResourceUrl;b.privateDoNotAccessOrElseTrustedResourceUrlWrappedValue_=a;return b};goog.html.SafeUrl=function(){this.privateDoNotAccessOrElseSafeHtmlWrappedValue_="";this.SAFE_URL_TYPE_MARKER_GOOG_HTML_SECURITY_PRIVATE_=goog.html.SafeUrl.TYPE_MARKER_GOOG_HTML_SECURITY_PRIVATE_};goog.html.SafeUrl.INNOCUOUS_STRING="about:invalid#zClosurez";goog.html.SafeUrl.prototype.implementsGoogStringTypedString=!0;goog.html.SafeUrl.prototype.getTypedStringValue=function(){return this.privateDoNotAccessOrElseSafeHtmlWrappedValue_};
goog.html.SafeUrl.prototype.implementsGoogI18nBidiDirectionalString=!0;goog.html.SafeUrl.prototype.getDirection=function(){return goog.i18n.bidi.Dir.LTR};goog.DEBUG&&(goog.html.SafeUrl.prototype.toString=function(){return"SafeUrl{"+this.privateDoNotAccessOrElseSafeHtmlWrappedValue_+"}"});
goog.html.SafeUrl.unwrap=function(a){if(a instanceof goog.html.SafeUrl&&a.constructor===goog.html.SafeUrl&&a.SAFE_URL_TYPE_MARKER_GOOG_HTML_SECURITY_PRIVATE_===goog.html.SafeUrl.TYPE_MARKER_GOOG_HTML_SECURITY_PRIVATE_)return a.privateDoNotAccessOrElseSafeHtmlWrappedValue_;goog.asserts.fail("expected object of type SafeUrl, got '"+a+"' of type "+goog.typeOf(a));return"type_error:SafeUrl"};goog.html.SafeUrl.fromConstant=function(a){return goog.html.SafeUrl.createSafeUrlSecurityPrivateDoNotAccessOrElse(goog.string.Const.unwrap(a))};
goog.html.SAFE_MIME_TYPE_PATTERN_=/^(?:image\/(?:bmp|gif|jpeg|jpg|png|tiff|webp)|video\/(?:mpeg|mp4|ogg|webm))$/i;goog.html.SafeUrl.fromBlob=function(a){a=goog.html.SAFE_MIME_TYPE_PATTERN_.test(a.type)?goog.fs.url.createObjectUrl(a):goog.html.SafeUrl.INNOCUOUS_STRING;return goog.html.SafeUrl.createSafeUrlSecurityPrivateDoNotAccessOrElse(a)};goog.html.DATA_URL_PATTERN_=/^data:([^;,]*);base64,[a-z0-9+\/]+=*$/i;
goog.html.SafeUrl.fromDataUrl=function(a){var b=a.match(goog.html.DATA_URL_PATTERN_),b=b&&goog.html.SAFE_MIME_TYPE_PATTERN_.test(b[1]);return goog.html.SafeUrl.createSafeUrlSecurityPrivateDoNotAccessOrElse(b?a:goog.html.SafeUrl.INNOCUOUS_STRING)};goog.html.SafeUrl.fromTelUrl=function(a){goog.string.caseInsensitiveStartsWith(a,"tel:")||(a=goog.html.SafeUrl.INNOCUOUS_STRING);return goog.html.SafeUrl.createSafeUrlSecurityPrivateDoNotAccessOrElse(a)};goog.html.SafeUrl.fromTrustedResourceUrl=function(a){return goog.html.SafeUrl.createSafeUrlSecurityPrivateDoNotAccessOrElse(goog.html.TrustedResourceUrl.unwrap(a))};
goog.html.SAFE_URL_PATTERN_=/^(?:(?:https?|mailto|ftp):|[^&:/?#]*(?:[/?#]|$))/i;goog.html.SafeUrl.sanitize=function(a){if(a instanceof goog.html.SafeUrl)return a;a=a.implementsGoogStringTypedString?a.getTypedStringValue():String(a);goog.html.SAFE_URL_PATTERN_.test(a)||(a=goog.html.SafeUrl.INNOCUOUS_STRING);return goog.html.SafeUrl.createSafeUrlSecurityPrivateDoNotAccessOrElse(a)};goog.html.SafeUrl.TYPE_MARKER_GOOG_HTML_SECURITY_PRIVATE_={};
goog.html.SafeUrl.createSafeUrlSecurityPrivateDoNotAccessOrElse=function(a){var b=new goog.html.SafeUrl;b.privateDoNotAccessOrElseSafeHtmlWrappedValue_=a;return b};goog.html.SafeUrl.ABOUT_BLANK=goog.html.SafeUrl.createSafeUrlSecurityPrivateDoNotAccessOrElse("about:blank");goog.html.SafeHtml=function(){this.privateDoNotAccessOrElseSafeHtmlWrappedValue_="";this.SAFE_HTML_TYPE_MARKER_GOOG_HTML_SECURITY_PRIVATE_=goog.html.SafeHtml.TYPE_MARKER_GOOG_HTML_SECURITY_PRIVATE_;this.dir_=null};goog.html.SafeHtml.prototype.implementsGoogI18nBidiDirectionalString=!0;goog.html.SafeHtml.prototype.getDirection=function(){return this.dir_};goog.html.SafeHtml.prototype.implementsGoogStringTypedString=!0;goog.html.SafeHtml.prototype.getTypedStringValue=function(){return this.privateDoNotAccessOrElseSafeHtmlWrappedValue_};
goog.DEBUG&&(goog.html.SafeHtml.prototype.toString=function(){return"SafeHtml{"+this.privateDoNotAccessOrElseSafeHtmlWrappedValue_+"}"});
goog.html.SafeHtml.unwrap=function(a){if(a instanceof goog.html.SafeHtml&&a.constructor===goog.html.SafeHtml&&a.SAFE_HTML_TYPE_MARKER_GOOG_HTML_SECURITY_PRIVATE_===goog.html.SafeHtml.TYPE_MARKER_GOOG_HTML_SECURITY_PRIVATE_)return a.privateDoNotAccessOrElseSafeHtmlWrappedValue_;goog.asserts.fail("expected object of type SafeHtml, got '"+a+"' of type "+goog.typeOf(a));return"type_error:SafeHtml"};
goog.html.SafeHtml.htmlEscape=function(a){if(a instanceof goog.html.SafeHtml)return a;var b=null;a.implementsGoogI18nBidiDirectionalString&&(b=a.getDirection());a=a.implementsGoogStringTypedString?a.getTypedStringValue():String(a);return goog.html.SafeHtml.createSafeHtmlSecurityPrivateDoNotAccessOrElse(goog.string.htmlEscape(a),b)};
goog.html.SafeHtml.htmlEscapePreservingNewlines=function(a){if(a instanceof goog.html.SafeHtml)return a;a=goog.html.SafeHtml.htmlEscape(a);return goog.html.SafeHtml.createSafeHtmlSecurityPrivateDoNotAccessOrElse(goog.string.newLineToBr(goog.html.SafeHtml.unwrap(a)),a.getDirection())};
goog.html.SafeHtml.htmlEscapePreservingNewlinesAndSpaces=function(a){if(a instanceof goog.html.SafeHtml)return a;a=goog.html.SafeHtml.htmlEscape(a);return goog.html.SafeHtml.createSafeHtmlSecurityPrivateDoNotAccessOrElse(goog.string.whitespaceEscape(goog.html.SafeHtml.unwrap(a)),a.getDirection())};goog.html.SafeHtml.from=goog.html.SafeHtml.htmlEscape;goog.html.SafeHtml.VALID_NAMES_IN_TAG_=/^[a-zA-Z0-9-]+$/;
goog.html.SafeHtml.URL_ATTRIBUTES_={action:!0,cite:!0,data:!0,formaction:!0,href:!0,manifest:!0,poster:!0,src:!0};goog.html.SafeHtml.NOT_ALLOWED_TAG_NAMES_={APPLET:!0,BASE:!0,EMBED:!0,IFRAME:!0,LINK:!0,MATH:!0,META:!0,OBJECT:!0,SCRIPT:!0,STYLE:!0,SVG:!0,TEMPLATE:!0};goog.html.SafeHtml.create=function(a,b,c){goog.html.SafeHtml.verifyTagName(String(a));return goog.html.SafeHtml.createSafeHtmlTagSecurityPrivateDoNotAccessOrElse(String(a),b,c)};
goog.html.SafeHtml.verifyTagName=function(a){if(!goog.html.SafeHtml.VALID_NAMES_IN_TAG_.test(a))throw Error("Invalid tag name <"+a+">.");if(a.toUpperCase()in goog.html.SafeHtml.NOT_ALLOWED_TAG_NAMES_)throw Error("Tag name <"+a+"> is not allowed for SafeHtml.");};
goog.html.SafeHtml.createIframe=function(a,b,c,d){a&&goog.html.TrustedResourceUrl.unwrap(a);var e={};e.src=a||null;e.srcdoc=b&&goog.html.SafeHtml.unwrap(b);a=goog.html.SafeHtml.combineAttributes(e,{sandbox:""},c);return goog.html.SafeHtml.createSafeHtmlTagSecurityPrivateDoNotAccessOrElse("iframe",a,d)};
goog.html.SafeHtml.createSandboxIframe=function(a,b,c,d){if(!goog.html.SafeHtml.canUseSandboxIframe())throw Error("The browser does not support sandboxed iframes.");var e={};e.src=a?goog.html.SafeUrl.unwrap(goog.html.SafeUrl.sanitize(a)):null;e.srcdoc=b||null;e.sandbox="";a=goog.html.SafeHtml.combineAttributes(e,{},c);return goog.html.SafeHtml.createSafeHtmlTagSecurityPrivateDoNotAccessOrElse("iframe",a,d)};
goog.html.SafeHtml.canUseSandboxIframe=function(){return goog.global.HTMLIFrameElement&&"sandbox"in goog.global.HTMLIFrameElement.prototype};goog.html.SafeHtml.createScriptSrc=function(a,b){goog.html.TrustedResourceUrl.unwrap(a);var c=goog.html.SafeHtml.combineAttributes({src:a},{},b);return goog.html.SafeHtml.createSafeHtmlTagSecurityPrivateDoNotAccessOrElse("script",c)};
goog.html.SafeHtml.createScript=function(a,b){for(var c in b){var d=c.toLowerCase();if("language"==d||"src"==d||"text"==d||"type"==d)throw Error('Cannot set "'+d+'" attribute');}c="";a=goog.array.concat(a);for(d=0;d<a.length;d++)c+=goog.html.SafeScript.unwrap(a[d]);c=goog.html.SafeHtml.createSafeHtmlSecurityPrivateDoNotAccessOrElse(c,goog.i18n.bidi.Dir.NEUTRAL);return goog.html.SafeHtml.createSafeHtmlTagSecurityPrivateDoNotAccessOrElse("script",b,c)};
goog.html.SafeHtml.createStyle=function(a,b){var c=goog.html.SafeHtml.combineAttributes({type:"text/css"},{},b),d="";a=goog.array.concat(a);for(var e=0;e<a.length;e++)d+=goog.html.SafeStyleSheet.unwrap(a[e]);d=goog.html.SafeHtml.createSafeHtmlSecurityPrivateDoNotAccessOrElse(d,goog.i18n.bidi.Dir.NEUTRAL);return goog.html.SafeHtml.createSafeHtmlTagSecurityPrivateDoNotAccessOrElse("style",c,d)};
goog.html.SafeHtml.createMetaRefresh=function(a,b){var c=goog.html.SafeUrl.unwrap(goog.html.SafeUrl.sanitize(a));(goog.labs.userAgent.browser.isIE()||goog.labs.userAgent.browser.isEdge())&&goog.string.contains(c,";")&&(c="'"+c.replace(/'/g,"%27")+"'");return goog.html.SafeHtml.createSafeHtmlTagSecurityPrivateDoNotAccessOrElse("meta",{"http-equiv":"refresh",content:(b||0)+"; url="+c})};
goog.html.SafeHtml.getAttrNameAndValue_=function(a,b,c){if(c instanceof goog.string.Const)c=goog.string.Const.unwrap(c);else if("style"==b.toLowerCase())c=goog.html.SafeHtml.getStyleValue_(c);else{if(/^on/i.test(b))throw Error('Attribute "'+b+'" requires goog.string.Const value, "'+c+'" given.');if(b.toLowerCase()in goog.html.SafeHtml.URL_ATTRIBUTES_)if(c instanceof goog.html.TrustedResourceUrl)c=goog.html.TrustedResourceUrl.unwrap(c);else if(c instanceof goog.html.SafeUrl)c=goog.html.SafeUrl.unwrap(c);
else if(goog.isString(c))c=goog.html.SafeUrl.sanitize(c).getTypedStringValue();else throw Error('Attribute "'+b+'" on tag "'+a+'" requires goog.html.SafeUrl, goog.string.Const, or string, value "'+c+'" given.');}c.implementsGoogStringTypedString&&(c=c.getTypedStringValue());goog.asserts.assert(goog.isString(c)||goog.isNumber(c),"String or number value expected, got "+typeof c+" with value: "+c);return b+'="'+goog.string.htmlEscape(String(c))+'"'};
goog.html.SafeHtml.getStyleValue_=function(a){if(!goog.isObject(a))throw Error('The "style" attribute requires goog.html.SafeStyle or map of style properties, '+typeof a+" given: "+a);a instanceof goog.html.SafeStyle||(a=goog.html.SafeStyle.create(a));return goog.html.SafeStyle.unwrap(a)};goog.html.SafeHtml.createWithDir=function(a,b,c,d){b=goog.html.SafeHtml.create(b,c,d);b.dir_=a;return b};
goog.html.SafeHtml.concat=function(a){var b=goog.i18n.bidi.Dir.NEUTRAL,c="",d=function(a){goog.isArray(a)?goog.array.forEach(a,d):(a=goog.html.SafeHtml.htmlEscape(a),c+=goog.html.SafeHtml.unwrap(a),a=a.getDirection(),b==goog.i18n.bidi.Dir.NEUTRAL?b=a:a!=goog.i18n.bidi.Dir.NEUTRAL&&b!=a&&(b=null))};goog.array.forEach(arguments,d);return goog.html.SafeHtml.createSafeHtmlSecurityPrivateDoNotAccessOrElse(c,b)};
goog.html.SafeHtml.concatWithDir=function(a,b){var c=goog.html.SafeHtml.concat(goog.array.slice(arguments,1));c.dir_=a;return c};goog.html.SafeHtml.TYPE_MARKER_GOOG_HTML_SECURITY_PRIVATE_={};goog.html.SafeHtml.createSafeHtmlSecurityPrivateDoNotAccessOrElse=function(a,b){return(new goog.html.SafeHtml).initSecurityPrivateDoNotAccessOrElse_(a,b)};goog.html.SafeHtml.prototype.initSecurityPrivateDoNotAccessOrElse_=function(a,b){this.privateDoNotAccessOrElseSafeHtmlWrappedValue_=a;this.dir_=b;return this};
goog.html.SafeHtml.createSafeHtmlTagSecurityPrivateDoNotAccessOrElse=function(a,b,c){var d=null,e;e="<"+a+goog.html.SafeHtml.stringifyAttributes(a,b);goog.isDefAndNotNull(c)?goog.isArray(c)||(c=[c]):c=[];goog.dom.tags.isVoidTag(a.toLowerCase())?(goog.asserts.assert(!c.length,"Void tag <"+a+"> does not allow content."),e+=">"):(d=goog.html.SafeHtml.concat(c),e+=">"+goog.html.SafeHtml.unwrap(d)+"</"+a+">",d=d.getDirection());(a=b&&b.dir)&&(d=/^(ltr|rtl|auto)$/i.test(a)?goog.i18n.bidi.Dir.NEUTRAL:null);
return goog.html.SafeHtml.createSafeHtmlSecurityPrivateDoNotAccessOrElse(e,d)};goog.html.SafeHtml.stringifyAttributes=function(a,b){var c="";if(b)for(var d in b){if(!goog.html.SafeHtml.VALID_NAMES_IN_TAG_.test(d))throw Error('Invalid attribute name "'+d+'".');var e=b[d];goog.isDefAndNotNull(e)&&(c+=" "+goog.html.SafeHtml.getAttrNameAndValue_(a,d,e))}return c};
goog.html.SafeHtml.combineAttributes=function(a,b,c){var d={},e;for(e in a)goog.asserts.assert(e.toLowerCase()==e,"Must be lower case"),d[e]=a[e];for(e in b)goog.asserts.assert(e.toLowerCase()==e,"Must be lower case"),d[e]=b[e];for(e in c){var f=e.toLowerCase();if(f in a)throw Error('Cannot override "'+f+'" attribute, got "'+e+'" with value "'+c[e]+'"');f in b&&delete d[f];d[e]=c[e]}return d};
goog.html.SafeHtml.DOCTYPE_HTML=goog.html.SafeHtml.createSafeHtmlSecurityPrivateDoNotAccessOrElse("<!DOCTYPE html>",goog.i18n.bidi.Dir.NEUTRAL);goog.html.SafeHtml.EMPTY=goog.html.SafeHtml.createSafeHtmlSecurityPrivateDoNotAccessOrElse("",goog.i18n.bidi.Dir.NEUTRAL);goog.html.SafeHtml.BR=goog.html.SafeHtml.createSafeHtmlSecurityPrivateDoNotAccessOrElse("<br>",goog.i18n.bidi.Dir.NEUTRAL);goog.dom.safe={};goog.dom.safe.InsertAdjacentHtmlPosition={AFTERBEGIN:"afterbegin",AFTEREND:"afterend",BEFOREBEGIN:"beforebegin",BEFOREEND:"beforeend"};goog.dom.safe.insertAdjacentHtml=function(a,b,c){a.insertAdjacentHTML(b,goog.html.SafeHtml.unwrap(c))};goog.dom.safe.SET_INNER_HTML_DISALLOWED_TAGS_={MATH:!0,SCRIPT:!0,STYLE:!0,SVG:!0,TEMPLATE:!0};
goog.dom.safe.setInnerHtml=function(a,b){if(goog.asserts.ENABLE_ASSERTS){var c=a.tagName.toUpperCase();if(goog.dom.safe.SET_INNER_HTML_DISALLOWED_TAGS_[c])throw Error("goog.dom.safe.setInnerHtml cannot be used to set content of "+a.tagName+".");}a.innerHTML=goog.html.SafeHtml.unwrap(b)};goog.dom.safe.setOuterHtml=function(a,b){a.outerHTML=goog.html.SafeHtml.unwrap(b)};goog.dom.safe.setStyle=function(a,b){a.style.cssText=goog.html.SafeStyle.unwrap(b)};goog.dom.safe.documentWrite=function(a,b){a.write(goog.html.SafeHtml.unwrap(b))};
goog.dom.safe.setAnchorHref=function(a,b){goog.dom.safe.assertIsHTMLAnchorElement_(a);var c;c=b instanceof goog.html.SafeUrl?b:goog.html.SafeUrl.sanitize(b);a.href=goog.html.SafeUrl.unwrap(c)};goog.dom.safe.setImageSrc=function(a,b){goog.dom.safe.assertIsHTMLImageElement_(a);var c;c=b instanceof goog.html.SafeUrl?b:goog.html.SafeUrl.sanitize(b);a.src=goog.html.SafeUrl.unwrap(c)};goog.dom.safe.setEmbedSrc=function(a,b){goog.dom.safe.assertIsHTMLEmbedElement_(a);a.src=goog.html.TrustedResourceUrl.unwrap(b)};
goog.dom.safe.setFrameSrc=function(a,b){goog.dom.safe.assertIsHTMLFrameElement_(a);a.src=goog.html.TrustedResourceUrl.unwrap(b)};goog.dom.safe.setIframeSrc=function(a,b){goog.dom.safe.assertIsHTMLIFrameElement_(a);a.src=goog.html.TrustedResourceUrl.unwrap(b)};goog.dom.safe.setIframeSrcdoc=function(a,b){goog.dom.safe.assertIsHTMLIFrameElement_(a);a.srcdoc=goog.html.SafeHtml.unwrap(b)};
goog.dom.safe.setLinkHrefAndRel=function(a,b,c){goog.dom.safe.assertIsHTMLLinkElement_(a);a.rel=c;goog.string.caseInsensitiveContains(c,"stylesheet")?(goog.asserts.assert(b instanceof goog.html.TrustedResourceUrl,'URL must be TrustedResourceUrl because "rel" contains "stylesheet"'),a.href=goog.html.TrustedResourceUrl.unwrap(b)):a.href=b instanceof goog.html.TrustedResourceUrl?goog.html.TrustedResourceUrl.unwrap(b):b instanceof goog.html.SafeUrl?goog.html.SafeUrl.unwrap(b):goog.html.SafeUrl.sanitize(b).getTypedStringValue()};
goog.dom.safe.setObjectData=function(a,b){goog.dom.safe.assertIsHTMLObjectElement_(a);a.data=goog.html.TrustedResourceUrl.unwrap(b)};goog.dom.safe.setScriptSrc=function(a,b){goog.dom.safe.assertIsHTMLScriptElement_(a);a.src=goog.html.TrustedResourceUrl.unwrap(b)};goog.dom.safe.setLocationHref=function(a,b){goog.dom.safe.assertIsLocation_(a);var c;c=b instanceof goog.html.SafeUrl?b:goog.html.SafeUrl.sanitize(b);a.href=goog.html.SafeUrl.unwrap(c)};
goog.dom.safe.openInWindow=function(a,b,c,d,e){a=a instanceof goog.html.SafeUrl?a:goog.html.SafeUrl.sanitize(a);return(b||window).open(goog.html.SafeUrl.unwrap(a),c?goog.string.Const.unwrap(c):"",d,e)};
goog.dom.safe.assertIsLocation_=function(a){goog.asserts.ENABLE_ASSERTS&&"undefined"!=typeof Location&&"undefined"!=typeof Element&&goog.asserts.assert(a&&(a instanceof Location||!(a instanceof Element)),"Argument is not a Location (or a non-Element mock); got: %s",goog.dom.safe.debugStringForType_(a));return a};
goog.dom.safe.assertIsHTMLAnchorElement_=function(a){goog.asserts.ENABLE_ASSERTS&&"undefined"!=typeof HTMLAnchorElement&&"undefined"!=typeof Location&&"undefined"!=typeof Element&&goog.asserts.assert(a&&(a instanceof HTMLAnchorElement||!(a instanceof Location||a instanceof Element)),"Argument is not a HTMLAnchorElement (or a non-Element mock); got: %s",goog.dom.safe.debugStringForType_(a));return a};
goog.dom.safe.assertIsHTMLLinkElement_=function(a){goog.asserts.ENABLE_ASSERTS&&"undefined"!=typeof HTMLLinkElement&&"undefined"!=typeof Location&&"undefined"!=typeof Element&&goog.asserts.assert(a&&(a instanceof HTMLLinkElement||!(a instanceof Location||a instanceof Element)),"Argument is not a HTMLLinkElement (or a non-Element mock); got: %s",goog.dom.safe.debugStringForType_(a));return a};
goog.dom.safe.assertIsHTMLImageElement_=function(a){goog.asserts.ENABLE_ASSERTS&&"undefined"!=typeof HTMLImageElement&&"undefined"!=typeof Element&&goog.asserts.assert(a&&(a instanceof HTMLImageElement||!(a instanceof Element)),"Argument is not a HTMLImageElement (or a non-Element mock); got: %s",goog.dom.safe.debugStringForType_(a));return a};
goog.dom.safe.assertIsHTMLEmbedElement_=function(a){goog.asserts.ENABLE_ASSERTS&&"undefined"!=typeof HTMLEmbedElement&&"undefined"!=typeof Element&&goog.asserts.assert(a&&(a instanceof HTMLEmbedElement||!(a instanceof Element)),"Argument is not a HTMLEmbedElement (or a non-Element mock); got: %s",goog.dom.safe.debugStringForType_(a));return a};
goog.dom.safe.assertIsHTMLFrameElement_=function(a){goog.asserts.ENABLE_ASSERTS&&"undefined"!=typeof HTMLFrameElement&&"undefined"!=typeof Element&&goog.asserts.assert(a&&(a instanceof HTMLFrameElement||!(a instanceof Element)),"Argument is not a HTMLFrameElement (or a non-Element mock); got: %s",goog.dom.safe.debugStringForType_(a));return a};
goog.dom.safe.assertIsHTMLIFrameElement_=function(a){goog.asserts.ENABLE_ASSERTS&&"undefined"!=typeof HTMLIFrameElement&&"undefined"!=typeof Element&&goog.asserts.assert(a&&(a instanceof HTMLIFrameElement||!(a instanceof Element)),"Argument is not a HTMLIFrameElement (or a non-Element mock); got: %s",goog.dom.safe.debugStringForType_(a));return a};
goog.dom.safe.assertIsHTMLObjectElement_=function(a){goog.asserts.ENABLE_ASSERTS&&"undefined"!=typeof HTMLObjectElement&&"undefined"!=typeof Element&&goog.asserts.assert(a&&(a instanceof HTMLObjectElement||!(a instanceof Element)),"Argument is not a HTMLObjectElement (or a non-Element mock); got: %s",goog.dom.safe.debugStringForType_(a));return a};
goog.dom.safe.assertIsHTMLScriptElement_=function(a){goog.asserts.ENABLE_ASSERTS&&"undefined"!=typeof HTMLScriptElement&&"undefined"!=typeof Element&&goog.asserts.assert(a&&(a instanceof HTMLScriptElement||!(a instanceof Element)),"Argument is not a HTMLScriptElement (or a non-Element mock); got: %s",goog.dom.safe.debugStringForType_(a));return a};
goog.dom.safe.debugStringForType_=function(a){return goog.isObject(a)?a.constructor.displayName||a.constructor.name||Object.prototype.toString.call(a):void 0===a?"undefined":null===a?"null":typeof a};goog.html.uncheckedconversions={};goog.html.uncheckedconversions.safeHtmlFromStringKnownToSatisfyTypeContract=function(a,b,c){goog.asserts.assertString(goog.string.Const.unwrap(a),"must provide justification");goog.asserts.assert(!goog.string.isEmptyOrWhitespace(goog.string.Const.unwrap(a)),"must provide non-empty justification");return goog.html.SafeHtml.createSafeHtmlSecurityPrivateDoNotAccessOrElse(b,c||null)};
goog.html.uncheckedconversions.safeScriptFromStringKnownToSatisfyTypeContract=function(a,b){goog.asserts.assertString(goog.string.Const.unwrap(a),"must provide justification");goog.asserts.assert(!goog.string.isEmpty(goog.string.Const.unwrap(a)),"must provide non-empty justification");return goog.html.SafeScript.createSafeScriptSecurityPrivateDoNotAccessOrElse(b)};
goog.html.uncheckedconversions.safeStyleFromStringKnownToSatisfyTypeContract=function(a,b){goog.asserts.assertString(goog.string.Const.unwrap(a),"must provide justification");goog.asserts.assert(!goog.string.isEmptyOrWhitespace(goog.string.Const.unwrap(a)),"must provide non-empty justification");return goog.html.SafeStyle.createSafeStyleSecurityPrivateDoNotAccessOrElse(b)};
goog.html.uncheckedconversions.safeStyleSheetFromStringKnownToSatisfyTypeContract=function(a,b){goog.asserts.assertString(goog.string.Const.unwrap(a),"must provide justification");goog.asserts.assert(!goog.string.isEmptyOrWhitespace(goog.string.Const.unwrap(a)),"must provide non-empty justification");return goog.html.SafeStyleSheet.createSafeStyleSheetSecurityPrivateDoNotAccessOrElse(b)};
goog.html.uncheckedconversions.safeUrlFromStringKnownToSatisfyTypeContract=function(a,b){goog.asserts.assertString(goog.string.Const.unwrap(a),"must provide justification");goog.asserts.assert(!goog.string.isEmptyOrWhitespace(goog.string.Const.unwrap(a)),"must provide non-empty justification");return goog.html.SafeUrl.createSafeUrlSecurityPrivateDoNotAccessOrElse(b)};
goog.html.uncheckedconversions.trustedResourceUrlFromStringKnownToSatisfyTypeContract=function(a,b){goog.asserts.assertString(goog.string.Const.unwrap(a),"must provide justification");goog.asserts.assert(!goog.string.isEmptyOrWhitespace(goog.string.Const.unwrap(a)),"must provide non-empty justification");return goog.html.TrustedResourceUrl.createTrustedResourceUrlSecurityPrivateDoNotAccessOrElse(b)};goog.math={};goog.math.randomInt=function(a){return Math.floor(Math.random()*a)};goog.math.uniformRandom=function(a,b){return a+Math.random()*(b-a)};goog.math.clamp=function(a,b,c){return Math.min(Math.max(a,b),c)};goog.math.modulo=function(a,b){var c=a%b;return 0>c*b?c+b:c};goog.math.lerp=function(a,b,c){return a+c*(b-a)};goog.math.nearlyEquals=function(a,b,c){return Math.abs(a-b)<=(c||1E-6)};goog.math.standardAngle=function(a){return goog.math.modulo(a,360)};
goog.math.standardAngleInRadians=function(a){return goog.math.modulo(a,2*Math.PI)};goog.math.toRadians=function(a){return a*Math.PI/180};goog.math.toDegrees=function(a){return 180*a/Math.PI};goog.math.angleDx=function(a,b){return b*Math.cos(goog.math.toRadians(a))};goog.math.angleDy=function(a,b){return b*Math.sin(goog.math.toRadians(a))};goog.math.angle=function(a,b,c,d){return goog.math.standardAngle(goog.math.toDegrees(Math.atan2(d-b,c-a)))};
goog.math.angleDifference=function(a,b){var c=goog.math.standardAngle(b)-goog.math.standardAngle(a);180<c?c-=360:-180>=c&&(c=360+c);return c};goog.math.sign=function(a){return 0<a?1:0>a?-1:a};
goog.math.longestCommonSubsequence=function(a,b,c,d){c=c||function(a,b){return a==b};d=d||function(b,c){return a[b]};for(var e=a.length,f=b.length,g=[],h=0;h<e+1;h++)g[h]=[],g[h][0]=0;for(var k=0;k<f+1;k++)g[0][k]=0;for(h=1;h<=e;h++)for(k=1;k<=f;k++)c(a[h-1],b[k-1])?g[h][k]=g[h-1][k-1]+1:g[h][k]=Math.max(g[h-1][k],g[h][k-1]);for(var l=[],h=e,k=f;0<h&&0<k;)c(a[h-1],b[k-1])?(l.unshift(d(h-1,k-1)),h--,k--):g[h-1][k]>g[h][k-1]?h--:k--;return l};
goog.math.sum=function(a){return goog.array.reduce(arguments,function(a,c){return a+c},0)};goog.math.average=function(a){return goog.math.sum.apply(null,arguments)/arguments.length};goog.math.sampleVariance=function(a){var b=arguments.length;if(2>b)return 0;var c=goog.math.average.apply(null,arguments);return goog.math.sum.apply(null,goog.array.map(arguments,function(a){return Math.pow(a-c,2)}))/(b-1)};goog.math.standardDeviation=function(a){return Math.sqrt(goog.math.sampleVariance.apply(null,arguments))};
goog.math.isInt=function(a){return isFinite(a)&&0==a%1};goog.math.isFiniteNumber=function(a){return isFinite(a)&&!isNaN(a)};goog.math.isNegativeZero=function(a){return 0==a&&0>1/a};goog.math.log10Floor=function(a){if(0<a){var b=Math.round(Math.log(a)*Math.LOG10E);return b-(parseFloat("1e"+b)>a?1:0)}return 0==a?-Infinity:NaN};goog.math.safeFloor=function(a,b){goog.asserts.assert(!goog.isDef(b)||0<b);return Math.floor(a+(b||2E-15))};
goog.math.safeCeil=function(a,b){goog.asserts.assert(!goog.isDef(b)||0<b);return Math.ceil(a-(b||2E-15))};goog.math.Coordinate=function(a,b){this.x=goog.isDef(a)?a:0;this.y=goog.isDef(b)?b:0};goog.math.Coordinate.prototype.clone=function(){return new goog.math.Coordinate(this.x,this.y)};goog.DEBUG&&(goog.math.Coordinate.prototype.toString=function(){return"("+this.x+", "+this.y+")"});goog.math.Coordinate.prototype.equals=function(a){return a instanceof goog.math.Coordinate&&goog.math.Coordinate.equals(this,a)};goog.math.Coordinate.equals=function(a,b){return a==b?!0:a&&b?a.x==b.x&&a.y==b.y:!1};
goog.math.Coordinate.distance=function(a,b){var c=a.x-b.x,d=a.y-b.y;return Math.sqrt(c*c+d*d)};goog.math.Coordinate.magnitude=function(a){return Math.sqrt(a.x*a.x+a.y*a.y)};goog.math.Coordinate.azimuth=function(a){return goog.math.angle(0,0,a.x,a.y)};goog.math.Coordinate.squaredDistance=function(a,b){var c=a.x-b.x,d=a.y-b.y;return c*c+d*d};goog.math.Coordinate.difference=function(a,b){return new goog.math.Coordinate(a.x-b.x,a.y-b.y)};
goog.math.Coordinate.sum=function(a,b){return new goog.math.Coordinate(a.x+b.x,a.y+b.y)};goog.math.Coordinate.prototype.ceil=function(){this.x=Math.ceil(this.x);this.y=Math.ceil(this.y);return this};goog.math.Coordinate.prototype.floor=function(){this.x=Math.floor(this.x);this.y=Math.floor(this.y);return this};goog.math.Coordinate.prototype.round=function(){this.x=Math.round(this.x);this.y=Math.round(this.y);return this};
goog.math.Coordinate.prototype.translate=function(a,b){a instanceof goog.math.Coordinate?(this.x+=a.x,this.y+=a.y):(this.x+=Number(a),goog.isNumber(b)&&(this.y+=b));return this};goog.math.Coordinate.prototype.scale=function(a,b){var c=goog.isNumber(b)?b:a;this.x*=a;this.y*=c;return this};goog.math.Coordinate.prototype.rotateRadians=function(a,b){var c=b||new goog.math.Coordinate(0,0),d=this.x,e=this.y,f=Math.cos(a),g=Math.sin(a);this.x=(d-c.x)*f-(e-c.y)*g+c.x;this.y=(d-c.x)*g+(e-c.y)*f+c.y};
goog.math.Coordinate.prototype.rotateDegrees=function(a,b){this.rotateRadians(goog.math.toRadians(a),b)};goog.math.Size=function(a,b){this.width=a;this.height=b};goog.math.Size.equals=function(a,b){return a==b?!0:a&&b?a.width==b.width&&a.height==b.height:!1};goog.math.Size.prototype.clone=function(){return new goog.math.Size(this.width,this.height)};goog.DEBUG&&(goog.math.Size.prototype.toString=function(){return"("+this.width+" x "+this.height+")"});goog.math.Size.prototype.getLongest=function(){return Math.max(this.width,this.height)};
goog.math.Size.prototype.getShortest=function(){return Math.min(this.width,this.height)};goog.math.Size.prototype.area=function(){return this.width*this.height};goog.math.Size.prototype.perimeter=function(){return 2*(this.width+this.height)};goog.math.Size.prototype.aspectRatio=function(){return this.width/this.height};goog.math.Size.prototype.isEmpty=function(){return!this.area()};goog.math.Size.prototype.ceil=function(){this.width=Math.ceil(this.width);this.height=Math.ceil(this.height);return this};
goog.math.Size.prototype.fitsInside=function(a){return this.width<=a.width&&this.height<=a.height};goog.math.Size.prototype.floor=function(){this.width=Math.floor(this.width);this.height=Math.floor(this.height);return this};goog.math.Size.prototype.round=function(){this.width=Math.round(this.width);this.height=Math.round(this.height);return this};goog.math.Size.prototype.scale=function(a,b){var c=goog.isNumber(b)?b:a;this.width*=a;this.height*=c;return this};
goog.math.Size.prototype.scaleToCover=function(a){a=this.aspectRatio()<=a.aspectRatio()?a.width/this.width:a.height/this.height;return this.scale(a)};goog.math.Size.prototype.scaleToFit=function(a){a=this.aspectRatio()>a.aspectRatio()?a.width/this.width:a.height/this.height;return this.scale(a)};goog.dom.ASSUME_QUIRKS_MODE=!1;goog.dom.ASSUME_STANDARDS_MODE=!1;goog.dom.COMPAT_MODE_KNOWN_=goog.dom.ASSUME_QUIRKS_MODE||goog.dom.ASSUME_STANDARDS_MODE;goog.dom.getDomHelper=function(a){return a?new goog.dom.DomHelper(goog.dom.getOwnerDocument(a)):goog.dom.defaultDomHelper_||(goog.dom.defaultDomHelper_=new goog.dom.DomHelper)};goog.dom.getDocument=function(){return document};goog.dom.getElement=function(a){return goog.dom.getElementHelper_(document,a)};
goog.dom.getElementHelper_=function(a,b){return goog.isString(b)?a.getElementById(b):b};goog.dom.getRequiredElement=function(a){return goog.dom.getRequiredElementHelper_(document,a)};goog.dom.getRequiredElementHelper_=function(a,b){goog.asserts.assertString(b);var c=goog.dom.getElementHelper_(a,b);return c=goog.asserts.assertElement(c,"No element found with id: "+b)};goog.dom.$=goog.dom.getElement;goog.dom.getElementsByTagName=function(a,b){return(b||document).getElementsByTagName(String(a))};
goog.dom.getElementsByTagNameAndClass=function(a,b,c){return goog.dom.getElementsByTagNameAndClass_(document,a,b,c)};goog.dom.getElementsByClass=function(a,b){var c=b||document;return goog.dom.canUseQuerySelector_(c)?c.querySelectorAll("."+a):goog.dom.getElementsByTagNameAndClass_(document,"*",a,b)};
goog.dom.getElementByClass=function(a,b){var c=b||document;return(c.getElementsByClassName?c.getElementsByClassName(a)[0]:goog.dom.canUseQuerySelector_(c)?c.querySelector("."+a):goog.dom.getElementsByTagNameAndClass_(document,"*",a,b)[0])||null};goog.dom.getRequiredElementByClass=function(a,b){var c=goog.dom.getElementByClass(a,b);return goog.asserts.assert(c,"No element found with className: "+a)};goog.dom.canUseQuerySelector_=function(a){return!(!a.querySelectorAll||!a.querySelector)};
goog.dom.getElementsByTagNameAndClass_=function(a,b,c,d){a=d||a;b=b&&"*"!=b?String(b).toUpperCase():"";if(goog.dom.canUseQuerySelector_(a)&&(b||c))return a.querySelectorAll(b+(c?"."+c:""));if(c&&a.getElementsByClassName){a=a.getElementsByClassName(c);if(b){d={};for(var e=0,f=0,g;g=a[f];f++)b==g.nodeName&&(d[e++]=g);d.length=e;return d}return a}a=a.getElementsByTagName(b||"*");if(c){d={};for(f=e=0;g=a[f];f++)b=g.className,"function"==typeof b.split&&goog.array.contains(b.split(/\s+/),c)&&(d[e++]=g);
d.length=e;return d}return a};goog.dom.$$=goog.dom.getElementsByTagNameAndClass;goog.dom.setProperties=function(a,b){goog.object.forEach(b,function(b,d){"style"==d?a.style.cssText=b:"class"==d?a.className=b:"for"==d?a.htmlFor=b:goog.dom.DIRECT_ATTRIBUTE_MAP_.hasOwnProperty(d)?a.setAttribute(goog.dom.DIRECT_ATTRIBUTE_MAP_[d],b):goog.string.startsWith(d,"aria-")||goog.string.startsWith(d,"data-")?a.setAttribute(d,b):a[d]=b})};
goog.dom.DIRECT_ATTRIBUTE_MAP_={cellpadding:"cellPadding",cellspacing:"cellSpacing",colspan:"colSpan",frameborder:"frameBorder",height:"height",maxlength:"maxLength",nonce:"nonce",role:"role",rowspan:"rowSpan",type:"type",usemap:"useMap",valign:"vAlign",width:"width"};goog.dom.getViewportSize=function(a){return goog.dom.getViewportSize_(a||window)};goog.dom.getViewportSize_=function(a){a=a.document;a=goog.dom.isCss1CompatMode_(a)?a.documentElement:a.body;return new goog.math.Size(a.clientWidth,a.clientHeight)};
goog.dom.getDocumentHeight=function(){return goog.dom.getDocumentHeight_(window)};goog.dom.getDocumentHeightForWindow=function(a){return goog.dom.getDocumentHeight_(a)};
goog.dom.getDocumentHeight_=function(a){var b=a.document,c=0;if(b){var c=b.body,d=b.documentElement;if(!d||!c)return 0;a=goog.dom.getViewportSize_(a).height;if(goog.dom.isCss1CompatMode_(b)&&d.scrollHeight)c=d.scrollHeight!=a?d.scrollHeight:d.offsetHeight;else{var b=d.scrollHeight,e=d.offsetHeight;d.clientHeight!=e&&(b=c.scrollHeight,e=c.offsetHeight);c=b>a?b>e?b:e:b<e?b:e}}return c};goog.dom.getPageScroll=function(a){return goog.dom.getDomHelper((a||goog.global||window).document).getDocumentScroll()};
goog.dom.getDocumentScroll=function(){return goog.dom.getDocumentScroll_(document)};goog.dom.getDocumentScroll_=function(a){var b=goog.dom.getDocumentScrollElement_(a);a=goog.dom.getWindow_(a);return goog.userAgent.IE&&goog.userAgent.isVersionOrHigher("10")&&a.pageYOffset!=b.scrollTop?new goog.math.Coordinate(b.scrollLeft,b.scrollTop):new goog.math.Coordinate(a.pageXOffset||b.scrollLeft,a.pageYOffset||b.scrollTop)};goog.dom.getDocumentScrollElement=function(){return goog.dom.getDocumentScrollElement_(document)};
goog.dom.getDocumentScrollElement_=function(a){return a.scrollingElement?a.scrollingElement:!goog.userAgent.WEBKIT&&goog.dom.isCss1CompatMode_(a)?a.documentElement:a.body||a.documentElement};goog.dom.getWindow=function(a){return a?goog.dom.getWindow_(a):window};goog.dom.getWindow_=function(a){return a.parentWindow||a.defaultView};goog.dom.createDom=function(a,b,c){return goog.dom.createDom_(document,arguments)};
goog.dom.createDom_=function(a,b){var c=String(b[0]),d=b[1];if(!goog.dom.BrowserFeature.CAN_ADD_NAME_OR_TYPE_ATTRIBUTES&&d&&(d.name||d.type)){c=["<",c];d.name&&c.push(' name="',goog.string.htmlEscape(d.name),'"');if(d.type){c.push(' type="',goog.string.htmlEscape(d.type),'"');var e={};goog.object.extend(e,d);delete e.type;d=e}c.push(">");c=c.join("")}c=a.createElement(c);d&&(goog.isString(d)?c.className=d:goog.isArray(d)?c.className=d.join(" "):goog.dom.setProperties(c,d));2<b.length&&goog.dom.append_(a,
c,b,2);return c};goog.dom.append_=function(a,b,c,d){function e(c){c&&b.appendChild(goog.isString(c)?a.createTextNode(c):c)}for(;d<c.length;d++){var f=c[d];goog.isArrayLike(f)&&!goog.dom.isNodeLike(f)?goog.array.forEach(goog.dom.isNodeList(f)?goog.array.toArray(f):f,e):e(f)}};goog.dom.$dom=goog.dom.createDom;goog.dom.createElement=function(a){return goog.dom.createElement_(document,a)};goog.dom.createElement_=function(a,b){return a.createElement(String(b))};goog.dom.createTextNode=function(a){return document.createTextNode(String(a))};
goog.dom.createTable=function(a,b,c){return goog.dom.createTable_(document,a,b,!!c)};goog.dom.createTable_=function(a,b,c,d){for(var e=goog.dom.createElement_(a,"TABLE"),f=e.appendChild(goog.dom.createElement_(a,"TBODY")),g=0;g<b;g++){for(var h=goog.dom.createElement_(a,"TR"),k=0;k<c;k++){var l=goog.dom.createElement_(a,"TD");d&&goog.dom.setTextContent(l,goog.string.Unicode.NBSP);h.appendChild(l)}f.appendChild(h)}return e};
goog.dom.constHtmlToNode=function(a){var b=goog.array.map(arguments,goog.string.Const.unwrap),b=goog.html.uncheckedconversions.safeHtmlFromStringKnownToSatisfyTypeContract(goog.string.Const.from("Constant HTML string, that gets turned into a Node later, so it will be automatically balanced."),b.join(""));return goog.dom.safeHtmlToNode(b)};goog.dom.safeHtmlToNode=function(a){return goog.dom.safeHtmlToNode_(document,a)};
goog.dom.safeHtmlToNode_=function(a,b){var c=goog.dom.createElement_(a,"DIV");goog.dom.BrowserFeature.INNER_HTML_NEEDS_SCOPED_ELEMENT?(goog.dom.safe.setInnerHtml(c,goog.html.SafeHtml.concat(goog.html.SafeHtml.BR,b)),c.removeChild(c.firstChild)):goog.dom.safe.setInnerHtml(c,b);return goog.dom.childrenToNode_(a,c)};goog.dom.childrenToNode_=function(a,b){if(1==b.childNodes.length)return b.removeChild(b.firstChild);for(var c=a.createDocumentFragment();b.firstChild;)c.appendChild(b.firstChild);return c};
goog.dom.isCss1CompatMode=function(){return goog.dom.isCss1CompatMode_(document)};goog.dom.isCss1CompatMode_=function(a){return goog.dom.COMPAT_MODE_KNOWN_?goog.dom.ASSUME_STANDARDS_MODE:"CSS1Compat"==a.compatMode};goog.dom.canHaveChildren=function(a){if(a.nodeType!=goog.dom.NodeType.ELEMENT)return!1;switch(a.tagName){case "APPLET":case "AREA":case "BASE":case "BR":case "COL":case "COMMAND":case "EMBED":case "FRAME":case "HR":case "IMG":case "INPUT":case "IFRAME":case "ISINDEX":case "KEYGEN":case "LINK":case "NOFRAMES":case "NOSCRIPT":case "META":case "OBJECT":case "PARAM":case "SCRIPT":case "SOURCE":case "STYLE":case "TRACK":case "WBR":return!1}return!0};
goog.dom.appendChild=function(a,b){a.appendChild(b)};goog.dom.append=function(a,b){goog.dom.append_(goog.dom.getOwnerDocument(a),a,arguments,1)};goog.dom.removeChildren=function(a){for(var b;b=a.firstChild;)a.removeChild(b)};goog.dom.insertSiblingBefore=function(a,b){b.parentNode&&b.parentNode.insertBefore(a,b)};goog.dom.insertSiblingAfter=function(a,b){b.parentNode&&b.parentNode.insertBefore(a,b.nextSibling)};goog.dom.insertChildAt=function(a,b,c){a.insertBefore(b,a.childNodes[c]||null)};
goog.dom.removeNode=function(a){return a&&a.parentNode?a.parentNode.removeChild(a):null};goog.dom.replaceNode=function(a,b){var c=b.parentNode;c&&c.replaceChild(a,b)};goog.dom.flattenElement=function(a){var b,c=a.parentNode;if(c&&c.nodeType!=goog.dom.NodeType.DOCUMENT_FRAGMENT){if(a.removeNode)return a.removeNode(!1);for(;b=a.firstChild;)c.insertBefore(b,a);return goog.dom.removeNode(a)}};
goog.dom.getChildren=function(a){return goog.dom.BrowserFeature.CAN_USE_CHILDREN_ATTRIBUTE&&void 0!=a.children?a.children:goog.array.filter(a.childNodes,function(a){return a.nodeType==goog.dom.NodeType.ELEMENT})};goog.dom.getFirstElementChild=function(a){return goog.isDef(a.firstElementChild)?a.firstElementChild:goog.dom.getNextElementNode_(a.firstChild,!0)};goog.dom.getLastElementChild=function(a){return goog.isDef(a.lastElementChild)?a.lastElementChild:goog.dom.getNextElementNode_(a.lastChild,!1)};
goog.dom.getNextElementSibling=function(a){return goog.isDef(a.nextElementSibling)?a.nextElementSibling:goog.dom.getNextElementNode_(a.nextSibling,!0)};goog.dom.getPreviousElementSibling=function(a){return goog.isDef(a.previousElementSibling)?a.previousElementSibling:goog.dom.getNextElementNode_(a.previousSibling,!1)};goog.dom.getNextElementNode_=function(a,b){for(;a&&a.nodeType!=goog.dom.NodeType.ELEMENT;)a=b?a.nextSibling:a.previousSibling;return a};
goog.dom.getNextNode=function(a){if(!a)return null;if(a.firstChild)return a.firstChild;for(;a&&!a.nextSibling;)a=a.parentNode;return a?a.nextSibling:null};goog.dom.getPreviousNode=function(a){if(!a)return null;if(!a.previousSibling)return a.parentNode;for(a=a.previousSibling;a&&a.lastChild;)a=a.lastChild;return a};goog.dom.isNodeLike=function(a){return goog.isObject(a)&&0<a.nodeType};goog.dom.isElement=function(a){return goog.isObject(a)&&a.nodeType==goog.dom.NodeType.ELEMENT};
goog.dom.isWindow=function(a){return goog.isObject(a)&&a.window==a};goog.dom.getParentElement=function(a){var b;if(goog.dom.BrowserFeature.CAN_USE_PARENT_ELEMENT_PROPERTY&&!(goog.userAgent.IE&&goog.userAgent.isVersionOrHigher("9")&&!goog.userAgent.isVersionOrHigher("10")&&goog.global.SVGElement&&a instanceof goog.global.SVGElement)&&(b=a.parentElement))return b;b=a.parentNode;return goog.dom.isElement(b)?b:null};
goog.dom.contains=function(a,b){if(!a||!b)return!1;if(a.contains&&b.nodeType==goog.dom.NodeType.ELEMENT)return a==b||a.contains(b);if("undefined"!=typeof a.compareDocumentPosition)return a==b||!!(a.compareDocumentPosition(b)&16);for(;b&&a!=b;)b=b.parentNode;return b==a};
goog.dom.compareNodeOrder=function(a,b){if(a==b)return 0;if(a.compareDocumentPosition)return a.compareDocumentPosition(b)&2?1:-1;if(goog.userAgent.IE&&!goog.userAgent.isDocumentModeOrHigher(9)){if(a.nodeType==goog.dom.NodeType.DOCUMENT)return-1;if(b.nodeType==goog.dom.NodeType.DOCUMENT)return 1}if("sourceIndex"in a||a.parentNode&&"sourceIndex"in a.parentNode){var c=a.nodeType==goog.dom.NodeType.ELEMENT,d=b.nodeType==goog.dom.NodeType.ELEMENT;if(c&&d)return a.sourceIndex-b.sourceIndex;var e=a.parentNode,
f=b.parentNode;return e==f?goog.dom.compareSiblingOrder_(a,b):!c&&goog.dom.contains(e,b)?-1*goog.dom.compareParentsDescendantNodeIe_(a,b):!d&&goog.dom.contains(f,a)?goog.dom.compareParentsDescendantNodeIe_(b,a):(c?a.sourceIndex:e.sourceIndex)-(d?b.sourceIndex:f.sourceIndex)}d=goog.dom.getOwnerDocument(a);c=d.createRange();c.selectNode(a);c.collapse(!0);d=d.createRange();d.selectNode(b);d.collapse(!0);return c.compareBoundaryPoints(goog.global.Range.START_TO_END,d)};
goog.dom.compareParentsDescendantNodeIe_=function(a,b){var c=a.parentNode;if(c==b)return-1;for(var d=b;d.parentNode!=c;)d=d.parentNode;return goog.dom.compareSiblingOrder_(d,a)};goog.dom.compareSiblingOrder_=function(a,b){for(var c=b;c=c.previousSibling;)if(c==a)return-1;return 1};
goog.dom.findCommonAncestor=function(a){var b,c=arguments.length;if(!c)return null;if(1==c)return arguments[0];var d=[],e=Infinity;for(b=0;b<c;b++){for(var f=[],g=arguments[b];g;)f.unshift(g),g=g.parentNode;d.push(f);e=Math.min(e,f.length)}f=null;for(b=0;b<e;b++){for(var g=d[0][b],h=1;h<c;h++)if(g!=d[h][b])return f;f=g}return f};goog.dom.getOwnerDocument=function(a){goog.asserts.assert(a,"Node cannot be null or undefined.");return a.nodeType==goog.dom.NodeType.DOCUMENT?a:a.ownerDocument||a.document};
goog.dom.getFrameContentDocument=function(a){return a.contentDocument||a.contentWindow.document};goog.dom.getFrameContentWindow=function(a){try{return a.contentWindow||(a.contentDocument?goog.dom.getWindow(a.contentDocument):null)}catch(b){}return null};
goog.dom.setTextContent=function(a,b){goog.asserts.assert(null!=a,"goog.dom.setTextContent expects a non-null value for node");if("textContent"in a)a.textContent=b;else if(a.nodeType==goog.dom.NodeType.TEXT)a.data=b;else if(a.firstChild&&a.firstChild.nodeType==goog.dom.NodeType.TEXT){for(;a.lastChild!=a.firstChild;)a.removeChild(a.lastChild);a.firstChild.data=b}else{goog.dom.removeChildren(a);var c=goog.dom.getOwnerDocument(a);a.appendChild(c.createTextNode(String(b)))}};
goog.dom.getOuterHtml=function(a){goog.asserts.assert(null!==a,"goog.dom.getOuterHtml expects a non-null value for element");if("outerHTML"in a)return a.outerHTML;var b=goog.dom.getOwnerDocument(a),b=goog.dom.createElement_(b,"DIV");b.appendChild(a.cloneNode(!0));return b.innerHTML};goog.dom.findNode=function(a,b){var c=[];return goog.dom.findNodes_(a,b,c,!0)?c[0]:void 0};goog.dom.findNodes=function(a,b){var c=[];goog.dom.findNodes_(a,b,c,!1);return c};
goog.dom.findNodes_=function(a,b,c,d){if(null!=a)for(a=a.firstChild;a;){if(b(a)&&(c.push(a),d)||goog.dom.findNodes_(a,b,c,d))return!0;a=a.nextSibling}return!1};goog.dom.TAGS_TO_IGNORE_={SCRIPT:1,STYLE:1,HEAD:1,IFRAME:1,OBJECT:1};goog.dom.PREDEFINED_TAG_VALUES_={IMG:" ",BR:"\n"};goog.dom.isFocusableTabIndex=function(a){return goog.dom.hasSpecifiedTabIndex_(a)&&goog.dom.isTabIndexFocusable_(a)};goog.dom.setFocusableTabIndex=function(a,b){b?a.tabIndex=0:(a.tabIndex=-1,a.removeAttribute("tabIndex"))};
goog.dom.isFocusable=function(a){var b;return(b=goog.dom.nativelySupportsFocus_(a)?!a.disabled&&(!goog.dom.hasSpecifiedTabIndex_(a)||goog.dom.isTabIndexFocusable_(a)):goog.dom.isFocusableTabIndex(a))&&goog.userAgent.IE?goog.dom.hasNonZeroBoundingRect_(a):b};goog.dom.hasSpecifiedTabIndex_=function(a){return goog.userAgent.IE&&!goog.userAgent.isVersionOrHigher("9")?(a=a.getAttributeNode("tabindex"),goog.isDefAndNotNull(a)&&a.specified):a.hasAttribute("tabindex")};
goog.dom.isTabIndexFocusable_=function(a){a=a.tabIndex;return goog.isNumber(a)&&0<=a&&32768>a};goog.dom.nativelySupportsFocus_=function(a){return"A"==a.tagName||"INPUT"==a.tagName||"TEXTAREA"==a.tagName||"SELECT"==a.tagName||"BUTTON"==a.tagName};goog.dom.hasNonZeroBoundingRect_=function(a){a=!goog.isFunction(a.getBoundingClientRect)||goog.userAgent.IE&&null==a.parentElement?{height:a.offsetHeight,width:a.offsetWidth}:a.getBoundingClientRect();return goog.isDefAndNotNull(a)&&0<a.height&&0<a.width};
goog.dom.getTextContent=function(a){if(goog.dom.BrowserFeature.CAN_USE_INNER_TEXT&&null!==a&&"innerText"in a)a=goog.string.canonicalizeNewlines(a.innerText);else{var b=[];goog.dom.getTextContent_(a,b,!0);a=b.join("")}a=a.replace(/ \xAD /g," ").replace(/\xAD/g,"");a=a.replace(/\u200B/g,"");goog.dom.BrowserFeature.CAN_USE_INNER_TEXT||(a=a.replace(/ +/g," "));" "!=a&&(a=a.replace(/^\s*/,""));return a};goog.dom.getRawTextContent=function(a){var b=[];goog.dom.getTextContent_(a,b,!1);return b.join("")};
goog.dom.getTextContent_=function(a,b,c){if(!(a.nodeName in goog.dom.TAGS_TO_IGNORE_))if(a.nodeType==goog.dom.NodeType.TEXT)c?b.push(String(a.nodeValue).replace(/(\r\n|\r|\n)/g,"")):b.push(a.nodeValue);else if(a.nodeName in goog.dom.PREDEFINED_TAG_VALUES_)b.push(goog.dom.PREDEFINED_TAG_VALUES_[a.nodeName]);else for(a=a.firstChild;a;)goog.dom.getTextContent_(a,b,c),a=a.nextSibling};goog.dom.getNodeTextLength=function(a){return goog.dom.getTextContent(a).length};
goog.dom.getNodeTextOffset=function(a,b){for(var c=b||goog.dom.getOwnerDocument(a).body,d=[];a&&a!=c;){for(var e=a;e=e.previousSibling;)d.unshift(goog.dom.getTextContent(e));a=a.parentNode}return goog.string.trimLeft(d.join("")).replace(/ +/g," ").length};
goog.dom.getNodeAtOffset=function(a,b,c){a=[a];for(var d=0,e=null;0<a.length&&d<b;)if(e=a.pop(),!(e.nodeName in goog.dom.TAGS_TO_IGNORE_))if(e.nodeType==goog.dom.NodeType.TEXT)var f=e.nodeValue.replace(/(\r\n|\r|\n)/g,"").replace(/ +/g," "),d=d+f.length;else if(e.nodeName in goog.dom.PREDEFINED_TAG_VALUES_)d+=goog.dom.PREDEFINED_TAG_VALUES_[e.nodeName].length;else for(f=e.childNodes.length-1;0<=f;f--)a.push(e.childNodes[f]);goog.isObject(c)&&(c.remainder=e?e.nodeValue.length+b-d-1:0,c.node=e);return e};
goog.dom.isNodeList=function(a){if(a&&"number"==typeof a.length){if(goog.isObject(a))return"function"==typeof a.item||"string"==typeof a.item;if(goog.isFunction(a))return"function"==typeof a.item}return!1};goog.dom.getAncestorByTagNameAndClass=function(a,b,c,d){if(!b&&!c)return null;var e=b?String(b).toUpperCase():null;return goog.dom.getAncestor(a,function(a){return(!e||a.nodeName==e)&&(!c||goog.isString(a.className)&&goog.array.contains(a.className.split(/\s+/),c))},!0,d)};
goog.dom.getAncestorByClass=function(a,b,c){return goog.dom.getAncestorByTagNameAndClass(a,null,b,c)};goog.dom.getAncestor=function(a,b,c,d){a&&!c&&(a=a.parentNode);for(c=0;a&&(null==d||c<=d);){goog.asserts.assert("parentNode"!=a.name);if(b(a))return a;a=a.parentNode;c++}return null};goog.dom.getActiveElement=function(a){try{return a&&a.activeElement}catch(b){}return null};
goog.dom.getPixelRatio=function(){var a=goog.dom.getWindow();return goog.isDef(a.devicePixelRatio)?a.devicePixelRatio:a.matchMedia?goog.dom.matchesPixelRatio_(3)||goog.dom.matchesPixelRatio_(2)||goog.dom.matchesPixelRatio_(1.5)||goog.dom.matchesPixelRatio_(1)||.75:1};goog.dom.matchesPixelRatio_=function(a){return goog.dom.getWindow().matchMedia("(min-resolution: "+a+"dppx),(min--moz-device-pixel-ratio: "+a+"),(min-resolution: "+96*a+"dpi)").matches?a:0};goog.dom.getCanvasContext2D=function(a){return a.getContext("2d")};
goog.dom.DomHelper=function(a){this.document_=a||goog.global.document||document};goog.dom.DomHelper.prototype.getDomHelper=goog.dom.getDomHelper;goog.dom.DomHelper.prototype.setDocument=function(a){this.document_=a};goog.dom.DomHelper.prototype.getDocument=function(){return this.document_};goog.dom.DomHelper.prototype.getElement=function(a){return goog.dom.getElementHelper_(this.document_,a)};
goog.dom.DomHelper.prototype.getRequiredElement=function(a){return goog.dom.getRequiredElementHelper_(this.document_,a)};goog.dom.DomHelper.prototype.$=goog.dom.DomHelper.prototype.getElement;goog.dom.DomHelper.prototype.getElementsByTagName=function(a,b){return(b||this.document_).getElementsByTagName(String(a))};goog.dom.DomHelper.prototype.getElementsByTagNameAndClass=function(a,b,c){return goog.dom.getElementsByTagNameAndClass_(this.document_,a,b,c)};
goog.dom.DomHelper.prototype.getElementsByClass=function(a,b){return goog.dom.getElementsByClass(a,b||this.document_)};goog.dom.DomHelper.prototype.getElementByClass=function(a,b){return goog.dom.getElementByClass(a,b||this.document_)};goog.dom.DomHelper.prototype.getRequiredElementByClass=function(a,b){return goog.dom.getRequiredElementByClass(a,b||this.document_)};goog.dom.DomHelper.prototype.$$=goog.dom.DomHelper.prototype.getElementsByTagNameAndClass;
goog.dom.DomHelper.prototype.setProperties=goog.dom.setProperties;goog.dom.DomHelper.prototype.getViewportSize=function(a){return goog.dom.getViewportSize(a||this.getWindow())};goog.dom.DomHelper.prototype.getDocumentHeight=function(){return goog.dom.getDocumentHeight_(this.getWindow())};goog.dom.DomHelper.prototype.createDom=function(a,b,c){return goog.dom.createDom_(this.document_,arguments)};goog.dom.DomHelper.prototype.$dom=goog.dom.DomHelper.prototype.createDom;
goog.dom.DomHelper.prototype.createElement=function(a){return goog.dom.createElement_(this.document_,a)};goog.dom.DomHelper.prototype.createTextNode=function(a){return this.document_.createTextNode(String(a))};goog.dom.DomHelper.prototype.createTable=function(a,b,c){return goog.dom.createTable_(this.document_,a,b,!!c)};goog.dom.DomHelper.prototype.safeHtmlToNode=function(a){return goog.dom.safeHtmlToNode_(this.document_,a)};goog.dom.DomHelper.prototype.isCss1CompatMode=function(){return goog.dom.isCss1CompatMode_(this.document_)};
goog.dom.DomHelper.prototype.getWindow=function(){return goog.dom.getWindow_(this.document_)};goog.dom.DomHelper.prototype.getDocumentScrollElement=function(){return goog.dom.getDocumentScrollElement_(this.document_)};goog.dom.DomHelper.prototype.getDocumentScroll=function(){return goog.dom.getDocumentScroll_(this.document_)};goog.dom.DomHelper.prototype.getActiveElement=function(a){return goog.dom.getActiveElement(a||this.document_)};goog.dom.DomHelper.prototype.appendChild=goog.dom.appendChild;
goog.dom.DomHelper.prototype.append=goog.dom.append;goog.dom.DomHelper.prototype.canHaveChildren=goog.dom.canHaveChildren;goog.dom.DomHelper.prototype.removeChildren=goog.dom.removeChildren;goog.dom.DomHelper.prototype.insertSiblingBefore=goog.dom.insertSiblingBefore;goog.dom.DomHelper.prototype.insertSiblingAfter=goog.dom.insertSiblingAfter;goog.dom.DomHelper.prototype.insertChildAt=goog.dom.insertChildAt;goog.dom.DomHelper.prototype.removeNode=goog.dom.removeNode;
goog.dom.DomHelper.prototype.replaceNode=goog.dom.replaceNode;goog.dom.DomHelper.prototype.flattenElement=goog.dom.flattenElement;goog.dom.DomHelper.prototype.getChildren=goog.dom.getChildren;goog.dom.DomHelper.prototype.getFirstElementChild=goog.dom.getFirstElementChild;goog.dom.DomHelper.prototype.getLastElementChild=goog.dom.getLastElementChild;goog.dom.DomHelper.prototype.getNextElementSibling=goog.dom.getNextElementSibling;goog.dom.DomHelper.prototype.getPreviousElementSibling=goog.dom.getPreviousElementSibling;
goog.dom.DomHelper.prototype.getNextNode=goog.dom.getNextNode;goog.dom.DomHelper.prototype.getPreviousNode=goog.dom.getPreviousNode;goog.dom.DomHelper.prototype.isNodeLike=goog.dom.isNodeLike;goog.dom.DomHelper.prototype.isElement=goog.dom.isElement;goog.dom.DomHelper.prototype.isWindow=goog.dom.isWindow;goog.dom.DomHelper.prototype.getParentElement=goog.dom.getParentElement;goog.dom.DomHelper.prototype.contains=goog.dom.contains;goog.dom.DomHelper.prototype.compareNodeOrder=goog.dom.compareNodeOrder;
goog.dom.DomHelper.prototype.findCommonAncestor=goog.dom.findCommonAncestor;goog.dom.DomHelper.prototype.getOwnerDocument=goog.dom.getOwnerDocument;goog.dom.DomHelper.prototype.getFrameContentDocument=goog.dom.getFrameContentDocument;goog.dom.DomHelper.prototype.getFrameContentWindow=goog.dom.getFrameContentWindow;goog.dom.DomHelper.prototype.setTextContent=goog.dom.setTextContent;goog.dom.DomHelper.prototype.getOuterHtml=goog.dom.getOuterHtml;goog.dom.DomHelper.prototype.findNode=goog.dom.findNode;
goog.dom.DomHelper.prototype.findNodes=goog.dom.findNodes;goog.dom.DomHelper.prototype.isFocusableTabIndex=goog.dom.isFocusableTabIndex;goog.dom.DomHelper.prototype.setFocusableTabIndex=goog.dom.setFocusableTabIndex;goog.dom.DomHelper.prototype.isFocusable=goog.dom.isFocusable;goog.dom.DomHelper.prototype.getTextContent=goog.dom.getTextContent;goog.dom.DomHelper.prototype.getNodeTextLength=goog.dom.getNodeTextLength;goog.dom.DomHelper.prototype.getNodeTextOffset=goog.dom.getNodeTextOffset;
goog.dom.DomHelper.prototype.getNodeAtOffset=goog.dom.getNodeAtOffset;goog.dom.DomHelper.prototype.isNodeList=goog.dom.isNodeList;goog.dom.DomHelper.prototype.getAncestorByTagNameAndClass=goog.dom.getAncestorByTagNameAndClass;goog.dom.DomHelper.prototype.getAncestorByClass=goog.dom.getAncestorByClass;goog.dom.DomHelper.prototype.getAncestor=goog.dom.getAncestor;goog.dom.DomHelper.prototype.getCanvasContext2D=goog.dom.getCanvasContext2D;goog.events.KeyCodes={WIN_KEY_FF_LINUX:0,MAC_ENTER:3,BACKSPACE:8,TAB:9,NUM_CENTER:12,ENTER:13,SHIFT:16,CTRL:17,ALT:18,PAUSE:19,CAPS_LOCK:20,ESC:27,SPACE:32,PAGE_UP:33,PAGE_DOWN:34,END:35,HOME:36,LEFT:37,UP:38,RIGHT:39,DOWN:40,PLUS_SIGN:43,PRINT_SCREEN:44,INSERT:45,DELETE:46,ZERO:48,ONE:49,TWO:50,THREE:51,FOUR:52,FIVE:53,SIX:54,SEVEN:55,EIGHT:56,NINE:57,FF_SEMICOLON:59,FF_EQUALS:61,FF_DASH:173,QUESTION_MARK:63,AT_SIGN:64,A:65,B:66,C:67,D:68,E:69,F:70,G:71,H:72,I:73,J:74,K:75,L:76,M:77,N:78,O:79,P:80,
Q:81,R:82,S:83,T:84,U:85,V:86,W:87,X:88,Y:89,Z:90,META:91,WIN_KEY_RIGHT:92,CONTEXT_MENU:93,NUM_ZERO:96,NUM_ONE:97,NUM_TWO:98,NUM_THREE:99,NUM_FOUR:100,NUM_FIVE:101,NUM_SIX:102,NUM_SEVEN:103,NUM_EIGHT:104,NUM_NINE:105,NUM_MULTIPLY:106,NUM_PLUS:107,NUM_MINUS:109,NUM_PERIOD:110,NUM_DIVISION:111,F1:112,F2:113,F3:114,F4:115,F5:116,F6:117,F7:118,F8:119,F9:120,F10:121,F11:122,F12:123,NUMLOCK:144,SCROLL_LOCK:145,FIRST_MEDIA_KEY:166,LAST_MEDIA_KEY:183,SEMICOLON:186,DASH:189,EQUALS:187,COMMA:188,PERIOD:190,
SLASH:191,APOSTROPHE:192,TILDE:192,SINGLE_QUOTE:222,OPEN_SQUARE_BRACKET:219,BACKSLASH:220,CLOSE_SQUARE_BRACKET:221,WIN_KEY:224,MAC_FF_META:224,MAC_WK_CMD_LEFT:91,MAC_WK_CMD_RIGHT:93,WIN_IME:229,VK_NONAME:252,PHANTOM:255};
goog.events.KeyCodes.isTextModifyingKeyEvent=function(a){if(a.altKey&&!a.ctrlKey||a.metaKey||a.keyCode>=goog.events.KeyCodes.F1&&a.keyCode<=goog.events.KeyCodes.F12)return!1;switch(a.keyCode){case goog.events.KeyCodes.ALT:case goog.events.KeyCodes.CAPS_LOCK:case goog.events.KeyCodes.CONTEXT_MENU:case goog.events.KeyCodes.CTRL:case goog.events.KeyCodes.DOWN:case goog.events.KeyCodes.END:case goog.events.KeyCodes.ESC:case goog.events.KeyCodes.HOME:case goog.events.KeyCodes.INSERT:case goog.events.KeyCodes.LEFT:case goog.events.KeyCodes.MAC_FF_META:case goog.events.KeyCodes.META:case goog.events.KeyCodes.NUMLOCK:case goog.events.KeyCodes.NUM_CENTER:case goog.events.KeyCodes.PAGE_DOWN:case goog.events.KeyCodes.PAGE_UP:case goog.events.KeyCodes.PAUSE:case goog.events.KeyCodes.PHANTOM:case goog.events.KeyCodes.PRINT_SCREEN:case goog.events.KeyCodes.RIGHT:case goog.events.KeyCodes.SCROLL_LOCK:case goog.events.KeyCodes.SHIFT:case goog.events.KeyCodes.UP:case goog.events.KeyCodes.VK_NONAME:case goog.events.KeyCodes.WIN_KEY:case goog.events.KeyCodes.WIN_KEY_RIGHT:return!1;case goog.events.KeyCodes.WIN_KEY_FF_LINUX:return!goog.userAgent.GECKO;
default:return a.keyCode<goog.events.KeyCodes.FIRST_MEDIA_KEY||a.keyCode>goog.events.KeyCodes.LAST_MEDIA_KEY}};
goog.events.KeyCodes.firesKeyPressEvent=function(a,b,c,d,e,f){if(!(goog.userAgent.IE||goog.userAgent.EDGE||goog.userAgent.WEBKIT&&goog.userAgent.isVersionOrHigher("525")))return!0;if(goog.userAgent.MAC&&e)return goog.events.KeyCodes.isCharacterKey(a);if(e&&!d)return!1;goog.isNumber(b)&&(b=goog.events.KeyCodes.normalizeKeyCode(b));e=b==goog.events.KeyCodes.CTRL||b==goog.events.KeyCodes.ALT||goog.userAgent.MAC&&b==goog.events.KeyCodes.META;f=b==goog.events.KeyCodes.SHIFT&&(d||f);if((!c||goog.userAgent.MAC)&&
e||goog.userAgent.MAC&&f)return!1;if((goog.userAgent.WEBKIT||goog.userAgent.EDGE)&&d&&c)switch(a){case goog.events.KeyCodes.BACKSLASH:case goog.events.KeyCodes.OPEN_SQUARE_BRACKET:case goog.events.KeyCodes.CLOSE_SQUARE_BRACKET:case goog.events.KeyCodes.TILDE:case goog.events.KeyCodes.SEMICOLON:case goog.events.KeyCodes.DASH:case goog.events.KeyCodes.EQUALS:case goog.events.KeyCodes.COMMA:case goog.events.KeyCodes.PERIOD:case goog.events.KeyCodes.SLASH:case goog.events.KeyCodes.APOSTROPHE:case goog.events.KeyCodes.SINGLE_QUOTE:return!1}if(goog.userAgent.IE&&
d&&b==a)return!1;switch(a){case goog.events.KeyCodes.ENTER:return!0;case goog.events.KeyCodes.ESC:return!(goog.userAgent.WEBKIT||goog.userAgent.EDGE)}return goog.events.KeyCodes.isCharacterKey(a)};
goog.events.KeyCodes.isCharacterKey=function(a){if(a>=goog.events.KeyCodes.ZERO&&a<=goog.events.KeyCodes.NINE||a>=goog.events.KeyCodes.NUM_ZERO&&a<=goog.events.KeyCodes.NUM_MULTIPLY||a>=goog.events.KeyCodes.A&&a<=goog.events.KeyCodes.Z||(goog.userAgent.WEBKIT||goog.userAgent.EDGE)&&0==a)return!0;switch(a){case goog.events.KeyCodes.SPACE:case goog.events.KeyCodes.PLUS_SIGN:case goog.events.KeyCodes.QUESTION_MARK:case goog.events.KeyCodes.AT_SIGN:case goog.events.KeyCodes.NUM_PLUS:case goog.events.KeyCodes.NUM_MINUS:case goog.events.KeyCodes.NUM_PERIOD:case goog.events.KeyCodes.NUM_DIVISION:case goog.events.KeyCodes.SEMICOLON:case goog.events.KeyCodes.FF_SEMICOLON:case goog.events.KeyCodes.DASH:case goog.events.KeyCodes.EQUALS:case goog.events.KeyCodes.FF_EQUALS:case goog.events.KeyCodes.COMMA:case goog.events.KeyCodes.PERIOD:case goog.events.KeyCodes.SLASH:case goog.events.KeyCodes.APOSTROPHE:case goog.events.KeyCodes.SINGLE_QUOTE:case goog.events.KeyCodes.OPEN_SQUARE_BRACKET:case goog.events.KeyCodes.BACKSLASH:case goog.events.KeyCodes.CLOSE_SQUARE_BRACKET:return!0;default:return!1}};
goog.events.KeyCodes.normalizeKeyCode=function(a){return goog.userAgent.GECKO?goog.events.KeyCodes.normalizeGeckoKeyCode(a):goog.userAgent.MAC&&goog.userAgent.WEBKIT?goog.events.KeyCodes.normalizeMacWebKitKeyCode(a):a};
goog.events.KeyCodes.normalizeGeckoKeyCode=function(a){switch(a){case goog.events.KeyCodes.FF_EQUALS:return goog.events.KeyCodes.EQUALS;case goog.events.KeyCodes.FF_SEMICOLON:return goog.events.KeyCodes.SEMICOLON;case goog.events.KeyCodes.FF_DASH:return goog.events.KeyCodes.DASH;case goog.events.KeyCodes.MAC_FF_META:return goog.events.KeyCodes.META;case goog.events.KeyCodes.WIN_KEY_FF_LINUX:return goog.events.KeyCodes.WIN_KEY;default:return a}};
goog.events.KeyCodes.normalizeMacWebKitKeyCode=function(a){switch(a){case goog.events.KeyCodes.MAC_WK_CMD_RIGHT:return goog.events.KeyCodes.META;default:return a}};goog.dom.vendor={};goog.dom.vendor.getVendorJsPrefix=function(){return goog.userAgent.WEBKIT?"Webkit":goog.userAgent.GECKO?"Moz":goog.userAgent.IE?"ms":goog.userAgent.OPERA?"O":null};goog.dom.vendor.getVendorPrefix=function(){return goog.userAgent.WEBKIT?"-webkit":goog.userAgent.GECKO?"-moz":goog.userAgent.IE?"-ms":goog.userAgent.OPERA?"-o":null};
goog.dom.vendor.getPrefixedPropertyName=function(a,b){if(b&&a in b)return a;var c=goog.dom.vendor.getVendorJsPrefix();return c?(c=c.toLowerCase(),c+=goog.string.toTitleCase(a),!goog.isDef(b)||c in b?c:null):null};goog.dom.vendor.getPrefixedEventType=function(a){return((goog.dom.vendor.getVendorJsPrefix()||"")+a).toLowerCase()};goog.html.legacyconversions={};goog.html.legacyconversions.safeHtmlFromString=function(a){goog.html.legacyconversions.reportCallback_();return goog.html.SafeHtml.createSafeHtmlSecurityPrivateDoNotAccessOrElse(a,null)};goog.html.legacyconversions.safeStyleFromString=function(a){goog.html.legacyconversions.reportCallback_();return goog.html.SafeStyle.createSafeStyleSecurityPrivateDoNotAccessOrElse(a)};
goog.html.legacyconversions.safeStyleSheetFromString=function(a){goog.html.legacyconversions.reportCallback_();return goog.html.SafeStyleSheet.createSafeStyleSheetSecurityPrivateDoNotAccessOrElse(a)};goog.html.legacyconversions.safeUrlFromString=function(a){goog.html.legacyconversions.reportCallback_();return goog.html.SafeUrl.createSafeUrlSecurityPrivateDoNotAccessOrElse(a)};goog.html.legacyconversions.trustedResourceUrlFromString=function(a){goog.html.legacyconversions.reportCallback_();return goog.html.TrustedResourceUrl.createTrustedResourceUrlSecurityPrivateDoNotAccessOrElse(a)};
goog.html.legacyconversions.reportCallback_=goog.nullFunction;goog.html.legacyconversions.setReportCallback=function(a){goog.html.legacyconversions.reportCallback_=a};goog.math.Box=function(a,b,c,d){this.top=a;this.right=b;this.bottom=c;this.left=d};goog.math.Box.boundingBox=function(a){for(var b=new goog.math.Box(arguments[0].y,arguments[0].x,arguments[0].y,arguments[0].x),c=1;c<arguments.length;c++)b.expandToIncludeCoordinate(arguments[c]);return b};goog.math.Box.prototype.getWidth=function(){return this.right-this.left};goog.math.Box.prototype.getHeight=function(){return this.bottom-this.top};
goog.math.Box.prototype.clone=function(){return new goog.math.Box(this.top,this.right,this.bottom,this.left)};goog.DEBUG&&(goog.math.Box.prototype.toString=function(){return"("+this.top+"t, "+this.right+"r, "+this.bottom+"b, "+this.left+"l)"});goog.math.Box.prototype.contains=function(a){return goog.math.Box.contains(this,a)};
goog.math.Box.prototype.expand=function(a,b,c,d){goog.isObject(a)?(this.top-=a.top,this.right+=a.right,this.bottom+=a.bottom,this.left-=a.left):(this.top-=a,this.right+=Number(b),this.bottom+=Number(c),this.left-=Number(d));return this};goog.math.Box.prototype.expandToInclude=function(a){this.left=Math.min(this.left,a.left);this.top=Math.min(this.top,a.top);this.right=Math.max(this.right,a.right);this.bottom=Math.max(this.bottom,a.bottom)};
goog.math.Box.prototype.expandToIncludeCoordinate=function(a){this.top=Math.min(this.top,a.y);this.right=Math.max(this.right,a.x);this.bottom=Math.max(this.bottom,a.y);this.left=Math.min(this.left,a.x)};goog.math.Box.equals=function(a,b){return a==b?!0:a&&b?a.top==b.top&&a.right==b.right&&a.bottom==b.bottom&&a.left==b.left:!1};
goog.math.Box.contains=function(a,b){return a&&b?b instanceof goog.math.Box?b.left>=a.left&&b.right<=a.right&&b.top>=a.top&&b.bottom<=a.bottom:b.x>=a.left&&b.x<=a.right&&b.y>=a.top&&b.y<=a.bottom:!1};goog.math.Box.relativePositionX=function(a,b){return b.x<a.left?b.x-a.left:b.x>a.right?b.x-a.right:0};goog.math.Box.relativePositionY=function(a,b){return b.y<a.top?b.y-a.top:b.y>a.bottom?b.y-a.bottom:0};
goog.math.Box.distance=function(a,b){var c=goog.math.Box.relativePositionX(a,b),d=goog.math.Box.relativePositionY(a,b);return Math.sqrt(c*c+d*d)};goog.math.Box.intersects=function(a,b){return a.left<=b.right&&b.left<=a.right&&a.top<=b.bottom&&b.top<=a.bottom};goog.math.Box.intersectsWithPadding=function(a,b,c){return a.left<=b.right+c&&b.left<=a.right+c&&a.top<=b.bottom+c&&b.top<=a.bottom+c};
goog.math.Box.prototype.ceil=function(){this.top=Math.ceil(this.top);this.right=Math.ceil(this.right);this.bottom=Math.ceil(this.bottom);this.left=Math.ceil(this.left);return this};goog.math.Box.prototype.floor=function(){this.top=Math.floor(this.top);this.right=Math.floor(this.right);this.bottom=Math.floor(this.bottom);this.left=Math.floor(this.left);return this};
goog.math.Box.prototype.round=function(){this.top=Math.round(this.top);this.right=Math.round(this.right);this.bottom=Math.round(this.bottom);this.left=Math.round(this.left);return this};goog.math.Box.prototype.translate=function(a,b){a instanceof goog.math.Coordinate?(this.left+=a.x,this.right+=a.x,this.top+=a.y,this.bottom+=a.y):(goog.asserts.assertNumber(a),this.left+=a,this.right+=a,goog.isNumber(b)&&(this.top+=b,this.bottom+=b));return this};
goog.math.Box.prototype.scale=function(a,b){var c=goog.isNumber(b)?b:a;this.left*=a;this.right*=a;this.top*=c;this.bottom*=c;return this};goog.math.IRect=function(){};goog.math.Rect=function(a,b,c,d){this.left=a;this.top=b;this.width=c;this.height=d};goog.math.Rect.prototype.clone=function(){return new goog.math.Rect(this.left,this.top,this.width,this.height)};goog.math.Rect.prototype.toBox=function(){return new goog.math.Box(this.top,this.left+this.width,this.top+this.height,this.left)};goog.math.Rect.createFromPositionAndSize=function(a,b){return new goog.math.Rect(a.x,a.y,b.width,b.height)};
goog.math.Rect.createFromBox=function(a){return new goog.math.Rect(a.left,a.top,a.right-a.left,a.bottom-a.top)};goog.DEBUG&&(goog.math.Rect.prototype.toString=function(){return"("+this.left+", "+this.top+" - "+this.width+"w x "+this.height+"h)"});goog.math.Rect.equals=function(a,b){return a==b?!0:a&&b?a.left==b.left&&a.width==b.width&&a.top==b.top&&a.height==b.height:!1};
goog.math.Rect.prototype.intersection=function(a){var b=Math.max(this.left,a.left),c=Math.min(this.left+this.width,a.left+a.width);if(b<=c){var d=Math.max(this.top,a.top);a=Math.min(this.top+this.height,a.top+a.height);if(d<=a)return this.left=b,this.top=d,this.width=c-b,this.height=a-d,!0}return!1};
goog.math.Rect.intersection=function(a,b){var c=Math.max(a.left,b.left),d=Math.min(a.left+a.width,b.left+b.width);if(c<=d){var e=Math.max(a.top,b.top),f=Math.min(a.top+a.height,b.top+b.height);if(e<=f)return new goog.math.Rect(c,e,d-c,f-e)}return null};goog.math.Rect.intersects=function(a,b){return a.left<=b.left+b.width&&b.left<=a.left+a.width&&a.top<=b.top+b.height&&b.top<=a.top+a.height};goog.math.Rect.prototype.intersects=function(a){return goog.math.Rect.intersects(this,a)};
goog.math.Rect.difference=function(a,b){var c=goog.math.Rect.intersection(a,b);if(!c||!c.height||!c.width)return[a.clone()];var c=[],d=a.top,e=a.height,f=a.left+a.width,g=a.top+a.height,h=b.left+b.width,k=b.top+b.height;b.top>a.top&&(c.push(new goog.math.Rect(a.left,a.top,a.width,b.top-a.top)),d=b.top,e-=b.top-a.top);k<g&&(c.push(new goog.math.Rect(a.left,k,a.width,g-k)),e=k-d);b.left>a.left&&c.push(new goog.math.Rect(a.left,d,b.left-a.left,e));h<f&&c.push(new goog.math.Rect(h,d,f-h,e));return c};
goog.math.Rect.prototype.difference=function(a){return goog.math.Rect.difference(this,a)};goog.math.Rect.prototype.boundingRect=function(a){var b=Math.max(this.left+this.width,a.left+a.width),c=Math.max(this.top+this.height,a.top+a.height);this.left=Math.min(this.left,a.left);this.top=Math.min(this.top,a.top);this.width=b-this.left;this.height=c-this.top};goog.math.Rect.boundingRect=function(a,b){if(!a||!b)return null;var c=new goog.math.Rect(a.left,a.top,a.width,a.height);c.boundingRect(b);return c};
goog.math.Rect.prototype.contains=function(a){return a instanceof goog.math.Coordinate?a.x>=this.left&&a.x<=this.left+this.width&&a.y>=this.top&&a.y<=this.top+this.height:this.left<=a.left&&this.left+this.width>=a.left+a.width&&this.top<=a.top&&this.top+this.height>=a.top+a.height};goog.math.Rect.prototype.squaredDistance=function(a){var b=a.x<this.left?this.left-a.x:Math.max(a.x-(this.left+this.width),0);a=a.y<this.top?this.top-a.y:Math.max(a.y-(this.top+this.height),0);return b*b+a*a};
goog.math.Rect.prototype.distance=function(a){return Math.sqrt(this.squaredDistance(a))};goog.math.Rect.prototype.getSize=function(){return new goog.math.Size(this.width,this.height)};goog.math.Rect.prototype.getTopLeft=function(){return new goog.math.Coordinate(this.left,this.top)};goog.math.Rect.prototype.getCenter=function(){return new goog.math.Coordinate(this.left+this.width/2,this.top+this.height/2)};
goog.math.Rect.prototype.getBottomRight=function(){return new goog.math.Coordinate(this.left+this.width,this.top+this.height)};goog.math.Rect.prototype.ceil=function(){this.left=Math.ceil(this.left);this.top=Math.ceil(this.top);this.width=Math.ceil(this.width);this.height=Math.ceil(this.height);return this};goog.math.Rect.prototype.floor=function(){this.left=Math.floor(this.left);this.top=Math.floor(this.top);this.width=Math.floor(this.width);this.height=Math.floor(this.height);return this};
goog.math.Rect.prototype.round=function(){this.left=Math.round(this.left);this.top=Math.round(this.top);this.width=Math.round(this.width);this.height=Math.round(this.height);return this};goog.math.Rect.prototype.translate=function(a,b){a instanceof goog.math.Coordinate?(this.left+=a.x,this.top+=a.y):(this.left+=goog.asserts.assertNumber(a),goog.isNumber(b)&&(this.top+=b));return this};
goog.math.Rect.prototype.scale=function(a,b){var c=goog.isNumber(b)?b:a;this.left*=a;this.width*=a;this.top*=c;this.height*=c;return this};goog.style={};goog.style.setStyle=function(a,b,c){if(goog.isString(b))goog.style.setStyle_(a,c,b);else for(var d in b)goog.style.setStyle_(a,b[d],d)};goog.style.setStyle_=function(a,b,c){(c=goog.style.getVendorJsStyleName_(a,c))&&(a.style[c]=b)};goog.style.styleNameCache_={};
goog.style.getVendorJsStyleName_=function(a,b){var c=goog.style.styleNameCache_[b];if(!c){var d=goog.string.toCamelCase(b),c=d;void 0===a.style[d]&&(d=goog.dom.vendor.getVendorJsPrefix()+goog.string.toTitleCase(d),void 0!==a.style[d]&&(c=d));goog.style.styleNameCache_[b]=c}return c};
goog.style.getVendorStyleName_=function(a,b){var c=goog.string.toCamelCase(b);return void 0===a.style[c]&&(c=goog.dom.vendor.getVendorJsPrefix()+goog.string.toTitleCase(c),void 0!==a.style[c])?goog.dom.vendor.getVendorPrefix()+"-"+b:b};goog.style.getStyle=function(a,b){var c=a.style[goog.string.toCamelCase(b)];return"undefined"!==typeof c?c:a.style[goog.style.getVendorJsStyleName_(a,b)]||""};
goog.style.getComputedStyle=function(a,b){var c=goog.dom.getOwnerDocument(a);return c.defaultView&&c.defaultView.getComputedStyle&&(c=c.defaultView.getComputedStyle(a,null))?c[b]||c.getPropertyValue(b)||"":""};goog.style.getCascadedStyle=function(a,b){return a.currentStyle?a.currentStyle[b]:null};goog.style.getStyle_=function(a,b){return goog.style.getComputedStyle(a,b)||goog.style.getCascadedStyle(a,b)||a.style&&a.style[b]};
goog.style.getComputedBoxSizing=function(a){return goog.style.getStyle_(a,"boxSizing")||goog.style.getStyle_(a,"MozBoxSizing")||goog.style.getStyle_(a,"WebkitBoxSizing")||null};goog.style.getComputedPosition=function(a){return goog.style.getStyle_(a,"position")};goog.style.getBackgroundColor=function(a){return goog.style.getStyle_(a,"backgroundColor")};goog.style.getComputedOverflowX=function(a){return goog.style.getStyle_(a,"overflowX")};
goog.style.getComputedOverflowY=function(a){return goog.style.getStyle_(a,"overflowY")};goog.style.getComputedZIndex=function(a){return goog.style.getStyle_(a,"zIndex")};goog.style.getComputedTextAlign=function(a){return goog.style.getStyle_(a,"textAlign")};goog.style.getComputedCursor=function(a){return goog.style.getStyle_(a,"cursor")};goog.style.getComputedTransform=function(a){var b=goog.style.getVendorStyleName_(a,"transform");return goog.style.getStyle_(a,b)||goog.style.getStyle_(a,"transform")};
goog.style.setPosition=function(a,b,c){var d;b instanceof goog.math.Coordinate?(d=b.x,b=b.y):(d=b,b=c);a.style.left=goog.style.getPixelStyleValue_(d,!1);a.style.top=goog.style.getPixelStyleValue_(b,!1)};goog.style.getPosition=function(a){return new goog.math.Coordinate(a.offsetLeft,a.offsetTop)};
goog.style.getClientViewportElement=function(a){a=a?goog.dom.getOwnerDocument(a):goog.dom.getDocument();return!goog.userAgent.IE||goog.userAgent.isDocumentModeOrHigher(9)||goog.dom.getDomHelper(a).isCss1CompatMode()?a.documentElement:a.body};goog.style.getViewportPageOffset=function(a){var b=a.body;a=a.documentElement;return new goog.math.Coordinate(b.scrollLeft||a.scrollLeft,b.scrollTop||a.scrollTop)};
goog.style.getBoundingClientRect_=function(a){var b;try{b=a.getBoundingClientRect()}catch(c){return{left:0,top:0,right:0,bottom:0}}goog.userAgent.IE&&a.ownerDocument.body&&(a=a.ownerDocument,b.left-=a.documentElement.clientLeft+a.body.clientLeft,b.top-=a.documentElement.clientTop+a.body.clientTop);return b};
goog.style.getOffsetParent=function(a){if(goog.userAgent.IE&&!goog.userAgent.isDocumentModeOrHigher(8))return goog.asserts.assert(a&&"offsetParent"in a),a.offsetParent;var b=goog.dom.getOwnerDocument(a),c=goog.style.getStyle_(a,"position"),d="fixed"==c||"absolute"==c;for(a=a.parentNode;a&&a!=b;a=a.parentNode)if(a.nodeType==goog.dom.NodeType.DOCUMENT_FRAGMENT&&a.host&&(a=a.host),c=goog.style.getStyle_(a,"position"),d=d&&"static"==c&&a!=b.documentElement&&a!=b.body,!d&&(a.scrollWidth>a.clientWidth||
a.scrollHeight>a.clientHeight||"fixed"==c||"absolute"==c||"relative"==c))return a;return null};
goog.style.getVisibleRectForElement=function(a){for(var b=new goog.math.Box(0,Infinity,Infinity,0),c=goog.dom.getDomHelper(a),d=c.getDocument().body,e=c.getDocument().documentElement,f=c.getDocumentScrollElement();a=goog.style.getOffsetParent(a);)if(!(goog.userAgent.IE&&0==a.clientWidth||goog.userAgent.WEBKIT&&0==a.clientHeight&&a==d)&&a!=d&&a!=e&&"visible"!=goog.style.getStyle_(a,"overflow")){var g=goog.style.getPageOffset(a),h=goog.style.getClientLeftTop(a);g.x+=h.x;g.y+=h.y;b.top=Math.max(b.top,
g.y);b.right=Math.min(b.right,g.x+a.clientWidth);b.bottom=Math.min(b.bottom,g.y+a.clientHeight);b.left=Math.max(b.left,g.x)}d=f.scrollLeft;f=f.scrollTop;b.left=Math.max(b.left,d);b.top=Math.max(b.top,f);c=c.getViewportSize();b.right=Math.min(b.right,d+c.width);b.bottom=Math.min(b.bottom,f+c.height);return 0<=b.top&&0<=b.left&&b.bottom>b.top&&b.right>b.left?b:null};
goog.style.getContainerOffsetToScrollInto=function(a,b,c){var d=b||goog.dom.getDocumentScrollElement(),e=goog.style.getPageOffset(a),f=goog.style.getPageOffset(d),g=goog.style.getBorderBox(d);d==goog.dom.getDocumentScrollElement()?(b=e.x-d.scrollLeft,e=e.y-d.scrollTop,goog.userAgent.IE&&!goog.userAgent.isDocumentModeOrHigher(10)&&(b+=g.left,e+=g.top)):(b=e.x-f.x-g.left,e=e.y-f.y-g.top);g=goog.style.getSizeWithDisplay_(a);a=d.clientWidth-g.width;g=d.clientHeight-g.height;f=d.scrollLeft;d=d.scrollTop;
c?(f+=b-a/2,d+=e-g/2):(f+=Math.min(b,Math.max(b-a,0)),d+=Math.min(e,Math.max(e-g,0)));return new goog.math.Coordinate(f,d)};goog.style.scrollIntoContainerView=function(a,b,c){b=b||goog.dom.getDocumentScrollElement();a=goog.style.getContainerOffsetToScrollInto(a,b,c);b.scrollLeft=a.x;b.scrollTop=a.y};goog.style.getClientLeftTop=function(a){return new goog.math.Coordinate(a.clientLeft,a.clientTop)};
goog.style.getPageOffset=function(a){var b=goog.dom.getOwnerDocument(a);goog.asserts.assertObject(a,"Parameter is required");var c=new goog.math.Coordinate(0,0),d=goog.style.getClientViewportElement(b);if(a==d)return c;a=goog.style.getBoundingClientRect_(a);b=goog.dom.getDomHelper(b).getDocumentScroll();c.x=a.left+b.x;c.y=a.top+b.y;return c};goog.style.getPageOffsetLeft=function(a){return goog.style.getPageOffset(a).x};goog.style.getPageOffsetTop=function(a){return goog.style.getPageOffset(a).y};
goog.style.getFramedPageOffset=function(a,b){var c=new goog.math.Coordinate(0,0),d=goog.dom.getWindow(goog.dom.getOwnerDocument(a));if(!goog.reflect.canAccessProperty(d,"parent"))return c;var e=a;do{var f=d==b?goog.style.getPageOffset(e):goog.style.getClientPositionForElement_(goog.asserts.assert(e));c.x+=f.x;c.y+=f.y}while(d&&d!=b&&d!=d.parent&&(e=d.frameElement)&&(d=d.parent));return c};
goog.style.translateRectForAnotherFrame=function(a,b,c){if(b.getDocument()!=c.getDocument()){var d=b.getDocument().body;c=goog.style.getFramedPageOffset(d,c.getWindow());c=goog.math.Coordinate.difference(c,goog.style.getPageOffset(d));!goog.userAgent.IE||goog.userAgent.isDocumentModeOrHigher(9)||b.isCss1CompatMode()||(c=goog.math.Coordinate.difference(c,b.getDocumentScroll()));a.left+=c.x;a.top+=c.y}};
goog.style.getRelativePosition=function(a,b){var c=goog.style.getClientPosition(a),d=goog.style.getClientPosition(b);return new goog.math.Coordinate(c.x-d.x,c.y-d.y)};goog.style.getClientPositionForElement_=function(a){a=goog.style.getBoundingClientRect_(a);return new goog.math.Coordinate(a.left,a.top)};
goog.style.getClientPosition=function(a){goog.asserts.assert(a);if(a.nodeType==goog.dom.NodeType.ELEMENT)return goog.style.getClientPositionForElement_(a);a=a.changedTouches?a.changedTouches[0]:a;return new goog.math.Coordinate(a.clientX,a.clientY)};goog.style.setPageOffset=function(a,b,c){var d=goog.style.getPageOffset(a);b instanceof goog.math.Coordinate&&(c=b.y,b=b.x);b=goog.asserts.assertNumber(b)-d.x;goog.style.setPosition(a,a.offsetLeft+b,a.offsetTop+(Number(c)-d.y))};
goog.style.setSize=function(a,b,c){if(b instanceof goog.math.Size)c=b.height,b=b.width;else if(void 0==c)throw Error("missing height argument");goog.style.setWidth(a,b);goog.style.setHeight(a,c)};goog.style.getPixelStyleValue_=function(a,b){"number"==typeof a&&(a=(b?Math.round(a):a)+"px");return a};goog.style.setHeight=function(a,b){a.style.height=goog.style.getPixelStyleValue_(b,!0)};goog.style.setWidth=function(a,b){a.style.width=goog.style.getPixelStyleValue_(b,!0)};
goog.style.getSize=function(a){return goog.style.evaluateWithTemporaryDisplay_(goog.style.getSizeWithDisplay_,a)};goog.style.evaluateWithTemporaryDisplay_=function(a,b){if("none"!=goog.style.getStyle_(b,"display"))return a(b);var c=b.style,d=c.display,e=c.visibility,f=c.position;c.visibility="hidden";c.position="absolute";c.display="inline";var g=a(b);c.display=d;c.position=f;c.visibility=e;return g};
goog.style.getSizeWithDisplay_=function(a){var b=a.offsetWidth,c=a.offsetHeight,d=goog.userAgent.WEBKIT&&!b&&!c;return goog.isDef(b)&&!d||!a.getBoundingClientRect?new goog.math.Size(b,c):(a=goog.style.getBoundingClientRect_(a),new goog.math.Size(a.right-a.left,a.bottom-a.top))};goog.style.getTransformedSize=function(a){if(!a.getBoundingClientRect)return null;a=goog.style.evaluateWithTemporaryDisplay_(goog.style.getBoundingClientRect_,a);return new goog.math.Size(a.right-a.left,a.bottom-a.top)};
goog.style.getBounds=function(a){var b=goog.style.getPageOffset(a);a=goog.style.getSize(a);return new goog.math.Rect(b.x,b.y,a.width,a.height)};goog.style.toCamelCase=function(a){return goog.string.toCamelCase(String(a))};goog.style.toSelectorCase=function(a){return goog.string.toSelectorCase(a)};
goog.style.getOpacity=function(a){goog.asserts.assert(a);var b=a.style;a="";"opacity"in b?a=b.opacity:"MozOpacity"in b?a=b.MozOpacity:"filter"in b&&(b=b.filter.match(/alpha\(opacity=([\d.]+)\)/))&&(a=String(b[1]/100));return""==a?a:Number(a)};goog.style.setOpacity=function(a,b){goog.asserts.assert(a);var c=a.style;"opacity"in c?c.opacity=b:"MozOpacity"in c?c.MozOpacity=b:"filter"in c&&(c.filter=""===b?"":"alpha(opacity="+100*Number(b)+")")};
goog.style.setTransparentBackgroundImage=function(a,b){var c=a.style;goog.userAgent.IE&&!goog.userAgent.isVersionOrHigher("8")?c.filter='progid:DXImageTransform.Microsoft.AlphaImageLoader(src="'+b+'", sizingMethod="crop")':(c.backgroundImage="url("+b+")",c.backgroundPosition="top left",c.backgroundRepeat="no-repeat")};goog.style.clearTransparentBackgroundImage=function(a){a=a.style;"filter"in a?a.filter="":a.backgroundImage="none"};
goog.style.showElement=function(a,b){goog.style.setElementShown(a,b)};goog.style.setElementShown=function(a,b){a.style.display=b?"":"none"};goog.style.isElementShown=function(a){return"none"!=a.style.display};goog.style.installStyles=function(a,b){return goog.style.installSafeStyleSheet(goog.html.legacyconversions.safeStyleSheetFromString(a),b)};
goog.style.installSafeStyleSheet=function(a,b){var c=goog.dom.getDomHelper(b),d,e=c.getDocument();goog.userAgent.IE&&e.createStyleSheet?(d=e.createStyleSheet(),goog.style.setSafeStyleSheet(d,a)):(e=c.getElementsByTagNameAndClass("HEAD")[0],e||(d=c.getElementsByTagNameAndClass("BODY")[0],e=c.createDom("HEAD"),d.parentNode.insertBefore(e,d)),d=c.createDom("STYLE"),goog.style.setSafeStyleSheet(d,a),c.appendChild(e,d));return d};
goog.style.uninstallStyles=function(a){goog.dom.removeNode(a.ownerNode||a.owningElement||a)};goog.style.setStyles=function(a,b){goog.style.setSafeStyleSheet(a,goog.html.legacyconversions.safeStyleSheetFromString(b))};goog.style.setSafeStyleSheet=function(a,b){var c=goog.html.SafeStyleSheet.unwrap(b);goog.userAgent.IE&&goog.isDef(a.cssText)?a.cssText=c:a.innerHTML=c};
goog.style.setPreWrap=function(a){a=a.style;goog.userAgent.IE&&!goog.userAgent.isVersionOrHigher("8")?(a.whiteSpace="pre",a.wordWrap="break-word"):a.whiteSpace=goog.userAgent.GECKO?"-moz-pre-wrap":"pre-wrap"};goog.style.setInlineBlock=function(a){a=a.style;a.position="relative";goog.userAgent.IE&&!goog.userAgent.isVersionOrHigher("8")?(a.zoom="1",a.display="inline"):a.display="inline-block"};goog.style.isRightToLeft=function(a){return"rtl"==goog.style.getStyle_(a,"direction")};
goog.style.unselectableStyle_=goog.userAgent.GECKO?"MozUserSelect":goog.userAgent.WEBKIT||goog.userAgent.EDGE?"WebkitUserSelect":null;goog.style.isUnselectable=function(a){return goog.style.unselectableStyle_?"none"==a.style[goog.style.unselectableStyle_].toLowerCase():goog.userAgent.IE||goog.userAgent.OPERA?"on"==a.getAttribute("unselectable"):!1};
goog.style.setUnselectable=function(a,b,c){c=c?null:a.getElementsByTagName("*");var d=goog.style.unselectableStyle_;if(d){if(b=b?"none":"",a.style&&(a.style[d]=b),c){a=0;for(var e;e=c[a];a++)e.style&&(e.style[d]=b)}}else if(goog.userAgent.IE||goog.userAgent.OPERA)if(b=b?"on":"",a.setAttribute("unselectable",b),c)for(a=0;e=c[a];a++)e.setAttribute("unselectable",b)};goog.style.getBorderBoxSize=function(a){return new goog.math.Size(a.offsetWidth,a.offsetHeight)};
goog.style.setBorderBoxSize=function(a,b){var c=goog.dom.getOwnerDocument(a),d=goog.dom.getDomHelper(c).isCss1CompatMode();if(!goog.userAgent.IE||goog.userAgent.isVersionOrHigher("10")||d&&goog.userAgent.isVersionOrHigher("8"))goog.style.setBoxSizingSize_(a,b,"border-box");else if(c=a.style,d){var d=goog.style.getPaddingBox(a),e=goog.style.getBorderBox(a);c.pixelWidth=b.width-e.left-d.left-d.right-e.right;c.pixelHeight=b.height-e.top-d.top-d.bottom-e.bottom}else c.pixelWidth=b.width,c.pixelHeight=
b.height};
goog.style.getContentBoxSize=function(a){var b=goog.dom.getOwnerDocument(a),c=goog.userAgent.IE&&a.currentStyle;if(c&&goog.dom.getDomHelper(b).isCss1CompatMode()&&"auto"!=c.width&&"auto"!=c.height&&!c.boxSizing)return b=goog.style.getIePixelValue_(a,c.width,"width","pixelWidth"),a=goog.style.getIePixelValue_(a,c.height,"height","pixelHeight"),new goog.math.Size(b,a);c=goog.style.getBorderBoxSize(a);b=goog.style.getPaddingBox(a);a=goog.style.getBorderBox(a);return new goog.math.Size(c.width-a.left-
b.left-b.right-a.right,c.height-a.top-b.top-b.bottom-a.bottom)};
goog.style.setContentBoxSize=function(a,b){var c=goog.dom.getOwnerDocument(a),d=goog.dom.getDomHelper(c).isCss1CompatMode();if(!goog.userAgent.IE||goog.userAgent.isVersionOrHigher("10")||d&&goog.userAgent.isVersionOrHigher("8"))goog.style.setBoxSizingSize_(a,b,"content-box");else if(c=a.style,d)c.pixelWidth=b.width,c.pixelHeight=b.height;else{var d=goog.style.getPaddingBox(a),e=goog.style.getBorderBox(a);c.pixelWidth=b.width+e.left+d.left+d.right+e.right;c.pixelHeight=b.height+e.top+d.top+d.bottom+
e.bottom}};goog.style.setBoxSizingSize_=function(a,b,c){a=a.style;goog.userAgent.GECKO?a.MozBoxSizing=c:goog.userAgent.WEBKIT?a.WebkitBoxSizing=c:a.boxSizing=c;a.width=Math.max(b.width,0)+"px";a.height=Math.max(b.height,0)+"px"};goog.style.getIePixelValue_=function(a,b,c,d){if(/^\d+px?$/.test(b))return parseInt(b,10);var e=a.style[c],f=a.runtimeStyle[c];a.runtimeStyle[c]=a.currentStyle[c];a.style[c]=b;b=a.style[d];a.style[c]=e;a.runtimeStyle[c]=f;return+b};
goog.style.getIePixelDistance_=function(a,b){var c=goog.style.getCascadedStyle(a,b);return c?goog.style.getIePixelValue_(a,c,"left","pixelLeft"):0};
goog.style.getBox_=function(a,b){if(goog.userAgent.IE){var c=goog.style.getIePixelDistance_(a,b+"Left"),d=goog.style.getIePixelDistance_(a,b+"Right"),e=goog.style.getIePixelDistance_(a,b+"Top"),f=goog.style.getIePixelDistance_(a,b+"Bottom");return new goog.math.Box(e,d,f,c)}c=goog.style.getComputedStyle(a,b+"Left");d=goog.style.getComputedStyle(a,b+"Right");e=goog.style.getComputedStyle(a,b+"Top");f=goog.style.getComputedStyle(a,b+"Bottom");return new goog.math.Box(parseFloat(e),parseFloat(d),parseFloat(f),
parseFloat(c))};goog.style.getPaddingBox=function(a){return goog.style.getBox_(a,"padding")};goog.style.getMarginBox=function(a){return goog.style.getBox_(a,"margin")};goog.style.ieBorderWidthKeywords_={thin:2,medium:4,thick:6};
goog.style.getIePixelBorder_=function(a,b){if("none"==goog.style.getCascadedStyle(a,b+"Style"))return 0;var c=goog.style.getCascadedStyle(a,b+"Width");return c in goog.style.ieBorderWidthKeywords_?goog.style.ieBorderWidthKeywords_[c]:goog.style.getIePixelValue_(a,c,"left","pixelLeft")};
goog.style.getBorderBox=function(a){if(goog.userAgent.IE&&!goog.userAgent.isDocumentModeOrHigher(9)){var b=goog.style.getIePixelBorder_(a,"borderLeft"),c=goog.style.getIePixelBorder_(a,"borderRight"),d=goog.style.getIePixelBorder_(a,"borderTop");a=goog.style.getIePixelBorder_(a,"borderBottom");return new goog.math.Box(d,c,a,b)}b=goog.style.getComputedStyle(a,"borderLeftWidth");c=goog.style.getComputedStyle(a,"borderRightWidth");d=goog.style.getComputedStyle(a,"borderTopWidth");a=goog.style.getComputedStyle(a,
"borderBottomWidth");return new goog.math.Box(parseFloat(d),parseFloat(c),parseFloat(a),parseFloat(b))};goog.style.getFontFamily=function(a){var b=goog.dom.getOwnerDocument(a),c="";if(b.body.createTextRange&&goog.dom.contains(b,a)){b=b.body.createTextRange();b.moveToElementText(a);try{c=b.queryCommandValue("FontName")}catch(d){c=""}}c||(c=goog.style.getStyle_(a,"fontFamily"));a=c.split(",");1<a.length&&(c=a[0]);return goog.string.stripQuotes(c,"\"'")};goog.style.lengthUnitRegex_=/[^\d]+$/;
goog.style.getLengthUnits=function(a){return(a=a.match(goog.style.lengthUnitRegex_))&&a[0]||null};goog.style.ABSOLUTE_CSS_LENGTH_UNITS_={cm:1,"in":1,mm:1,pc:1,pt:1};goog.style.CONVERTIBLE_RELATIVE_CSS_UNITS_={em:1,ex:1};
goog.style.getFontSize=function(a){var b=goog.style.getStyle_(a,"fontSize"),c=goog.style.getLengthUnits(b);if(b&&"px"==c)return parseInt(b,10);if(goog.userAgent.IE){if(String(c)in goog.style.ABSOLUTE_CSS_LENGTH_UNITS_)return goog.style.getIePixelValue_(a,b,"left","pixelLeft");if(a.parentNode&&a.parentNode.nodeType==goog.dom.NodeType.ELEMENT&&String(c)in goog.style.CONVERTIBLE_RELATIVE_CSS_UNITS_)return a=a.parentNode,c=goog.style.getStyle_(a,"fontSize"),goog.style.getIePixelValue_(a,b==c?"1em":b,
"left","pixelLeft")}c=goog.dom.createDom("SPAN",{style:"visibility:hidden;position:absolute;line-height:0;padding:0;margin:0;border:0;height:1em;"});goog.dom.appendChild(a,c);b=c.offsetHeight;goog.dom.removeNode(c);return b};goog.style.parseStyleAttribute=function(a){var b={};goog.array.forEach(a.split(/\s*;\s*/),function(a){var c=a.match(/\s*([\w-]+)\s*\:(.+)/);c&&(a=c[1],c=goog.string.trim(c[2]),b[goog.string.toCamelCase(a.toLowerCase())]=c)});return b};
goog.style.toStyleAttribute=function(a){var b=[];goog.object.forEach(a,function(a,d){b.push(goog.string.toSelectorCase(d),":",a,";")});return b.join("")};goog.style.setFloat=function(a,b){a.style[goog.userAgent.IE?"styleFloat":"cssFloat"]=b};goog.style.getFloat=function(a){return a.style[goog.userAgent.IE?"styleFloat":"cssFloat"]||""};
goog.style.getScrollbarWidth=function(a){var b=goog.dom.createElement("DIV");a&&(b.className=a);b.style.cssText="overflow:auto;position:absolute;top:0;width:100px;height:100px";a=goog.dom.createElement("DIV");goog.style.setSize(a,"200px","200px");b.appendChild(a);goog.dom.appendChild(goog.dom.getDocument().body,b);a=b.offsetWidth-b.clientWidth;goog.dom.removeNode(b);return a};goog.style.MATRIX_TRANSLATION_REGEX_=/matrix\([0-9\.\-]+, [0-9\.\-]+, [0-9\.\-]+, [0-9\.\-]+, ([0-9\.\-]+)p?x?, ([0-9\.\-]+)p?x?\)/;
goog.style.getCssTranslation=function(a){a=goog.style.getComputedTransform(a);return a?(a=a.match(goog.style.MATRIX_TRANSLATION_REGEX_))?new goog.math.Coordinate(parseFloat(a[1]),parseFloat(a[2])):new goog.math.Coordinate(0,0):new goog.math.Coordinate(0,0)};goog.json={};goog.json.USE_NATIVE_JSON=!1;goog.json.isValid=function(a){return/^\s*$/.test(a)?!1:/^[\],:{}\s\u2028\u2029]*$/.test(a.replace(/\\["\\\/bfnrtu]/g,"@").replace(/(?:"[^"\\\n\r\u2028\u2029\x00-\x08\x0a-\x1f]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)[\s\u2028\u2029]*(?=:|,|]|}|$)/g,"]").replace(/(?:^|:|,)(?:[\s\u2028\u2029]*\[)+/g,""))};
goog.json.parse=goog.json.USE_NATIVE_JSON?goog.global.JSON.parse:function(a){a=String(a);if(goog.json.isValid(a))try{return eval("("+a+")")}catch(b){}throw Error("Invalid JSON string: "+a);};goog.json.unsafeParse=goog.json.USE_NATIVE_JSON?goog.global.JSON.parse:function(a){return eval("("+a+")")};goog.json.serialize=goog.json.USE_NATIVE_JSON?goog.global.JSON.stringify:function(a,b){return(new goog.json.Serializer(b)).serialize(a)};goog.json.Serializer=function(a){this.replacer_=a};
goog.json.Serializer.prototype.serialize=function(a){var b=[];this.serializeInternal(a,b);return b.join("")};
goog.json.Serializer.prototype.serializeInternal=function(a,b){if(null==a)b.push("null");else{if("object"==typeof a){if(goog.isArray(a)){this.serializeArray(a,b);return}if(a instanceof String||a instanceof Number||a instanceof Boolean)a=a.valueOf();else{this.serializeObject_(a,b);return}}switch(typeof a){case "string":this.serializeString_(a,b);break;case "number":this.serializeNumber_(a,b);break;case "boolean":b.push(String(a));break;case "function":b.push("null");break;default:throw Error("Unknown type: "+
typeof a);}}};goog.json.Serializer.charToJsonCharCache_={'"':'\\"',"\\":"\\\\","/":"\\/","\b":"\\b","\f":"\\f","\n":"\\n","\r":"\\r","\t":"\\t","\x0B":"\\u000b"};goog.json.Serializer.charsToReplace_=/\uffff/.test("\uffff")?/[\\\"\x00-\x1f\x7f-\uffff]/g:/[\\\"\x00-\x1f\x7f-\xff]/g;
goog.json.Serializer.prototype.serializeString_=function(a,b){b.push('"',a.replace(goog.json.Serializer.charsToReplace_,function(a){var b=goog.json.Serializer.charToJsonCharCache_[a];b||(b="\\u"+(a.charCodeAt(0)|65536).toString(16).substr(1),goog.json.Serializer.charToJsonCharCache_[a]=b);return b}),'"')};goog.json.Serializer.prototype.serializeNumber_=function(a,b){b.push(isFinite(a)&&!isNaN(a)?String(a):"null")};
goog.json.Serializer.prototype.serializeArray=function(a,b){var c=a.length;b.push("[");for(var d="",e=0;e<c;e++)b.push(d),d=a[e],this.serializeInternal(this.replacer_?this.replacer_.call(a,String(e),d):d,b),d=",";b.push("]")};
goog.json.Serializer.prototype.serializeObject_=function(a,b){b.push("{");var c="",d;for(d in a)if(Object.prototype.hasOwnProperty.call(a,d)){var e=a[d];"function"!=typeof e&&(b.push(c),this.serializeString_(d,b),b.push(":"),this.serializeInternal(this.replacer_?this.replacer_.call(a,d,e):e,b),c=",")}b.push("}")};goog.structs={};goog.structs.getCount=function(a){return a.getCount&&"function"==typeof a.getCount?a.getCount():goog.isArrayLike(a)||goog.isString(a)?a.length:goog.object.getCount(a)};goog.structs.getValues=function(a){if(a.getValues&&"function"==typeof a.getValues)return a.getValues();if(goog.isString(a))return a.split("");if(goog.isArrayLike(a)){for(var b=[],c=a.length,d=0;d<c;d++)b.push(a[d]);return b}return goog.object.getValues(a)};
goog.structs.getKeys=function(a){if(a.getKeys&&"function"==typeof a.getKeys)return a.getKeys();if(!a.getValues||"function"!=typeof a.getValues){if(goog.isArrayLike(a)||goog.isString(a)){var b=[];a=a.length;for(var c=0;c<a;c++)b.push(c);return b}return goog.object.getKeys(a)}};
goog.structs.contains=function(a,b){return a.contains&&"function"==typeof a.contains?a.contains(b):a.containsValue&&"function"==typeof a.containsValue?a.containsValue(b):goog.isArrayLike(a)||goog.isString(a)?goog.array.contains(a,b):goog.object.containsValue(a,b)};goog.structs.isEmpty=function(a){return a.isEmpty&&"function"==typeof a.isEmpty?a.isEmpty():goog.isArrayLike(a)||goog.isString(a)?goog.array.isEmpty(a):goog.object.isEmpty(a)};
goog.structs.clear=function(a){a.clear&&"function"==typeof a.clear?a.clear():goog.isArrayLike(a)?goog.array.clear(a):goog.object.clear(a)};goog.structs.forEach=function(a,b,c){if(a.forEach&&"function"==typeof a.forEach)a.forEach(b,c);else if(goog.isArrayLike(a)||goog.isString(a))goog.array.forEach(a,b,c);else for(var d=goog.structs.getKeys(a),e=goog.structs.getValues(a),f=e.length,g=0;g<f;g++)b.call(c,e[g],d&&d[g],a)};
goog.structs.filter=function(a,b,c){if("function"==typeof a.filter)return a.filter(b,c);if(goog.isArrayLike(a)||goog.isString(a))return goog.array.filter(a,b,c);var d,e=goog.structs.getKeys(a),f=goog.structs.getValues(a),g=f.length;if(e){d={};for(var h=0;h<g;h++)b.call(c,f[h],e[h],a)&&(d[e[h]]=f[h])}else for(d=[],h=0;h<g;h++)b.call(c,f[h],void 0,a)&&d.push(f[h]);return d};
goog.structs.map=function(a,b,c){if("function"==typeof a.map)return a.map(b,c);if(goog.isArrayLike(a)||goog.isString(a))return goog.array.map(a,b,c);var d,e=goog.structs.getKeys(a),f=goog.structs.getValues(a),g=f.length;if(e){d={};for(var h=0;h<g;h++)d[e[h]]=b.call(c,f[h],e[h],a)}else for(d=[],h=0;h<g;h++)d[h]=b.call(c,f[h],void 0,a);return d};
goog.structs.some=function(a,b,c){if("function"==typeof a.some)return a.some(b,c);if(goog.isArrayLike(a)||goog.isString(a))return goog.array.some(a,b,c);for(var d=goog.structs.getKeys(a),e=goog.structs.getValues(a),f=e.length,g=0;g<f;g++)if(b.call(c,e[g],d&&d[g],a))return!0;return!1};
goog.structs.every=function(a,b,c){if("function"==typeof a.every)return a.every(b,c);if(goog.isArrayLike(a)||goog.isString(a))return goog.array.every(a,b,c);for(var d=goog.structs.getKeys(a),e=goog.structs.getValues(a),f=e.length,g=0;g<f;g++)if(!b.call(c,e[g],d&&d[g],a))return!1;return!0};goog.structs.Collection=function(){};goog.iter={};goog.iter.StopIteration="StopIteration"in goog.global?goog.global.StopIteration:{message:"StopIteration",stack:""};goog.iter.Iterator=function(){};goog.iter.Iterator.prototype.next=function(){throw goog.iter.StopIteration;};goog.iter.Iterator.prototype.__iterator__=function(a){return this};
goog.iter.toIterator=function(a){if(a instanceof goog.iter.Iterator)return a;if("function"==typeof a.__iterator__)return a.__iterator__(!1);if(goog.isArrayLike(a)){var b=0,c=new goog.iter.Iterator;c.next=function(){for(;;){if(b>=a.length)throw goog.iter.StopIteration;if(b in a)return a[b++];b++}};return c}throw Error("Not implemented");};
goog.iter.forEach=function(a,b,c){if(goog.isArrayLike(a))try{goog.array.forEach(a,b,c)}catch(d){if(d!==goog.iter.StopIteration)throw d;}else{a=goog.iter.toIterator(a);try{for(;;)b.call(c,a.next(),void 0,a)}catch(d){if(d!==goog.iter.StopIteration)throw d;}}};goog.iter.filter=function(a,b,c){var d=goog.iter.toIterator(a);a=new goog.iter.Iterator;a.next=function(){for(;;){var a=d.next();if(b.call(c,a,void 0,d))return a}};return a};
goog.iter.filterFalse=function(a,b,c){return goog.iter.filter(a,goog.functions.not(b),c)};goog.iter.range=function(a,b,c){var d=0,e=a,f=c||1;1<arguments.length&&(d=a,e=b);if(0==f)throw Error("Range step argument must not be zero");var g=new goog.iter.Iterator;g.next=function(){if(0<f&&d>=e||0>f&&d<=e)throw goog.iter.StopIteration;var a=d;d+=f;return a};return g};goog.iter.join=function(a,b){return goog.iter.toArray(a).join(b)};
goog.iter.map=function(a,b,c){var d=goog.iter.toIterator(a);a=new goog.iter.Iterator;a.next=function(){var a=d.next();return b.call(c,a,void 0,d)};return a};goog.iter.reduce=function(a,b,c,d){var e=c;goog.iter.forEach(a,function(a){e=b.call(d,e,a)});return e};goog.iter.some=function(a,b,c){a=goog.iter.toIterator(a);try{for(;;)if(b.call(c,a.next(),void 0,a))return!0}catch(d){if(d!==goog.iter.StopIteration)throw d;}return!1};
goog.iter.every=function(a,b,c){a=goog.iter.toIterator(a);try{for(;;)if(!b.call(c,a.next(),void 0,a))return!1}catch(d){if(d!==goog.iter.StopIteration)throw d;}return!0};goog.iter.chain=function(a){return goog.iter.chainFromIterable(arguments)};
goog.iter.chainFromIterable=function(a){var b=goog.iter.toIterator(a);a=new goog.iter.Iterator;var c=null;a.next=function(){for(;;){if(null==c){var a=b.next();c=goog.iter.toIterator(a)}try{return c.next()}catch(e){if(e!==goog.iter.StopIteration)throw e;c=null}}};return a};goog.iter.dropWhile=function(a,b,c){var d=goog.iter.toIterator(a);a=new goog.iter.Iterator;var e=!0;a.next=function(){for(;;){var a=d.next();if(!e||!b.call(c,a,void 0,d))return e=!1,a}};return a};
goog.iter.takeWhile=function(a,b,c){var d=goog.iter.toIterator(a);a=new goog.iter.Iterator;a.next=function(){var a=d.next();if(b.call(c,a,void 0,d))return a;throw goog.iter.StopIteration;};return a};goog.iter.toArray=function(a){if(goog.isArrayLike(a))return goog.array.toArray(a);a=goog.iter.toIterator(a);var b=[];goog.iter.forEach(a,function(a){b.push(a)});return b};
goog.iter.equals=function(a,b,c){a=goog.iter.zipLongest({},a,b);var d=c||goog.array.defaultCompareEquality;return goog.iter.every(a,function(a){return d(a[0],a[1])})};goog.iter.nextOrValue=function(a,b){try{return goog.iter.toIterator(a).next()}catch(c){if(c!=goog.iter.StopIteration)throw c;return b}};
goog.iter.product=function(a){if(goog.array.some(arguments,function(a){return!a.length})||!arguments.length)return new goog.iter.Iterator;var b=new goog.iter.Iterator,c=arguments,d=goog.array.repeat(0,c.length);b.next=function(){if(d){for(var a=goog.array.map(d,function(a,b){return c[b][a]}),b=d.length-1;0<=b;b--){goog.asserts.assert(d);if(d[b]<c[b].length-1){d[b]++;break}if(0==b){d=null;break}d[b]=0}return a}throw goog.iter.StopIteration;};return b};
goog.iter.cycle=function(a){var b=goog.iter.toIterator(a),c=[],d=0;a=new goog.iter.Iterator;var e=!1;a.next=function(){var a=null;if(!e)try{return a=b.next(),c.push(a),a}catch(g){if(g!=goog.iter.StopIteration||goog.array.isEmpty(c))throw g;e=!0}a=c[d];d=(d+1)%c.length;return a};return a};goog.iter.count=function(a,b){var c=a||0,d=goog.isDef(b)?b:1,e=new goog.iter.Iterator;e.next=function(){var a=c;c+=d;return a};return e};
goog.iter.repeat=function(a){var b=new goog.iter.Iterator;b.next=goog.functions.constant(a);return b};goog.iter.accumulate=function(a){var b=goog.iter.toIterator(a),c=0;a=new goog.iter.Iterator;a.next=function(){return c+=b.next()};return a};goog.iter.zip=function(a){var b=arguments,c=new goog.iter.Iterator;if(0<b.length){var d=goog.array.map(b,goog.iter.toIterator);c.next=function(){return goog.array.map(d,function(a){return a.next()})}}return c};
goog.iter.zipLongest=function(a,b){var c=goog.array.slice(arguments,1),d=new goog.iter.Iterator;if(0<c.length){var e=goog.array.map(c,goog.iter.toIterator);d.next=function(){var b=!1,c=goog.array.map(e,function(c){var d;try{d=c.next(),b=!0}catch(l){if(l!==goog.iter.StopIteration)throw l;d=a}return d});if(!b)throw goog.iter.StopIteration;return c}}return d};goog.iter.compress=function(a,b){var c=goog.iter.toIterator(b);return goog.iter.filter(a,function(){return!!c.next()})};
goog.iter.GroupByIterator_=function(a,b){this.iterator=goog.iter.toIterator(a);this.keyFunc=b||goog.functions.identity};goog.inherits(goog.iter.GroupByIterator_,goog.iter.Iterator);goog.iter.GroupByIterator_.prototype.next=function(){for(;this.currentKey==this.targetKey;)this.currentValue=this.iterator.next(),this.currentKey=this.keyFunc(this.currentValue);this.targetKey=this.currentKey;return[this.currentKey,this.groupItems_(this.targetKey)]};
goog.iter.GroupByIterator_.prototype.groupItems_=function(a){for(var b=[];this.currentKey==a;){b.push(this.currentValue);try{this.currentValue=this.iterator.next()}catch(c){if(c!==goog.iter.StopIteration)throw c;break}this.currentKey=this.keyFunc(this.currentValue)}return b};goog.iter.groupBy=function(a,b){return new goog.iter.GroupByIterator_(a,b)};
goog.iter.starMap=function(a,b,c){var d=goog.iter.toIterator(a);a=new goog.iter.Iterator;a.next=function(){var a=goog.iter.toArray(d.next());return b.apply(c,goog.array.concat(a,void 0,d))};return a};
goog.iter.tee=function(a,b){var c=goog.iter.toIterator(a),d=goog.isNumber(b)?b:2,e=goog.array.map(goog.array.range(d),function(){return[]}),f=function(){var a=c.next();goog.array.forEach(e,function(b){b.push(a)})};return goog.array.map(e,function(a){var b=new goog.iter.Iterator;b.next=function(){goog.array.isEmpty(a)&&f();goog.asserts.assert(!goog.array.isEmpty(a));return a.shift()};return b})};goog.iter.enumerate=function(a,b){return goog.iter.zip(goog.iter.count(b),a)};
goog.iter.limit=function(a,b){goog.asserts.assert(goog.math.isInt(b)&&0<=b);var c=goog.iter.toIterator(a),d=new goog.iter.Iterator,e=b;d.next=function(){if(0<e--)return c.next();throw goog.iter.StopIteration;};return d};goog.iter.consume=function(a,b){goog.asserts.assert(goog.math.isInt(b)&&0<=b);for(var c=goog.iter.toIterator(a);0<b--;)goog.iter.nextOrValue(c,null);return c};
goog.iter.slice=function(a,b,c){goog.asserts.assert(goog.math.isInt(b)&&0<=b);a=goog.iter.consume(a,b);goog.isNumber(c)&&(goog.asserts.assert(goog.math.isInt(c)&&c>=b),a=goog.iter.limit(a,c-b));return a};goog.iter.hasDuplicates_=function(a){var b=[];goog.array.removeDuplicates(a,b);return a.length!=b.length};goog.iter.permutations=function(a,b){var c=goog.iter.toArray(a),d=goog.isNumber(b)?b:c.length,c=goog.array.repeat(c,d),c=goog.iter.product.apply(void 0,c);return goog.iter.filter(c,function(a){return!goog.iter.hasDuplicates_(a)})};
goog.iter.combinations=function(a,b){function c(a){return d[a]}var d=goog.iter.toArray(a),e=goog.iter.range(d.length),e=goog.iter.permutations(e,b),f=goog.iter.filter(e,function(a){return goog.array.isSorted(a)}),e=new goog.iter.Iterator;e.next=function(){return goog.array.map(f.next(),c)};return e};
goog.iter.combinationsWithReplacement=function(a,b){function c(a){return d[a]}var d=goog.iter.toArray(a),e=goog.array.range(d.length),e=goog.array.repeat(e,b),e=goog.iter.product.apply(void 0,e),f=goog.iter.filter(e,function(a){return goog.array.isSorted(a)}),e=new goog.iter.Iterator;e.next=function(){return goog.array.map(f.next(),c)};return e};goog.structs.Map=function(a,b){this.map_={};this.keys_=[];this.version_=this.count_=0;var c=arguments.length;if(1<c){if(c%2)throw Error("Uneven number of arguments");for(var d=0;d<c;d+=2)this.set(arguments[d],arguments[d+1])}else a&&this.addAll(a)};goog.structs.Map.prototype.getCount=function(){return this.count_};goog.structs.Map.prototype.getValues=function(){this.cleanupKeysArray_();for(var a=[],b=0;b<this.keys_.length;b++)a.push(this.map_[this.keys_[b]]);return a};
goog.structs.Map.prototype.getKeys=function(){this.cleanupKeysArray_();return this.keys_.concat()};goog.structs.Map.prototype.containsKey=function(a){return goog.structs.Map.hasKey_(this.map_,a)};goog.structs.Map.prototype.containsValue=function(a){for(var b=0;b<this.keys_.length;b++){var c=this.keys_[b];if(goog.structs.Map.hasKey_(this.map_,c)&&this.map_[c]==a)return!0}return!1};
goog.structs.Map.prototype.equals=function(a,b){if(this===a)return!0;if(this.count_!=a.getCount())return!1;var c=b||goog.structs.Map.defaultEquals;this.cleanupKeysArray_();for(var d,e=0;d=this.keys_[e];e++)if(!c(this.get(d),a.get(d)))return!1;return!0};goog.structs.Map.defaultEquals=function(a,b){return a===b};goog.structs.Map.prototype.isEmpty=function(){return 0==this.count_};goog.structs.Map.prototype.clear=function(){this.map_={};this.version_=this.count_=this.keys_.length=0};
goog.structs.Map.prototype.remove=function(a){return goog.structs.Map.hasKey_(this.map_,a)?(delete this.map_[a],this.count_--,this.version_++,this.keys_.length>2*this.count_&&this.cleanupKeysArray_(),!0):!1};
goog.structs.Map.prototype.cleanupKeysArray_=function(){if(this.count_!=this.keys_.length){for(var a=0,b=0;a<this.keys_.length;){var c=this.keys_[a];goog.structs.Map.hasKey_(this.map_,c)&&(this.keys_[b++]=c);a++}this.keys_.length=b}if(this.count_!=this.keys_.length){for(var d={},b=a=0;a<this.keys_.length;)c=this.keys_[a],goog.structs.Map.hasKey_(d,c)||(this.keys_[b++]=c,d[c]=1),a++;this.keys_.length=b}};
goog.structs.Map.prototype.get=function(a,b){return goog.structs.Map.hasKey_(this.map_,a)?this.map_[a]:b};goog.structs.Map.prototype.set=function(a,b){goog.structs.Map.hasKey_(this.map_,a)||(this.count_++,this.keys_.push(a),this.version_++);this.map_[a]=b};goog.structs.Map.prototype.addAll=function(a){var b;a instanceof goog.structs.Map?(b=a.getKeys(),a=a.getValues()):(b=goog.object.getKeys(a),a=goog.object.getValues(a));for(var c=0;c<b.length;c++)this.set(b[c],a[c])};
goog.structs.Map.prototype.forEach=function(a,b){for(var c=this.getKeys(),d=0;d<c.length;d++){var e=c[d],f=this.get(e);a.call(b,f,e,this)}};goog.structs.Map.prototype.clone=function(){return new goog.structs.Map(this)};goog.structs.Map.prototype.transpose=function(){for(var a=new goog.structs.Map,b=0;b<this.keys_.length;b++){var c=this.keys_[b];a.set(this.map_[c],c)}return a};
goog.structs.Map.prototype.toObject=function(){this.cleanupKeysArray_();for(var a={},b=0;b<this.keys_.length;b++){var c=this.keys_[b];a[c]=this.map_[c]}return a};goog.structs.Map.prototype.getKeyIterator=function(){return this.__iterator__(!0)};goog.structs.Map.prototype.getValueIterator=function(){return this.__iterator__(!1)};
goog.structs.Map.prototype.__iterator__=function(a){this.cleanupKeysArray_();var b=0,c=this.version_,d=this,e=new goog.iter.Iterator;e.next=function(){if(c!=d.version_)throw Error("The map has changed since the iterator was created");if(b>=d.keys_.length)throw goog.iter.StopIteration;var e=d.keys_[b++];return a?e:d.map_[e]};return e};goog.structs.Map.hasKey_=function(a,b){return Object.prototype.hasOwnProperty.call(a,b)};goog.structs.Set=function(a){this.map_=new goog.structs.Map;a&&this.addAll(a)};goog.structs.Set.getKey_=function(a){var b=typeof a;return"object"==b&&a||"function"==b?"o"+goog.getUid(a):b.substr(0,1)+a};goog.structs.Set.prototype.getCount=function(){return this.map_.getCount()};goog.structs.Set.prototype.add=function(a){this.map_.set(goog.structs.Set.getKey_(a),a)};goog.structs.Set.prototype.addAll=function(a){a=goog.structs.getValues(a);for(var b=a.length,c=0;c<b;c++)this.add(a[c])};
goog.structs.Set.prototype.removeAll=function(a){a=goog.structs.getValues(a);for(var b=a.length,c=0;c<b;c++)this.remove(a[c])};goog.structs.Set.prototype.remove=function(a){return this.map_.remove(goog.structs.Set.getKey_(a))};goog.structs.Set.prototype.clear=function(){this.map_.clear()};goog.structs.Set.prototype.isEmpty=function(){return this.map_.isEmpty()};goog.structs.Set.prototype.contains=function(a){return this.map_.containsKey(goog.structs.Set.getKey_(a))};
goog.structs.Set.prototype.containsAll=function(a){return goog.structs.every(a,this.contains,this)};goog.structs.Set.prototype.intersection=function(a){var b=new goog.structs.Set;a=goog.structs.getValues(a);for(var c=0;c<a.length;c++){var d=a[c];this.contains(d)&&b.add(d)}return b};goog.structs.Set.prototype.difference=function(a){var b=this.clone();b.removeAll(a);return b};goog.structs.Set.prototype.getValues=function(){return this.map_.getValues()};goog.structs.Set.prototype.clone=function(){return new goog.structs.Set(this)};
goog.structs.Set.prototype.equals=function(a){return this.getCount()==goog.structs.getCount(a)&&this.isSubsetOf(a)};goog.structs.Set.prototype.isSubsetOf=function(a){var b=goog.structs.getCount(a);if(this.getCount()>b)return!1;!(a instanceof goog.structs.Set)&&5<b&&(a=new goog.structs.Set(a));return goog.structs.every(this,function(b){return goog.structs.contains(a,b)})};goog.structs.Set.prototype.__iterator__=function(a){return this.map_.__iterator__(!1)};goog.debug.LOGGING_ENABLED=goog.DEBUG;goog.debug.FORCE_SLOPPY_STACKS=!1;goog.debug.catchErrors=function(a,b,c){c=c||goog.global;var d=c.onerror,e=!!b;goog.userAgent.WEBKIT&&!goog.userAgent.isVersionOrHigher("535.3")&&(e=!e);c.onerror=function(b,c,h,k,l){d&&d(b,c,h,k,l);a({message:b,fileName:c,line:h,col:k,error:l});return e}};
goog.debug.expose=function(a,b){if("undefined"==typeof a)return"undefined";if(null==a)return"NULL";var c=[],d;for(d in a)if(b||!goog.isFunction(a[d])){var e=d+" = ";try{e+=a[d]}catch(f){e+="*** "+f+" ***"}c.push(e)}return c.join("\n")};
goog.debug.deepExpose=function(a,b){var c=[],d=function(a,f,g){var e=f+"  ";g=new goog.structs.Set(g);try{if(goog.isDef(a))if(goog.isNull(a))c.push("NULL");else if(goog.isString(a))c.push('"'+a.replace(/\n/g,"\n"+f)+'"');else if(goog.isFunction(a))c.push(String(a).replace(/\n/g,"\n"+f));else if(goog.isObject(a))if(g.contains(a))c.push("*** reference loop detected ***");else{g.add(a);c.push("{");for(var k in a)if(b||!goog.isFunction(a[k]))c.push("\n"),c.push(e),c.push(k+" = "),d(a[k],e,g);c.push("\n"+
f+"}")}else c.push(a);else c.push("undefined")}catch(l){c.push("*** "+l+" ***")}};d(a,"",new goog.structs.Set);return c.join("")};goog.debug.exposeArray=function(a){for(var b=[],c=0;c<a.length;c++)goog.isArray(a[c])?b.push(goog.debug.exposeArray(a[c])):b.push(a[c]);return"[ "+b.join(", ")+" ]"};goog.debug.exposeException=function(a,b){var c=goog.debug.exposeExceptionAsHtml(a,b);return goog.html.SafeHtml.unwrap(c)};
goog.debug.exposeExceptionAsHtml=function(a,b){try{var c=goog.debug.normalizeErrorObject(a),d=goog.debug.createViewSourceUrl_(c.fileName);return goog.html.SafeHtml.concat(goog.html.SafeHtml.htmlEscapePreservingNewlinesAndSpaces("Message: "+c.message+"\nUrl: "),goog.html.SafeHtml.create("a",{href:d,target:"_new"},c.fileName),goog.html.SafeHtml.htmlEscapePreservingNewlinesAndSpaces("\nLine: "+c.lineNumber+"\n\nBrowser stack:\n"+c.stack+"-> [end]\n\nJS stack traversal:\n"+goog.debug.getStacktrace(b)+
"-> "))}catch(e){return goog.html.SafeHtml.htmlEscapePreservingNewlinesAndSpaces("Exception trying to expose exception! You win, we lose. "+e)}};
goog.debug.createViewSourceUrl_=function(a){goog.isDefAndNotNull(a)||(a="");if(!/^https?:\/\//i.test(a))return goog.html.SafeUrl.fromConstant(goog.string.Const.from("sanitizedviewsrc"));a=goog.html.SafeUrl.sanitize(a);return goog.html.uncheckedconversions.safeUrlFromStringKnownToSatisfyTypeContract(goog.string.Const.from("view-source scheme plus HTTP/HTTPS URL"),"view-source:"+goog.html.SafeUrl.unwrap(a))};
goog.debug.normalizeErrorObject=function(a){var b=goog.getObjectByName("window.location.href");if(goog.isString(a))return{message:a,name:"Unknown error",lineNumber:"Not available",fileName:b,stack:"Not available"};var c,d,e=!1;try{c=a.lineNumber||a.line||"Not available"}catch(f){c="Not available",e=!0}try{d=a.fileName||a.filename||a.sourceURL||goog.global.$googDebugFname||b}catch(f){d="Not available",e=!0}return!e&&a.lineNumber&&a.fileName&&a.stack&&a.message&&a.name?a:{message:a.message||"Not available",
name:a.name||"UnknownError",lineNumber:c,fileName:d,stack:a.stack||"Not available"}};goog.debug.enhanceError=function(a,b){var c;a instanceof Error?c=a:(c=Error(a),Error.captureStackTrace&&Error.captureStackTrace(c,goog.debug.enhanceError));c.stack||(c.stack=goog.debug.getStacktrace(goog.debug.enhanceError));if(b){for(var d=0;c["message"+d];)++d;c["message"+d]=String(b)}return c};
goog.debug.getStacktraceSimple=function(a){if(!goog.debug.FORCE_SLOPPY_STACKS){var b=goog.debug.getNativeStackTrace_(goog.debug.getStacktraceSimple);if(b)return b}for(var b=[],c=arguments.callee.caller,d=0;c&&(!a||d<a);){b.push(goog.debug.getFunctionName(c));b.push("()\n");try{c=c.caller}catch(e){b.push("[exception trying to get caller]\n");break}d++;if(d>=goog.debug.MAX_STACK_DEPTH){b.push("[...long stack...]");break}}a&&d>=a?b.push("[...reached max depth limit...]"):b.push("[end]");return b.join("")};
goog.debug.MAX_STACK_DEPTH=50;goog.debug.getNativeStackTrace_=function(a){var b=Error();if(Error.captureStackTrace)return Error.captureStackTrace(b,a),String(b.stack);try{throw b;}catch(c){b=c}return(a=b.stack)?String(a):null};goog.debug.getStacktrace=function(a){var b;goog.debug.FORCE_SLOPPY_STACKS||(b=goog.debug.getNativeStackTrace_(a||goog.debug.getStacktrace));b||(b=goog.debug.getStacktraceHelper_(a||arguments.callee.caller,[]));return b};
goog.debug.getStacktraceHelper_=function(a,b){var c=[];if(goog.array.contains(b,a))c.push("[...circular reference...]");else if(a&&b.length<goog.debug.MAX_STACK_DEPTH){c.push(goog.debug.getFunctionName(a)+"(");for(var d=a.arguments,e=0;d&&e<d.length;e++){0<e&&c.push(", ");var f;f=d[e];switch(typeof f){case "object":f=f?"object":"null";break;case "string":break;case "number":f=String(f);break;case "boolean":f=f?"true":"false";break;case "function":f=(f=goog.debug.getFunctionName(f))?f:"[fn]";break;
default:f=typeof f}40<f.length&&(f=f.substr(0,40)+"...");c.push(f)}b.push(a);c.push(")\n");try{c.push(goog.debug.getStacktraceHelper_(a.caller,b))}catch(g){c.push("[exception trying to get caller]\n")}}else a?c.push("[...long stack...]"):c.push("[end]");return c.join("")};goog.debug.setFunctionResolver=function(a){goog.debug.fnNameResolver_=a};
goog.debug.getFunctionName=function(a){if(goog.debug.fnNameCache_[a])return goog.debug.fnNameCache_[a];if(goog.debug.fnNameResolver_){var b=goog.debug.fnNameResolver_(a);if(b)return goog.debug.fnNameCache_[a]=b}a=String(a);goog.debug.fnNameCache_[a]||(b=/function ([^\(]+)/.exec(a),goog.debug.fnNameCache_[a]=b?b[1]:"[Anonymous]");return goog.debug.fnNameCache_[a]};
goog.debug.makeWhitespaceVisible=function(a){return a.replace(/ /g,"[_]").replace(/\f/g,"[f]").replace(/\n/g,"[n]\n").replace(/\r/g,"[r]").replace(/\t/g,"[t]")};goog.debug.runtimeType=function(a){return a instanceof Function?a.displayName||a.name||"unknown type name":a instanceof Object?a.constructor.displayName||a.constructor.name||Object.prototype.toString.call(a):null===a?"null":typeof a};goog.debug.fnNameCache_={};goog.debug.LogRecord=function(a,b,c,d,e){this.reset(a,b,c,d,e)};goog.debug.LogRecord.prototype.sequenceNumber_=0;goog.debug.LogRecord.prototype.exception_=null;goog.debug.LogRecord.ENABLE_SEQUENCE_NUMBERS=!0;goog.debug.LogRecord.nextSequenceNumber_=0;
goog.debug.LogRecord.prototype.reset=function(a,b,c,d,e){goog.debug.LogRecord.ENABLE_SEQUENCE_NUMBERS&&(this.sequenceNumber_="number"==typeof e?e:goog.debug.LogRecord.nextSequenceNumber_++);this.time_=d||goog.now();this.level_=a;this.msg_=b;this.loggerName_=c;delete this.exception_};goog.debug.LogRecord.prototype.getLoggerName=function(){return this.loggerName_};goog.debug.LogRecord.prototype.getException=function(){return this.exception_};
goog.debug.LogRecord.prototype.setException=function(a){this.exception_=a};goog.debug.LogRecord.prototype.setLoggerName=function(a){this.loggerName_=a};goog.debug.LogRecord.prototype.getLevel=function(){return this.level_};goog.debug.LogRecord.prototype.setLevel=function(a){this.level_=a};goog.debug.LogRecord.prototype.getMessage=function(){return this.msg_};goog.debug.LogRecord.prototype.setMessage=function(a){this.msg_=a};goog.debug.LogRecord.prototype.getMillis=function(){return this.time_};
goog.debug.LogRecord.prototype.setMillis=function(a){this.time_=a};goog.debug.LogRecord.prototype.getSequenceNumber=function(){return this.sequenceNumber_};goog.debug.LogBuffer=function(){goog.asserts.assert(goog.debug.LogBuffer.isBufferingEnabled(),"Cannot use goog.debug.LogBuffer without defining goog.debug.LogBuffer.CAPACITY.");this.clear()};goog.debug.LogBuffer.getInstance=function(){goog.debug.LogBuffer.instance_||(goog.debug.LogBuffer.instance_=new goog.debug.LogBuffer);return goog.debug.LogBuffer.instance_};goog.debug.LogBuffer.CAPACITY=0;
goog.debug.LogBuffer.prototype.addRecord=function(a,b,c){var d=(this.curIndex_+1)%goog.debug.LogBuffer.CAPACITY;this.curIndex_=d;if(this.isFull_)return d=this.buffer_[d],d.reset(a,b,c),d;this.isFull_=d==goog.debug.LogBuffer.CAPACITY-1;return this.buffer_[d]=new goog.debug.LogRecord(a,b,c)};goog.debug.LogBuffer.isBufferingEnabled=function(){return 0<goog.debug.LogBuffer.CAPACITY};
goog.debug.LogBuffer.prototype.clear=function(){this.buffer_=Array(goog.debug.LogBuffer.CAPACITY);this.curIndex_=-1;this.isFull_=!1};goog.debug.LogBuffer.prototype.forEachRecord=function(a){var b=this.buffer_;if(b[0]){var c=this.curIndex_,d=this.isFull_?c:-1;do d=(d+1)%goog.debug.LogBuffer.CAPACITY,a(b[d]);while(d!=c)}};goog.debug.Logger=function(a){this.name_=a;this.handlers_=this.children_=this.level_=this.parent_=null};goog.debug.Logger.ROOT_LOGGER_NAME="";goog.debug.Logger.ENABLE_HIERARCHY=!0;goog.debug.Logger.ENABLE_HIERARCHY||(goog.debug.Logger.rootHandlers_=[]);goog.debug.Logger.Level=function(a,b){this.name=a;this.value=b};goog.debug.Logger.Level.prototype.toString=function(){return this.name};goog.debug.Logger.Level.OFF=new goog.debug.Logger.Level("OFF",Infinity);
goog.debug.Logger.Level.SHOUT=new goog.debug.Logger.Level("SHOUT",1200);goog.debug.Logger.Level.SEVERE=new goog.debug.Logger.Level("SEVERE",1E3);goog.debug.Logger.Level.WARNING=new goog.debug.Logger.Level("WARNING",900);goog.debug.Logger.Level.INFO=new goog.debug.Logger.Level("INFO",800);goog.debug.Logger.Level.CONFIG=new goog.debug.Logger.Level("CONFIG",700);goog.debug.Logger.Level.FINE=new goog.debug.Logger.Level("FINE",500);goog.debug.Logger.Level.FINER=new goog.debug.Logger.Level("FINER",400);
goog.debug.Logger.Level.FINEST=new goog.debug.Logger.Level("FINEST",300);goog.debug.Logger.Level.ALL=new goog.debug.Logger.Level("ALL",0);goog.debug.Logger.Level.PREDEFINED_LEVELS=[goog.debug.Logger.Level.OFF,goog.debug.Logger.Level.SHOUT,goog.debug.Logger.Level.SEVERE,goog.debug.Logger.Level.WARNING,goog.debug.Logger.Level.INFO,goog.debug.Logger.Level.CONFIG,goog.debug.Logger.Level.FINE,goog.debug.Logger.Level.FINER,goog.debug.Logger.Level.FINEST,goog.debug.Logger.Level.ALL];
goog.debug.Logger.Level.predefinedLevelsCache_=null;goog.debug.Logger.Level.createPredefinedLevelsCache_=function(){goog.debug.Logger.Level.predefinedLevelsCache_={};for(var a=0,b;b=goog.debug.Logger.Level.PREDEFINED_LEVELS[a];a++)goog.debug.Logger.Level.predefinedLevelsCache_[b.value]=b,goog.debug.Logger.Level.predefinedLevelsCache_[b.name]=b};
goog.debug.Logger.Level.getPredefinedLevel=function(a){goog.debug.Logger.Level.predefinedLevelsCache_||goog.debug.Logger.Level.createPredefinedLevelsCache_();return goog.debug.Logger.Level.predefinedLevelsCache_[a]||null};
goog.debug.Logger.Level.getPredefinedLevelByValue=function(a){goog.debug.Logger.Level.predefinedLevelsCache_||goog.debug.Logger.Level.createPredefinedLevelsCache_();if(a in goog.debug.Logger.Level.predefinedLevelsCache_)return goog.debug.Logger.Level.predefinedLevelsCache_[a];for(var b=0;b<goog.debug.Logger.Level.PREDEFINED_LEVELS.length;++b){var c=goog.debug.Logger.Level.PREDEFINED_LEVELS[b];if(c.value<=a)return c}return null};goog.debug.Logger.getLogger=function(a){return goog.debug.LogManager.getLogger(a)};
goog.debug.Logger.logToProfilers=function(a){goog.global.console&&(goog.global.console.timeStamp?goog.global.console.timeStamp(a):goog.global.console.markTimeline&&goog.global.console.markTimeline(a));goog.global.msWriteProfilerMark&&goog.global.msWriteProfilerMark(a)};goog.debug.Logger.prototype.getName=function(){return this.name_};
goog.debug.Logger.prototype.addHandler=function(a){goog.debug.LOGGING_ENABLED&&(goog.debug.Logger.ENABLE_HIERARCHY?(this.handlers_||(this.handlers_=[]),this.handlers_.push(a)):(goog.asserts.assert(!this.name_,"Cannot call addHandler on a non-root logger when goog.debug.Logger.ENABLE_HIERARCHY is false."),goog.debug.Logger.rootHandlers_.push(a)))};
goog.debug.Logger.prototype.removeHandler=function(a){if(goog.debug.LOGGING_ENABLED){var b=goog.debug.Logger.ENABLE_HIERARCHY?this.handlers_:goog.debug.Logger.rootHandlers_;return!!b&&goog.array.remove(b,a)}return!1};goog.debug.Logger.prototype.getParent=function(){return this.parent_};goog.debug.Logger.prototype.getChildren=function(){this.children_||(this.children_={});return this.children_};
goog.debug.Logger.prototype.setLevel=function(a){goog.debug.LOGGING_ENABLED&&(goog.debug.Logger.ENABLE_HIERARCHY?this.level_=a:(goog.asserts.assert(!this.name_,"Cannot call setLevel() on a non-root logger when goog.debug.Logger.ENABLE_HIERARCHY is false."),goog.debug.Logger.rootLevel_=a))};goog.debug.Logger.prototype.getLevel=function(){return goog.debug.LOGGING_ENABLED?this.level_:goog.debug.Logger.Level.OFF};
goog.debug.Logger.prototype.getEffectiveLevel=function(){if(!goog.debug.LOGGING_ENABLED)return goog.debug.Logger.Level.OFF;if(!goog.debug.Logger.ENABLE_HIERARCHY)return goog.debug.Logger.rootLevel_;if(this.level_)return this.level_;if(this.parent_)return this.parent_.getEffectiveLevel();goog.asserts.fail("Root logger has no level set.");return null};goog.debug.Logger.prototype.isLoggable=function(a){return goog.debug.LOGGING_ENABLED&&a.value>=this.getEffectiveLevel().value};
goog.debug.Logger.prototype.log=function(a,b,c){goog.debug.LOGGING_ENABLED&&this.isLoggable(a)&&(goog.isFunction(b)&&(b=b()),this.doLogRecord_(this.getLogRecord(a,b,c)))};goog.debug.Logger.prototype.getLogRecord=function(a,b,c){a=goog.debug.LogBuffer.isBufferingEnabled()?goog.debug.LogBuffer.getInstance().addRecord(a,b,this.name_):new goog.debug.LogRecord(a,String(b),this.name_);c&&a.setException(c);return a};
goog.debug.Logger.prototype.shout=function(a,b){goog.debug.LOGGING_ENABLED&&this.log(goog.debug.Logger.Level.SHOUT,a,b)};goog.debug.Logger.prototype.severe=function(a,b){goog.debug.LOGGING_ENABLED&&this.log(goog.debug.Logger.Level.SEVERE,a,b)};goog.debug.Logger.prototype.warning=function(a,b){goog.debug.LOGGING_ENABLED&&this.log(goog.debug.Logger.Level.WARNING,a,b)};goog.debug.Logger.prototype.info=function(a,b){goog.debug.LOGGING_ENABLED&&this.log(goog.debug.Logger.Level.INFO,a,b)};
goog.debug.Logger.prototype.config=function(a,b){goog.debug.LOGGING_ENABLED&&this.log(goog.debug.Logger.Level.CONFIG,a,b)};goog.debug.Logger.prototype.fine=function(a,b){goog.debug.LOGGING_ENABLED&&this.log(goog.debug.Logger.Level.FINE,a,b)};goog.debug.Logger.prototype.finer=function(a,b){goog.debug.LOGGING_ENABLED&&this.log(goog.debug.Logger.Level.FINER,a,b)};goog.debug.Logger.prototype.finest=function(a,b){goog.debug.LOGGING_ENABLED&&this.log(goog.debug.Logger.Level.FINEST,a,b)};
goog.debug.Logger.prototype.logRecord=function(a){goog.debug.LOGGING_ENABLED&&this.isLoggable(a.getLevel())&&this.doLogRecord_(a)};goog.debug.Logger.prototype.doLogRecord_=function(a){goog.debug.Logger.logToProfilers("log:"+a.getMessage());if(goog.debug.Logger.ENABLE_HIERARCHY)for(var b=this;b;)b.callPublish_(a),b=b.getParent();else for(var b=0,c;c=goog.debug.Logger.rootHandlers_[b++];)c(a)};goog.debug.Logger.prototype.callPublish_=function(a){if(this.handlers_)for(var b=0,c;c=this.handlers_[b];b++)c(a)};
goog.debug.Logger.prototype.setParent_=function(a){this.parent_=a};goog.debug.Logger.prototype.addChild_=function(a,b){this.getChildren()[a]=b};goog.debug.LogManager={};goog.debug.LogManager.loggers_={};goog.debug.LogManager.rootLogger_=null;
goog.debug.LogManager.initialize=function(){goog.debug.LogManager.rootLogger_||(goog.debug.LogManager.rootLogger_=new goog.debug.Logger(goog.debug.Logger.ROOT_LOGGER_NAME),goog.debug.LogManager.loggers_[goog.debug.Logger.ROOT_LOGGER_NAME]=goog.debug.LogManager.rootLogger_,goog.debug.LogManager.rootLogger_.setLevel(goog.debug.Logger.Level.CONFIG))};goog.debug.LogManager.getLoggers=function(){return goog.debug.LogManager.loggers_};
goog.debug.LogManager.getRoot=function(){goog.debug.LogManager.initialize();return goog.debug.LogManager.rootLogger_};goog.debug.LogManager.getLogger=function(a){goog.debug.LogManager.initialize();return goog.debug.LogManager.loggers_[a]||goog.debug.LogManager.createLogger_(a)};goog.debug.LogManager.createFunctionForCatchErrors=function(a){return function(b){(a||goog.debug.LogManager.getRoot()).severe("Error: "+b.message+" ("+b.fileName+" @ Line: "+b.line+")")}};
goog.debug.LogManager.createLogger_=function(a){var b=new goog.debug.Logger(a);if(goog.debug.Logger.ENABLE_HIERARCHY){var c=a.lastIndexOf("."),d=a.substr(0,c),c=a.substr(c+1),d=goog.debug.LogManager.getLogger(d);d.addChild_(c,b);b.setParent_(d)}return goog.debug.LogManager.loggers_[a]=b};goog.debug.RelativeTimeProvider=function(){this.relativeTimeStart_=goog.now()};goog.debug.RelativeTimeProvider.defaultInstance_=new goog.debug.RelativeTimeProvider;goog.debug.RelativeTimeProvider.prototype.set=function(a){this.relativeTimeStart_=a};goog.debug.RelativeTimeProvider.prototype.reset=function(){this.set(goog.now())};goog.debug.RelativeTimeProvider.prototype.get=function(){return this.relativeTimeStart_};goog.debug.RelativeTimeProvider.getDefaultInstance=function(){return goog.debug.RelativeTimeProvider.defaultInstance_};goog.debug.Formatter=function(a){this.prefix_=a||"";this.startTimeProvider_=goog.debug.RelativeTimeProvider.getDefaultInstance()};goog.debug.Formatter.prototype.appendNewline=!0;goog.debug.Formatter.prototype.showAbsoluteTime=!0;goog.debug.Formatter.prototype.showRelativeTime=!0;goog.debug.Formatter.prototype.showLoggerName=!0;goog.debug.Formatter.prototype.showExceptionText=!1;goog.debug.Formatter.prototype.showSeverityLevel=!1;goog.debug.Formatter.prototype.formatRecord=goog.abstractMethod;
goog.debug.Formatter.prototype.formatRecordAsHtml=goog.abstractMethod;goog.debug.Formatter.prototype.setStartTimeProvider=function(a){this.startTimeProvider_=a};goog.debug.Formatter.prototype.getStartTimeProvider=function(){return this.startTimeProvider_};goog.debug.Formatter.prototype.resetRelativeTimeStart=function(){this.startTimeProvider_.reset()};
goog.debug.Formatter.getDateTimeStamp_=function(a){a=new Date(a.getMillis());return goog.debug.Formatter.getTwoDigitString_(a.getFullYear()-2E3)+goog.debug.Formatter.getTwoDigitString_(a.getMonth()+1)+goog.debug.Formatter.getTwoDigitString_(a.getDate())+" "+goog.debug.Formatter.getTwoDigitString_(a.getHours())+":"+goog.debug.Formatter.getTwoDigitString_(a.getMinutes())+":"+goog.debug.Formatter.getTwoDigitString_(a.getSeconds())+"."+goog.debug.Formatter.getTwoDigitString_(Math.floor(a.getMilliseconds()/
10))};goog.debug.Formatter.getTwoDigitString_=function(a){return 10>a?"0"+a:String(a)};goog.debug.Formatter.getRelativeTime_=function(a,b){var c=(a.getMillis()-b)/1E3,d=c.toFixed(3),e=0;if(1>c)e=2;else for(;100>c;)e++,c*=10;for(;0<e--;)d=" "+d;return d};goog.debug.HtmlFormatter=function(a){goog.debug.Formatter.call(this,a)};goog.inherits(goog.debug.HtmlFormatter,goog.debug.Formatter);goog.debug.HtmlFormatter.prototype.showExceptionText=!0;
goog.debug.HtmlFormatter.prototype.formatRecord=function(a){return a?this.formatRecordAsHtml(a).getTypedStringValue():""};
goog.debug.HtmlFormatter.prototype.formatRecordAsHtml=function(a){if(!a)return goog.html.SafeHtml.EMPTY;var b;switch(a.getLevel().value){case goog.debug.Logger.Level.SHOUT.value:b="dbg-sh";break;case goog.debug.Logger.Level.SEVERE.value:b="dbg-sev";break;case goog.debug.Logger.Level.WARNING.value:b="dbg-w";break;case goog.debug.Logger.Level.INFO.value:b="dbg-i";break;default:b="dbg-f"}var c=[];c.push(this.prefix_," ");this.showAbsoluteTime&&c.push("[",goog.debug.Formatter.getDateTimeStamp_(a),"] ");
this.showRelativeTime&&c.push("[",goog.debug.Formatter.getRelativeTime_(a,this.startTimeProvider_.get()),"s] ");this.showLoggerName&&c.push("[",a.getLoggerName(),"] ");this.showSeverityLevel&&c.push("[",a.getLevel().name,"] ");var c=goog.html.SafeHtml.htmlEscapePreservingNewlinesAndSpaces(c.join("")),d=goog.html.SafeHtml.EMPTY;this.showExceptionText&&a.getException()&&(d=goog.html.SafeHtml.concat(goog.html.SafeHtml.BR,goog.debug.exposeExceptionAsHtml(a.getException())));a=goog.html.SafeHtml.htmlEscapePreservingNewlinesAndSpaces(a.getMessage());
b=goog.html.SafeHtml.create("span",{"class":b},goog.html.SafeHtml.concat(a,d));return this.appendNewline?goog.html.SafeHtml.concat(c,b,goog.html.SafeHtml.BR):goog.html.SafeHtml.concat(c,b)};goog.debug.TextFormatter=function(a){goog.debug.Formatter.call(this,a)};goog.inherits(goog.debug.TextFormatter,goog.debug.Formatter);
goog.debug.TextFormatter.prototype.formatRecord=function(a){var b=[];b.push(this.prefix_," ");this.showAbsoluteTime&&b.push("[",goog.debug.Formatter.getDateTimeStamp_(a),"] ");this.showRelativeTime&&b.push("[",goog.debug.Formatter.getRelativeTime_(a,this.startTimeProvider_.get()),"s] ");this.showLoggerName&&b.push("[",a.getLoggerName(),"] ");this.showSeverityLevel&&b.push("[",a.getLevel().name,"] ");b.push(a.getMessage());this.showExceptionText&&(a=a.getException())&&b.push("\n",a instanceof Error?
a.message:a.toString());this.appendNewline&&b.push("\n");return b.join("")};goog.debug.TextFormatter.prototype.formatRecordAsHtml=function(a){return goog.html.SafeHtml.htmlEscapePreservingNewlinesAndSpaces(goog.debug.TextFormatter.prototype.formatRecord(a))};goog.debug.Console=function(){this.publishHandler_=goog.bind(this.addLogRecord,this);this.formatter_=new goog.debug.TextFormatter;this.formatter_.showAbsoluteTime=!1;this.formatter_.showExceptionText=!1;this.isCapturing_=this.formatter_.appendNewline=!1;this.logBuffer_="";this.filteredLoggers_={}};goog.debug.Console.prototype.getFormatter=function(){return this.formatter_};
goog.debug.Console.prototype.setCapturing=function(a){if(a!=this.isCapturing_){var b=goog.debug.LogManager.getRoot();a?b.addHandler(this.publishHandler_):(b.removeHandler(this.publishHandler_),this.logBuffer="");this.isCapturing_=a}};
goog.debug.Console.prototype.addLogRecord=function(a){if(!this.filteredLoggers_[a.getLoggerName()]){var b=this.formatter_.formatRecord(a),c=goog.debug.Console.console_;if(c)switch(a.getLevel()){case goog.debug.Logger.Level.SHOUT:goog.debug.Console.logToConsole_(c,"info",b);break;case goog.debug.Logger.Level.SEVERE:goog.debug.Console.logToConsole_(c,"error",b);break;case goog.debug.Logger.Level.WARNING:goog.debug.Console.logToConsole_(c,"warn",b);break;default:goog.debug.Console.logToConsole_(c,"debug",
b)}else this.logBuffer_+=b}};goog.debug.Console.prototype.addFilter=function(a){this.filteredLoggers_[a]=!0};goog.debug.Console.prototype.removeFilter=function(a){delete this.filteredLoggers_[a]};goog.debug.Console.instance=null;goog.debug.Console.console_=goog.global.console;goog.debug.Console.setConsole=function(a){goog.debug.Console.console_=a};
goog.debug.Console.autoInstall=function(){goog.debug.Console.instance||(goog.debug.Console.instance=new goog.debug.Console);goog.global.location&&-1!=goog.global.location.href.indexOf("Debug=true")&&goog.debug.Console.instance.setCapturing(!0)};goog.debug.Console.show=function(){alert(goog.debug.Console.instance.logBuffer_)};goog.debug.Console.logToConsole_=function(a,b,c){if(a[b])a[b](c);else a.log(c)};goog.log={};goog.log.ENABLED=goog.debug.LOGGING_ENABLED;goog.log.ROOT_LOGGER_NAME=goog.debug.Logger.ROOT_LOGGER_NAME;goog.log.Logger=goog.debug.Logger;goog.log.Level=goog.debug.Logger.Level;goog.log.LogRecord=goog.debug.LogRecord;goog.log.getLogger=function(a,b){if(goog.log.ENABLED){var c=goog.debug.LogManager.getLogger(a);b&&c&&c.setLevel(b);return c}return null};goog.log.addHandler=function(a,b){goog.log.ENABLED&&a&&a.addHandler(b)};
goog.log.removeHandler=function(a,b){return goog.log.ENABLED&&a?a.removeHandler(b):!1};goog.log.log=function(a,b,c,d){goog.log.ENABLED&&a&&a.log(b,c,d)};goog.log.error=function(a,b,c){goog.log.ENABLED&&a&&a.severe(b,c)};goog.log.warning=function(a,b,c){goog.log.ENABLED&&a&&a.warning(b,c)};goog.log.info=function(a,b,c){goog.log.ENABLED&&a&&a.info(b,c)};goog.log.fine=function(a,b,c){goog.log.ENABLED&&a&&a.fine(b,c)};goog.structs.SimplePool=function(a,b){goog.Disposable.call(this);this.disposeObjectFn_=this.createObjectFn_=null;this.maxCount_=b;this.freeQueue_=[];this.createInitial_(a)};goog.inherits(goog.structs.SimplePool,goog.Disposable);goog.structs.SimplePool.prototype.setCreateObjectFn=function(a){this.createObjectFn_=a};goog.structs.SimplePool.prototype.setDisposeObjectFn=function(a){this.disposeObjectFn_=a};
goog.structs.SimplePool.prototype.getObject=function(){return this.freeQueue_.length?this.freeQueue_.pop():this.createObject()};goog.structs.SimplePool.prototype.releaseObject=function(a){this.freeQueue_.length<this.maxCount_?this.freeQueue_.push(a):this.disposeObject(a)};goog.structs.SimplePool.prototype.createInitial_=function(a){if(a>this.maxCount_)throw Error("[goog.structs.SimplePool] Initial cannot be greater than max");for(var b=0;b<a;b++)this.freeQueue_.push(this.createObject())};
goog.structs.SimplePool.prototype.createObject=function(){return this.createObjectFn_?this.createObjectFn_():{}};goog.structs.SimplePool.prototype.disposeObject=function(a){if(this.disposeObjectFn_)this.disposeObjectFn_(a);else if(goog.isObject(a))if(goog.isFunction(a.dispose))a.dispose();else for(var b in a)delete a[b]};
goog.structs.SimplePool.prototype.disposeInternal=function(){goog.structs.SimplePool.superClass_.disposeInternal.call(this);for(var a=this.freeQueue_;a.length;)this.disposeObject(a.pop());delete this.freeQueue_};goog.debug.Trace_=function(){this.events_=[];this.outstandingEvents_=new goog.structs.Map;this.tracerOverheadComment_=this.tracerOverheadEnd_=this.tracerOverheadStart_=this.startTime_=0;this.stats_=new goog.structs.Map;this.commentCount_=this.tracerCount_=0;this.nextId_=1;this.eventPool_=new goog.structs.SimplePool(0,4E3);this.eventPool_.createObject=function(){return new goog.debug.Trace_.Event_};this.statPool_=new goog.structs.SimplePool(0,50);this.statPool_.createObject=function(){return new goog.debug.Trace_.Stat_};
var a=this;this.idPool_=new goog.structs.SimplePool(0,2E3);this.idPool_.createObject=function(){return String(a.nextId_++)};this.idPool_.disposeObject=function(a){};this.defaultThreshold_=3};goog.debug.Trace_.prototype.logger_=goog.log.getLogger("goog.debug.Trace");goog.debug.Trace_.prototype.MAX_TRACE_SIZE=1E3;goog.debug.Trace_.EventType={START:0,STOP:1,COMMENT:2};goog.debug.Trace_.Stat_=function(){this.varAlloc=this.time=this.count=0};
goog.debug.Trace_.Stat_.prototype.toString=function(){var a=[];a.push(this.type," ",this.count," (",Math.round(10*this.time)/10," ms)");this.varAlloc&&a.push(" [VarAlloc = ",this.varAlloc,"]");return a.join("")};goog.debug.Trace_.Event_=function(){};
goog.debug.Trace_.Event_.prototype.toTraceString=function(a,b,c){var d=[];-1==b?d.push("    "):d.push(goog.debug.Trace_.longToPaddedString_(this.eventTime-b));d.push(" ",goog.debug.Trace_.formatTime_(this.eventTime-a));this.eventType==goog.debug.Trace_.EventType.START?d.push(" Start        "):this.eventType==goog.debug.Trace_.EventType.STOP?(d.push(" Done "),d.push(goog.debug.Trace_.longToPaddedString_(this.stopTime-this.startTime)," ms ")):d.push(" Comment      ");d.push(c,this);0<this.totalVarAlloc&&
d.push("[VarAlloc ",this.totalVarAlloc,"] ");return d.join("")};goog.debug.Trace_.Event_.prototype.toString=function(){return null==this.type?this.comment:"["+this.type+"] "+this.comment};goog.debug.Trace_.prototype.setStartTime=function(a){this.startTime_=a};goog.debug.Trace_.prototype.initCurrentTrace=function(a){this.reset(a)};goog.debug.Trace_.prototype.clearCurrentTrace=function(){this.reset(0)};
goog.debug.Trace_.prototype.reset=function(a){this.defaultThreshold_=a;this.releaseEvents_();this.outstandingEvents_.clear();this.startTime_=goog.debug.Trace_.now();this.commentCount_=this.tracerCount_=this.tracerOverheadComment_=this.tracerOverheadEnd_=this.tracerOverheadStart_=0;a=this.stats_.getKeys();for(var b=0;b<a.length;b++){var c=this.stats_.get(a[b]);c.count=0;c.time=0;c.varAlloc=0;this.statPool_.releaseObject(c)}this.stats_.clear()};
goog.debug.Trace_.prototype.releaseEvents_=function(){for(var a=0;a<this.events_.length;a++){var b=this.events_[a];b.id&&this.idPool_.releaseObject(b.id);this.eventPool_.releaseObject(b)}this.events_.length=0};
goog.debug.Trace_.prototype.startTracer=function(a,b){var c=goog.debug.Trace_.now(),d=this.getTotalVarAlloc(),e=this.outstandingEvents_.getCount();this.events_.length+e>this.MAX_TRACE_SIZE&&(goog.log.warning(this.logger_,"Giant thread trace. Clearing to avoid memory leak."),this.events_.length>this.MAX_TRACE_SIZE/2&&this.releaseEvents_(),e>this.MAX_TRACE_SIZE/2&&this.outstandingEvents_.clear());goog.debug.Logger.logToProfilers("Start : "+a);e=this.eventPool_.getObject();e.totalVarAlloc=d;e.eventType=
goog.debug.Trace_.EventType.START;e.id=Number(this.idPool_.getObject());e.comment=a;e.type=b;this.events_.push(e);this.outstandingEvents_.set(String(e.id),e);this.tracerCount_++;d=goog.debug.Trace_.now();e.startTime=e.eventTime=d;this.tracerOverheadStart_+=d-c;return e.id};
goog.debug.Trace_.prototype.stopTracer=function(a,b){var c=goog.debug.Trace_.now(),d;d=0===b?0:b?b:this.defaultThreshold_;var e=this.outstandingEvents_.get(String(a));if(null==e)return null;this.outstandingEvents_.remove(String(a));var f,g=c-e.startTime;if(g<d)for(d=this.events_.length-1;0<=d;d--){if(this.events_[d]==e){this.events_.splice(d,1);this.idPool_.releaseObject(e.id);this.eventPool_.releaseObject(e);break}}else f=this.eventPool_.getObject(),f.eventType=goog.debug.Trace_.EventType.STOP,f.startTime=
e.startTime,f.comment=e.comment,f.type=e.type,f.stopTime=f.eventTime=c,this.events_.push(f);d=e.type;var h=null;d&&(h=this.getStat_(d),h.count++,h.time+=g);f&&(goog.debug.Logger.logToProfilers("Stop : "+f.comment),f.totalVarAlloc=this.getTotalVarAlloc(),h&&(h.varAlloc+=f.totalVarAlloc-e.totalVarAlloc));e=goog.debug.Trace_.now();this.tracerOverheadEnd_+=e-c;return g};goog.debug.Trace_.prototype.setGcTracer=function(a){this.gcTracer_=a};
goog.debug.Trace_.prototype.getTotalVarAlloc=function(){var a=this.gcTracer_;return a&&a.isTracing()?a.totalVarAlloc:-1};
goog.debug.Trace_.prototype.addComment=function(a,b,c){var d=goog.debug.Trace_.now(),e=c?c:d,f=this.eventPool_.getObject();f.eventType=goog.debug.Trace_.EventType.COMMENT;f.eventTime=e;f.type=b;f.comment=a;f.totalVarAlloc=this.getTotalVarAlloc();this.commentCount_++;if(c){a=this.events_.length;for(b=0;b<a;b++)if(this.events_[b].eventTime>e){goog.array.insertAt(this.events_,f,b);break}b==a&&this.events_.push(f)}else this.events_.push(f);(e=f.type)&&this.getStat_(e).count++;this.tracerOverheadComment_+=
goog.debug.Trace_.now()-d};goog.debug.Trace_.prototype.getStat_=function(a){var b=this.stats_.get(a);b||(b=this.statPool_.getObject(),b.type=a,this.stats_.set(a,b));return b};goog.debug.Trace_.prototype.getFormattedTrace=function(){return this.toString()};
goog.debug.Trace_.prototype.toString=function(){for(var a=[],b=-1,c=[],d=0;d<this.events_.length;d++){var e=this.events_[d];e.eventType==goog.debug.Trace_.EventType.STOP&&c.pop();a.push(" ",e.toTraceString(this.startTime_,b,c.join("")));b=e.eventTime;a.push("\n");e.eventType==goog.debug.Trace_.EventType.START&&c.push("|  ")}if(0!=this.outstandingEvents_.getCount()){var f=goog.debug.Trace_.now();a.push(" Unstopped timers:\n");goog.iter.forEach(this.outstandingEvents_,function(b){a.push("  ",b," (",
f-b.startTime," ms, started at ",goog.debug.Trace_.formatTime_(b.startTime),")\n")})}b=this.stats_.getKeys();for(d=0;d<b.length;d++)c=this.stats_.get(b[d]),1<c.count&&a.push(" TOTAL ",c,"\n");a.push("Total tracers created ",this.tracerCount_,"\n","Total comments created ",this.commentCount_,"\n","Overhead start: ",this.tracerOverheadStart_," ms\n","Overhead end: ",this.tracerOverheadEnd_," ms\n","Overhead comment: ",this.tracerOverheadComment_," ms\n");return a.join("")};
goog.debug.Trace_.longToPaddedString_=function(a){a=Math.round(a);var b="";1E3>a&&(b=" ");100>a&&(b="  ");10>a&&(b="   ");return b+a};goog.debug.Trace_.formatTime_=function(a){a=Math.round(a);var b=a%1E3;return String(100+a/1E3%60).substring(1,3)+"."+String(1E3+b).substring(1,4)};goog.debug.Trace_.now=function(){return goog.now()};goog.debug.Trace=new goog.debug.Trace_;goog.debug.ErrorHandler=function(a){goog.Disposable.call(this);this.errorHandlerFn_=a;this.wrapErrors_=!0;this.prefixErrorMessages_=!1};goog.inherits(goog.debug.ErrorHandler,goog.Disposable);goog.debug.ErrorHandler.prototype.addTracersToProtectedFunctions_=!1;goog.debug.ErrorHandler.prototype.setAddTracersToProtectedFunctions=function(a){this.addTracersToProtectedFunctions_=a};goog.debug.ErrorHandler.prototype.wrap=function(a){return this.protectEntryPoint(goog.asserts.assertFunction(a))};
goog.debug.ErrorHandler.prototype.unwrap=function(a){goog.asserts.assertFunction(a);return a[this.getFunctionIndex_(!1)]||a};goog.debug.ErrorHandler.prototype.getStackTraceHolder_=function(a){var b=[];b.push("##PE_STACK_START##");b.push(a.replace(/(\r\n|\r|\n)/g,"##STACK_BR##"));b.push("##PE_STACK_END##");return b.join("")};goog.debug.ErrorHandler.prototype.getFunctionIndex_=function(a){return(a?"__wrapper_":"__protected_")+goog.getUid(this)+"__"};
goog.debug.ErrorHandler.prototype.protectEntryPoint=function(a){var b=this.getFunctionIndex_(!0);a[b]||((a[b]=this.getProtectedFunction(a))[this.getFunctionIndex_(!1)]=a);return a[b]};
goog.debug.ErrorHandler.prototype.getProtectedFunction=function(a){var b=this,c=this.addTracersToProtectedFunctions_;if(c)var d=goog.debug.getStacktraceSimple(15);var e=function(){if(b.isDisposed())return a.apply(this,arguments);if(c)var e=goog.debug.Trace.startTracer("protectedEntryPoint: "+b.getStackTraceHolder_(d));try{return a.apply(this,arguments)}catch(h){var g=goog.debug.ErrorHandler.ProtectedFunctionError.MESSAGE_PREFIX;if(!(h&&"object"===typeof h&&h.message&&0==h.message.indexOf(g)||"string"===
typeof h&&0==h.indexOf(g))){b.errorHandlerFn_(h);if(!b.wrapErrors_)throw b.prefixErrorMessages_&&("object"===typeof h&&h&&"message"in h?h.message=g+h.message:h=g+h),goog.DEBUG&&h&&h.stack&&Error.captureStackTrace&&goog.global.console&&goog.global.console.error(h.message,h.stack),h;throw new goog.debug.ErrorHandler.ProtectedFunctionError(h);}}finally{c&&goog.debug.Trace.stopTracer(e)}};e[this.getFunctionIndex_(!1)]=a;return e};goog.debug.ErrorHandler.prototype.protectWindowSetTimeout=function(){this.protectWindowFunctionsHelper_("setTimeout")};
goog.debug.ErrorHandler.prototype.protectWindowSetInterval=function(){this.protectWindowFunctionsHelper_("setInterval")};goog.debug.ErrorHandler.prototype.protectWindowRequestAnimationFrame=function(){for(var a=goog.getObjectByName("window"),b=["requestAnimationFrame","mozRequestAnimationFrame","webkitAnimationFrame","msRequestAnimationFrame"],c=0;c<b.length;c++){var d=b[c];b[c]in a&&this.protectWindowFunctionsHelper_(d)}};
goog.debug.ErrorHandler.prototype.protectWindowFunctionsHelper_=function(a){var b=goog.getObjectByName("window"),c=b[a],d=this;b[a]=function(a,b){goog.isString(a)&&(a=goog.partial(goog.globalEval,a));arguments[0]=a=d.protectEntryPoint(a);if(c.apply)return c.apply(this,arguments);var e=a;if(2<arguments.length)var f=Array.prototype.slice.call(arguments,2),e=function(){a.apply(this,f)};return c(e,b)};b[a][this.getFunctionIndex_(!1)]=c};
goog.debug.ErrorHandler.prototype.setWrapErrors=function(a){this.wrapErrors_=a};goog.debug.ErrorHandler.prototype.setPrefixErrorMessages=function(a){this.prefixErrorMessages_=a};goog.debug.ErrorHandler.prototype.disposeInternal=function(){var a=goog.getObjectByName("window");a.setTimeout=this.unwrap(a.setTimeout);a.setInterval=this.unwrap(a.setInterval);goog.debug.ErrorHandler.superClass_.disposeInternal.call(this)};
goog.debug.ErrorHandler.ProtectedFunctionError=function(a){goog.debug.Error.call(this,goog.debug.ErrorHandler.ProtectedFunctionError.MESSAGE_PREFIX+(a&&a.message?String(a.message):String(a)));(a=(this.cause=a)&&a.stack)&&goog.isString(a)&&(this.stack=a)};goog.inherits(goog.debug.ErrorHandler.ProtectedFunctionError,goog.debug.Error);goog.debug.ErrorHandler.ProtectedFunctionError.MESSAGE_PREFIX="Error in protected function: ";goog.async.Throttle=function(a,b,c){goog.Disposable.call(this);this.listener_=null!=c?goog.bind(a,c):a;this.interval_=b;this.callback_=goog.bind(this.onTimer_,this);this.args_=[]};goog.inherits(goog.async.Throttle,goog.Disposable);goog.Throttle=goog.async.Throttle;goog.async.Throttle.prototype.shouldFire_=!1;goog.async.Throttle.prototype.pauseCount_=0;goog.async.Throttle.prototype.timer_=null;
goog.async.Throttle.prototype.fire=function(a){this.args_=arguments;this.timer_||this.pauseCount_?this.shouldFire_=!0:this.doAction_()};goog.async.Throttle.prototype.stop=function(){this.timer_&&(goog.Timer.clear(this.timer_),this.timer_=null,this.shouldFire_=!1,this.args_=[])};goog.async.Throttle.prototype.pause=function(){this.pauseCount_++};goog.async.Throttle.prototype.resume=function(){this.pauseCount_--;this.pauseCount_||!this.shouldFire_||this.timer_||(this.shouldFire_=!1,this.doAction_())};
goog.async.Throttle.prototype.disposeInternal=function(){goog.async.Throttle.superClass_.disposeInternal.call(this);this.stop()};goog.async.Throttle.prototype.onTimer_=function(){this.timer_=null;this.shouldFire_&&!this.pauseCount_&&(this.shouldFire_=!1,this.doAction_())};goog.async.Throttle.prototype.doAction_=function(){this.timer_=goog.Timer.callOnce(this.callback_,this.interval_);this.listener_.apply(null,this.args_)};goog.color={};
goog.color.names={aliceblue:"#f0f8ff",antiquewhite:"#faebd7",aqua:"#00ffff",aquamarine:"#7fffd4",azure:"#f0ffff",beige:"#f5f5dc",bisque:"#ffe4c4",black:"#000000",blanchedalmond:"#ffebcd",blue:"#0000ff",blueviolet:"#8a2be2",brown:"#a52a2a",burlywood:"#deb887",cadetblue:"#5f9ea0",chartreuse:"#7fff00",chocolate:"#d2691e",coral:"#ff7f50",cornflowerblue:"#6495ed",cornsilk:"#fff8dc",crimson:"#dc143c",cyan:"#00ffff",darkblue:"#00008b",darkcyan:"#008b8b",darkgoldenrod:"#b8860b",darkgray:"#a9a9a9",darkgreen:"#006400",
darkgrey:"#a9a9a9",darkkhaki:"#bdb76b",darkmagenta:"#8b008b",darkolivegreen:"#556b2f",darkorange:"#ff8c00",darkorchid:"#9932cc",darkred:"#8b0000",darksalmon:"#e9967a",darkseagreen:"#8fbc8f",darkslateblue:"#483d8b",darkslategray:"#2f4f4f",darkslategrey:"#2f4f4f",darkturquoise:"#00ced1",darkviolet:"#9400d3",deeppink:"#ff1493",deepskyblue:"#00bfff",dimgray:"#696969",dimgrey:"#696969",dodgerblue:"#1e90ff",firebrick:"#b22222",floralwhite:"#fffaf0",forestgreen:"#228b22",fuchsia:"#ff00ff",gainsboro:"#dcdcdc",
ghostwhite:"#f8f8ff",gold:"#ffd700",goldenrod:"#daa520",gray:"#808080",green:"#008000",greenyellow:"#adff2f",grey:"#808080",honeydew:"#f0fff0",hotpink:"#ff69b4",indianred:"#cd5c5c",indigo:"#4b0082",ivory:"#fffff0",khaki:"#f0e68c",lavender:"#e6e6fa",lavenderblush:"#fff0f5",lawngreen:"#7cfc00",lemonchiffon:"#fffacd",lightblue:"#add8e6",lightcoral:"#f08080",lightcyan:"#e0ffff",lightgoldenrodyellow:"#fafad2",lightgray:"#d3d3d3",lightgreen:"#90ee90",lightgrey:"#d3d3d3",lightpink:"#ffb6c1",lightsalmon:"#ffa07a",
lightseagreen:"#20b2aa",lightskyblue:"#87cefa",lightslategray:"#778899",lightslategrey:"#778899",lightsteelblue:"#b0c4de",lightyellow:"#ffffe0",lime:"#00ff00",limegreen:"#32cd32",linen:"#faf0e6",magenta:"#ff00ff",maroon:"#800000",mediumaquamarine:"#66cdaa",mediumblue:"#0000cd",mediumorchid:"#ba55d3",mediumpurple:"#9370db",mediumseagreen:"#3cb371",mediumslateblue:"#7b68ee",mediumspringgreen:"#00fa9a",mediumturquoise:"#48d1cc",mediumvioletred:"#c71585",midnightblue:"#191970",mintcream:"#f5fffa",mistyrose:"#ffe4e1",
moccasin:"#ffe4b5",navajowhite:"#ffdead",navy:"#000080",oldlace:"#fdf5e6",olive:"#808000",olivedrab:"#6b8e23",orange:"#ffa500",orangered:"#ff4500",orchid:"#da70d6",palegoldenrod:"#eee8aa",palegreen:"#98fb98",paleturquoise:"#afeeee",palevioletred:"#db7093",papayawhip:"#ffefd5",peachpuff:"#ffdab9",peru:"#cd853f",pink:"#ffc0cb",plum:"#dda0dd",powderblue:"#b0e0e6",purple:"#800080",red:"#ff0000",rosybrown:"#bc8f8f",royalblue:"#4169e1",saddlebrown:"#8b4513",salmon:"#fa8072",sandybrown:"#f4a460",seagreen:"#2e8b57",
seashell:"#fff5ee",sienna:"#a0522d",silver:"#c0c0c0",skyblue:"#87ceeb",slateblue:"#6a5acd",slategray:"#708090",slategrey:"#708090",snow:"#fffafa",springgreen:"#00ff7f",steelblue:"#4682b4",tan:"#d2b48c",teal:"#008080",thistle:"#d8bfd8",tomato:"#ff6347",turquoise:"#40e0d0",violet:"#ee82ee",wheat:"#f5deb3",white:"#ffffff",whitesmoke:"#f5f5f5",yellow:"#ffff00",yellowgreen:"#9acd32"};goog.color.parse=function(a){var b={};a=String(a);var c=goog.color.prependHashIfNecessaryHelper(a);if(goog.color.isValidHexColor_(c))return b.hex=goog.color.normalizeHex(c),b.type="hex",b;c=goog.color.isValidRgbColor_(a);if(c.length)return b.hex=goog.color.rgbArrayToHex(c),b.type="rgb",b;if(goog.color.names&&(c=goog.color.names[a.toLowerCase()]))return b.hex=c,b.type="named",b;throw Error(a+" is not a valid color string");};
goog.color.isValidColor=function(a){var b=goog.color.prependHashIfNecessaryHelper(a);return!!(goog.color.isValidHexColor_(b)||goog.color.isValidRgbColor_(a).length||goog.color.names&&goog.color.names[a.toLowerCase()])};goog.color.parseRgb=function(a){var b=goog.color.isValidRgbColor_(a);if(!b.length)throw Error(a+" is not a valid RGB color");return b};goog.color.hexToRgbStyle=function(a){return goog.color.rgbStyle_(goog.color.hexToRgb(a))};goog.color.hexTripletRe_=/#(.)(.)(.)/;
goog.color.normalizeHex=function(a){if(!goog.color.isValidHexColor_(a))throw Error("'"+a+"' is not a valid hex color");4==a.length&&(a=a.replace(goog.color.hexTripletRe_,"#$1$1$2$2$3$3"));return a.toLowerCase()};goog.color.hexToRgb=function(a){a=goog.color.normalizeHex(a);var b=parseInt(a.substr(1,2),16),c=parseInt(a.substr(3,2),16);a=parseInt(a.substr(5,2),16);return[b,c,a]};
goog.color.rgbToHex=function(a,b,c){a=Number(a);b=Number(b);c=Number(c);if(a!=(a&255)||b!=(b&255)||c!=(c&255))throw Error('"('+a+","+b+","+c+'") is not a valid RGB color');a=goog.color.prependZeroIfNecessaryHelper(a.toString(16));b=goog.color.prependZeroIfNecessaryHelper(b.toString(16));c=goog.color.prependZeroIfNecessaryHelper(c.toString(16));return"#"+a+b+c};goog.color.rgbArrayToHex=function(a){return goog.color.rgbToHex(a[0],a[1],a[2])};
goog.color.rgbToHsl=function(a,b,c){a/=255;b/=255;c/=255;var d=Math.max(a,b,c),e=Math.min(a,b,c),f=0,g=0,h=.5*(d+e);d!=e&&(d==a?f=60*(b-c)/(d-e):d==b?f=60*(c-a)/(d-e)+120:d==c&&(f=60*(a-b)/(d-e)+240),g=0<h&&.5>=h?(d-e)/(2*h):(d-e)/(2-2*h));return[Math.round(f+360)%360,g,h]};goog.color.rgbArrayToHsl=function(a){return goog.color.rgbToHsl(a[0],a[1],a[2])};goog.color.hueToRgb_=function(a,b,c){0>c?c+=1:1<c&&--c;return 1>6*c?a+6*(b-a)*c:1>2*c?b:2>3*c?a+(b-a)*(2/3-c)*6:a};
goog.color.hslToRgb=function(a,b,c){a/=360;if(0==b)c=b=a=255*c;else{var d,e;e=.5>c?c*(1+b):c+b-b*c;d=2*c-e;c=255*goog.color.hueToRgb_(d,e,a+1/3);b=255*goog.color.hueToRgb_(d,e,a);a=255*goog.color.hueToRgb_(d,e,a-1/3)}return[Math.round(c),Math.round(b),Math.round(a)]};goog.color.hslArrayToRgb=function(a){return goog.color.hslToRgb(a[0],a[1],a[2])};goog.color.validHexColorRe_=/^#(?:[0-9a-f]{3}){1,2}$/i;goog.color.isValidHexColor_=function(a){return goog.color.validHexColorRe_.test(a)};
goog.color.normalizedHexColorRe_=/^#[0-9a-f]{6}$/;goog.color.isNormalizedHexColor_=function(a){return goog.color.normalizedHexColorRe_.test(a)};goog.color.rgbColorRe_=/^(?:rgb)?\((0|[1-9]\d{0,2}),\s?(0|[1-9]\d{0,2}),\s?(0|[1-9]\d{0,2})\)$/i;goog.color.isValidRgbColor_=function(a){var b=a.match(goog.color.rgbColorRe_);if(b){a=Number(b[1]);var c=Number(b[2]),b=Number(b[3]);if(0<=a&&255>=a&&0<=c&&255>=c&&0<=b&&255>=b)return[a,c,b]}return[]};
goog.color.prependZeroIfNecessaryHelper=function(a){return 1==a.length?"0"+a:a};goog.color.prependHashIfNecessaryHelper=function(a){return"#"==a.charAt(0)?a:"#"+a};goog.color.rgbStyle_=function(a){return"rgb("+a.join(",")+")"};
goog.color.hsvToRgb=function(a,b,c){var d=0,e=0,f=0;if(0==b)f=e=d=c;else{var g=Math.floor(a/60),h=a/60-g;a=c*(1-b);var k=c*(1-b*h);b=c*(1-b*(1-h));switch(g){case 1:d=k;e=c;f=a;break;case 2:d=a;e=c;f=b;break;case 3:d=a;e=k;f=c;break;case 4:d=b;e=a;f=c;break;case 5:d=c;e=a;f=k;break;case 6:case 0:d=c,e=b,f=a}}return[Math.floor(d),Math.floor(e),Math.floor(f)]};
goog.color.rgbToHsv=function(a,b,c){var d=Math.max(Math.max(a,b),c),e=Math.min(Math.min(a,b),c);if(e==d)e=a=0;else{var f=d-e,e=f/d;a=60*(a==d?(b-c)/f:b==d?2+(c-a)/f:4+(a-b)/f);0>a&&(a+=360);360<a&&(a-=360)}return[a,e,d]};goog.color.rgbArrayToHsv=function(a){return goog.color.rgbToHsv(a[0],a[1],a[2])};goog.color.hsvArrayToRgb=function(a){return goog.color.hsvToRgb(a[0],a[1],a[2])};goog.color.hexToHsl=function(a){a=goog.color.hexToRgb(a);return goog.color.rgbToHsl(a[0],a[1],a[2])};
goog.color.hslToHex=function(a,b,c){return goog.color.rgbArrayToHex(goog.color.hslToRgb(a,b,c))};goog.color.hslArrayToHex=function(a){return goog.color.rgbArrayToHex(goog.color.hslToRgb(a[0],a[1],a[2]))};goog.color.hexToHsv=function(a){return goog.color.rgbArrayToHsv(goog.color.hexToRgb(a))};goog.color.hsvToHex=function(a,b,c){return goog.color.rgbArrayToHex(goog.color.hsvToRgb(a,b,c))};goog.color.hsvArrayToHex=function(a){return goog.color.hsvToHex(a[0],a[1],a[2])};
goog.color.hslDistance=function(a,b){var c,d;c=.5>=a[2]?a[1]*a[2]:a[1]*(1-a[2]);d=.5>=b[2]?b[1]*b[2]:b[1]*(1-b[2]);return(a[2]-b[2])*(a[2]-b[2])+c*c+d*d-2*c*d*Math.cos(2*(a[0]/360-b[0]/360)*Math.PI)};goog.color.blend=function(a,b,c){c=goog.math.clamp(c,0,1);return[Math.round(c*a[0]+(1-c)*b[0]),Math.round(c*a[1]+(1-c)*b[1]),Math.round(c*a[2]+(1-c)*b[2])]};goog.color.darken=function(a,b){return goog.color.blend([0,0,0],a,b)};goog.color.lighten=function(a,b){return goog.color.blend([255,255,255],a,b)};
goog.color.highContrast=function(a,b){for(var c=[],d=0;d<b.length;d++)c.push({color:b[d],diff:goog.color.yiqBrightnessDiff_(b[d],a)+goog.color.colorDiff_(b[d],a)});c.sort(function(a,b){return b.diff-a.diff});return c[0].color};goog.color.yiqBrightness_=function(a){return Math.round((299*a[0]+587*a[1]+114*a[2])/1E3)};goog.color.yiqBrightnessDiff_=function(a,b){return Math.abs(goog.color.yiqBrightness_(a)-goog.color.yiqBrightness_(b))};
goog.color.colorDiff_=function(a,b){return Math.abs(a[0]-b[0])+Math.abs(a[1]-b[1])+Math.abs(a[2]-b[2])};goog.dom.classlist={};goog.dom.classlist.ALWAYS_USE_DOM_TOKEN_LIST=!1;goog.dom.classlist.get=function(a){if(goog.dom.classlist.ALWAYS_USE_DOM_TOKEN_LIST||a.classList)return a.classList;a=a.className;return goog.isString(a)&&a.match(/\S+/g)||[]};goog.dom.classlist.set=function(a,b){a.className=b};goog.dom.classlist.contains=function(a,b){return goog.dom.classlist.ALWAYS_USE_DOM_TOKEN_LIST||a.classList?a.classList.contains(b):goog.array.contains(goog.dom.classlist.get(a),b)};
goog.dom.classlist.add=function(a,b){goog.dom.classlist.ALWAYS_USE_DOM_TOKEN_LIST||a.classList?a.classList.add(b):goog.dom.classlist.contains(a,b)||(a.className+=0<a.className.length?" "+b:b)};
goog.dom.classlist.addAll=function(a,b){if(goog.dom.classlist.ALWAYS_USE_DOM_TOKEN_LIST||a.classList)goog.array.forEach(b,function(b){goog.dom.classlist.add(a,b)});else{var c={};goog.array.forEach(goog.dom.classlist.get(a),function(a){c[a]=!0});goog.array.forEach(b,function(a){c[a]=!0});a.className="";for(var d in c)a.className+=0<a.className.length?" "+d:d}};
goog.dom.classlist.remove=function(a,b){goog.dom.classlist.ALWAYS_USE_DOM_TOKEN_LIST||a.classList?a.classList.remove(b):goog.dom.classlist.contains(a,b)&&(a.className=goog.array.filter(goog.dom.classlist.get(a),function(a){return a!=b}).join(" "))};
goog.dom.classlist.removeAll=function(a,b){goog.dom.classlist.ALWAYS_USE_DOM_TOKEN_LIST||a.classList?goog.array.forEach(b,function(b){goog.dom.classlist.remove(a,b)}):a.className=goog.array.filter(goog.dom.classlist.get(a),function(a){return!goog.array.contains(b,a)}).join(" ")};goog.dom.classlist.enable=function(a,b,c){c?goog.dom.classlist.add(a,b):goog.dom.classlist.remove(a,b)};goog.dom.classlist.enableAll=function(a,b,c){(c?goog.dom.classlist.addAll:goog.dom.classlist.removeAll)(a,b)};
goog.dom.classlist.swap=function(a,b,c){return goog.dom.classlist.contains(a,b)?(goog.dom.classlist.remove(a,b),goog.dom.classlist.add(a,c),!0):!1};goog.dom.classlist.toggle=function(a,b){var c=!goog.dom.classlist.contains(a,b);goog.dom.classlist.enable(a,b,c);return c};goog.dom.classlist.addRemove=function(a,b,c){goog.dom.classlist.remove(a,b);goog.dom.classlist.add(a,c)};goog.uri={};goog.uri.utils={};goog.uri.utils.CharCode_={AMPERSAND:38,EQUAL:61,HASH:35,QUESTION:63};goog.uri.utils.buildFromEncodedParts=function(a,b,c,d,e,f,g){var h="";a&&(h+=a+":");c&&(h+="//",b&&(h+=b+"@"),h+=c,d&&(h+=":"+d));e&&(h+=e);f&&(h+="?"+f);g&&(h+="#"+g);return h};goog.uri.utils.splitRe_=/^(?:([^:/?#.]+):)?(?:\/\/(?:([^/?#]*)@)?([^/#?]*?)(?::([0-9]+))?(?=[/#?]|$))?([^?#]+)?(?:\?([^#]*))?(?:#([\s\S]*))?$/;
goog.uri.utils.ComponentIndex={SCHEME:1,USER_INFO:2,DOMAIN:3,PORT:4,PATH:5,QUERY_DATA:6,FRAGMENT:7};goog.uri.utils.split=function(a){return a.match(goog.uri.utils.splitRe_)};goog.uri.utils.decodeIfPossible_=function(a,b){return a?b?decodeURI(a):decodeURIComponent(a):a};goog.uri.utils.getComponentByIndex_=function(a,b){return goog.uri.utils.split(b)[a]||null};goog.uri.utils.getScheme=function(a){return goog.uri.utils.getComponentByIndex_(goog.uri.utils.ComponentIndex.SCHEME,a)};
goog.uri.utils.getEffectiveScheme=function(a){a=goog.uri.utils.getScheme(a);!a&&goog.global.self&&goog.global.self.location&&(a=goog.global.self.location.protocol,a=a.substr(0,a.length-1));return a?a.toLowerCase():""};goog.uri.utils.getUserInfoEncoded=function(a){return goog.uri.utils.getComponentByIndex_(goog.uri.utils.ComponentIndex.USER_INFO,a)};goog.uri.utils.getUserInfo=function(a){return goog.uri.utils.decodeIfPossible_(goog.uri.utils.getUserInfoEncoded(a))};
goog.uri.utils.getDomainEncoded=function(a){return goog.uri.utils.getComponentByIndex_(goog.uri.utils.ComponentIndex.DOMAIN,a)};goog.uri.utils.getDomain=function(a){return goog.uri.utils.decodeIfPossible_(goog.uri.utils.getDomainEncoded(a),!0)};goog.uri.utils.getPort=function(a){return Number(goog.uri.utils.getComponentByIndex_(goog.uri.utils.ComponentIndex.PORT,a))||null};goog.uri.utils.getPathEncoded=function(a){return goog.uri.utils.getComponentByIndex_(goog.uri.utils.ComponentIndex.PATH,a)};
goog.uri.utils.getPath=function(a){return goog.uri.utils.decodeIfPossible_(goog.uri.utils.getPathEncoded(a),!0)};goog.uri.utils.getQueryData=function(a){return goog.uri.utils.getComponentByIndex_(goog.uri.utils.ComponentIndex.QUERY_DATA,a)};goog.uri.utils.getFragmentEncoded=function(a){var b=a.indexOf("#");return 0>b?null:a.substr(b+1)};goog.uri.utils.setFragmentEncoded=function(a,b){return goog.uri.utils.removeFragment(a)+(b?"#"+b:"")};goog.uri.utils.getFragment=function(a){return goog.uri.utils.decodeIfPossible_(goog.uri.utils.getFragmentEncoded(a))};
goog.uri.utils.getHost=function(a){a=goog.uri.utils.split(a);return goog.uri.utils.buildFromEncodedParts(a[goog.uri.utils.ComponentIndex.SCHEME],a[goog.uri.utils.ComponentIndex.USER_INFO],a[goog.uri.utils.ComponentIndex.DOMAIN],a[goog.uri.utils.ComponentIndex.PORT])};goog.uri.utils.getOrigin=function(a){a=goog.uri.utils.split(a);return goog.uri.utils.buildFromEncodedParts(a[goog.uri.utils.ComponentIndex.SCHEME],null,a[goog.uri.utils.ComponentIndex.DOMAIN],a[goog.uri.utils.ComponentIndex.PORT])};
goog.uri.utils.getPathAndAfter=function(a){a=goog.uri.utils.split(a);return goog.uri.utils.buildFromEncodedParts(null,null,null,null,a[goog.uri.utils.ComponentIndex.PATH],a[goog.uri.utils.ComponentIndex.QUERY_DATA],a[goog.uri.utils.ComponentIndex.FRAGMENT])};goog.uri.utils.removeFragment=function(a){var b=a.indexOf("#");return 0>b?a:a.substr(0,b)};
goog.uri.utils.haveSameDomain=function(a,b){var c=goog.uri.utils.split(a),d=goog.uri.utils.split(b);return c[goog.uri.utils.ComponentIndex.DOMAIN]==d[goog.uri.utils.ComponentIndex.DOMAIN]&&c[goog.uri.utils.ComponentIndex.SCHEME]==d[goog.uri.utils.ComponentIndex.SCHEME]&&c[goog.uri.utils.ComponentIndex.PORT]==d[goog.uri.utils.ComponentIndex.PORT]};
goog.uri.utils.assertNoFragmentsOrQueries_=function(a){goog.asserts.assert(0>a.indexOf("#")&&0>a.indexOf("?"),"goog.uri.utils: Fragment or query identifiers are not supported: [%s]",a)};goog.uri.utils.parseQueryData=function(a,b){if(a)for(var c=a.split("&"),d=0;d<c.length;d++){var e=c[d].indexOf("="),f,g=null;0<=e?(f=c[d].substring(0,e),g=c[d].substring(e+1)):f=c[d];b(f,g?goog.string.urlDecode(g):"")}};
goog.uri.utils.appendQueryData_=function(a){if(a[1]){var b=a[0],c=b.indexOf("#");0<=c&&(a.push(b.substr(c)),a[0]=b=b.substr(0,c));c=b.indexOf("?");0>c?a[1]="?":c==b.length-1&&(a[1]=void 0)}return a.join("")};goog.uri.utils.appendKeyValuePairs_=function(a,b,c){if(goog.isArray(b)){goog.asserts.assertArray(b);for(var d=0;d<b.length;d++)goog.uri.utils.appendKeyValuePairs_(a,String(b[d]),c)}else null!=b&&c.push("&",a,""===b?"":"=",goog.string.urlEncode(b))};
goog.uri.utils.buildQueryDataBuffer_=function(a,b,c){goog.asserts.assert(0==Math.max(b.length-(c||0),0)%2,"goog.uri.utils: Key/value lists must be even in length.");for(c=c||0;c<b.length;c+=2)goog.uri.utils.appendKeyValuePairs_(b[c],b[c+1],a);return a};goog.uri.utils.buildQueryData=function(a,b){var c=goog.uri.utils.buildQueryDataBuffer_([],a,b);c[0]="";return c.join("")};goog.uri.utils.buildQueryDataBufferFromMap_=function(a,b){for(var c in b)goog.uri.utils.appendKeyValuePairs_(c,b[c],a);return a};
goog.uri.utils.buildQueryDataFromMap=function(a){a=goog.uri.utils.buildQueryDataBufferFromMap_([],a);a[0]="";return a.join("")};goog.uri.utils.appendParams=function(a,b){return goog.uri.utils.appendQueryData_(2==arguments.length?goog.uri.utils.buildQueryDataBuffer_([a],arguments[1],0):goog.uri.utils.buildQueryDataBuffer_([a],arguments,1))};goog.uri.utils.appendParamsFromMap=function(a,b){return goog.uri.utils.appendQueryData_(goog.uri.utils.buildQueryDataBufferFromMap_([a],b))};
goog.uri.utils.appendParam=function(a,b,c){a=[a,"&",b];goog.isDefAndNotNull(c)&&a.push("=",goog.string.urlEncode(c));return goog.uri.utils.appendQueryData_(a)};
goog.uri.utils.findParam_=function(a,b,c,d){for(var e=c.length;0<=(b=a.indexOf(c,b))&&b<d;){var f=a.charCodeAt(b-1);if(f==goog.uri.utils.CharCode_.AMPERSAND||f==goog.uri.utils.CharCode_.QUESTION)if(f=a.charCodeAt(b+e),!f||f==goog.uri.utils.CharCode_.EQUAL||f==goog.uri.utils.CharCode_.AMPERSAND||f==goog.uri.utils.CharCode_.HASH)return b;b+=e+1}return-1};goog.uri.utils.hashOrEndRe_=/#|$/;goog.uri.utils.hasParam=function(a,b){return 0<=goog.uri.utils.findParam_(a,0,b,a.search(goog.uri.utils.hashOrEndRe_))};
goog.uri.utils.getParamValue=function(a,b){var c=a.search(goog.uri.utils.hashOrEndRe_),d=goog.uri.utils.findParam_(a,0,b,c);if(0>d)return null;var e=a.indexOf("&",d);if(0>e||e>c)e=c;d+=b.length+1;return goog.string.urlDecode(a.substr(d,e-d))};goog.uri.utils.getParamValues=function(a,b){for(var c=a.search(goog.uri.utils.hashOrEndRe_),d=0,e,f=[];0<=(e=goog.uri.utils.findParam_(a,d,b,c));){d=a.indexOf("&",e);if(0>d||d>c)d=c;e+=b.length+1;f.push(goog.string.urlDecode(a.substr(e,d-e)))}return f};
goog.uri.utils.trailingQueryPunctuationRe_=/[?&]($|#)/;goog.uri.utils.removeParam=function(a,b){for(var c=a.search(goog.uri.utils.hashOrEndRe_),d=0,e,f=[];0<=(e=goog.uri.utils.findParam_(a,d,b,c));)f.push(a.substring(d,e)),d=Math.min(a.indexOf("&",e)+1||c,c);f.push(a.substr(d));return f.join("").replace(goog.uri.utils.trailingQueryPunctuationRe_,"$1")};goog.uri.utils.setParam=function(a,b,c){return goog.uri.utils.appendParam(goog.uri.utils.removeParam(a,b),b,c)};
goog.uri.utils.appendPath=function(a,b){goog.uri.utils.assertNoFragmentsOrQueries_(a);goog.string.endsWith(a,"/")&&(a=a.substr(0,a.length-1));goog.string.startsWith(b,"/")&&(b=b.substr(1));return goog.string.buildString(a,"/",b)};
goog.uri.utils.setPath=function(a,b){goog.string.startsWith(b,"/")||(b="/"+b);var c=goog.uri.utils.split(a);return goog.uri.utils.buildFromEncodedParts(c[goog.uri.utils.ComponentIndex.SCHEME],c[goog.uri.utils.ComponentIndex.USER_INFO],c[goog.uri.utils.ComponentIndex.DOMAIN],c[goog.uri.utils.ComponentIndex.PORT],b,c[goog.uri.utils.ComponentIndex.QUERY_DATA],c[goog.uri.utils.ComponentIndex.FRAGMENT])};goog.uri.utils.StandardQueryParam={RANDOM:"zx"};
goog.uri.utils.makeUnique=function(a){return goog.uri.utils.setParam(a,goog.uri.utils.StandardQueryParam.RANDOM,goog.string.getRandomString())};goog.Uri=function(a,b){this.domain_=this.userInfo_=this.scheme_="";this.port_=null;this.fragment_=this.path_="";this.ignoreCase_=this.isReadOnly_=!1;var c;a instanceof goog.Uri?(this.ignoreCase_=goog.isDef(b)?b:a.getIgnoreCase(),this.setScheme(a.getScheme()),this.setUserInfo(a.getUserInfo()),this.setDomain(a.getDomain()),this.setPort(a.getPort()),this.setPath(a.getPath()),this.setQueryData(a.getQueryData().clone()),this.setFragment(a.getFragment())):a&&(c=goog.uri.utils.split(String(a)))?(this.ignoreCase_=
!!b,this.setScheme(c[goog.uri.utils.ComponentIndex.SCHEME]||"",!0),this.setUserInfo(c[goog.uri.utils.ComponentIndex.USER_INFO]||"",!0),this.setDomain(c[goog.uri.utils.ComponentIndex.DOMAIN]||"",!0),this.setPort(c[goog.uri.utils.ComponentIndex.PORT]),this.setPath(c[goog.uri.utils.ComponentIndex.PATH]||"",!0),this.setQueryData(c[goog.uri.utils.ComponentIndex.QUERY_DATA]||"",!0),this.setFragment(c[goog.uri.utils.ComponentIndex.FRAGMENT]||"",!0)):(this.ignoreCase_=!!b,this.queryData_=new goog.Uri.QueryData(null,
null,this.ignoreCase_))};goog.Uri.preserveParameterTypesCompatibilityFlag=!1;goog.Uri.RANDOM_PARAM=goog.uri.utils.StandardQueryParam.RANDOM;
goog.Uri.prototype.toString=function(){var a=[],b=this.getScheme();b&&a.push(goog.Uri.encodeSpecialChars_(b,goog.Uri.reDisallowedInSchemeOrUserInfo_,!0),":");var c=this.getDomain();if(c||"file"==b)a.push("//"),(b=this.getUserInfo())&&a.push(goog.Uri.encodeSpecialChars_(b,goog.Uri.reDisallowedInSchemeOrUserInfo_,!0),"@"),a.push(goog.Uri.removeDoubleEncoding_(goog.string.urlEncode(c))),c=this.getPort(),null!=c&&a.push(":",String(c));if(c=this.getPath())this.hasDomain()&&"/"!=c.charAt(0)&&a.push("/"),
a.push(goog.Uri.encodeSpecialChars_(c,"/"==c.charAt(0)?goog.Uri.reDisallowedInAbsolutePath_:goog.Uri.reDisallowedInRelativePath_,!0));(c=this.getEncodedQuery())&&a.push("?",c);(c=this.getFragment())&&a.push("#",goog.Uri.encodeSpecialChars_(c,goog.Uri.reDisallowedInFragment_));return a.join("")};
goog.Uri.prototype.resolve=function(a){var b=this.clone(),c=a.hasScheme();c?b.setScheme(a.getScheme()):c=a.hasUserInfo();c?b.setUserInfo(a.getUserInfo()):c=a.hasDomain();c?b.setDomain(a.getDomain()):c=a.hasPort();var d=a.getPath();if(c)b.setPort(a.getPort());else if(c=a.hasPath()){if("/"!=d.charAt(0))if(this.hasDomain()&&!this.hasPath())d="/"+d;else{var e=b.getPath().lastIndexOf("/");-1!=e&&(d=b.getPath().substr(0,e+1)+d)}d=goog.Uri.removeDotSegments(d)}c?b.setPath(d):c=a.hasQuery();c?b.setQueryData(a.getQueryData().clone()):
c=a.hasFragment();c&&b.setFragment(a.getFragment());return b};goog.Uri.prototype.clone=function(){return new goog.Uri(this)};goog.Uri.prototype.getScheme=function(){return this.scheme_};goog.Uri.prototype.setScheme=function(a,b){this.enforceReadOnly();if(this.scheme_=b?goog.Uri.decodeOrEmpty_(a,!0):a)this.scheme_=this.scheme_.replace(/:$/,"");return this};goog.Uri.prototype.hasScheme=function(){return!!this.scheme_};goog.Uri.prototype.getUserInfo=function(){return this.userInfo_};
goog.Uri.prototype.setUserInfo=function(a,b){this.enforceReadOnly();this.userInfo_=b?goog.Uri.decodeOrEmpty_(a):a;return this};goog.Uri.prototype.hasUserInfo=function(){return!!this.userInfo_};goog.Uri.prototype.getDomain=function(){return this.domain_};goog.Uri.prototype.setDomain=function(a,b){this.enforceReadOnly();this.domain_=b?goog.Uri.decodeOrEmpty_(a,!0):a;return this};goog.Uri.prototype.hasDomain=function(){return!!this.domain_};goog.Uri.prototype.getPort=function(){return this.port_};
goog.Uri.prototype.setPort=function(a){this.enforceReadOnly();if(a){a=Number(a);if(isNaN(a)||0>a)throw Error("Bad port number "+a);this.port_=a}else this.port_=null;return this};goog.Uri.prototype.hasPort=function(){return null!=this.port_};goog.Uri.prototype.getPath=function(){return this.path_};goog.Uri.prototype.setPath=function(a,b){this.enforceReadOnly();this.path_=b?goog.Uri.decodeOrEmpty_(a,!0):a;return this};goog.Uri.prototype.hasPath=function(){return!!this.path_};
goog.Uri.prototype.hasQuery=function(){return""!==this.queryData_.toString()};goog.Uri.prototype.setQueryData=function(a,b){this.enforceReadOnly();a instanceof goog.Uri.QueryData?(this.queryData_=a,this.queryData_.setIgnoreCase(this.ignoreCase_)):(b||(a=goog.Uri.encodeSpecialChars_(a,goog.Uri.reDisallowedInQuery_)),this.queryData_=new goog.Uri.QueryData(a,null,this.ignoreCase_));return this};goog.Uri.prototype.setQuery=function(a,b){return this.setQueryData(a,b)};
goog.Uri.prototype.getEncodedQuery=function(){return this.queryData_.toString()};goog.Uri.prototype.getDecodedQuery=function(){return this.queryData_.toDecodedString()};goog.Uri.prototype.getQueryData=function(){return this.queryData_};goog.Uri.prototype.getQuery=function(){return this.getEncodedQuery()};goog.Uri.prototype.setParameterValue=function(a,b){this.enforceReadOnly();this.queryData_.set(a,b);return this};
goog.Uri.prototype.setParameterValues=function(a,b){this.enforceReadOnly();goog.isArray(b)||(b=[String(b)]);this.queryData_.setValues(a,b);return this};goog.Uri.prototype.getParameterValues=function(a){return this.queryData_.getValues(a)};goog.Uri.prototype.getParameterValue=function(a){return this.queryData_.get(a)};goog.Uri.prototype.getFragment=function(){return this.fragment_};goog.Uri.prototype.setFragment=function(a,b){this.enforceReadOnly();this.fragment_=b?goog.Uri.decodeOrEmpty_(a):a;return this};
goog.Uri.prototype.hasFragment=function(){return!!this.fragment_};goog.Uri.prototype.hasSameDomainAs=function(a){return(!this.hasDomain()&&!a.hasDomain()||this.getDomain()==a.getDomain())&&(!this.hasPort()&&!a.hasPort()||this.getPort()==a.getPort())};goog.Uri.prototype.makeUnique=function(){this.enforceReadOnly();this.setParameterValue(goog.Uri.RANDOM_PARAM,goog.string.getRandomString());return this};goog.Uri.prototype.removeParameter=function(a){this.enforceReadOnly();this.queryData_.remove(a);return this};
goog.Uri.prototype.setReadOnly=function(a){this.isReadOnly_=a;return this};goog.Uri.prototype.isReadOnly=function(){return this.isReadOnly_};goog.Uri.prototype.enforceReadOnly=function(){if(this.isReadOnly_)throw Error("Tried to modify a read-only Uri");};goog.Uri.prototype.setIgnoreCase=function(a){this.ignoreCase_=a;this.queryData_&&this.queryData_.setIgnoreCase(a);return this};goog.Uri.prototype.getIgnoreCase=function(){return this.ignoreCase_};
goog.Uri.parse=function(a,b){return a instanceof goog.Uri?a.clone():new goog.Uri(a,b)};goog.Uri.create=function(a,b,c,d,e,f,g,h){h=new goog.Uri(null,h);a&&h.setScheme(a);b&&h.setUserInfo(b);c&&h.setDomain(c);d&&h.setPort(d);e&&h.setPath(e);f&&h.setQueryData(f);g&&h.setFragment(g);return h};goog.Uri.resolve=function(a,b){a instanceof goog.Uri||(a=goog.Uri.parse(a));b instanceof goog.Uri||(b=goog.Uri.parse(b));return a.resolve(b)};
goog.Uri.removeDotSegments=function(a){if(".."==a||"."==a)return"";if(goog.string.contains(a,"./")||goog.string.contains(a,"/.")){var b=goog.string.startsWith(a,"/");a=a.split("/");for(var c=[],d=0;d<a.length;){var e=a[d++];"."==e?b&&d==a.length&&c.push(""):".."==e?((1<c.length||1==c.length&&""!=c[0])&&c.pop(),b&&d==a.length&&c.push("")):(c.push(e),b=!0)}return c.join("/")}return a};goog.Uri.decodeOrEmpty_=function(a,b){return a?b?decodeURI(a.replace(/%25/g,"%2525")):decodeURIComponent(a):""};
goog.Uri.encodeSpecialChars_=function(a,b,c){return goog.isString(a)?(a=encodeURI(a).replace(b,goog.Uri.encodeChar_),c&&(a=goog.Uri.removeDoubleEncoding_(a)),a):null};goog.Uri.encodeChar_=function(a){a=a.charCodeAt(0);return"%"+(a>>4&15).toString(16)+(a&15).toString(16)};goog.Uri.removeDoubleEncoding_=function(a){return a.replace(/%25([0-9a-fA-F]{2})/g,"%$1")};goog.Uri.reDisallowedInSchemeOrUserInfo_=/[#\/\?@]/g;goog.Uri.reDisallowedInRelativePath_=/[\#\?:]/g;
goog.Uri.reDisallowedInAbsolutePath_=/[\#\?]/g;goog.Uri.reDisallowedInQuery_=/[\#\?@]/g;goog.Uri.reDisallowedInFragment_=/#/g;goog.Uri.haveSameDomain=function(a,b){var c=goog.uri.utils.split(a),d=goog.uri.utils.split(b);return c[goog.uri.utils.ComponentIndex.DOMAIN]==d[goog.uri.utils.ComponentIndex.DOMAIN]&&c[goog.uri.utils.ComponentIndex.PORT]==d[goog.uri.utils.ComponentIndex.PORT]};goog.Uri.QueryData=function(a,b,c){this.count_=this.keyMap_=null;this.encodedQuery_=a||null;this.ignoreCase_=!!c};
goog.Uri.QueryData.prototype.ensureKeyMapInitialized_=function(){if(!this.keyMap_&&(this.keyMap_=new goog.structs.Map,this.count_=0,this.encodedQuery_)){var a=this;goog.uri.utils.parseQueryData(this.encodedQuery_,function(b,c){a.add(goog.string.urlDecode(b),c)})}};
goog.Uri.QueryData.createFromMap=function(a,b,c){b=goog.structs.getKeys(a);if("undefined"==typeof b)throw Error("Keys are undefined");c=new goog.Uri.QueryData(null,null,c);a=goog.structs.getValues(a);for(var d=0;d<b.length;d++){var e=b[d],f=a[d];goog.isArray(f)?c.setValues(e,f):c.add(e,f)}return c};
goog.Uri.QueryData.createFromKeysValues=function(a,b,c,d){if(a.length!=b.length)throw Error("Mismatched lengths for keys/values");c=new goog.Uri.QueryData(null,null,d);for(d=0;d<a.length;d++)c.add(a[d],b[d]);return c};goog.Uri.QueryData.prototype.getCount=function(){this.ensureKeyMapInitialized_();return this.count_};
goog.Uri.QueryData.prototype.add=function(a,b){this.ensureKeyMapInitialized_();this.invalidateCache_();a=this.getKeyName_(a);var c=this.keyMap_.get(a);c||this.keyMap_.set(a,c=[]);c.push(b);this.count_=goog.asserts.assertNumber(this.count_)+1;return this};
goog.Uri.QueryData.prototype.remove=function(a){this.ensureKeyMapInitialized_();a=this.getKeyName_(a);return this.keyMap_.containsKey(a)?(this.invalidateCache_(),this.count_=goog.asserts.assertNumber(this.count_)-this.keyMap_.get(a).length,this.keyMap_.remove(a)):!1};goog.Uri.QueryData.prototype.clear=function(){this.invalidateCache_();this.keyMap_=null;this.count_=0};goog.Uri.QueryData.prototype.isEmpty=function(){this.ensureKeyMapInitialized_();return 0==this.count_};
goog.Uri.QueryData.prototype.containsKey=function(a){this.ensureKeyMapInitialized_();a=this.getKeyName_(a);return this.keyMap_.containsKey(a)};goog.Uri.QueryData.prototype.containsValue=function(a){var b=this.getValues();return goog.array.contains(b,a)};goog.Uri.QueryData.prototype.getKeys=function(){this.ensureKeyMapInitialized_();for(var a=this.keyMap_.getValues(),b=this.keyMap_.getKeys(),c=[],d=0;d<b.length;d++)for(var e=a[d],f=0;f<e.length;f++)c.push(b[d]);return c};
goog.Uri.QueryData.prototype.getValues=function(a){this.ensureKeyMapInitialized_();var b=[];if(goog.isString(a))this.containsKey(a)&&(b=goog.array.concat(b,this.keyMap_.get(this.getKeyName_(a))));else{a=this.keyMap_.getValues();for(var c=0;c<a.length;c++)b=goog.array.concat(b,a[c])}return b};
goog.Uri.QueryData.prototype.set=function(a,b){this.ensureKeyMapInitialized_();this.invalidateCache_();a=this.getKeyName_(a);this.containsKey(a)&&(this.count_=goog.asserts.assertNumber(this.count_)-this.keyMap_.get(a).length);this.keyMap_.set(a,[b]);this.count_=goog.asserts.assertNumber(this.count_)+1;return this};goog.Uri.QueryData.prototype.get=function(a,b){var c=a?this.getValues(a):[];return goog.Uri.preserveParameterTypesCompatibilityFlag?0<c.length?c[0]:b:0<c.length?String(c[0]):b};
goog.Uri.QueryData.prototype.setValues=function(a,b){this.remove(a);0<b.length&&(this.invalidateCache_(),this.keyMap_.set(this.getKeyName_(a),goog.array.clone(b)),this.count_=goog.asserts.assertNumber(this.count_)+b.length)};
goog.Uri.QueryData.prototype.toString=function(){if(this.encodedQuery_)return this.encodedQuery_;if(!this.keyMap_)return"";for(var a=[],b=this.keyMap_.getKeys(),c=0;c<b.length;c++)for(var d=b[c],e=goog.string.urlEncode(d),d=this.getValues(d),f=0;f<d.length;f++){var g=e;""!==d[f]&&(g+="="+goog.string.urlEncode(d[f]));a.push(g)}return this.encodedQuery_=a.join("&")};goog.Uri.QueryData.prototype.toDecodedString=function(){return goog.Uri.decodeOrEmpty_(this.toString())};
goog.Uri.QueryData.prototype.invalidateCache_=function(){this.encodedQuery_=null};goog.Uri.QueryData.prototype.filterKeys=function(a){this.ensureKeyMapInitialized_();this.keyMap_.forEach(function(b,c){goog.array.contains(a,c)||this.remove(c)},this);return this};goog.Uri.QueryData.prototype.clone=function(){var a=new goog.Uri.QueryData;a.encodedQuery_=this.encodedQuery_;this.keyMap_&&(a.keyMap_=this.keyMap_.clone(),a.count_=this.count_);return a};
goog.Uri.QueryData.prototype.getKeyName_=function(a){a=String(a);this.ignoreCase_&&(a=a.toLowerCase());return a};goog.Uri.QueryData.prototype.setIgnoreCase=function(a){a&&!this.ignoreCase_&&(this.ensureKeyMapInitialized_(),this.invalidateCache_(),this.keyMap_.forEach(function(a,c){var b=c.toLowerCase();c!=b&&(this.remove(c),this.setValues(b,a))},this));this.ignoreCase_=a};
goog.Uri.QueryData.prototype.extend=function(a){for(var b=0;b<arguments.length;b++)goog.structs.forEach(arguments[b],function(a,b){this.add(b,a)},this)};goog.net={};goog.net.ErrorCode={NO_ERROR:0,ACCESS_DENIED:1,FILE_NOT_FOUND:2,FF_SILENT_ERROR:3,CUSTOM_ERROR:4,EXCEPTION:5,HTTP_ERROR:6,ABORT:7,TIMEOUT:8,OFFLINE:9};
goog.net.ErrorCode.getDebugMessage=function(a){switch(a){case goog.net.ErrorCode.NO_ERROR:return"No Error";case goog.net.ErrorCode.ACCESS_DENIED:return"Access denied to content document";case goog.net.ErrorCode.FILE_NOT_FOUND:return"File not found";case goog.net.ErrorCode.FF_SILENT_ERROR:return"Firefox silently errored";case goog.net.ErrorCode.CUSTOM_ERROR:return"Application custom error";case goog.net.ErrorCode.EXCEPTION:return"An exception occurred";case goog.net.ErrorCode.HTTP_ERROR:return"Http response at 400 or 500 level";
case goog.net.ErrorCode.ABORT:return"Request was aborted";case goog.net.ErrorCode.TIMEOUT:return"Request timed out";case goog.net.ErrorCode.OFFLINE:return"The resource is not available offline";default:return"Unrecognized error code"}};goog.net.EventType={COMPLETE:"complete",SUCCESS:"success",ERROR:"error",ABORT:"abort",READY:"ready",READY_STATE_CHANGE:"readystatechange",TIMEOUT:"timeout",INCREMENTAL_DATA:"incrementaldata",PROGRESS:"progress",DOWNLOAD_PROGRESS:"downloadprogress",UPLOAD_PROGRESS:"uploadprogress"};goog.net.HttpStatus={CONTINUE:100,SWITCHING_PROTOCOLS:101,OK:200,CREATED:201,ACCEPTED:202,NON_AUTHORITATIVE_INFORMATION:203,NO_CONTENT:204,RESET_CONTENT:205,PARTIAL_CONTENT:206,MULTIPLE_CHOICES:300,MOVED_PERMANENTLY:301,FOUND:302,SEE_OTHER:303,NOT_MODIFIED:304,USE_PROXY:305,TEMPORARY_REDIRECT:307,BAD_REQUEST:400,UNAUTHORIZED:401,PAYMENT_REQUIRED:402,FORBIDDEN:403,NOT_FOUND:404,METHOD_NOT_ALLOWED:405,NOT_ACCEPTABLE:406,PROXY_AUTHENTICATION_REQUIRED:407,REQUEST_TIMEOUT:408,CONFLICT:409,GONE:410,LENGTH_REQUIRED:411,
PRECONDITION_FAILED:412,REQUEST_ENTITY_TOO_LARGE:413,REQUEST_URI_TOO_LONG:414,UNSUPPORTED_MEDIA_TYPE:415,REQUEST_RANGE_NOT_SATISFIABLE:416,EXPECTATION_FAILED:417,PRECONDITION_REQUIRED:428,TOO_MANY_REQUESTS:429,REQUEST_HEADER_FIELDS_TOO_LARGE:431,INTERNAL_SERVER_ERROR:500,NOT_IMPLEMENTED:501,BAD_GATEWAY:502,SERVICE_UNAVAILABLE:503,GATEWAY_TIMEOUT:504,HTTP_VERSION_NOT_SUPPORTED:505,NETWORK_AUTHENTICATION_REQUIRED:511,QUIRK_IE_NO_CONTENT:1223};
goog.net.HttpStatus.isSuccess=function(a){switch(a){case goog.net.HttpStatus.OK:case goog.net.HttpStatus.CREATED:case goog.net.HttpStatus.ACCEPTED:case goog.net.HttpStatus.NO_CONTENT:case goog.net.HttpStatus.PARTIAL_CONTENT:case goog.net.HttpStatus.NOT_MODIFIED:case goog.net.HttpStatus.QUIRK_IE_NO_CONTENT:return!0;default:return!1}};goog.net.XhrLike=function(){};goog.net.XhrLike.prototype.open=function(a,b,c,d,e){};goog.net.XhrLike.prototype.send=function(a){};goog.net.XhrLike.prototype.abort=function(){};goog.net.XhrLike.prototype.setRequestHeader=function(a,b){};goog.net.XhrLike.prototype.getResponseHeader=function(a){};goog.net.XhrLike.prototype.getAllResponseHeaders=function(){};goog.net.XmlHttpFactory=function(){};goog.net.XmlHttpFactory.prototype.cachedOptions_=null;goog.net.XmlHttpFactory.prototype.createInstance=goog.abstractMethod;goog.net.XmlHttpFactory.prototype.getOptions=function(){return this.cachedOptions_||(this.cachedOptions_=this.internalGetOptions())};goog.net.XmlHttpFactory.prototype.internalGetOptions=goog.abstractMethod;goog.net.WrapperXmlHttpFactory=function(a,b){goog.net.XmlHttpFactory.call(this);this.xhrFactory_=a;this.optionsFactory_=b};goog.inherits(goog.net.WrapperXmlHttpFactory,goog.net.XmlHttpFactory);goog.net.WrapperXmlHttpFactory.prototype.createInstance=function(){return this.xhrFactory_()};goog.net.WrapperXmlHttpFactory.prototype.getOptions=function(){return this.optionsFactory_()};goog.net.XmlHttp=function(){return goog.net.XmlHttp.factory_.createInstance()};goog.net.XmlHttp.ASSUME_NATIVE_XHR=!1;goog.net.XmlHttpDefines={};goog.net.XmlHttpDefines.ASSUME_NATIVE_XHR=!1;goog.net.XmlHttp.getOptions=function(){return goog.net.XmlHttp.factory_.getOptions()};goog.net.XmlHttp.OptionType={USE_NULL_FUNCTION:0,LOCAL_REQUEST_ERROR:1};goog.net.XmlHttp.ReadyState={UNINITIALIZED:0,LOADING:1,LOADED:2,INTERACTIVE:3,COMPLETE:4};
goog.net.XmlHttp.setFactory=function(a,b){goog.net.XmlHttp.setGlobalFactory(new goog.net.WrapperXmlHttpFactory(goog.asserts.assert(a),goog.asserts.assert(b)))};goog.net.XmlHttp.setGlobalFactory=function(a){goog.net.XmlHttp.factory_=a};goog.net.DefaultXmlHttpFactory=function(){goog.net.XmlHttpFactory.call(this)};goog.inherits(goog.net.DefaultXmlHttpFactory,goog.net.XmlHttpFactory);
goog.net.DefaultXmlHttpFactory.prototype.createInstance=function(){var a=this.getProgId_();return a?new ActiveXObject(a):new XMLHttpRequest};goog.net.DefaultXmlHttpFactory.prototype.internalGetOptions=function(){var a={};this.getProgId_()&&(a[goog.net.XmlHttp.OptionType.USE_NULL_FUNCTION]=!0,a[goog.net.XmlHttp.OptionType.LOCAL_REQUEST_ERROR]=!0);return a};
goog.net.DefaultXmlHttpFactory.prototype.getProgId_=function(){if(goog.net.XmlHttp.ASSUME_NATIVE_XHR||goog.net.XmlHttpDefines.ASSUME_NATIVE_XHR)return"";if(!this.ieProgId_&&"undefined"==typeof XMLHttpRequest&&"undefined"!=typeof ActiveXObject){for(var a=["MSXML2.XMLHTTP.6.0","MSXML2.XMLHTTP.3.0","MSXML2.XMLHTTP","Microsoft.XMLHTTP"],b=0;b<a.length;b++){var c=a[b];try{return new ActiveXObject(c),this.ieProgId_=c}catch(d){}}throw Error("Could not create ActiveXObject. ActiveX might be disabled, or MSXML might not be installed");
}return this.ieProgId_};goog.net.XmlHttp.setGlobalFactory(new goog.net.DefaultXmlHttpFactory);goog.net.XhrIo=function(a){goog.events.EventTarget.call(this);this.headers=new goog.structs.Map;this.xmlHttpFactory_=a||null;this.active_=!1;this.xhrOptions_=this.xhr_=null;this.lastMethod_=this.lastUri_="";this.lastErrorCode_=goog.net.ErrorCode.NO_ERROR;this.lastError_="";this.inAbort_=this.inOpen_=this.inSend_=this.errorDispatched_=!1;this.timeoutInterval_=0;this.timeoutId_=null;this.responseType_=goog.net.XhrIo.ResponseType.DEFAULT;this.useXhr2Timeout_=this.progressEventsEnabled_=this.withCredentials_=
!1};goog.inherits(goog.net.XhrIo,goog.events.EventTarget);goog.net.XhrIo.ResponseType={DEFAULT:"",TEXT:"text",DOCUMENT:"document",BLOB:"blob",ARRAY_BUFFER:"arraybuffer"};goog.net.XhrIo.prototype.logger_=goog.log.getLogger("goog.net.XhrIo");goog.net.XhrIo.CONTENT_TYPE_HEADER="Content-Type";goog.net.XhrIo.CONTENT_TRANSFER_ENCODING="Content-Transfer-Encoding";goog.net.XhrIo.HTTP_SCHEME_PATTERN=/^https?$/i;goog.net.XhrIo.METHODS_WITH_FORM_DATA=["POST","PUT"];goog.net.XhrIo.FORM_CONTENT_TYPE="application/x-www-form-urlencoded;charset=utf-8";
goog.net.XhrIo.XHR2_TIMEOUT_="timeout";goog.net.XhrIo.XHR2_ON_TIMEOUT_="ontimeout";goog.net.XhrIo.sendInstances_=[];goog.net.XhrIo.send=function(a,b,c,d,e,f,g){var h=new goog.net.XhrIo;goog.net.XhrIo.sendInstances_.push(h);b&&h.listen(goog.net.EventType.COMPLETE,b);h.listenOnce(goog.net.EventType.READY,h.cleanupSend_);f&&h.setTimeoutInterval(f);g&&h.setWithCredentials(g);h.send(a,c,d,e);return h};goog.net.XhrIo.cleanup=function(){for(var a=goog.net.XhrIo.sendInstances_;a.length;)a.pop().dispose()};
goog.net.XhrIo.protectEntryPoints=function(a){goog.net.XhrIo.prototype.onReadyStateChangeEntryPoint_=a.protectEntryPoint(goog.net.XhrIo.prototype.onReadyStateChangeEntryPoint_)};goog.net.XhrIo.prototype.cleanupSend_=function(){this.dispose();goog.array.remove(goog.net.XhrIo.sendInstances_,this)};goog.net.XhrIo.prototype.getTimeoutInterval=function(){return this.timeoutInterval_};goog.net.XhrIo.prototype.setTimeoutInterval=function(a){this.timeoutInterval_=Math.max(0,a)};
goog.net.XhrIo.prototype.setResponseType=function(a){this.responseType_=a};goog.net.XhrIo.prototype.getResponseType=function(){return this.responseType_};goog.net.XhrIo.prototype.setWithCredentials=function(a){this.withCredentials_=a};goog.net.XhrIo.prototype.getWithCredentials=function(){return this.withCredentials_};goog.net.XhrIo.prototype.setProgressEventsEnabled=function(a){this.progressEventsEnabled_=a};goog.net.XhrIo.prototype.getProgressEventsEnabled=function(){return this.progressEventsEnabled_};
goog.net.XhrIo.prototype.send=function(a,b,c,d){if(this.xhr_)throw Error("[goog.net.XhrIo] Object is active with another request="+this.lastUri_+"; newUri="+a);b=b?b.toUpperCase():"GET";this.lastUri_=a;this.lastError_="";this.lastErrorCode_=goog.net.ErrorCode.NO_ERROR;this.lastMethod_=b;this.errorDispatched_=!1;this.active_=!0;this.xhr_=this.createXhr();this.xhrOptions_=this.xmlHttpFactory_?this.xmlHttpFactory_.getOptions():goog.net.XmlHttp.getOptions();this.xhr_.onreadystatechange=goog.bind(this.onReadyStateChange_,
this);this.getProgressEventsEnabled()&&"onprogress"in this.xhr_&&(this.xhr_.onprogress=goog.bind(function(a){this.onProgressHandler_(a,!0)},this),this.xhr_.upload&&(this.xhr_.upload.onprogress=goog.bind(this.onProgressHandler_,this)));try{goog.log.fine(this.logger_,this.formatMsg_("Opening Xhr")),this.inOpen_=!0,this.xhr_.open(b,String(a),!0),this.inOpen_=!1}catch(f){goog.log.fine(this.logger_,this.formatMsg_("Error opening Xhr: "+f.message));this.error_(goog.net.ErrorCode.EXCEPTION,f);return}a=c||
"";var e=this.headers.clone();d&&goog.structs.forEach(d,function(a,b){e.set(b,a)});d=goog.array.find(e.getKeys(),goog.net.XhrIo.isContentTypeHeader_);c=goog.global.FormData&&a instanceof goog.global.FormData;!goog.array.contains(goog.net.XhrIo.METHODS_WITH_FORM_DATA,b)||d||c||e.set(goog.net.XhrIo.CONTENT_TYPE_HEADER,goog.net.XhrIo.FORM_CONTENT_TYPE);e.forEach(function(a,b){this.xhr_.setRequestHeader(b,a)},this);this.responseType_&&(this.xhr_.responseType=this.responseType_);"withCredentials"in this.xhr_&&
this.xhr_.withCredentials!==this.withCredentials_&&(this.xhr_.withCredentials=this.withCredentials_);try{this.cleanUpTimeoutTimer_(),0<this.timeoutInterval_&&(this.useXhr2Timeout_=goog.net.XhrIo.shouldUseXhr2Timeout_(this.xhr_),goog.log.fine(this.logger_,this.formatMsg_("Will abort after "+this.timeoutInterval_+"ms if incomplete, xhr2 "+this.useXhr2Timeout_)),this.useXhr2Timeout_?(this.xhr_[goog.net.XhrIo.XHR2_TIMEOUT_]=this.timeoutInterval_,this.xhr_[goog.net.XhrIo.XHR2_ON_TIMEOUT_]=goog.bind(this.timeout_,
this)):this.timeoutId_=goog.Timer.callOnce(this.timeout_,this.timeoutInterval_,this)),goog.log.fine(this.logger_,this.formatMsg_("Sending request")),this.inSend_=!0,this.xhr_.send(a),this.inSend_=!1}catch(f){goog.log.fine(this.logger_,this.formatMsg_("Send error: "+f.message)),this.error_(goog.net.ErrorCode.EXCEPTION,f)}};goog.net.XhrIo.shouldUseXhr2Timeout_=function(a){return goog.userAgent.IE&&goog.userAgent.isVersionOrHigher(9)&&goog.isNumber(a[goog.net.XhrIo.XHR2_TIMEOUT_])&&goog.isDef(a[goog.net.XhrIo.XHR2_ON_TIMEOUT_])};
goog.net.XhrIo.isContentTypeHeader_=function(a){return goog.string.caseInsensitiveEquals(goog.net.XhrIo.CONTENT_TYPE_HEADER,a)};goog.net.XhrIo.prototype.createXhr=function(){return this.xmlHttpFactory_?this.xmlHttpFactory_.createInstance():goog.net.XmlHttp()};
goog.net.XhrIo.prototype.timeout_=function(){"undefined"!=typeof goog&&this.xhr_&&(this.lastError_="Timed out after "+this.timeoutInterval_+"ms, aborting",this.lastErrorCode_=goog.net.ErrorCode.TIMEOUT,goog.log.fine(this.logger_,this.formatMsg_(this.lastError_)),this.dispatchEvent(goog.net.EventType.TIMEOUT),this.abort(goog.net.ErrorCode.TIMEOUT))};
goog.net.XhrIo.prototype.error_=function(a,b){this.active_=!1;this.xhr_&&(this.inAbort_=!0,this.xhr_.abort(),this.inAbort_=!1);this.lastError_=b;this.lastErrorCode_=a;this.dispatchErrors_();this.cleanUpXhr_()};goog.net.XhrIo.prototype.dispatchErrors_=function(){this.errorDispatched_||(this.errorDispatched_=!0,this.dispatchEvent(goog.net.EventType.COMPLETE),this.dispatchEvent(goog.net.EventType.ERROR))};
goog.net.XhrIo.prototype.abort=function(a){this.xhr_&&this.active_&&(goog.log.fine(this.logger_,this.formatMsg_("Aborting")),this.active_=!1,this.inAbort_=!0,this.xhr_.abort(),this.inAbort_=!1,this.lastErrorCode_=a||goog.net.ErrorCode.ABORT,this.dispatchEvent(goog.net.EventType.COMPLETE),this.dispatchEvent(goog.net.EventType.ABORT),this.cleanUpXhr_())};
goog.net.XhrIo.prototype.disposeInternal=function(){this.xhr_&&(this.active_&&(this.active_=!1,this.inAbort_=!0,this.xhr_.abort(),this.inAbort_=!1),this.cleanUpXhr_(!0));goog.net.XhrIo.superClass_.disposeInternal.call(this)};goog.net.XhrIo.prototype.onReadyStateChange_=function(){if(!this.isDisposed())if(this.inOpen_||this.inSend_||this.inAbort_)this.onReadyStateChangeHelper_();else this.onReadyStateChangeEntryPoint_()};goog.net.XhrIo.prototype.onReadyStateChangeEntryPoint_=function(){this.onReadyStateChangeHelper_()};
goog.net.XhrIo.prototype.onReadyStateChangeHelper_=function(){if(this.active_&&"undefined"!=typeof goog)if(this.xhrOptions_[goog.net.XmlHttp.OptionType.LOCAL_REQUEST_ERROR]&&this.getReadyState()==goog.net.XmlHttp.ReadyState.COMPLETE&&2==this.getStatus())goog.log.fine(this.logger_,this.formatMsg_("Local request error detected and ignored"));else if(this.inSend_&&this.getReadyState()==goog.net.XmlHttp.ReadyState.COMPLETE)goog.Timer.callOnce(this.onReadyStateChange_,0,this);else if(this.dispatchEvent(goog.net.EventType.READY_STATE_CHANGE),
this.isComplete()){goog.log.fine(this.logger_,this.formatMsg_("Request complete"));this.active_=!1;try{this.isSuccess()?(this.dispatchEvent(goog.net.EventType.COMPLETE),this.dispatchEvent(goog.net.EventType.SUCCESS)):(this.lastErrorCode_=goog.net.ErrorCode.HTTP_ERROR,this.lastError_=this.getStatusText()+" ["+this.getStatus()+"]",this.dispatchErrors_())}finally{this.cleanUpXhr_()}}};
goog.net.XhrIo.prototype.onProgressHandler_=function(a,b){goog.asserts.assert(a.type===goog.net.EventType.PROGRESS,"goog.net.EventType.PROGRESS is of the same type as raw XHR progress.");this.dispatchEvent(goog.net.XhrIo.buildProgressEvent_(a,goog.net.EventType.PROGRESS));this.dispatchEvent(goog.net.XhrIo.buildProgressEvent_(a,b?goog.net.EventType.DOWNLOAD_PROGRESS:goog.net.EventType.UPLOAD_PROGRESS))};
goog.net.XhrIo.buildProgressEvent_=function(a,b){return{type:b,lengthComputable:a.lengthComputable,loaded:a.loaded,total:a.total}};
goog.net.XhrIo.prototype.cleanUpXhr_=function(a){if(this.xhr_){this.cleanUpTimeoutTimer_();var b=this.xhr_,c=this.xhrOptions_[goog.net.XmlHttp.OptionType.USE_NULL_FUNCTION]?goog.nullFunction:null;this.xhrOptions_=this.xhr_=null;a||this.dispatchEvent(goog.net.EventType.READY);try{b.onreadystatechange=c}catch(d){goog.log.error(this.logger_,"Problem encountered resetting onreadystatechange: "+d.message)}}};
goog.net.XhrIo.prototype.cleanUpTimeoutTimer_=function(){this.xhr_&&this.useXhr2Timeout_&&(this.xhr_[goog.net.XhrIo.XHR2_ON_TIMEOUT_]=null);goog.isNumber(this.timeoutId_)&&(goog.Timer.clear(this.timeoutId_),this.timeoutId_=null)};goog.net.XhrIo.prototype.isActive=function(){return!!this.xhr_};goog.net.XhrIo.prototype.isComplete=function(){return this.getReadyState()==goog.net.XmlHttp.ReadyState.COMPLETE};
goog.net.XhrIo.prototype.isSuccess=function(){var a=this.getStatus();return goog.net.HttpStatus.isSuccess(a)||0===a&&!this.isLastUriEffectiveSchemeHttp_()};goog.net.XhrIo.prototype.isLastUriEffectiveSchemeHttp_=function(){var a=goog.uri.utils.getEffectiveScheme(String(this.lastUri_));return goog.net.XhrIo.HTTP_SCHEME_PATTERN.test(a)};goog.net.XhrIo.prototype.getReadyState=function(){return this.xhr_?this.xhr_.readyState:goog.net.XmlHttp.ReadyState.UNINITIALIZED};
goog.net.XhrIo.prototype.getStatus=function(){try{return this.getReadyState()>goog.net.XmlHttp.ReadyState.LOADED?this.xhr_.status:-1}catch(a){return-1}};goog.net.XhrIo.prototype.getStatusText=function(){try{return this.getReadyState()>goog.net.XmlHttp.ReadyState.LOADED?this.xhr_.statusText:""}catch(a){return goog.log.fine(this.logger_,"Can not get status: "+a.message),""}};goog.net.XhrIo.prototype.getLastUri=function(){return String(this.lastUri_)};
goog.net.XhrIo.prototype.getResponseText=function(){try{return this.xhr_?this.xhr_.responseText:""}catch(a){return goog.log.fine(this.logger_,"Can not get responseText: "+a.message),""}};goog.net.XhrIo.prototype.getResponseBody=function(){try{if(this.xhr_&&"responseBody"in this.xhr_)return this.xhr_.responseBody}catch(a){goog.log.fine(this.logger_,"Can not get responseBody: "+a.message)}return null};
goog.net.XhrIo.prototype.getResponseXml=function(){try{return this.xhr_?this.xhr_.responseXML:null}catch(a){return goog.log.fine(this.logger_,"Can not get responseXML: "+a.message),null}};goog.net.XhrIo.prototype.getResponseJson=function(a){if(this.xhr_){var b=this.xhr_.responseText;a&&0==b.indexOf(a)&&(b=b.substring(a.length));return goog.json.parse(b)}};
goog.net.XhrIo.prototype.getResponse=function(){try{if(!this.xhr_)return null;if("response"in this.xhr_)return this.xhr_.response;switch(this.responseType_){case goog.net.XhrIo.ResponseType.DEFAULT:case goog.net.XhrIo.ResponseType.TEXT:return this.xhr_.responseText;case goog.net.XhrIo.ResponseType.ARRAY_BUFFER:if("mozResponseArrayBuffer"in this.xhr_)return this.xhr_.mozResponseArrayBuffer}goog.log.error(this.logger_,"Response type "+this.responseType_+" is not supported on this browser");return null}catch(a){return goog.log.fine(this.logger_,
"Can not get response: "+a.message),null}};goog.net.XhrIo.prototype.getResponseHeader=function(a){if(this.xhr_&&this.isComplete())return a=this.xhr_.getResponseHeader(a),goog.isNull(a)?void 0:a};goog.net.XhrIo.prototype.getAllResponseHeaders=function(){return this.xhr_&&this.isComplete()?this.xhr_.getAllResponseHeaders():""};
goog.net.XhrIo.prototype.getResponseHeaders=function(){for(var a={},b=this.getAllResponseHeaders().split("\r\n"),c=0;c<b.length;c++)if(!goog.string.isEmptyOrWhitespace(b[c])){var d=goog.string.splitLimit(b[c],": ",2);a[d[0]]=a[d[0]]?a[d[0]]+(", "+d[1]):d[1]}return a};goog.net.XhrIo.prototype.getStreamingResponseHeader=function(a){return this.xhr_?this.xhr_.getResponseHeader(a):null};
goog.net.XhrIo.prototype.getAllStreamingResponseHeaders=function(){return this.xhr_?this.xhr_.getAllResponseHeaders():""};goog.net.XhrIo.prototype.getLastErrorCode=function(){return this.lastErrorCode_};goog.net.XhrIo.prototype.getLastError=function(){return goog.isString(this.lastError_)?this.lastError_:String(this.lastError_)};goog.net.XhrIo.prototype.formatMsg_=function(a){return a+" ["+this.lastMethod_+" "+this.lastUri_+" "+this.getStatus()+"]"};
goog.debug.entryPointRegistry.register(function(a){goog.net.XhrIo.prototype.onReadyStateChangeEntryPoint_=a(goog.net.XhrIo.prototype.onReadyStateChangeEntryPoint_)});goog.events.EventHandler=function(a){goog.Disposable.call(this);this.handler_=a;this.keys_={}};goog.inherits(goog.events.EventHandler,goog.Disposable);goog.events.EventHandler.typeArray_=[];goog.events.EventHandler.prototype.listen=function(a,b,c,d){return this.listen_(a,b,c,d)};goog.events.EventHandler.prototype.listenWithScope=function(a,b,c,d,e){return this.listen_(a,b,c,d,e)};
goog.events.EventHandler.prototype.listen_=function(a,b,c,d,e){goog.isArray(b)||(b&&(goog.events.EventHandler.typeArray_[0]=b.toString()),b=goog.events.EventHandler.typeArray_);for(var f=0;f<b.length;f++){var g=goog.events.listen(a,b[f],c||this.handleEvent,d||!1,e||this.handler_||this);if(!g)break;this.keys_[g.key]=g}return this};goog.events.EventHandler.prototype.listenOnce=function(a,b,c,d){return this.listenOnce_(a,b,c,d)};
goog.events.EventHandler.prototype.listenOnceWithScope=function(a,b,c,d,e){return this.listenOnce_(a,b,c,d,e)};goog.events.EventHandler.prototype.listenOnce_=function(a,b,c,d,e){if(goog.isArray(b))for(var f=0;f<b.length;f++)this.listenOnce_(a,b[f],c,d,e);else{a=goog.events.listenOnce(a,b,c||this.handleEvent,d,e||this.handler_||this);if(!a)return this;this.keys_[a.key]=a}return this};goog.events.EventHandler.prototype.listenWithWrapper=function(a,b,c,d){return this.listenWithWrapper_(a,b,c,d)};
goog.events.EventHandler.prototype.listenWithWrapperAndScope=function(a,b,c,d,e){return this.listenWithWrapper_(a,b,c,d,e)};goog.events.EventHandler.prototype.listenWithWrapper_=function(a,b,c,d,e){b.listen(a,c,d,e||this.handler_||this,this);return this};goog.events.EventHandler.prototype.getListenerCount=function(){var a=0,b;for(b in this.keys_)Object.prototype.hasOwnProperty.call(this.keys_,b)&&a++;return a};
goog.events.EventHandler.prototype.unlisten=function(a,b,c,d,e){if(goog.isArray(b))for(var f=0;f<b.length;f++)this.unlisten(a,b[f],c,d,e);else if(a=goog.events.getListener(a,b,c||this.handleEvent,d,e||this.handler_||this))goog.events.unlistenByKey(a),delete this.keys_[a.key];return this};goog.events.EventHandler.prototype.unlistenWithWrapper=function(a,b,c,d,e){b.unlisten(a,c,d,e||this.handler_||this,this);return this};
goog.events.EventHandler.prototype.removeAll=function(){goog.object.forEach(this.keys_,function(a,b){this.keys_.hasOwnProperty(b)&&goog.events.unlistenByKey(a)},this);this.keys_={}};goog.events.EventHandler.prototype.disposeInternal=function(){goog.events.EventHandler.superClass_.disposeInternal.call(this);this.removeAll()};goog.events.EventHandler.prototype.handleEvent=function(a){throw Error("EventHandler.handleEvent not implemented");};goog.structs.CircularBuffer=function(a){this.nextPtr_=0;this.maxSize_=a||100;this.buff_=[]};goog.structs.CircularBuffer.prototype.add=function(a){var b=this.buff_[this.nextPtr_];this.buff_[this.nextPtr_]=a;this.nextPtr_=(this.nextPtr_+1)%this.maxSize_;return b};goog.structs.CircularBuffer.prototype.get=function(a){a=this.normalizeIndex_(a);return this.buff_[a]};goog.structs.CircularBuffer.prototype.set=function(a,b){a=this.normalizeIndex_(a);this.buff_[a]=b};
goog.structs.CircularBuffer.prototype.getCount=function(){return this.buff_.length};goog.structs.CircularBuffer.prototype.isEmpty=function(){return 0==this.buff_.length};goog.structs.CircularBuffer.prototype.clear=function(){this.nextPtr_=this.buff_.length=0};goog.structs.CircularBuffer.prototype.getValues=function(){return this.getNewestValues(this.getCount())};
goog.structs.CircularBuffer.prototype.getNewestValues=function(a){var b=this.getCount(),c=[];for(a=this.getCount()-a;a<b;a++)c.push(this.get(a));return c};goog.structs.CircularBuffer.prototype.getKeys=function(){for(var a=[],b=this.getCount(),c=0;c<b;c++)a[c]=c;return a};goog.structs.CircularBuffer.prototype.containsKey=function(a){return a<this.getCount()};goog.structs.CircularBuffer.prototype.containsValue=function(a){for(var b=this.getCount(),c=0;c<b;c++)if(this.get(c)==a)return!0;return!1};
goog.structs.CircularBuffer.prototype.getLast=function(){return 0==this.getCount()?null:this.get(this.getCount()-1)};goog.structs.CircularBuffer.prototype.normalizeIndex_=function(a){if(a>=this.buff_.length)throw Error("Out of bounds exception");return this.buff_.length<this.maxSize_?a:(this.nextPtr_+Number(a))%this.maxSize_};goog.debug.DebugWindow=function(a,b){this.identifier=a||"";this.outputBuffer=[];this.prefix_=b||"";this.savedMessages_=new goog.structs.CircularBuffer(goog.debug.DebugWindow.MAX_SAVED);this.publishHandler_=goog.bind(this.addLogRecord,this);this.formatter_=new goog.debug.HtmlFormatter(this.prefix_);this.filteredLoggers_={};this.setCapturing(!0);this.enabled_=goog.debug.DebugWindow.isEnabled(this.identifier);goog.global.setInterval(goog.bind(this.saveWindowPositionSize_,this),7500)};
goog.debug.DebugWindow.MAX_SAVED=500;goog.debug.DebugWindow.COOKIE_TIME=2592E6;goog.debug.DebugWindow.prototype.welcomeMessage="LOGGING";goog.debug.DebugWindow.prototype.enableOnSevere_=!1;goog.debug.DebugWindow.prototype.win=null;goog.debug.DebugWindow.prototype.winOpening_=!1;goog.debug.DebugWindow.prototype.isCapturing_=!1;goog.debug.DebugWindow.showedBlockedAlert_=!1;goog.debug.DebugWindow.prototype.bufferTimeout_=null;goog.debug.DebugWindow.prototype.lastCall=goog.now();
goog.debug.DebugWindow.prototype.setWelcomeMessage=function(a){this.welcomeMessage=a};goog.debug.DebugWindow.prototype.init=function(){this.enabled_&&this.openWindow_()};goog.debug.DebugWindow.prototype.isEnabled=function(){return this.enabled_};goog.debug.DebugWindow.prototype.setEnabled=function(a){(this.enabled_=a)&&this.openWindow_();this.setCookie_("enabled",a?"1":"0")};goog.debug.DebugWindow.prototype.setForceEnableOnSevere=function(a){this.enableOnSevere_=a};
goog.debug.DebugWindow.prototype.isCapturing=function(){return this.isCapturing_};goog.debug.DebugWindow.prototype.setCapturing=function(a){if(a!=this.isCapturing_){this.isCapturing_=a;var b=goog.debug.LogManager.getRoot();a?b.addHandler(this.publishHandler_):b.removeHandler(this.publishHandler_)}};goog.debug.DebugWindow.prototype.getFormatter=function(){return this.formatter_};goog.debug.DebugWindow.prototype.setFormatter=function(a){this.formatter_=a};
goog.debug.DebugWindow.prototype.addSeparator=function(){this.write_(goog.html.SafeHtml.create("hr"))};goog.debug.DebugWindow.prototype.hasActiveWindow=function(){return!!this.win&&!this.win.closed};goog.debug.DebugWindow.prototype.clear=function(){this.savedMessages_.clear();this.hasActiveWindow()&&this.writeInitialDocument()};
goog.debug.DebugWindow.prototype.addLogRecord=function(a){if(!this.filteredLoggers_[a.getLoggerName()]){var b=this.formatter_.formatRecordAsHtml(a);this.write_(b);this.enableOnSevere_&&a.getLevel().value>=goog.debug.Logger.Level.SEVERE.value&&this.setEnabled(!0)}};goog.debug.DebugWindow.prototype.write_=function(a){this.enabled_?(this.openWindow_(),this.savedMessages_.add(a),this.writeToLog_(a)):this.savedMessages_.add(a)};
goog.debug.DebugWindow.prototype.writeToLog_=function(a){this.outputBuffer.push(a);goog.global.clearTimeout(this.bufferTimeout_);750<goog.now()-this.lastCall?this.writeBufferToLog():this.bufferTimeout_=goog.global.setTimeout(goog.bind(this.writeBufferToLog,this),250)};
goog.debug.DebugWindow.prototype.writeBufferToLog=function(){this.lastCall=goog.now();if(this.hasActiveWindow()){var a=this.win.document.body,a=a&&100>=a.scrollHeight-(a.scrollTop+a.clientHeight);goog.dom.safe.documentWrite(this.win.document,goog.html.SafeHtml.concat(this.outputBuffer));this.outputBuffer.length=0;a&&this.win.scrollTo(0,1E6)}};goog.debug.DebugWindow.prototype.writeSavedMessages=function(){for(var a=this.savedMessages_.getValues(),b=0;b<a.length;b++)this.writeToLog_(a[b])};
goog.debug.DebugWindow.prototype.openWindow_=function(){if(!this.hasActiveWindow()&&!this.winOpening_){var a=this.getCookie_("dbg","0,0,800,500").split(","),b=Number(a[0]),c=Number(a[1]),d=Number(a[2]),a=Number(a[3]);this.winOpening_=!0;this.win=window.open("",this.getWindowName_(),"width="+d+",height="+a+",toolbar=no,resizable=yes,scrollbars=yes,left="+b+",top="+c+",status=no,screenx="+b+",screeny="+c);this.win||goog.debug.DebugWindow.showedBlockedAlert_||(alert("Logger popup was blocked"),goog.debug.DebugWindow.showedBlockedAlert_=
!0);this.winOpening_=!1;this.win&&this.writeInitialDocument()}};goog.debug.DebugWindow.prototype.getWindowName_=function(){return goog.userAgent.IE?this.identifier.replace(/[\s\-\.\,]/g,"_"):this.identifier};goog.debug.DebugWindow.prototype.getStyleRules=function(){return goog.html.SafeStyleSheet.fromConstant(goog.string.Const.from("*{font:normal 14px monospace;}.dbg-sev{color:#F00}.dbg-w{color:#E92}.dbg-sh{background-color:#fd4;font-weight:bold;color:#000}.dbg-i{color:#666}.dbg-f{color:#999}.dbg-ev{color:#0A0}.dbg-m{color:#990}"))};
goog.debug.DebugWindow.prototype.writeInitialDocument=function(){if(this.hasActiveWindow()){this.win.document.open();var a=goog.html.SafeHtml.create("div",{"class":"dbg-ev",style:goog.string.Const.from("text-align:center;")},goog.html.SafeHtml.concat(this.welcomeMessage,goog.html.SafeHtml.BR,goog.html.SafeHtml.create("small",{},"Logger: "+this.identifier))),a=goog.html.SafeHtml.concat(goog.html.SafeHtml.createStyle(this.getStyleRules()),goog.html.SafeHtml.create("hr"),a,goog.html.SafeHtml.create("hr"));
this.writeToLog_(a);this.writeSavedMessages()}};goog.debug.DebugWindow.prototype.setCookie_=function(a,b){var c=goog.debug.DebugWindow.getCookieKey_(this.identifier,a);document.cookie=c+"="+encodeURIComponent(b)+";path=/;expires="+(new Date(goog.now()+goog.debug.DebugWindow.COOKIE_TIME)).toUTCString()};goog.debug.DebugWindow.prototype.getCookie_=function(a,b){return goog.debug.DebugWindow.getCookieValue_(this.identifier,a,b)};
goog.debug.DebugWindow.getCookieKey_=function(a,b){return(b+a).replace(/[;=\s]/g,"_")};goog.debug.DebugWindow.getCookieValue_=function(a,b,c){a=goog.debug.DebugWindow.getCookieKey_(a,b);b=String(document.cookie);var d=b.indexOf(a+"=");return-1!=d?(c=b.indexOf(";",d),decodeURIComponent(b.substring(d+a.length+1,-1==c?b.length:c))):c||""};goog.debug.DebugWindow.isEnabled=function(a){return"1"==goog.debug.DebugWindow.getCookieValue_(a,"enabled")};
goog.debug.DebugWindow.prototype.saveWindowPositionSize_=function(){this.hasActiveWindow()&&this.setCookie_("dbg",(this.win.screenX||this.win.screenLeft||0)+","+(this.win.screenY||this.win.screenTop||0)+","+(this.win.outerWidth||800)+","+(this.win.outerHeight||500))};goog.debug.DebugWindow.prototype.addFilter=function(a){this.filteredLoggers_[a]=1};goog.debug.DebugWindow.prototype.removeFilter=function(a){delete this.filteredLoggers_[a]};
goog.debug.DebugWindow.prototype.resetBufferWithNewSize=function(a){0<a&&5E4>a&&(this.clear(),this.savedMessages_=new goog.structs.CircularBuffer(a))};goog.debug.FancyWindow=function(a,b){this.readOptionsFromLocalStorage_();goog.debug.DebugWindow.call(this,a,b);this.dh_=null};goog.inherits(goog.debug.FancyWindow,goog.debug.DebugWindow);goog.debug.FancyWindow.HAS_LOCAL_STORE=function(){try{return!!window.localStorage.getItem}catch(a){}return!1}();goog.debug.FancyWindow.LOCAL_STORE_PREFIX="fancywindow.sel.";
goog.debug.FancyWindow.prototype.writeBufferToLog=function(){this.lastCall=goog.now();if(this.hasActiveWindow()){for(var a=this.dh_.getElement("log"),b=100>=a.scrollHeight-(a.scrollTop+a.offsetHeight),c=0;c<this.outputBuffer.length;c++){var d=this.dh_.createDom("DIV","logmsg");goog.dom.safe.setInnerHtml(d,this.outputBuffer[c]);a.appendChild(d)}this.outputBuffer.length=0;this.resizeStuff_();b&&(a.scrollTop=a.scrollHeight)}};
goog.debug.FancyWindow.prototype.writeInitialDocument=function(){if(this.hasActiveWindow()){var a=this.win.document;a.open();goog.dom.safe.documentWrite(a,this.getHtml_());a.close();(goog.userAgent.IE?a.body:this.win).onresize=goog.bind(this.resizeStuff_,this);this.dh_=new goog.dom.DomHelper(a);this.dh_.getElement("openbutton").onclick=goog.bind(this.openOptions_,this);this.dh_.getElement("closebutton").onclick=goog.bind(this.closeOptions_,this);this.dh_.getElement("clearbutton").onclick=goog.bind(this.clear,
this);this.dh_.getElement("exitbutton").onclick=goog.bind(this.exit_,this);this.writeSavedMessages()}};
goog.debug.FancyWindow.prototype.openOptions_=function(){var a=goog.asserts.assert(this.dh_.getElement("optionsarea"));goog.dom.safe.setInnerHtml(a,goog.html.SafeHtml.EMPTY);for(var b=goog.debug.FancyWindow.getLoggers_(),c=this.dh_,d=0;d<b.length;d++){var e=b[d],f=e.getLevel()?e.getLevel().name:"INHERIT",e=c.createDom("DIV",{},this.getDropDown_("sel"+e.getName(),f),c.createDom("SPAN",{},e.getName()||"(root)"));a.appendChild(e)}this.dh_.getElement("options").style.display="block";return!1};
goog.debug.FancyWindow.prototype.getDropDown_=function(a,b){for(var c=this.dh_,d=c.createDom("SELECT",{id:a}),e=goog.debug.Logger.Level.PREDEFINED_LEVELS,f=0;f<e.length;f++){var g=e[f],h=c.createDom("OPTION",{},g.name);b==g.name&&(h.selected=!0);d.appendChild(h)}d.appendChild(c.createDom("OPTION",{selected:"INHERIT"==b},"INHERIT"));return d};
goog.debug.FancyWindow.prototype.closeOptions_=function(){this.dh_.getElement("options").style.display="none";for(var a=goog.debug.FancyWindow.getLoggers_(),b=this.dh_,c=0;c<a.length;c++){var d=a[c],e=b.getElement("sel"+d.getName()),e=e.options[e.selectedIndex].text;"INHERIT"==e?d.setLevel(null):d.setLevel(goog.debug.Logger.Level.getPredefinedLevel(e))}this.writeOptionsToLocalStorage_();return!1};
goog.debug.FancyWindow.prototype.resizeStuff_=function(){var a=this.dh_,b=a.getElement("log"),c=a.getElement("head");b.style.top=c.offsetHeight+"px";b.style.height=a.getDocument().body.offsetHeight-c.offsetHeight-(goog.userAgent.IE?4:0)+"px"};goog.debug.FancyWindow.prototype.exit_=function(a){this.setEnabled(!1);this.win&&this.win.close()};
goog.debug.FancyWindow.prototype.getStyleRules=function(){var a=goog.debug.FancyWindow.superClass_.getStyleRules.call(this),b=goog.html.SafeStyleSheet.fromConstant(goog.string.Const.from("html,body{height:100%;width:100%;margin:0px;padding:0px;background-color:#FFF;overflow:hidden}*{}.logmsg{border-bottom:1px solid #CCC;padding:2px;font:90% monospace}#head{position:absolute;width:100%;font:x-small arial;border-bottom:2px solid #999;background-color:#EEE;}#head p{margin:0px 5px;}#log{position:absolute;width:100%;background-color:#FFF;}#options{position:absolute;right:0px;width:50%;height:100%;border-left:1px solid #999;background-color:#DDD;display:none;padding-left: 5px;font:normal small arial;overflow:auto;}#openbutton,#closebutton{text-decoration:underline;color:#00F;cursor:pointer;position:absolute;top:0px;right:5px;font:x-small arial;}#clearbutton{text-decoration:underline;color:#00F;cursor:pointer;position:absolute;top:0px;right:80px;font:x-small arial;}#exitbutton{text-decoration:underline;color:#00F;cursor:pointer;position:absolute;top:0px;right:50px;font:x-small arial;}select{font:x-small arial;margin-right:10px;}hr{border:0;height:5px;background-color:#8c8;color:#8c8;}"));return goog.html.SafeStyleSheet.concat(a,
b)};
goog.debug.FancyWindow.prototype.getHtml_=function(){var a=goog.html.SafeHtml,b=a.create("head",{},a.concat(a.create("title",{},"Logging: "+this.identifier),a.createStyle(this.getStyleRules()))),c=a.create("body",{},a.concat(a.create("div",{id:"log",style:goog.string.Const.from("overflow:auto")}),a.create("div",{id:"head"},a.concat(a.create("p",{},a.create("b",{},"Logging: "+this.identifier)),a.create("p",{},this.welcomeMessage),a.create("span",{id:"clearbutton"},"clear"),a.create("span",{id:"exitbutton"},"exit"),
a.create("span",{id:"openbutton"},"options"))),a.create("div",{id:"options"},a.concat(a.create("big",{},a.create("b",{},"Options:")),a.create("div",{id:"optionsarea"}),a.create("span",{id:"closebutton"},"save and close")))));return a.create("html",{},a.concat(b,c))};
goog.debug.FancyWindow.prototype.writeOptionsToLocalStorage_=function(){if(goog.debug.FancyWindow.HAS_LOCAL_STORE)for(var a=goog.debug.FancyWindow.getLoggers_(),b=goog.debug.FancyWindow.getStoredKeys_(),c=0;c<a.length;c++){var d=goog.debug.FancyWindow.LOCAL_STORE_PREFIX+a[c].getName(),e=a[c].getLevel();d in b?e?window.localStorage.getItem(d)!=e.name&&window.localStorage.setItem(d,e.name):window.localStorage.removeItem(d):e&&window.localStorage.setItem(d,e.name)}};
goog.debug.FancyWindow.prototype.readOptionsFromLocalStorage_=function(){if(goog.debug.FancyWindow.HAS_LOCAL_STORE){var a=goog.debug.FancyWindow.getStoredKeys_(),b;for(b in a){var a=b.replace(goog.debug.FancyWindow.LOCAL_STORE_PREFIX,""),a=goog.debug.LogManager.getLogger(a),c=a.getLevel(),d=window.localStorage.getItem(b).toString();c&&c.toString()==d||a.setLevel(goog.debug.Logger.Level.getPredefinedLevel(d))}}};
goog.debug.FancyWindow.getStoredKeys_=function(){for(var a={},b=0,c=window.localStorage.length;b<c;b++){var d=window.localStorage.key(b);null!=d&&goog.string.startsWith(d,goog.debug.FancyWindow.LOCAL_STORE_PREFIX)&&(a[d]=!0)}return a};goog.debug.FancyWindow.getLoggers_=function(){var a=goog.object.getValues(goog.debug.LogManager.getLoggers());goog.array.sort(a,function(a,c){return goog.array.defaultCompare(a.getName(),c.getName())});return a};goog.string.StringBuffer=function(a,b){null!=a&&this.append.apply(this,arguments)};goog.string.StringBuffer.prototype.buffer_="";goog.string.StringBuffer.prototype.set=function(a){this.buffer_=""+a};goog.string.StringBuffer.prototype.append=function(a,b,c){this.buffer_+=String(a);if(null!=b)for(var d=1;d<arguments.length;d++)this.buffer_+=arguments[d];return this};goog.string.StringBuffer.prototype.clear=function(){this.buffer_=""};goog.string.StringBuffer.prototype.getLength=function(){return this.buffer_.length};
goog.string.StringBuffer.prototype.toString=function(){return this.buffer_};goog.string.format=function(a,b){var c=Array.prototype.slice.call(arguments),d=c.shift();if("undefined"==typeof d)throw Error("[goog.string.format] Template required");return d.replace(/%([0\-\ \+]*)(\d+)?(\.(\d+))?([%sfdiu])/g,function(a,b,d,h,k,l,m,n){if("%"==l)return"%";var e=c.shift();if("undefined"==typeof e)throw Error("[goog.string.format] Not enough arguments");arguments[0]=e;return goog.string.format.demuxes_[l].apply(null,arguments)})};goog.string.format.demuxes_={};
goog.string.format.demuxes_.s=function(a,b,c,d,e,f,g,h){return isNaN(c)||""==c||a.length>=Number(c)?a:a=-1<b.indexOf("-",0)?a+goog.string.repeat(" ",Number(c)-a.length):goog.string.repeat(" ",Number(c)-a.length)+a};
goog.string.format.demuxes_.f=function(a,b,c,d,e,f,g,h){d=a.toString();isNaN(e)||""==e||(d=parseFloat(a).toFixed(e));f=0>Number(a)?"-":0<=b.indexOf("+")?"+":0<=b.indexOf(" ")?" ":"";0<=Number(a)&&(d=f+d);if(isNaN(c)||d.length>=Number(c))return d;d=isNaN(e)?Math.abs(Number(a)).toString():Math.abs(Number(a)).toFixed(e);a=Number(c)-d.length-f.length;0<=b.indexOf("-",0)?d=f+d+goog.string.repeat(" ",a):(b=0<=b.indexOf("0",0)?"0":" ",d=f+goog.string.repeat(b,a)+d);return d};
goog.string.format.demuxes_.d=function(a,b,c,d,e,f,g,h){return goog.string.format.demuxes_.f(parseInt(a,10),b,c,d,0,f,g,h)};goog.string.format.demuxes_.i=goog.string.format.demuxes_.d;goog.string.format.demuxes_.u=goog.string.format.demuxes_.d;goog.format={};goog.format.JsonPrettyPrinter=function(a){this.delimiters_=a||new goog.format.JsonPrettyPrinter.TextDelimiters;this.jsonSerializer_=new goog.json.Serializer};goog.format.JsonPrettyPrinter.prototype.format=function(a){a=this.format_(a);for(var b="",c=0;c<a.length;c++)var d=a[c],b=b+(d instanceof goog.html.SafeHtml?goog.html.SafeHtml.unwrap(d):d);return b};goog.format.JsonPrettyPrinter.prototype.formatSafeHtml=function(a){return goog.html.SafeHtml.concat(this.format_(a))};
goog.format.JsonPrettyPrinter.prototype.format_=function(a){if(!goog.isDefAndNotNull(a))return[];if(goog.isString(a)){if(goog.string.isEmptyOrWhitespace(a))return[];a=goog.json.parse(a)}var b=[];this.printObject_(a,b,0);return b};
goog.format.JsonPrettyPrinter.prototype.printObject_=function(a,b,c){var d=goog.typeOf(a);switch(d){case "null":case "boolean":case "number":case "string":this.printValue_(a,d,b);break;case "array":b.push(this.delimiters_.arrayStart);var e;for(e=0;e<a.length;e++)0<e&&b.push(this.delimiters_.propertySeparator),b.push(this.delimiters_.lineBreak),this.printSpaces_(c+this.delimiters_.indent,b),this.printObject_(a[e],b,c+this.delimiters_.indent);0<e&&(b.push(this.delimiters_.lineBreak),this.printSpaces_(c,
b));b.push(this.delimiters_.arrayEnd);break;case "object":b.push(this.delimiters_.objectStart);d=0;for(e in a)a.hasOwnProperty(e)&&(0<d&&b.push(this.delimiters_.propertySeparator),b.push(this.delimiters_.lineBreak),this.printSpaces_(c+this.delimiters_.indent,b),this.printName_(e,b),b.push(this.delimiters_.nameValueSeparator,this.delimiters_.space),this.printObject_(a[e],b,c+this.delimiters_.indent),d++);0<d&&(b.push(this.delimiters_.lineBreak),this.printSpaces_(c,b));b.push(this.delimiters_.objectEnd);
break;default:this.printValue_("","unknown",b)}};goog.format.JsonPrettyPrinter.prototype.printName_=function(a,b){b.push(this.delimiters_.formatName(this.jsonSerializer_.serialize(a)))};goog.format.JsonPrettyPrinter.prototype.printValue_=function(a,b,c){a=this.jsonSerializer_.serialize(a);c.push(this.delimiters_.formatValue(a,b))};goog.format.JsonPrettyPrinter.prototype.printSpaces_=function(a,b){b.push(goog.string.repeat(this.delimiters_.space,a))};goog.format.JsonPrettyPrinter.TextDelimiters=function(){};
goog.format.JsonPrettyPrinter.TextDelimiters.prototype.space=" ";goog.format.JsonPrettyPrinter.TextDelimiters.prototype.lineBreak="\n";goog.format.JsonPrettyPrinter.TextDelimiters.prototype.objectStart="{";goog.format.JsonPrettyPrinter.TextDelimiters.prototype.objectEnd="}";goog.format.JsonPrettyPrinter.TextDelimiters.prototype.arrayStart="[";goog.format.JsonPrettyPrinter.TextDelimiters.prototype.arrayEnd="]";goog.format.JsonPrettyPrinter.TextDelimiters.prototype.propertySeparator=",";
goog.format.JsonPrettyPrinter.TextDelimiters.prototype.nameValueSeparator=":";goog.format.JsonPrettyPrinter.TextDelimiters.prototype.preName="";goog.format.JsonPrettyPrinter.TextDelimiters.prototype.postName="";goog.format.JsonPrettyPrinter.TextDelimiters.prototype.formatName=function(a){return this.preName+a+this.postName};goog.format.JsonPrettyPrinter.TextDelimiters.prototype.preValue="";goog.format.JsonPrettyPrinter.TextDelimiters.prototype.postValue="";
goog.format.JsonPrettyPrinter.TextDelimiters.prototype.formatValue=function(a,b){return goog.string.format(this.preValue,b)+a+this.postValue};goog.format.JsonPrettyPrinter.TextDelimiters.prototype.indent=2;goog.format.JsonPrettyPrinter.SafeHtmlDelimiters=function(){goog.format.JsonPrettyPrinter.TextDelimiters.call(this)};goog.inherits(goog.format.JsonPrettyPrinter.SafeHtmlDelimiters,goog.format.JsonPrettyPrinter.TextDelimiters);
goog.format.JsonPrettyPrinter.SafeHtmlDelimiters.prototype.formatName=function(a){return goog.html.SafeHtml.create("span",{"class":"goog-jsonprettyprinter-propertyname"},a)};goog.format.JsonPrettyPrinter.SafeHtmlDelimiters.prototype.formatValue=function(a,b){var c=this.getValueCssName(b);return goog.html.SafeHtml.create("span",{"class":c},a)};
goog.format.JsonPrettyPrinter.SafeHtmlDelimiters.prototype.getValueCssName=function(a){switch(a){case "null":return"goog-jsonprettyprinter-propertyvalue-null";case "boolean":return"goog-jsonprettyprinter-propertyvalue-boolean";case "number":return"goog-jsonprettyprinter-propertyvalue-number";case "string":return"goog-jsonprettyprinter-propertyvalue-string";case "array":return"goog-jsonprettyprinter-propertyvalue-array";case "object":return"goog-jsonprettyprinter-propertyvalue-object";default:return"goog-jsonprettyprinter-propertyvalue-unknown"}};goog.memoize=function(a,b){var c=b||goog.memoize.simpleSerializer;return function(){if(goog.memoize.ENABLE_MEMOIZE){var b=this||goog.global,b=b[goog.memoize.CACHE_PROPERTY_]||(b[goog.memoize.CACHE_PROPERTY_]={}),e=c(goog.getUid(a),arguments);return b.hasOwnProperty(e)?b[e]:b[e]=a.apply(this,arguments)}return a.apply(this,arguments)}};goog.memoize.ENABLE_MEMOIZE=!0;goog.memoize.clearCache=function(a){a[goog.memoize.CACHE_PROPERTY_]={}};goog.memoize.CACHE_PROPERTY_="closure_memoize_cache_";
goog.memoize.simpleSerializer=function(a,b){for(var c=[a],d=b.length-1;0<=d;--d)c.push(typeof b[d],b[d]);return c.join("\x0B")};goog.html.sanitizer={};
goog.html.sanitizer.AttributeWhitelist={"* ARIA-CHECKED":!0,"* ARIA-DESCRIBEDBY":!0,"* ARIA-DISABLED":!0,"* ARIA-LABEL":!0,"* ARIA-LABELLEDBY":!0,"* ARIA-READONLY":!0,"* ARIA-REQUIRED":!0,"* ARIA-SELECTED":!0,"* ABBR":!0,"* ACCEPT":!0,"* ACCESSKEY":!0,"* ALIGN":!0,"* ALT":!0,"* AUTOCOMPLETE":!0,"* AXIS":!0,"* BGCOLOR":!0,"* BORDER":!0,"* CELLPADDING":!0,"* CELLSPACING":!0,"* CHAROFF":!0,"* CHAR":!0,"* CHECKED":!0,"* CLEAR":!0,"* COLOR":!0,"* COLSPAN":!0,"* COLS":!0,"* COMPACT":!0,"* COORDS":!0,"* DATETIME":!0,
"* DIR":!0,"* DISABLED":!0,"* ENCTYPE":!0,"* FACE":!0,"* FRAME":!0,"* HEIGHT":!0,"* HREFLANG":!0,"* HSPACE":!0,"* ISMAP":!0,"* LABEL":!0,"* LANG":!0,"* MAXLENGTH":!0,"* METHOD":!0,"* MULTIPLE":!0,"* NOHREF":!0,"* NOSHADE":!0,"* NOWRAP":!0,"* READONLY":!0,"* REL":!0,"* REV":!0,"* ROWSPAN":!0,"* ROWS":!0,"* RULES":!0,"* SCOPE":!0,"* SELECTED":!0,"* SHAPE":!0,"* SIZE":!0,"* SPAN":!0,"* START":!0,"* SUMMARY":!0,"* TABINDEX":!0,"* TITLE":!0,"* TYPE":!0,"* VALIGN":!0,"* VALUE":!0,"* VSPACE":!0,"* WIDTH":!0};
goog.html.sanitizer.AttributeSanitizedWhitelist={"* USEMAP":!0,"* ACTION":!0,"* CITE":!0,"* HREF":!0,"* LONGDESC":!0,"* SRC":!0,"LINK HREF":!0,"* FOR":!0,"* HEADERS":!0,"* NAME":!0,"A TARGET":!0,"* CLASS":!0,"* ID":!0,"* STYLE":!0};goog.html.sanitizer.CssSanitizer={};goog.html.sanitizer.CssSanitizer.NORM_URL_REGEXP_=/[\n\f\r\"\'()*<>]/g;goog.html.sanitizer.CssSanitizer.NORM_URL_REPLACEMENTS_={"\n":"%0a","\f":"%0c","\r":"%0d",'"':"%22","'":"%27","(":"%28",")":"%29","*":"%2a","<":"%3c",">":"%3e"};goog.html.sanitizer.CssSanitizer.normalizeUrlChar_=function(a){return goog.html.sanitizer.CssSanitizer.NORM_URL_REPLACEMENTS_[a]||null};
goog.html.sanitizer.CssSanitizer.getSafeUri_=function(a,b,c){return c?(a=c(a,b))?'url("'+a.replace(goog.html.sanitizer.CssSanitizer.NORM_URL_REGEXP_,goog.html.sanitizer.CssSanitizer.normalizeUrlChar_)+'")':null:null};goog.html.sanitizer.CssSanitizer.ALLOWED_FUNCTIONS_="rgb rgba alpha rect image linear-gradient radial-gradient repeating-linear-gradient repeating-radial-gradient cubic-bezier matrix perspective rotate rotate3d rotatex rotatey steps rotatez scale scale3d scalex scaley scalez skew skewx skewy translate translate3d translatex translatey translatez".split(" ");
goog.html.sanitizer.CssSanitizer.withoutVendorPrefix_=function(a){return a.replace(/^-(?:apple|css|epub|khtml|moz|mso?|o|rim|wap|webkit|xv)-(?=[a-z])/i,"")};
goog.html.sanitizer.CssSanitizer.sanitizeProperty_=function(a,b,c){var d=goog.string.trim(b).toLowerCase();if(""===d)return null;goog.string.startsWith(d,"url(")?c?(d=goog.string.trim(b),b=goog.string.stripQuotes(d.substring(4,d.length-1),"\"'"),d=goog.html.sanitizer.CssSanitizer.getSafeUri_(b,a,c)):d=null:0<d.indexOf("(")&&(1<goog.string.countOf(d,"(")||!goog.array.contains(goog.html.sanitizer.CssSanitizer.ALLOWED_FUNCTIONS_,d.substring(0,d.indexOf("(")))||!goog.string.endsWith(d,")"))&&(d=null);
return d};
goog.html.sanitizer.CssSanitizer.sanitizeInlineStyle=function(a,b){if(!a)return goog.html.SafeStyle.EMPTY;for(var c=document.createElement("div").style,d=goog.html.sanitizer.CssSanitizer.getCssPropNames_(a),e=0;e<d.length;e++){var f=goog.html.sanitizer.CssSanitizer.withoutVendorPrefix_(d[e]);if(!goog.html.sanitizer.CssSanitizer.isDisallowedPropertyName_(f)){var g=goog.html.sanitizer.CssSanitizer.getCssValue_(a,f),g=goog.html.sanitizer.CssSanitizer.sanitizeProperty_(f,g,b);goog.html.sanitizer.CssSanitizer.setCssValue_(c,f,
g)}}return goog.html.uncheckedconversions.safeStyleFromStringKnownToSatisfyTypeContract(goog.string.Const.from("Output of CSS sanitizer"),c.cssText||"")};goog.html.sanitizer.CssSanitizer.sanitizeInlineStyleString=function(a,b){if(goog.userAgent.IE&&10>document.documentMode)return new goog.html.SafeStyle;var c=goog.html.sanitizer.CssSanitizer.createInertDocument_().createElement("DIV");c.style.cssText=a;return goog.html.sanitizer.CssSanitizer.sanitizeInlineStyle(c.style,b)};
goog.html.sanitizer.CssSanitizer.createInertDocument_=function(){var a=document;"function"===typeof HTMLTemplateElement&&(a=goog.dom.createElement("TEMPLATE").content.ownerDocument);return a.implementation.createHTMLDocument("")};goog.html.sanitizer.CssSanitizer.getCssPropNames_=function(a){return goog.isArrayLike(a)?goog.array.toArray(a):goog.object.getKeys(a)};
goog.html.sanitizer.CssSanitizer.getCssValue_=function(a,b){var c=Object.getOwnPropertyDescriptor(CSSStyleDeclaration.prototype,"getPropertyValue");return c&&a.getPropertyValue?c.value.call(a,b)||"":a.getAttribute?String(a.getAttribute(b)):""};goog.html.sanitizer.CssSanitizer.setCssValue_=function(a,b,c){if(c){var d=Object.getOwnPropertyDescriptor(CSSStyleDeclaration.prototype,"setProperty");d&&a.setProperty?d.value.call(a,b,c):a.setAttribute&&a.setAttribute(b,c)}};
goog.html.sanitizer.CssSanitizer.isDisallowedPropertyName_=function(a){return goog.string.startsWith(a,"--")||goog.string.startsWith(a,"var")};goog.html.sanitizer.TagBlacklist={APPLET:!0,AUDIO:!0,BASE:!0,BGSOUND:!0,EMBED:!0,IFRAME:!0,ISINDEX:!0,KEYGEN:!0,LAYER:!0,LINK:!0,META:!0,OBJECT:!0,SCRIPT:!0,SVG:!0,STYLE:!0,TEMPLATE:!0,VIDEO:!0};goog.html.sanitizer.TagWhitelist={A:!0,ABBR:!0,ACRONYM:!0,ADDRESS:!0,AREA:!0,ARTICLE:!0,ASIDE:!0,B:!0,BDI:!0,BDO:!0,BIG:!0,BLOCKQUOTE:!0,BR:!0,BUTTON:!0,CAPTION:!0,CENTER:!0,CITE:!0,CODE:!0,COL:!0,COLGROUP:!0,DATA:!0,DATALIST:!0,DD:!0,DEL:!0,DETAILS:!0,DFN:!0,DIALOG:!0,DIR:!0,DIV:!0,DL:!0,DT:!0,EM:!0,FIELDSET:!0,FIGCAPTION:!0,FIGURE:!0,FONT:!0,FOOTER:!0,FORM:!0,H1:!0,H2:!0,H3:!0,H4:!0,H5:!0,H6:!0,HEADER:!0,HGROUP:!0,HR:!0,I:!0,IMG:!0,INPUT:!0,INS:!0,KBD:!0,LABEL:!0,LEGEND:!0,LI:!0,MAIN:!0,MAP:!0,
MARK:!0,MENU:!0,METER:!0,NAV:!0,NOSCRIPT:!0,OL:!0,OPTGROUP:!0,OPTION:!0,OUTPUT:!0,P:!0,PRE:!0,PROGRESS:!0,Q:!0,S:!0,SAMP:!0,SECTION:!0,SELECT:!0,SMALL:!0,SOURCE:!0,SPAN:!0,STRIKE:!0,STRONG:!0,SUB:!0,SUMMARY:!0,SUP:!0,TABLE:!0,TBODY:!0,TD:!0,TEXTAREA:!0,TFOOT:!0,TH:!0,THEAD:!0,TIME:!0,TR:!0,TT:!0,U:!0,UL:!0,VAR:!0,WBR:!0};goog.html.sanitizer.HTML_SANITIZER_SUPPORTED_=!goog.userAgent.IE||10<=document.documentMode;goog.html.sanitizer.HTML_SANITIZER_TEMPLATE_SUPPORTED=!goog.userAgent.IE||null==document.documentMode;goog.html.sanitizer.HTML_SANITIZER_BOOKKEEPING_PREFIX_="data-sanitizer-";goog.html.sanitizer.HTML_SANITIZER_BOOKKEEPING_ATTR_NAME_=goog.html.sanitizer.HTML_SANITIZER_BOOKKEEPING_PREFIX_+"elem-num";
goog.html.sanitizer.HTML_SANITIZER_SANITIZED_ATTR_NAME_=goog.html.sanitizer.HTML_SANITIZER_BOOKKEEPING_PREFIX_+"original-tag";goog.html.sanitizer.HTML_SANITIZER_BLACKLISTED_TAG_=goog.html.sanitizer.HTML_SANITIZER_BOOKKEEPING_PREFIX_+"blacklisted-tag";
goog.html.sanitizer.HTML_SANITIZER_PROPERTY_DESCRIPTORS_=goog.html.sanitizer.HTML_SANITIZER_SUPPORTED_?{attributes:Object.getOwnPropertyDescriptor(Element.prototype,"attributes"),setAttribute:Object.getOwnPropertyDescriptor(Element.prototype,"setAttribute"),innerHTML:Object.getOwnPropertyDescriptor(Element.prototype,"innerHTML"),nodeName:Object.getOwnPropertyDescriptor(Node.prototype,"nodeName"),parentNode:Object.getOwnPropertyDescriptor(Node.prototype,"parentNode"),childNodes:Object.getOwnPropertyDescriptor(Node.prototype,
"childNodes"),style:Object.getOwnPropertyDescriptor(HTMLElement.prototype,"style")}:{};
goog.html.sanitizer.HtmlSanitizer=function(a){a=a||new goog.html.sanitizer.HtmlSanitizer.Builder;this.shouldSanitizeTemplateContents_=a.shouldSanitizeTemplateContents_;this.attributeHandlers_=goog.object.clone(a.attributeWhitelist_);this.tagBlacklist_=goog.object.clone(a.tagBlacklist_);this.tagWhitelist_=goog.object.clone(a.tagWhitelist_);this.shouldAddOriginalTagNames_=a.shouldAddOriginalTagNames_;goog.array.forEach(a.dataAttributeWhitelist_,function(a){goog.asserts.assert(goog.string.startsWith(a,
"data-"));goog.asserts.assert(!goog.string.startsWith(a,goog.html.sanitizer.HTML_SANITIZER_BOOKKEEPING_PREFIX_));this.attributeHandlers_["* "+a.toUpperCase()]=goog.html.sanitizer.HtmlSanitizer.cleanUpAttribute_},this)};goog.html.sanitizer.HtmlSanitizer.sanitizeUrl_=function(a){return function(b,c){var d=goog.html.sanitizer.HtmlSanitizer.cleanUpAttribute_(b,c);return(d=a(d,c))&&goog.html.SafeUrl.unwrap(d)!=goog.html.SafeUrl.INNOCUOUS_STRING?goog.html.SafeUrl.unwrap(d):null}};
goog.html.sanitizer.HtmlSanitizer.Builder=function(){this.attributeWhitelist_={};goog.array.forEach([goog.html.sanitizer.AttributeWhitelist,goog.html.sanitizer.AttributeSanitizedWhitelist],function(a){goog.array.forEach(goog.object.getKeys(a),function(a){this.attributeWhitelist_[a]=goog.html.sanitizer.HtmlSanitizer.cleanUpAttribute_},this)},this);this.attributeOverrideList_={};this.allowFormTag_=!1;this.shouldSanitizeTemplateContents_=!0;this.dataAttributeWhitelist_=[];this.tagBlacklist_={};this.tagWhitelist_=
goog.object.clone(goog.html.sanitizer.TagWhitelist);this.shouldAddOriginalTagNames_=!1;this.urlPolicy_=goog.html.sanitizer.HtmlSanitizer.defaultUrlPolicy_;this.networkRequestUrlPolicy_=goog.html.sanitizer.HtmlSanitizer.defaultNetworkRequestUrlPolicy_;this.namePolicy_=goog.html.sanitizer.HtmlSanitizer.defaultNamePolicy_;this.tokenPolicy_=goog.html.sanitizer.HtmlSanitizer.defaultTokenPolicy_;this.sanitizeCssPolicy_=void 0};
goog.html.sanitizer.HtmlSanitizer.Builder.prototype.allowDataAttributes=function(a){goog.array.extend(this.dataAttributeWhitelist_,a);return this};goog.html.sanitizer.HtmlSanitizer.Builder.prototype.allowFormTag=function(){this.allowFormTag_=!0;return this};goog.html.sanitizer.HtmlSanitizer.Builder.prototype.alsoAllowTagsPrivateDoNotAccessOrElse=function(a){goog.array.forEach(a,function(a){this.tagWhitelist_[a.toUpperCase()]=!0},this);return this};
goog.html.sanitizer.HtmlSanitizer.Builder.prototype.alsoAllowAttributesPrivateDoNotAccessOrElse=function(a){goog.array.forEach(a,function(a){goog.isString(a)&&(a={tagName:"*",attributeName:a,policy:null});var b=goog.html.sanitizer.HtmlSanitizer.attrIdentifier_(a.tagName,a.attributeName);this.attributeWhitelist_[b]=a.policy?a.policy:goog.html.sanitizer.HtmlSanitizer.cleanUpAttribute_;this.attributeOverrideList_[b]=!0},this);return this};
goog.html.sanitizer.HtmlSanitizer.Builder.prototype.keepUnsanitizedTemplateContentsPrivateDoNotAccessOrElse=function(){if(!goog.html.sanitizer.HTML_SANITIZER_TEMPLATE_SUPPORTED)throw Error("Cannot let unsanitized template contents through on browsers that do not support TEMPLATE");this.shouldSanitizeTemplateContents_=!1;return this};
goog.html.sanitizer.HtmlSanitizer.Builder.prototype.onlyAllowTags=function(a){this.tagWhitelist_={SPAN:!0};goog.array.forEach(a,function(a){a=a.toUpperCase();if(goog.html.sanitizer.TagWhitelist[a])this.tagWhitelist_[a]=!0;else throw Error("Only whitelisted tags can be allowed. See goog.html.sanitizer.TagWhitelist");},this);return this};
goog.html.sanitizer.HtmlSanitizer.Builder.prototype.onlyAllowAttributes=function(a){var b=this.attributeWhitelist_;this.attributeWhitelist_={};goog.array.forEach(a,function(a){"string"===goog.typeOf(a)&&(a={tagName:"*",attributeName:a.toUpperCase(),policy:null});var c=goog.html.sanitizer.HtmlSanitizer.attrIdentifier_(a.tagName,a.attributeName);if(!b[c])throw Error("Only whitelisted attributes can be allowed.");this.attributeWhitelist_[c]=a.policy?a.policy:goog.html.sanitizer.HtmlSanitizer.cleanUpAttribute_},
this);return this};goog.html.sanitizer.HtmlSanitizer.Builder.prototype.addOriginalTagNames=function(){this.shouldAddOriginalTagNames_=!0;return this};goog.html.sanitizer.HtmlSanitizer.Builder.prototype.withCustomNetworkRequestUrlPolicy=function(a){this.networkRequestUrlPolicy_=goog.html.sanitizer.HtmlSanitizer.sanitizeUrl_(a);return this};goog.html.sanitizer.HtmlSanitizer.Builder.prototype.withCustomUrlPolicy=function(a){this.urlPolicy_=goog.html.sanitizer.HtmlSanitizer.sanitizeUrl_(a);return this};
goog.html.sanitizer.HtmlSanitizer.Builder.prototype.withCustomNamePolicy=function(a){this.namePolicy_=a;return this};goog.html.sanitizer.HtmlSanitizer.Builder.prototype.withCustomTokenPolicy=function(a){this.tokenPolicy_=a;return this};goog.html.sanitizer.HtmlSanitizer.Builder.prototype.allowCssStyles=function(){this.sanitizeCssPolicy_=goog.html.sanitizer.HtmlSanitizer.sanitizeCssBlock_;return this};
goog.html.sanitizer.HtmlSanitizer.wrapPolicy_=function(a,b){return function(c,d,e,f){c=a(c,d,e,f);return null==c?null:b(c,d,e,f)}};goog.html.sanitizer.HtmlSanitizer.installDefaultPolicy_=function(a,b,c,d){a[c]&&!b[c]&&(a[c]=goog.html.sanitizer.HtmlSanitizer.wrapPolicy_(a[c],d))};
goog.html.sanitizer.HtmlSanitizer.Builder.prototype.build=function(){this.allowFormTag_||(this.tagBlacklist_.FORM=!0);var a=goog.html.sanitizer.HtmlSanitizer.installDefaultPolicy_;a(this.attributeWhitelist_,this.attributeOverrideList_,"* USEMAP",goog.html.sanitizer.HtmlSanitizer.sanitizeUrlFragment_);goog.array.forEach(["* ACTION","* CITE","* HREF"],function(b){a(this.attributeWhitelist_,this.attributeOverrideList_,b,this.urlPolicy_)},this);goog.array.forEach(["* LONGDESC","* SRC","LINK HREF"],function(b){a(this.attributeWhitelist_,
this.attributeOverrideList_,b,this.networkRequestUrlPolicy_)},this);goog.array.forEach(["* FOR","* HEADERS","* NAME"],function(b){a(this.attributeWhitelist_,this.attributeOverrideList_,b,goog.partial(goog.html.sanitizer.HtmlSanitizer.sanitizeName_,this.namePolicy_))},this);a(this.attributeWhitelist_,this.attributeOverrideList_,"A TARGET",goog.partial(goog.html.sanitizer.HtmlSanitizer.allowedAttributeValues_,["_blank","_self"]));a(this.attributeWhitelist_,this.attributeOverrideList_,"* CLASS",goog.partial(goog.html.sanitizer.HtmlSanitizer.sanitizeClasses_,
this.tokenPolicy_));a(this.attributeWhitelist_,this.attributeOverrideList_,"* ID",goog.partial(goog.html.sanitizer.HtmlSanitizer.sanitizeId_,this.tokenPolicy_));this.sanitizeCssPolicy_?a(this.attributeWhitelist_,this.attributeOverrideList_,"* STYLE",goog.partial(this.sanitizeCssPolicy_,this.networkRequestUrlPolicy_)):a(this.attributeWhitelist_,this.attributeOverrideList_,"* STYLE",goog.functions.NULL);return new goog.html.sanitizer.HtmlSanitizer(this)};
goog.html.sanitizer.HtmlSanitizer.defaultUrlPolicy_=goog.html.sanitizer.HtmlSanitizer.sanitizeUrl_(goog.html.SafeUrl.sanitize);goog.html.sanitizer.HtmlSanitizer.defaultNetworkRequestUrlPolicy_=goog.functions.NULL;goog.html.sanitizer.HtmlSanitizer.defaultNamePolicy_=goog.functions.NULL;goog.html.sanitizer.HtmlSanitizer.defaultTokenPolicy_=goog.functions.NULL;goog.html.sanitizer.HtmlSanitizer.attrIdentifier_=function(a,b){a||(a="*");return(a+" "+b).toUpperCase()};
goog.html.sanitizer.HtmlSanitizer.sanitizeCssBlock_=function(a,b,c,d){if(!d.cssStyle)return null;b=goog.html.SafeStyle.unwrap(goog.html.sanitizer.CssSanitizer.sanitizeInlineStyle(d.cssStyle,function(b,d){c.cssProperty=d;return a(b,c)}));return""==b?null:b};goog.html.sanitizer.HtmlSanitizer.cleanUpAttribute_=function(a,b){return goog.string.trim(a)};goog.html.sanitizer.HtmlSanitizer.allowedAttributeValues_=function(a,b,c){b=goog.string.trim(b);return goog.array.contains(a,b.toLowerCase())?b:null};
goog.html.sanitizer.HtmlSanitizer.sanitizeUrlFragment_=function(a,b){var c=goog.string.trim(a);return c&&"#"==c.charAt(0)?c:null};goog.html.sanitizer.HtmlSanitizer.sanitizeName_=function(a,b,c){b=goog.string.trim(b);return a(b,c)};goog.html.sanitizer.HtmlSanitizer.sanitizeClasses_=function(a,b,c){b=b.split(/(?:\s+)/);for(var d=[],e=0;e<b.length;e++){var f=a(b[e],c);f&&d.push(f)}return 0==d.length?null:d.join(" ")};
goog.html.sanitizer.HtmlSanitizer.sanitizeId_=function(a,b,c){b=goog.string.trim(b);return a(b,c)};goog.html.sanitizer.HtmlSanitizer.getDomTreeWalker_=function(a){var b;b=document.createElement("template");if("content"in b)b.innerHTML=a,b=b.content;else{var c=document.implementation.createHTMLDocument("x");b=c.body;c.body.innerHTML=a}return document.createTreeWalker(b,NodeFilter.SHOW_ELEMENT|NodeFilter.SHOW_TEXT,null,!1)};
goog.html.sanitizer.HtmlSanitizer.getAttributes_=function(a){var b=goog.html.sanitizer.HTML_SANITIZER_PROPERTY_DESCRIPTORS_.attributes;return b&&b.get?b.get.apply(a):a.attributes instanceof NamedNodeMap?a.attributes:null};goog.html.sanitizer.HtmlSanitizer.getAttribute_=function(a,b){var c=Element.prototype.getAttribute;return c&&a instanceof Element?c.call(a,b)||"":""};
goog.html.sanitizer.HtmlSanitizer.setAttribute_=function(a,b,c){var d=goog.html.sanitizer.HTML_SANITIZER_PROPERTY_DESCRIPTORS_.setAttribute;if(d&&d.value)try{d.value.call(a,b,c)}catch(e){if(-1==e.message.indexOf("A security problem occurred"))throw e;}};goog.html.sanitizer.HtmlSanitizer.getInnerHTML_=function(a){var b=goog.html.sanitizer.HTML_SANITIZER_PROPERTY_DESCRIPTORS_.innerHTML;return b&&b.get?b.get.apply(a):"string"==typeof a.innerHTML?a.innerHTML:""};
goog.html.sanitizer.HtmlSanitizer.getStyle_=function(a){var b=goog.html.sanitizer.HTML_SANITIZER_PROPERTY_DESCRIPTORS_.style;return a instanceof HTMLElement&&b&&b.get?b.get.apply(a):a.style instanceof CSSStyleDeclaration?a.style:null};goog.html.sanitizer.HtmlSanitizer.getNodeName_=function(a){var b=goog.html.sanitizer.HTML_SANITIZER_PROPERTY_DESCRIPTORS_.nodeName;return b&&b.get?b.get.apply(a):"string"==typeof a.nodeName?a.nodeName:"unknown"};
goog.html.sanitizer.HtmlSanitizer.getParentNode_=function(a){if(null==a)return null;var b=goog.html.sanitizer.HTML_SANITIZER_PROPERTY_DESCRIPTORS_.parentNode;return b&&b.get?b.get.apply(a):(a=a.parentNode)&&a.name&&"string"==typeof a.name&&"parentnode"==a.name.toLowerCase()?null:a};
goog.html.sanitizer.HtmlSanitizer.getChildNodes_=function(a){var b=goog.html.sanitizer.HTML_SANITIZER_PROPERTY_DESCRIPTORS_.childNodes;return goog.dom.isElement(a)&&b&&b.get?b.get.apply(a):a.childNodes instanceof NodeList?a.childNodes:null};
goog.html.sanitizer.HtmlSanitizer.prototype.sanitize=function(a){a=this.sanitizeToDomNode(a);a=(new XMLSerializer).serializeToString(a);goog.string.startsWith(a,"<span")&&(goog.string.endsWith(a,"</span>")?a=a.slice(a.indexOf(">")+1,-7):goog.string.endsWith(a,"/>")&&(a=""));return goog.html.uncheckedconversions.safeHtmlFromStringKnownToSatisfyTypeContract(goog.string.Const.from("Output of HTML sanitizer"),a)};
goog.html.sanitizer.HtmlSanitizer.prototype.sanitizeToDomNode=function(a){var b=document.createElement("span");if(!goog.html.sanitizer.HTML_SANITIZER_SUPPORTED_||!a)return b;try{var c=goog.html.sanitizer.HtmlSanitizer.getDomTreeWalker_(a)}catch(k){return b}a={};for(var d=0,e;e=c.nextNode();){d++;var f=this.sanitizeElement_(e);f.nodeType!=goog.dom.NodeType.TEXT&&(this.sanitizeAttrs_(e,f),a[d]=f,goog.html.sanitizer.HtmlSanitizer.setAttribute_(e,goog.html.sanitizer.HTML_SANITIZER_BOOKKEEPING_ATTR_NAME_,
String(d)));var g=goog.html.sanitizer.HtmlSanitizer.getNodeName_(e);goog.html.sanitizer.HTML_SANITIZER_TEMPLATE_SUPPORTED&&"template"===g.toLowerCase()&&!f.hasAttribute(goog.html.sanitizer.HTML_SANITIZER_BLACKLISTED_TAG_)&&this.processTemplateContents_(e,f);e=goog.html.sanitizer.HtmlSanitizer.getParentNode_(e);g=!1;if(goog.isNull(e))g=!0;else if("body"==goog.html.sanitizer.HtmlSanitizer.getNodeName_(e).toLowerCase()||e.nodeType==goog.dom.NodeType.DOCUMENT_FRAGMENT){var h=goog.html.sanitizer.HtmlSanitizer.getParentNode_(e);
e.nodeType==goog.dom.NodeType.DOCUMENT_FRAGMENT&&goog.isNull(h)?g=!0:"body"==goog.html.sanitizer.HtmlSanitizer.getNodeName_(e).toLowerCase()&&(h=goog.html.sanitizer.HtmlSanitizer.getParentNode_(h),goog.isNull(goog.html.sanitizer.HtmlSanitizer.getParentNode_(h))&&(g=!0))}e=g||!e?b:a[goog.html.sanitizer.HtmlSanitizer.getAttribute_(e,goog.html.sanitizer.HTML_SANITIZER_BOOKKEEPING_ATTR_NAME_)];e.content&&(e=e.content);goog.dom.isElement(f)&&f.hasAttribute(goog.html.sanitizer.HTML_SANITIZER_BLACKLISTED_TAG_)||
e.appendChild(f)}return b};
goog.html.sanitizer.HtmlSanitizer.prototype.sanitizeElement_=function(a){if(a.nodeType==goog.dom.NodeType.TEXT)return document.createTextNode(a.data);a=goog.html.sanitizer.HtmlSanitizer.getNodeName_(a).toUpperCase();var b=!1,c=!1,d;a in goog.html.sanitizer.TagBlacklist||a in this.tagBlacklist_?(d="template",c=!0):this.tagWhitelist_[a]?d=a:(d="span",b=!0);d=document.createElement(d);this.shouldAddOriginalTagNames_&&b&&goog.html.sanitizer.HtmlSanitizer.setAttribute_(d,goog.html.sanitizer.HTML_SANITIZER_SANITIZED_ATTR_NAME_,a.toLowerCase());
c&&goog.html.sanitizer.HtmlSanitizer.setAttribute_(d,goog.html.sanitizer.HTML_SANITIZER_BLACKLISTED_TAG_,"");return d};goog.html.sanitizer.HtmlSanitizer.prototype.sanitizeAttrs_=function(a,b){var c=goog.html.sanitizer.HtmlSanitizer.getAttributes_(a);if(null==c)return b;for(var d=0,e;e=c[d];d++)if(e.specified){var f=this.sanitizeAttribute_(a,e);goog.isNull(f)||goog.html.sanitizer.HtmlSanitizer.setAttribute_(b,e.name,f)}return b};
goog.html.sanitizer.HtmlSanitizer.prototype.sanitizeAttribute_=function(a,b){var c=b.name;if(goog.string.startsWith(goog.html.sanitizer.HTML_SANITIZER_BOOKKEEPING_PREFIX_,c))return null;var d=goog.html.sanitizer.HtmlSanitizer.getNodeName_(a),e=b.value,f={tagName:goog.string.trim(d).toLowerCase(),attributeName:goog.string.trim(c).toLowerCase()},g=goog.html.sanitizer.HtmlSanitizer.getContext_(f.attributeName,a),d=goog.html.sanitizer.HtmlSanitizer.attrIdentifier_(d,c);if(d in this.attributeHandlers_)return c=
this.attributeHandlers_[d],c(e,f,g);c=goog.html.sanitizer.HtmlSanitizer.attrIdentifier_(null,c);return c in this.attributeHandlers_?(c=this.attributeHandlers_[c],c(e,f,g)):null};
goog.html.sanitizer.HtmlSanitizer.prototype.processTemplateContents_=function(a,b){if(this.shouldSanitizeTemplateContents_||"template"!==b.nodeName.toLowerCase()){var c=goog.html.sanitizer.HtmlSanitizer.getInnerHTML_(a),c=this.sanitizeToDomNode(c);goog.array.forEach(c.childNodes,function(a){b.appendChild(a)})}else c=goog.asserts.assert(b.content.ownerDocument.importNode(a,!0)),c=goog.html.sanitizer.HtmlSanitizer.getChildNodes_(c),goog.array.forEach(c,function(a){b.appendChild(a)})};
goog.html.sanitizer.HtmlSanitizer.getContext_=function(a,b){var c={cssStyle:void 0};"style"==a&&(c.cssStyle=goog.html.sanitizer.HtmlSanitizer.getStyle_(b));return c};goog.html.sanitizer.HtmlSanitizer.sanitize=function(a){return(new goog.html.sanitizer.HtmlSanitizer.Builder).build().sanitize(a)};goog.html.sanitizer.unsafe={};goog.html.sanitizer.unsafe.alsoAllowTags=function(a,b,c){goog.asserts.assertString(goog.string.Const.unwrap(a),"must provide justification");goog.asserts.assert(!goog.string.isEmptyOrWhitespace(goog.string.Const.unwrap(a)),"must provide non-empty justification");return b.alsoAllowTagsPrivateDoNotAccessOrElse(c)};
goog.html.sanitizer.unsafe.alsoAllowAttributes=function(a,b,c){goog.asserts.assertString(goog.string.Const.unwrap(a),"must provide justification");goog.asserts.assert(!goog.string.isEmptyOrWhitespace(goog.string.Const.unwrap(a)),"must provide non-empty justification");return b.alsoAllowAttributesPrivateDoNotAccessOrElse(c)};
goog.html.sanitizer.unsafe.keepUnsanitizedTemplateContents=function(a,b){goog.asserts.assertString(goog.string.Const.unwrap(a),"must provide justification");goog.asserts.assert(!goog.string.isEmptyOrWhitespace(goog.string.Const.unwrap(a)),"must provide non-empty justification");return b.keepUnsanitizedTemplateContentsPrivateDoNotAccessOrElse()};goog.storage={};goog.storage.ErrorCode={INVALID_VALUE:"Storage: Invalid value was encountered",DECRYPTION_ERROR:"Storage: The value could not be decrypted"};goog.storage.Storage=function(a){this.mechanism=a};goog.storage.Storage.prototype.set=function(a,b){goog.isDef(b)?this.mechanism.set(a,goog.json.serialize(b)):this.mechanism.remove(a)};goog.storage.Storage.prototype.get=function(a){var b;try{b=this.mechanism.get(a)}catch(c){return}if(!goog.isNull(b))try{return goog.json.parse(b)}catch(c){throw goog.storage.ErrorCode.INVALID_VALUE;}};goog.storage.Storage.prototype.remove=function(a){this.mechanism.remove(a)};goog.storage.mechanism={};goog.storage.mechanism.ErrorCode={INVALID_VALUE:"Storage mechanism: Invalid value was encountered",QUOTA_EXCEEDED:"Storage mechanism: Quota exceeded",STORAGE_DISABLED:"Storage mechanism: Storage disabled"};goog.storage.mechanism.Mechanism=function(){};goog.storage.mechanism.Mechanism.prototype.set=goog.abstractMethod;goog.storage.mechanism.Mechanism.prototype.get=goog.abstractMethod;goog.storage.mechanism.Mechanism.prototype.remove=goog.abstractMethod;goog.storage.mechanism.IterableMechanism=function(){goog.storage.mechanism.Mechanism.call(this)};goog.inherits(goog.storage.mechanism.IterableMechanism,goog.storage.mechanism.Mechanism);goog.storage.mechanism.IterableMechanism.prototype.getCount=function(){var a=0;goog.iter.forEach(this.__iterator__(!0),function(b){goog.asserts.assertString(b);a++});return a};goog.storage.mechanism.IterableMechanism.prototype.__iterator__=goog.abstractMethod;
goog.storage.mechanism.IterableMechanism.prototype.clear=function(){var a=goog.iter.toArray(this.__iterator__(!0)),b=this;goog.array.forEach(a,function(a){b.remove(a)})};goog.storage.mechanism.HTML5WebStorage=function(a){goog.storage.mechanism.IterableMechanism.call(this);this.storage_=a};goog.inherits(goog.storage.mechanism.HTML5WebStorage,goog.storage.mechanism.IterableMechanism);goog.storage.mechanism.HTML5WebStorage.STORAGE_AVAILABLE_KEY_="__sak";
goog.storage.mechanism.HTML5WebStorage.prototype.isAvailable=function(){if(!this.storage_)return!1;try{return this.storage_.setItem(goog.storage.mechanism.HTML5WebStorage.STORAGE_AVAILABLE_KEY_,"1"),this.storage_.removeItem(goog.storage.mechanism.HTML5WebStorage.STORAGE_AVAILABLE_KEY_),!0}catch(a){return!1}};
goog.storage.mechanism.HTML5WebStorage.prototype.set=function(a,b){try{this.storage_.setItem(a,b)}catch(c){if(0==this.storage_.length)throw goog.storage.mechanism.ErrorCode.STORAGE_DISABLED;throw goog.storage.mechanism.ErrorCode.QUOTA_EXCEEDED;}};goog.storage.mechanism.HTML5WebStorage.prototype.get=function(a){a=this.storage_.getItem(a);if(!goog.isString(a)&&!goog.isNull(a))throw goog.storage.mechanism.ErrorCode.INVALID_VALUE;return a};goog.storage.mechanism.HTML5WebStorage.prototype.remove=function(a){this.storage_.removeItem(a)};
goog.storage.mechanism.HTML5WebStorage.prototype.getCount=function(){return this.storage_.length};goog.storage.mechanism.HTML5WebStorage.prototype.__iterator__=function(a){var b=0,c=this.storage_,d=new goog.iter.Iterator;d.next=function(){if(b>=c.length)throw goog.iter.StopIteration;var d=goog.asserts.assertString(c.key(b++));if(a)return d;d=c.getItem(d);if(!goog.isString(d))throw goog.storage.mechanism.ErrorCode.INVALID_VALUE;return d};return d};
goog.storage.mechanism.HTML5WebStorage.prototype.clear=function(){this.storage_.clear()};goog.storage.mechanism.HTML5WebStorage.prototype.key=function(a){return this.storage_.key(a)};goog.storage.mechanism.HTML5LocalStorage=function(){var a=null;try{a=window.localStorage||null}catch(b){}goog.storage.mechanism.HTML5WebStorage.call(this,a)};goog.inherits(goog.storage.mechanism.HTML5LocalStorage,goog.storage.mechanism.HTML5WebStorage);goog.storage.RichStorage=function(a){goog.storage.Storage.call(this,a)};goog.inherits(goog.storage.RichStorage,goog.storage.Storage);goog.storage.RichStorage.DATA_KEY="data";goog.storage.RichStorage.Wrapper=function(a){this[goog.storage.RichStorage.DATA_KEY]=a};goog.storage.RichStorage.Wrapper.wrapIfNecessary=function(a){return!goog.isDef(a)||a instanceof goog.storage.RichStorage.Wrapper?a:new goog.storage.RichStorage.Wrapper(a)};
goog.storage.RichStorage.Wrapper.unwrap=function(a){a=a[goog.storage.RichStorage.DATA_KEY];if(!goog.isDef(a))throw goog.storage.ErrorCode.INVALID_VALUE;return a};goog.storage.RichStorage.Wrapper.unwrapIfPossible=function(a){if(a)return goog.storage.RichStorage.Wrapper.unwrap(a)};goog.storage.RichStorage.prototype.set=function(a,b){goog.storage.RichStorage.superClass_.set.call(this,a,goog.storage.RichStorage.Wrapper.wrapIfNecessary(b))};
goog.storage.RichStorage.prototype.getWrapper=function(a){a=goog.storage.RichStorage.superClass_.get.call(this,a);if(!goog.isDef(a)||a instanceof Object)return a;throw goog.storage.ErrorCode.INVALID_VALUE;};goog.storage.RichStorage.prototype.get=function(a){return goog.storage.RichStorage.Wrapper.unwrapIfPossible(this.getWrapper(a))};goog.storage.ExpiringStorage=function(a){goog.storage.RichStorage.call(this,a)};goog.inherits(goog.storage.ExpiringStorage,goog.storage.RichStorage);goog.storage.ExpiringStorage.EXPIRATION_TIME_KEY="expiration";goog.storage.ExpiringStorage.CREATION_TIME_KEY="creation";goog.storage.ExpiringStorage.getCreationTime=function(a){return a[goog.storage.ExpiringStorage.CREATION_TIME_KEY]};goog.storage.ExpiringStorage.getExpirationTime=function(a){return a[goog.storage.ExpiringStorage.EXPIRATION_TIME_KEY]};
goog.storage.ExpiringStorage.isExpired=function(a){var b=goog.storage.ExpiringStorage.getCreationTime(a);a=goog.storage.ExpiringStorage.getExpirationTime(a);return!!a&&a<goog.now()||!!b&&b>goog.now()};
goog.storage.ExpiringStorage.prototype.set=function(a,b,c){if(b=goog.storage.RichStorage.Wrapper.wrapIfNecessary(b)){if(c){if(c<goog.now()){goog.storage.ExpiringStorage.prototype.remove.call(this,a);return}b[goog.storage.ExpiringStorage.EXPIRATION_TIME_KEY]=c}b[goog.storage.ExpiringStorage.CREATION_TIME_KEY]=goog.now()}goog.storage.ExpiringStorage.superClass_.set.call(this,a,b)};
goog.storage.ExpiringStorage.prototype.getWrapper=function(a,b){var c=goog.storage.ExpiringStorage.superClass_.getWrapper.call(this,a);if(c)if(!b&&goog.storage.ExpiringStorage.isExpired(c))goog.storage.ExpiringStorage.prototype.remove.call(this,a);else return c};goog.storage.CollectableStorage=function(a){goog.storage.ExpiringStorage.call(this,a)};goog.inherits(goog.storage.CollectableStorage,goog.storage.ExpiringStorage);
goog.storage.CollectableStorage.prototype.getExpiredKeys_=function(a,b){var c=[];goog.iter.forEach(a,function(a){var d;try{d=goog.storage.CollectableStorage.prototype.getWrapper.call(this,a,!0)}catch(f){if(f==goog.storage.ErrorCode.INVALID_VALUE){b&&c.push(a);return}throw f;}if(!goog.isDef(d))c.push(a);else if(goog.storage.ExpiringStorage.isExpired(d))c.push(a);else if(b)try{goog.storage.RichStorage.Wrapper.unwrap(d)}catch(f){if(f==goog.storage.ErrorCode.INVALID_VALUE)c.push(a);else throw f;}},this);
return c};goog.storage.CollectableStorage.prototype.collectInternal=function(a,b){var c=this.getExpiredKeys_(a,b);goog.array.forEach(c,function(a){goog.storage.CollectableStorage.prototype.remove.call(this,a)},this);return c};goog.storage.CollectableStorage.prototype.collect=function(a){this.collectInternal(this.mechanism.__iterator__(!0),a)};goog.math.Range=function(a,b){this.start=a<b?a:b;this.end=a<b?b:a};goog.math.Range.fromPair=function(a){goog.asserts.assert(2==a.length);return new goog.math.Range(a[0],a[1])};goog.math.Range.prototype.clone=function(){return new goog.math.Range(this.start,this.end)};goog.math.Range.prototype.getLength=function(){return this.end-this.start};goog.math.Range.prototype.includePoint=function(a){this.start=Math.min(this.start,a);this.end=Math.max(this.end,a)};
goog.math.Range.prototype.includeRange=function(a){this.start=Math.min(this.start,a.start);this.end=Math.max(this.end,a.end)};goog.DEBUG&&(goog.math.Range.prototype.toString=function(){return"["+this.start+", "+this.end+"]"});goog.math.Range.equals=function(a,b){return a==b?!0:a&&b?a.start==b.start&&a.end==b.end:!1};goog.math.Range.intersection=function(a,b){var c=Math.max(a.start,b.start),d=Math.min(a.end,b.end);return c<=d?new goog.math.Range(c,d):null};
goog.math.Range.hasIntersection=function(a,b){return Math.max(a.start,b.start)<=Math.min(a.end,b.end)};goog.math.Range.boundingRange=function(a,b){return new goog.math.Range(Math.min(a.start,b.start),Math.max(a.end,b.end))};goog.math.Range.contains=function(a,b){return a.start<=b.start&&a.end>=b.end};goog.math.Range.containsPoint=function(a,b){return a.start<=b&&a.end>=b};goog.math.RangeSet=function(){this.ranges_=[]};goog.DEBUG&&(goog.math.RangeSet.prototype.toString=function(){return"["+this.ranges_.join(", ")+"]"});goog.math.RangeSet.equals=function(a,b){return a==b||!!(a&&b&&goog.array.equals(a.ranges_,b.ranges_,goog.math.Range.equals))};goog.math.RangeSet.prototype.clone=function(){for(var a=new goog.math.RangeSet,b=this.ranges_.length;b--;)a.ranges_[b]=this.ranges_[b].clone();return a};
goog.math.RangeSet.prototype.add=function(a){if(!(a.end<=a.start)){a=a.clone();for(var b=0,c;c=this.ranges_[b];b++)if(a.start<=c.end){a.start=Math.min(a.start,c.start);break}for(var d=b;(c=this.ranges_[b])&&!(a.end<c.start);b++)a.end=Math.max(a.end,c.end);this.ranges_.splice(d,b-d,a)}};
goog.math.RangeSet.prototype.remove=function(a){if(!(a.end<=a.start)){for(var b=0,c;(c=this.ranges_[b])&&!(a.start<c.end);b++);if(c&&!(a.end<c.start)){var d=b;a.start>c.start&&(d++,a.end<c.end&&goog.array.insertAt(this.ranges_,new goog.math.Range(a.end,c.end),d),c.end=a.start);for(b=d;(c=this.ranges_[b])&&!(c.start=Math.max(a.end,c.start),a.end<c.end);b++);this.ranges_.splice(d,b-d)}}};
goog.math.RangeSet.prototype.contains=function(a){if(a.end<=a.start)return!1;for(var b=0,c;c=this.ranges_[b];b++)if(a.start<c.end){if(a.end>=c.start)return goog.math.Range.contains(c,a);break}return!1};goog.math.RangeSet.prototype.containsValue=function(a){for(var b=0,c;c=this.ranges_[b];b++)if(a<c.end){if(a>=c.start)return!0;break}return!1};goog.math.RangeSet.prototype.union=function(a){a=a.clone();for(var b=0,c;c=this.ranges_[b];b++)a.add(c);return a};
goog.math.RangeSet.prototype.difference=function(a){for(var b=this.clone(),c=0,d;d=a.ranges_[c];c++)b.remove(d);return b};goog.math.RangeSet.prototype.intersection=function(a){return this.isEmpty()||a.isEmpty()?new goog.math.RangeSet:this.difference(a.inverse(this.getBounds()))};
goog.math.RangeSet.prototype.slice=function(a){var b=new goog.math.RangeSet;if(a.start>=a.end)return b;for(var c=0,d;d=this.ranges_[c];c++)if(!(d.end<=a.start)){if(d.start>a.end)break;b.add(new goog.math.Range(Math.max(a.start,d.start),Math.min(a.end,d.end)))}return b};goog.math.RangeSet.prototype.inverse=function(a){var b=new goog.math.RangeSet;b.add(a);for(var c=0,d;d=this.ranges_[c];c++)if(!(a.start>=d.end)){if(a.end<d.start)break;b.remove(d)}return b};
goog.math.RangeSet.prototype.coveredLength=function(){return goog.array.reduce(this.ranges_,function(a,b){return a+b.end-b.start},0)};goog.math.RangeSet.prototype.getBounds=function(){return this.ranges_.length?new goog.math.Range(this.ranges_[0].start,goog.array.peek(this.ranges_).end):null};goog.math.RangeSet.prototype.isEmpty=function(){return 0==this.ranges_.length};goog.math.RangeSet.prototype.clear=function(){this.ranges_.length=0};
goog.math.RangeSet.prototype.__iterator__=function(a){var b=0,c=this.ranges_;a=new goog.iter.Iterator;a.next=function(){if(b>=c.length)throw goog.iter.StopIteration;return c[b++].clone()};return a};goog.structs.StringSet=function(a){this.elements_={};if(a)for(var b=0;b<a.length;b++)this.elements_[goog.structs.StringSet.encode_(a[b])]=null;goog.asserts.assertObjectPrototypeIsIntact()};goog.structs.StringSet.EMPTY_OBJECT_={};goog.structs.StringSet.encode_=function(a){return a in goog.structs.StringSet.EMPTY_OBJECT_||32==String(a).charCodeAt(0)?" "+a:a};goog.structs.StringSet.decode_=function(a){return 32==a.charCodeAt(0)?a.substr(1):a};
goog.structs.StringSet.prototype.add=function(a){this.elements_[goog.structs.StringSet.encode_(a)]=null};goog.structs.StringSet.prototype.addArray=function(a){for(var b=0;b<a.length;b++)this.elements_[goog.structs.StringSet.encode_(a[b])]=null};goog.structs.StringSet.prototype.addDifference_=function(a,b){for(var c in a.elements_)c in b.elements_||(this.elements_[c]=null)};goog.structs.StringSet.prototype.addSet=function(a){for(var b in a.elements_)this.elements_[b]=null};
goog.structs.StringSet.prototype.clear=function(){this.elements_={}};goog.structs.StringSet.prototype.clone=function(){var a=new goog.structs.StringSet;a.addSet(this);return a};goog.structs.StringSet.prototype.contains=function(a){return goog.structs.StringSet.encode_(a)in this.elements_};goog.structs.StringSet.prototype.containsArray=function(a){for(var b=0;b<a.length;b++)if(!(goog.structs.StringSet.encode_(a[b])in this.elements_))return!1;return!0};
goog.structs.StringSet.prototype.equals=function(a){return this.isSubsetOf(a)&&a.isSubsetOf(this)};goog.structs.StringSet.prototype.forEach=function(a,b){for(var c in this.elements_)a.call(b,goog.structs.StringSet.decode_(c),void 0,this)};goog.structs.StringSet.prototype.getCount=Object.keys?function(){return Object.keys(this.elements_).length}:function(){var a=0,b;for(b in this.elements_)a++;return a};
goog.structs.StringSet.prototype.getDifference=function(a){var b=new goog.structs.StringSet;b.addDifference_(this,a);return b};goog.structs.StringSet.prototype.getIntersection=function(a){var b=new goog.structs.StringSet,c;for(c in this.elements_)c in a.elements_&&(b.elements_[c]=null);return b};goog.structs.StringSet.prototype.getSymmetricDifference=function(a){var b=new goog.structs.StringSet;b.addDifference_(this,a);b.addDifference_(a,this);return b};
goog.structs.StringSet.prototype.getUnion=function(a){var b=this.clone();b.addSet(a);return b};goog.structs.StringSet.prototype.getValues=Object.keys?function(){return Object.keys(this.elements_).map(goog.structs.StringSet.decode_,this)}:function(){var a=[],b;for(b in this.elements_)a.push(goog.structs.StringSet.decode_(b));return a};goog.structs.StringSet.prototype.isDisjoint=function(a){for(var b in this.elements_)if(b in a.elements_)return!1;return!0};
goog.structs.StringSet.prototype.isEmpty=function(){for(var a in this.elements_)return!1;return!0};goog.structs.StringSet.prototype.isSubsetOf=function(a){for(var b in this.elements_)if(!(b in a.elements_))return!1;return!0};goog.structs.StringSet.prototype.isSupersetOf=function(a){return a.isSubsetOf(this)};goog.structs.StringSet.prototype.remove=function(a){a=goog.structs.StringSet.encode_(a);return a in this.elements_?(delete this.elements_[a],!0):!1};
goog.structs.StringSet.prototype.removeArray=function(a){for(var b=0;b<a.length;b++)delete this.elements_[goog.structs.StringSet.encode_(a[b])]};goog.structs.StringSet.prototype.removeSet=function(a){for(var b in a.elements_)delete this.elements_[b]};goog.structs.StringSet.prototype.__iterator__=function(a){return goog.iter.toIterator(this.getValues())};goog.exportSymbol("goog.require",goog.require);goog.exportSymbol("goog.DEBUG",goog.DEBUG);goog.exportSymbol("goog.debug.Console",goog.debug.Console);goog.exportSymbol("goog.debug.FancyWindow",goog.debug.FancyWindow);goog.exportSymbol("goog.inherits",goog.inherits);goog.exportSymbol("goog.events.EventTarget",goog.events.EventTarget);goog.exportSymbol("goog.addSingletonGetter",goog.addSingletonGetter);goog.exportSymbol("goog.format.JsonPrettyPrinter",goog.format.JsonPrettyPrinter);
goog.exportSymbol("goog.exportSymbol",goog.exportSymbol);goog.exportSymbol("goog.exportProperty",goog.exportProperty);goog.exportSymbol("goog.array",goog.array);goog.exportSymbol("goog.array.clear",goog.array.clear);goog.exportSymbol("goog.array.insert",goog.array.insert);goog.exportSymbol("goog.array.insertArrayAt",goog.array.insertArrayAt);goog.exportSymbol("goog.array.remove",goog.array.remove);goog.exportSymbol("goog.array.removeAt",goog.array.removeAt);
goog.exportSymbol("goog.math.log10Floor",goog.math.log10Floor);goog.exportSymbol("goog.math.sign",goog.math.sign);goog.exportSymbol("goog.structs.StringSet.prototype",goog.structs.StringSet.prototype);goog.exportSymbol("goog.structs.StringSet.prototype.add",goog.structs.StringSet.prototype.add);goog.exportSymbol("goog.structs.StringSet.prototype.addArray",goog.structs.StringSet.prototype.addArray);goog.exportSymbol("goog.structs.StringSet.prototype.addSet",goog.structs.StringSet.prototype.addSet);
goog.exportSymbol("goog.structs.StringSet.prototype.clear",goog.structs.StringSet.prototype.clear);goog.exportSymbol("goog.structs.StringSet.prototype.containsArray",goog.structs.StringSet.prototype.containsArray);goog.exportSymbol("goog.structs.StringSet.prototype.equals",goog.structs.StringSet.prototype.equals);goog.exportSymbol("goog.structs.StringSet.prototype.forEach",goog.structs.StringSet.prototype.forEach);goog.exportSymbol("goog.structs.StringSet.prototype.getCount",goog.structs.StringSet.prototype.getCount);
goog.exportSymbol("goog.structs.StringSet.prototype.getValues",goog.structs.StringSet.prototype.getValues);goog.exportSymbol("goog.structs.StringSet.prototype.isEmpty",goog.structs.StringSet.prototype.isEmpty);goog.exportSymbol("goog.structs.StringSet.prototype.remove",goog.structs.StringSet.prototype.remove);goog.exportSymbol("goog.structs.StringSet.prototype.removeArray",goog.structs.StringSet.prototype.removeArray);goog.exportSymbol("goog.structs.StringSet.prototype.removeSet",goog.structs.StringSet.prototype.removeSet);
goog.exportSymbol("goog.structs.Set",goog.structs.Set);goog.exportSymbol("goog.isNull",goog.isNull);goog.exportSymbol("goog.json.serialize",goog.json.serialize);goog.exportSymbol("goog.partial",goog.partial);goog.exportSymbol("goog.events.getUniqueId",goog.events.getUniqueId);goog.exportSymbol("goog.array.clone",goog.array.clone);goog.exportSymbol("goog.isDefAndNotNull",goog.isDefAndNotNull);goog.exportSymbol("goog.isString",goog.isString);goog.exportSymbol("goog.events.listen",goog.events.listen);
goog.exportSymbol("goog.string.toTitleCase",goog.string.toTitleCase);goog.exportSymbol("goog.array.sort",goog.array.sort);goog.exportSymbol("goog.structs.StringSet",goog.structs.StringSet);goog.exportSymbol("goog.dom.classlist.enable",goog.dom.classlist.enable);goog.exportSymbol("goog.Timer.callOnce",goog.Timer.callOnce);goog.exportSymbol("goog.events.KeyCodes.ENTER",goog.events.KeyCodes.ENTER);goog.exportSymbol("goog.dom.contains",goog.dom.contains);goog.exportSymbol("goog.events.KeyCodes.ESC",goog.events.KeyCodes.ESC);
goog.exportSymbol("goog.userAgent.GECKO",goog.userAgent.GECKO);goog.exportSymbol("goog.events.KeyCodes.BACKSPACE",goog.events.KeyCodes.BACKSPACE);goog.exportSymbol("goog.events.KeyCodes.DELETE",goog.events.KeyCodes.DELETE);goog.exportSymbol("goog.dom.NodeType.TEXT",goog.dom.NodeType.TEXT);goog.exportSymbol("goog.dom.NodeType.COMMENT",goog.dom.NodeType.COMMENT);goog.exportSymbol("goog.memoize",goog.memoize);goog.exportSymbol("goog.isDef",goog.isDef);goog.exportSymbol("goog.array.map",goog.array.map);
goog.exportSymbol("goog.Uri",goog.Uri);goog.exportSymbol("goog.object.getKeys",goog.object.getKeys);goog.exportSymbol("goog.object.clone",goog.object.clone);goog.exportSymbol("goog.object.extend",goog.object.extend);goog.exportSymbol("goog.object.forEach",goog.object.forEach);goog.exportSymbol("goog.array.concat",goog.array.concat);goog.exportSymbol("goog.isFunction",goog.isFunction);goog.exportSymbol("goog.string.format",goog.string.format);goog.exportSymbol("goog.array.contains",goog.array.contains);
goog.exportSymbol("goog.array.forEach",goog.array.forEach);goog.exportSymbol("goog.string.startsWith",goog.string.startsWith);goog.exportSymbol("goog.events.KeyCodes.A",goog.events.KeyCodes.A);goog.exportSymbol("goog.events.EventHandler",goog.events.EventHandler);goog.exportSymbol("goog.abstractMethod",goog.abstractMethod);goog.exportSymbol("goog.functions.lock",goog.functions.lock);goog.exportSymbol("goog.array.removeDuplicates",goog.array.removeDuplicates);goog.exportSymbol("goog.isObject",goog.isObject);
goog.exportSymbol("goog.isArray",goog.isArray);goog.exportSymbol("goog.isNumber",goog.isNumber);goog.exportSymbol("goog.array.isEmpty",goog.array.isEmpty);goog.exportSymbol("goog.Timer.clear",goog.Timer.clear);goog.exportSymbol("goog.string.isEmptySafe",goog.string.isEmptySafe);goog.exportSymbol("goog.json.parse",goog.json.parse);goog.exportSymbol("goog.structs.Map",goog.structs.Map);goog.exportSymbol("goog.object.filter",goog.object.filter);goog.exportSymbol("goog.array.equals",goog.array.equals);
goog.exportSymbol("goog.string.contains",goog.string.contains);goog.exportSymbol("goog.events.EventType.DRAGOVER",goog.events.EventType.DRAGOVER);goog.exportSymbol("goog.string.caseInsensitiveCompare",goog.string.caseInsensitiveCompare);goog.exportSymbol("goog.string.isNumeric",goog.string.isNumeric);goog.exportSymbol("goog.array.reduce",goog.array.reduce);goog.exportSymbol("goog.dom.NodeType.ELEMENT",goog.dom.NodeType.ELEMENT);goog.exportSymbol("goog.array.some",goog.array.some);
goog.exportSymbol("goog.array.moveItem",goog.array.moveItem);goog.exportSymbol("goog.object.findValue",goog.object.findValue);goog.exportSymbol("goog.array.indexOf",goog.array.indexOf);goog.exportSymbol("goog.array.defaultCompare",goog.array.defaultCompare);goog.exportSymbol("goog.string.makeSafe",goog.string.makeSafe);goog.exportSymbol("goog.array.find",goog.array.find);goog.exportSymbol("goog.dom.classlist.add",goog.dom.classlist.add);goog.exportSymbol("goog.dom.classlist.remove",goog.dom.classlist.remove);
goog.exportSymbol("goog.events.KeyCodes",goog.events.KeyCodes);goog.exportSymbol("goog.events.KeyCodes.T",goog.events.KeyCodes.T);goog.exportSymbol("goog.events.KeyCodes.F",goog.events.KeyCodes.F);goog.exportSymbol("goog.events.KeyCodes.C",goog.events.KeyCodes.C);goog.exportSymbol("goog.events.KeyCodes.UP",goog.events.KeyCodes.UP);goog.exportSymbol("goog.events.KeyCodes.DOWN",goog.events.KeyCodes.DOWN);goog.exportSymbol("goog.events.KeyCodes.LEFT",goog.events.KeyCodes.LEFT);
goog.exportSymbol("goog.events.KeyCodes.RIGHT",goog.events.KeyCodes.RIGHT);goog.exportSymbol("goog.events.KeyCodes.HOME",goog.events.KeyCodes.HOME);goog.exportSymbol("goog.events.KeyCodes.END",goog.events.KeyCodes.END);goog.exportSymbol("goog.events.KeyCodes.PAGE_UP",goog.events.KeyCodes.PAGE_UP);goog.exportSymbol("goog.events.KeyCodes.PAGE_DOWN",goog.events.KeyCodes.PAGE_DOWN);goog.exportSymbol("goog.events.BrowserEvent.MouseButton.LEFT",goog.events.BrowserEvent.MouseButton.LEFT);
goog.exportSymbol("goog.events.BrowserEvent.MouseButton.RIGHT",goog.events.BrowserEvent.MouseButton.RIGHT);goog.exportSymbol("goog.string.isEmptyOrWhitespace",goog.string.isEmptyOrWhitespace);goog.exportSymbol("goog.string.isEmpty",goog.string.isEmpty);goog.exportSymbol("goog.object.findKey",goog.object.findKey);goog.exportSymbol("goog.object.every",goog.object.every);goog.exportSymbol("goog.functions.compose",goog.functions.compose);goog.exportSymbol("goog.object.some",goog.object.some);
goog.exportSymbol("goog.events.listenOnce",goog.events.listenOnce);goog.exportSymbol("goog.async.Throttle",goog.async.Throttle);goog.exportSymbol("goog.string.caseInsensitiveContains",goog.string.caseInsensitiveContains);goog.exportSymbol("goog.array.filter",goog.array.filter);goog.exportSymbol("goog.functions.NULL",goog.functions.NULL);goog.exportSymbol("goog.userAgent.MAC",goog.userAgent.MAC);goog.exportSymbol("goog.userAgent.IE",goog.userAgent.IE);goog.exportSymbol("goog.array.removeIf",goog.array.removeIf);
goog.exportSymbol("goog.array.extend",goog.array.extend);goog.exportSymbol("goog.string.trim",goog.string.trim);goog.exportSymbol("goog.array.findIndex",goog.array.findIndex);goog.exportSymbol("goog.array.isSorted",goog.array.isSorted);goog.exportSymbol("goog.events.EventType.DRAGSTART",goog.events.EventType.DRAGSTART);goog.exportSymbol("goog.userAgent.EDGE_OR_IE",goog.userAgent.EDGE_OR_IE);goog.exportSymbol("goog.math.Range",goog.math.Range);goog.exportSymbol("goog.math.Range.containsPoint",goog.math.Range.containsPoint);
goog.exportSymbol("goog.Disposable",goog.Disposable);goog.exportSymbol("goog.Timer",goog.Timer);goog.exportSymbol("goog.array.slice",goog.array.slice);goog.exportSymbol("goog.array.insertAt",goog.array.insertAt);goog.exportSymbol("goog.Timer.TICK",goog.Timer.TICK);goog.exportSymbol("goog.array.removeAllIf",goog.array.removeAllIf);goog.exportSymbol("goog.iter.forEach",goog.iter.forEach);goog.exportSymbol("goog.object.set",goog.object.set);goog.exportSymbol("goog.object.map",goog.object.map);
goog.exportSymbol("goog.object.containsKey",goog.object.containsKey);goog.exportSymbol("goog.events.Listenable.isImplementedBy",goog.events.Listenable.isImplementedBy);goog.exportSymbol("goog.now",goog.now);goog.exportSymbol("goog.iter.toArray",goog.iter.toArray);goog.exportSymbol("goog.iter.map",goog.iter.map);goog.exportSymbol("goog.math.RangeSet",goog.math.RangeSet);goog.exportSymbol("goog.array.every",goog.array.every);goog.exportSymbol("goog.object.isEmpty",goog.object.isEmpty);
goog.exportSymbol("goog.object.getCount",goog.object.getCount);goog.exportSymbol("goog.object.getValues",goog.object.getValues);goog.exportSymbol("goog.object.get",goog.object.get);goog.exportSymbol("goog.object.remove",goog.object.remove);goog.exportSymbol("goog.object.clear",goog.object.clear);goog.exportSymbol("goog.functions.and",goog.functions.and);goog.exportSymbol("goog.events.unlistenByKey",goog.events.unlistenByKey);goog.exportSymbol("goog.uri.utils.appendPath",goog.uri.utils.appendPath);
goog.exportSymbol("goog.net.XhrIo.send",goog.net.XhrIo.send);goog.exportSymbol("goog.Uri.parse",goog.Uri.parse);goog.exportSymbol("goog.debug.Logger.Level.SEVERE",goog.debug.Logger.Level.SEVERE);goog.exportSymbol("goog.debug.LogRecord",goog.debug.LogRecord);goog.exportSymbol("goog.debug.catchErrors",goog.debug.catchErrors);goog.exportSymbol("goog.debug.ErrorHandler",goog.debug.ErrorHandler);goog.exportSymbol("goog.debug.LogManager.getRoot",goog.debug.LogManager.getRoot);
goog.exportSymbol("goog.debug.Logger.Level.getPredefinedLevel",goog.debug.Logger.Level.getPredefinedLevel);goog.exportSymbol("goog.debug.Logger.Level.ALL",goog.debug.Logger.Level.ALL);goog.exportSymbol("goog.log.getLogger",goog.log.getLogger);goog.exportSymbol("goog.debug.Logger.Level.SHOUT",goog.debug.Logger.Level.SHOUT);goog.exportSymbol("goog.debug.Logger.Level.WARNING",goog.debug.Logger.Level.WARNING);goog.exportSymbol("goog.debug.Logger.Level.INFO",goog.debug.Logger.Level.INFO);
goog.exportSymbol("goog.debug.Logger.Level.CONFIG",goog.debug.Logger.Level.CONFIG);goog.exportSymbol("goog.debug.Logger.Level.FINE",goog.debug.Logger.Level.FINE);goog.exportSymbol("goog.debug.Logger.Level.FINER",goog.debug.Logger.Level.FINER);goog.exportSymbol("goog.debug.Logger.Level.FINEST",goog.debug.Logger.Level.FINEST);goog.exportSymbol("goog.net.ErrorCode.TIMEOUT",goog.net.ErrorCode.TIMEOUT);goog.exportSymbol("goog.net.ErrorCode.HTTP_ERROR",goog.net.ErrorCode.HTTP_ERROR);
goog.exportSymbol("goog.net.ErrorCode.OFFLINE",goog.net.ErrorCode.OFFLINE);goog.exportSymbol("goog.events.Event",goog.events.Event);goog.exportSymbol("goog.color.hexToRgb",goog.color.hexToRgb);goog.exportSymbol("goog.object.unsafeClone",goog.object.unsafeClone);goog.exportSymbol("goog.storage.mechanism.HTML5LocalStorage",goog.storage.mechanism.HTML5LocalStorage);goog.exportSymbol("goog.storage.CollectableStorage",goog.storage.CollectableStorage);goog.exportSymbol("goog.iter.filter",goog.iter.filter);
goog.exportSymbol("goog.string.splitLimit",goog.string.splitLimit);goog.exportSymbol("goog.string.stripQuotes",goog.string.stripQuotes);goog.exportSymbol("goog.string.remove",goog.string.remove);goog.exportSymbol("goog.string.StringBuffer",goog.string.StringBuffer);goog.exportSymbol("goog.string.getRandomString",goog.string.getRandomString);goog.exportSymbol("goog.asserts.assert",goog.asserts.assert);goog.exportSymbol("goog.object.createSet",goog.object.createSet);
goog.exportSymbol("goog.iter.toIterator",goog.iter.toIterator);goog.exportSymbol("goog.iter.Iterator",goog.iter.Iterator);goog.exportSymbol("goog.iter.StopIteration",goog.iter.StopIteration);goog.exportSymbol("goog.events.EventType.MOUSEMOVE",goog.events.EventType.MOUSEMOVE);goog.exportSymbol("goog.events.EventType.MOUSEDOWN",goog.events.EventType.MOUSEDOWN);goog.exportSymbol("goog.events.EventType.MOUSEUP",goog.events.EventType.MOUSEUP);goog.exportSymbol("goog.events.EventType.KEYDOWN",goog.events.EventType.KEYDOWN);
goog.exportSymbol("goog.events.EventType.KEYUP",goog.events.EventType.KEYUP);goog.exportSymbol("goog.dom.getWindow",goog.dom.getWindow);goog.exportSymbol("goog.events.EventType.BLUR",goog.events.EventType.BLUR);goog.exportSymbol("goog.events.EventType.FOCUS",goog.events.EventType.FOCUS);goog.exportSymbol("goog.object.equals",goog.object.equals);goog.exportSymbol("goog.object.setIfUndefined",goog.object.setIfUndefined);goog.exportSymbol("goog.math.nearlyEquals",goog.math.nearlyEquals);
goog.exportSymbol("goog.functions.fail",goog.functions.fail);goog.exportSymbol("goog.string.newLineToBr",goog.string.newLineToBr);goog.exportSymbol("goog.memoize.apply",goog.memoize.apply);goog.exportSymbol("goog.html.SafeHtml.unwrap",goog.html.SafeHtml.unwrap);goog.exportSymbol("goog.html.sanitizer.HtmlSanitizer.Builder",goog.html.sanitizer.HtmlSanitizer.Builder);goog.exportSymbol("goog.string.Const.from",goog.string.Const.from);goog.exportSymbol("goog.html.sanitizer.unsafe.alsoAllowTags",goog.html.sanitizer.unsafe.alsoAllowTags);
goog.exportSymbol("goog.html.sanitizer.unsafe.alsoAllowAttributes",goog.html.sanitizer.unsafe.alsoAllowAttributes);goog.exportSymbol("goog.html.SafeUrl.sanitize",goog.html.SafeUrl.sanitize);goog.exportSymbol("goog.html.SafeUrl.unwrap",goog.html.SafeUrl.unwrap);goog.exportSymbol("goog.html.SafeUrl.INNOCUOUS_STRING",goog.html.SafeUrl.INNOCUOUS_STRING);goog.exportSymbol("goog.uri.utils.split",goog.uri.utils.split);goog.exportSymbol("goog.uri.utils.ComponentIndex.SCHEME",goog.uri.utils.ComponentIndex.SCHEME);
goog.exportSymbol("goog.uri.utils.ComponentIndex.DOMAIN",goog.uri.utils.ComponentIndex.DOMAIN);goog.exportSymbol("goog.uri.utils.ComponentIndex.PATH",goog.uri.utils.ComponentIndex.PATH);goog.exportSymbol("goog.math.standardDeviation.apply",goog.math.standardDeviation.apply);goog.exportSymbol("goog.Uri.QueryData.createFromMap",goog.Uri.QueryData.createFromMap);goog.exportSymbol("goog.net.XhrIo",goog.net.XhrIo);goog.exportSymbol("goog.net.XhrIo.ResponseType.ARRAY_BUFFER",goog.net.XhrIo.ResponseType.ARRAY_BUFFER);
goog.exportSymbol("goog.net.EventType.COMPLETE",goog.net.EventType.COMPLETE);goog.exportSymbol("goog.dom.classlist.contains",goog.dom.classlist.contains);goog.exportSymbol("goog.dom.getActiveElement",goog.dom.getActiveElement);goog.exportSymbol("goog.dom.getDocument",goog.dom.getDocument);goog.exportSymbol("goog.dom.getElementsByClass",goog.dom.getElementsByClass);goog.exportSymbol("goog.isArrayLike",goog.isArrayLike);goog.exportSymbol("goog.events.BrowserEvent",goog.events.BrowserEvent);
goog.exportSymbol("goog.dom.isElement",goog.dom.isElement);goog.exportSymbol("goog.dom.getParentElement",goog.dom.getParentElement);goog.exportSymbol("goog.events.KeyCodes.SINGLE_QUOTE",goog.events.KeyCodes.SINGLE_QUOTE);goog.exportSymbol("goog.array.peek",goog.array.peek);goog.exportSymbol("goog.array.rotate",goog.array.rotate);goog.exportSymbol("goog.style.setElementShown",goog.style.setElementShown);goog.exportSymbol("goog.math.average",goog.math.average);
goog.exportSymbol("goog.math.standardDeviation",goog.math.standardDeviation);goog.exportSymbol("goog.string.urlEncode",goog.string.urlEncode);goog.exportSymbol("goog.events.EventType.CLICK",goog.events.EventType.CLICK);goog.exportSymbol("goog.events.EventType.KEYPRESS",goog.events.EventType.KEYPRESS);goog.exportSymbol("goog.dom.setTextContent",goog.dom.setTextContent);
    this.goog.global = this;
    return this.goog;
}).bind(self || this)));

